import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import {
  selectDashboardHeader,
  selectDataForQuickReport,
  selectForDashboardRangePicker,
  selectGraphDomains,
  selectProjectInfo
} from "../../state/openDatxSlice";
import { getUser } from "../../state/sessionSlice";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import {
  PdfExportComponent,
  HeaderData,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import QuickReport from "../MicroComponents/QuickReport";
import dayjs from "dayjs";

interface IProps {
  fileId: string;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}

export interface QuickReportData {
  angleWarning?: boolean;
  accWarning?: boolean;
  tempWarning?: boolean;
  rhWarning?: boolean;
  pressureWarning?: boolean;
  recParams?: VMRecordingParameters;
}

const QuickReportDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const data = useSelector(selectDataForQuickReport(props.fileId));
  const projectInfo = useSelector(selectProjectInfo(props.fileId));
  const domains = useSelector(selectGraphDomains(props.fileId));
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const { dataDomain, timezone } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);

  const utcOffsetStr = createUtcOffsetStr(timezone);

  const { isPrinting, startPrint, finishPrint } = usePrintHook();
  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      printAction: startPrint
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const headerData: HeaderData = {
    reportType: "QuickReport",
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <MoveableCard
      title={t("QuickReport")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={props.fileId}
    >
      {isPrinting && (
        <PrintableComponent
          WrappedComponent={QuickReport}
          props={{ data }}
          reportPrintDone={finishPrint}
          header={headerData}
          orientation="landscape"
        />
      )}
      {isExporting && (
        <PdfExportComponent
          ComponentBody={QuickReport}
          props={{ data }}
          type="html"
          name="quick-report.pdf"
          header={headerData}
          reportExportDone={finishExport}
        />
      )}
      <QuickReport data={data} />
    </MoveableCard>
  );
};

export default QuickReportDashboardCard;
