import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Dropdown,
  MenuProps,
  Progress,
  Space,
  Input,
  InputNumber,
  Row,
  Select
} from "antd";
import {
  grey,
  purple,
  red,
  cyan,
  blue,
  lime,
  geekblue
} from "@ant-design/colors";
import { PlusOutlined } from "@ant-design/icons";
import { SmallText } from "../Common/CommonFonts";
import { selectRecordingParameters } from "../../state/openParxSlice";
import {
  getHoursFromDaysHoursMinutes,
  getScheduleDuration
} from "../../helpers/paramsHelper";
import { selectTargetDevice } from "../../state/paramsPageSlice";
import { firmwareEnergyConsumptionConstants } from "../../constants/batteryConfigurationPresets";
import { getSystemInfoVersionAsString } from "../../helpers/versionHelper";
import { interpretSystemInfoHasFeatures } from "../../helpers/parsers/parseSystemInfoHelper";
import { size } from "../../helpers/pageHelper";
import { isNull, isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import BatteryType from "../../models/BatteryType";
import {
  appendBattery,
  resetBatteries,
  selectBatteries
} from "../../state/persistantStateSlice";
import { selectDefaultBattery } from "../../state/sessionSlice";
import StandardModal from "../Common/StandardModal";
const { Option } = Select;

const ParamsBuilderStats: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const batteryConfigurations = useSelector(selectBatteries);
  if (isUndefined(batteryConfigurations)) {
    dispatch(resetBatteries());
  }

  /* State for adding new battery type */
  const [newBatteryModalOpen, setNewBatteryModalOpen] = useState(false);
  const [newBatteryName, setNewBatteryName] = useState<string>();
  const [newBatteryVolt, setNewBatteryVolt] = useState<number>();
  const [newBatteryMah, setNewBatteryMah] = useState<number>();
  const [newBatteryForm, setNewBatteryForm] = useState<string>();
  const [newBatteryType, setNewBatteryType] = useState<string>();
  const newBattery = {
    name: newBatteryName ?? "",
    volt: newBatteryVolt ?? 0,
    mah: newBatteryMah ?? 0,
    form: newBatteryForm ?? "",
    type: newBatteryType ?? ""
  };
  const notFullyFilled =
    !newBatteryName ||
    !newBatteryVolt ||
    !newBatteryMah ||
    !newBatteryForm ||
    !newBatteryType;

  const addCustomBattery = (battery: BatteryType) => {
    dispatch(appendBattery(battery));
    setNewBatteryName(undefined);
    setNewBatteryVolt(undefined);
    setNewBatteryMah(undefined);
    setNewBatteryForm(undefined);
    setNewBatteryType(undefined);
    setNewBatteryModalOpen(false);
  };

  const defaultBattery = useSelector(selectDefaultBattery);
  const vmRecParams = useSelector(selectRecordingParameters);
  const targetDevice = useSelector(selectTargetDevice);
  const { powerSource } = targetDevice;
  const firmwareVersion: string = getSystemInfoVersionAsString(targetDevice);
  const batteries = useSelector(selectBatteries);
  const [selectedBatteryName, setSelectedBatteryName] = useState(
    defaultBattery ?? batteries[0].name
  );
  const currentBattery =
    batteries.find((b) => b.name === selectedBatteryName) ?? batteries[0];
  const totalDuration = getScheduleDuration(vmRecParams.scheduleBlocks);
  const energyConstants = firmwareEnergyConsumptionConstants;
  const deviceFeatures = interpretSystemInfoHasFeatures(
    targetDevice.hasFeatures
  );
  const maxUsage = 0.85;
  const statSteps = 50;
  let externalRequired = false;

  // Get the last bit of powerSource. If it is 1, the device uses 4 AA batteries, and if it is 3 return 2 C batteries.
  // This is the battry form of the connected device.
  let batteryForm = "";
  switch (powerSource & 0x000f) {
    case 1:
      batteryForm = "4xAA";
      break;
    case 3:
      batteryForm = "2xC";
      break;
    default:
      batteryForm = "";
  }

  let supplyVoltage = 0;
  let efficiency = 0;
  switch (currentBattery.form) {
    case "4xAA":
      supplyVoltage = 4 * currentBattery.volt;
      if (supplyVoltage > 5.5 && supplyVoltage < 6.5) {
        efficiency = 0.8; // 80% efficiency
      } else {
        efficiency = 0.7; // 70% efficiency
      }

      break;
    case "2xC":
      supplyVoltage = 2 * currentBattery.volt;
      if (supplyVoltage > 6.5 && supplyVoltage < 7.5) {
        efficiency = 0.8; // 80% efficiency
      } else {
        efficiency = 0.7; // 70% efficiency
      }
      break;
  }

  // Currents in mA for different functions in different firmware versions
  const currents =
    energyConstants.find((f) => f.fwver === firmwareVersion) ??
    energyConstants[0];
  const accPerHour = vmRecParams.AccParams.useAcc ? 1 / 6 : 0;
  const anglePerHour = vmRecParams.AngleParams.useAngle ? 1 / 3 : 0;
  const healthPerHour = 1 / 24;
  const transformation = 3.3 / (supplyVoltage * efficiency);

  const colors = {
    base: grey[5],
    acc: lime[6],
    temp: red[6],
    rh: blue[7],
    angle: purple[4],
    gps: geekblue[4],
    lte: cyan[6]
  };

  let totalUsage = 0;
  const totalCapacity = currentBattery.mah * maxUsage;

  const createStats = () => {
    // One stat-item equals 2% of the battery capacity
    const stats: string[] = [];

    // Base
    const baseCurrent = currents.base * totalDuration * transformation;
    totalUsage += baseCurrent;

    // Health
    const healthCurrent =
      currents.healthReg *
      currents.healthRegDuration *
      healthPerHour *
      totalDuration *
      transformation;
    totalUsage += healthCurrent;

    const baseAndHealthCurrents = baseCurrent + healthCurrent;
    const baseTimes = (baseAndHealthCurrents / totalCapacity) * statSteps;
    for (let i = 0; i < baseTimes; i++) {
      stats.push(colors.base);
    }

    // Acceleration
    if (vmRecParams.AccParams.useAcc) {
      const accCurrent =
        currents.accReg *
        currents.accRegDuration *
        accPerHour *
        totalDuration *
        transformation;
      totalUsage += accCurrent;
      const times = (accCurrent / totalCapacity) * statSteps;
      for (let i = 0; i < times; i++) {
        stats.push(colors.acc);
      }
    }

    // Temperature
    if (vmRecParams.TempParams.useTemp) {
      const tempInterval = getHoursFromDaysHoursMinutes(
        vmRecParams.TempParams.intervalDays,
        vmRecParams.TempParams.intervalHours,
        vmRecParams.TempParams.intervalMinutes
      );
      if (tempInterval !== 0) {
        const tempCurrent =
          currents.tempReg *
          currents.tempRegDuration *
          (1 / tempInterval) *
          totalDuration *
          transformation;
        totalUsage += tempCurrent;
        const times = (tempCurrent / totalCapacity) * statSteps;
        for (let i = 0; i < times; i++) {
          stats.push(colors.temp);
        }
      }
    }

    // Humidity
    if (vmRecParams.RhParams.useRh) {
      const rhInterval = getHoursFromDaysHoursMinutes(
        vmRecParams.RhParams.intervalDays,
        vmRecParams.RhParams.intervalHours,
        vmRecParams.RhParams.intervalMinutes
      );
      if (rhInterval !== 0) {
        const rhCurrent =
          currents.rhReg *
          currents.rhRegDuration *
          (1 / rhInterval) *
          totalDuration *
          transformation;
        totalUsage += rhCurrent;
        const times = (rhCurrent / totalCapacity) * statSteps;
        for (let i = 0; i < times; i++) {
          stats.push(colors.rh);
        }
      }
    }

    // Angle
    if (vmRecParams.AngleParams.useAngle) {
      const angleCurrent =
        currents.angleReg *
        currents.angleRegDuration *
        anglePerHour *
        totalDuration *
        transformation;
      totalUsage += angleCurrent;
      const times = (angleCurrent / totalCapacity) * statSteps;
      for (let i = 0; i < times; i++) {
        stats.push(colors.angle);
      }
    }

    // GPS
    if (deviceFeatures.DF_GPS) {
      let gpsCurrents = 0;
      vmRecParams.scheduleBlocks.forEach((block) => {
        if (!block.GpsParams.flightModeGps && !block.flightMode) {
          if (block.GpsParams.useGpsInterval) {
            const gpsInterval = getHoursFromDaysHoursMinutes(
              block.GpsParams.intervalDays,
              block.GpsParams.intervalHours,
              block.GpsParams.intervalMinutes
            );
            if (gpsInterval !== 0) {
              gpsCurrents +=
                currents.gpsReg *
                currents.gpsRegDuration *
                (1 / gpsInterval) *
                block.blockDuration *
                transformation;
            }
          }
          if (block.GpsParams.alwaysOn) {
            externalRequired = true;
          }
        }
      });
      totalUsage += gpsCurrents;
      const gpsTimes = (gpsCurrents / totalCapacity) * statSteps;
      for (let i = 0; i < gpsTimes; i++) {
        stats.push(colors.gps);
      }
    }

    // LTE
    if (deviceFeatures.DF_LTE) {
      const { LteBands } = vmRecParams.LteParams;
      let lteCurrents = 0;
      vmRecParams.scheduleBlocks.forEach((block) => {
        if (!block.LteParams.flightModeLte && !block.flightMode) {
          if (block.LteParams.useLteInterval) {
            const lteInterval = getHoursFromDaysHoursMinutes(
              block.LteParams.intervalDays,
              block.LteParams.intervalHours,
              block.LteParams.intervalMinutes
            );
            if (lteInterval !== 0) {
              if (LteBands.Enable2G) {
                lteCurrents +=
                  currents.lte2GReg *
                  currents.lteRegDuration *
                  (1 / lteInterval) *
                  block.blockDuration *
                  transformation;
              } else if (LteBands.Enable3G) {
                lteCurrents +=
                  currents.lte3GReg *
                  currents.lteRegDuration *
                  (1 / lteInterval) *
                  block.blockDuration *
                  transformation;
              } else if (LteBands.Enable4G) {
                lteCurrents +=
                  currents.lte4GReg *
                  currents.lteRegDuration *
                  (1 / lteInterval) *
                  block.blockDuration *
                  transformation;
              } else if (LteBands.Enable5G) {
                lteCurrents +=
                  currents.lte5GReg *
                  currents.lteRegDuration *
                  (1 / lteInterval) *
                  block.blockDuration *
                  transformation;
              } else if (LteBands.Enable6G) {
                lteCurrents +=
                  currents.lte6GReg *
                  currents.lteRegDuration *
                  (1 / lteInterval) *
                  block.blockDuration *
                  transformation;
              }
              if (block.LteParams.GpsRequired) {
                lteCurrents +=
                  currents.gpsReg *
                  currents.gpsRegDuration *
                  (1 / lteInterval) *
                  block.blockDuration *
                  transformation;
              }
            }
          }
          if (block.LteParams.alwaysOn) {
            externalRequired = true;
          }
        }
      });
      totalUsage += lteCurrents;
      const lteTimes = (lteCurrents / totalCapacity) * statSteps;
      for (let i = 0; i < lteTimes; i++) {
        stats.push(colors.lte);
      }
    }

    return stats;
  };

  const statistics = createStats();
  const statsStatus =
    statistics && totalUsage > totalCapacity ? "exception" : undefined;

  const items: MenuProps["items"] = batteries
    .filter((battery) => batteryForm === "" || batteryForm === battery.form)
    .map((battery) => ({
      label: `${battery.name} (${battery.form} ${battery.mah} mAh)`,
      key: battery.name
    }));

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setSelectedBatteryName(key);
  };

  if (externalRequired) {
    return <SmallText>{t("ExternalPowerSourceRequired")}</SmallText>;
  } else {
    return (
      <Col style={{ display: "flex", alignItems: "center" }}>
        <Dropdown menu={{ items, onClick }}>
          <SmallText style={{ fontSize: 14, marginRight: size.s2 }}>
            {currentBattery.name}
          </SmallText>
        </Dropdown>
        <Button
          onClick={() => setNewBatteryModalOpen(true)}
          shape="circle"
          icon={<PlusOutlined />}
        />
        <Space
          style={{ marginLeft: size.m1 }}
          size={0}
          direction="vertical"
          align="start"
        >
          <Progress
            size="small"
            percent={Math.round((100 * totalUsage) / totalCapacity)}
            steps={statSteps}
            strokeColor={statistics}
            status={statsStatus}
          />
          <SmallText style={{ fontSize: 12 }}>
            {statistics && statistics.includes(colors.base) && (
              <span style={{ color: colors.base }}>Base </span>
            )}
            {statistics && statistics.includes(colors.acc) && (
              <span style={{ color: colors.acc }}>Acc </span>
            )}
            {statistics && statistics.includes(colors.temp) && (
              <span style={{ color: colors.temp }}>Temp </span>
            )}
            {statistics && statistics.includes(colors.rh) && (
              <span style={{ color: colors.rh }}>Rh </span>
            )}
            {statistics && statistics.includes(colors.angle) && (
              <span style={{ color: colors.angle }}>Angle </span>
            )}
            {statistics && statistics.includes(colors.gps) && (
              <span style={{ color: colors.gps }}>GPS </span>
            )}
            {statistics && statistics.includes(colors.lte) && (
              <span style={{ color: colors.lte }}>LTE </span>
            )}
          </SmallText>
        </Space>

        <StandardModal
          title={t("AddBattery")}
          open={newBatteryModalOpen}
          onCancel={() => setNewBatteryModalOpen(false)}
          onOk={() => addCustomBattery(newBattery)}
          okButtonProps={{ disabled: notFullyFilled }}
          closable={true}
          zIndex={1045}
          width={400}
        >
          <Row align="middle" style={{ marginBottom: size.m1 }}>
            <Col span={8}>
              <SmallText>{t("Name")}</SmallText>
            </Col>
            <Col span={16}>
              <Input
                style={{ width: "100%" }}
                value={newBatteryName}
                onChange={(e) => setNewBatteryName(e.target.value)}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: size.m1 }}>
            <Col span={8}>
              <SmallText>{t("VoltageV")}</SmallText>
            </Col>
            <Col span={16}>
              <InputNumber
                min={0}
                max={24}
                style={{ width: "100%" }}
                value={newBatteryVolt}
                onChange={(value) =>
                  isNull(value) ? {} : setNewBatteryVolt(value)
                }
              />
            </Col>
          </Row>

          <Row align="middle" style={{ marginBottom: size.m1 }}>
            <Col span={8}>
              <SmallText>{t("CapacitymAh")}</SmallText>
            </Col>
            <Col span={16}>
              <InputNumber
                min={0}
                max={300000}
                style={{ width: "100%" }}
                value={newBatteryMah}
                onChange={(value) =>
                  isNull(value) ? {} : setNewBatteryMah(value)
                }
              />
            </Col>
          </Row>

          <Row align="middle" style={{ marginBottom: size.m1 }}>
            <Col span={8}>
              <SmallText>{t("Form")}</SmallText>
            </Col>
            <Col span={16}>
              <Select
                style={{ width: "100%" }}
                value={newBatteryForm}
                onChange={(value) => setNewBatteryForm(value)}
              >
                <Option key={"2xC"} value={"2xC"}>
                  C
                </Option>
                <Option key={"4xAA"} value={"4xAA"}>
                  AA
                </Option>
              </Select>
            </Col>
          </Row>

          <Row align="middle">
            <Col span={8}>
              <SmallText>{t("Type")}</SmallText>
            </Col>
            <Col span={16}>
              <Select
                style={{ width: "100%" }}
                value={newBatteryType}
                onChange={(value) => setNewBatteryType(value)}
              >
                <Option key={"alkaline"} value={"Alkaline"}>
                  {t("Alkaline")}
                </Option>
                <Option key={"litium"} value={"Litium"}>
                  {t("Litium")}
                </Option>
              </Select>
            </Col>
          </Row>
        </StandardModal>
      </Col>
    );
  }
};

export default ParamsBuilderStats;
