import React from "react";
import { Row, Col, Space, Tooltip } from "antd";
import { selectDashboardHeader } from "../../state/openDatxSlice";
import { FileOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DashboardHeaderExtraInfoMenu from "./DashboardHeaderExtraInfoMenu";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { SmallText } from "../Common/CommonFonts";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import ExportReportButton from "./ExportReportButton";
import UploadDatxButton from "../MicroComponents/UploadDatxButton";

export interface VMDashboardHeader {
  systemInfo?: GeneralSystemInfo;
  recordingParameters: VMRecordingParameters;
  serialNumber: string;
  fileName: string;
  filePath: string;
}

interface IProps {
  fileId: string;
  style?: React.CSSProperties;
}
const GeneralInfo = (props: {
  serialNumber: string;
  fileName: string;
  filePath: string;
}) => {
  const { t } = useTranslation();
  const windowWidth = window.innerWidth;
  const textStyle: React.CSSProperties = {
    margin: 0,
    width: windowWidth > 1075 ? 150 : 120,
    textAlign: "left"
  };

  const flexContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline"
  };

  const flexItemStyle: React.CSSProperties = { flex: 1 };

  return (
    <Space direction="horizontal" align="center" size="middle">
      <FileOutlined style={{ fontSize: "25px" }} />
      <div style={flexContainerStyle}>
        <div style={flexItemStyle}>
          <SmallText strong style={textStyle}>
            {t("SerialNumber")}:
          </SmallText>
        </div>

        <div style={flexItemStyle}>
          <SmallText strong style={textStyle}>
            {t("Filename")}:
          </SmallText>
        </div>
      </div>

      {/* ghost element: Create extra space between descriptors and data */}
      {windowWidth > 1010 ? <div /> : undefined}

      <div style={flexContainerStyle}>
        <div style={flexItemStyle}>
          <SmallText style={textStyle}>{props.serialNumber}</SmallText>
        </div>
        <div style={flexItemStyle}>
          <Tooltip placement="bottom" title={props.filePath}>
            <SmallText ellipsis style={textStyle}>
              {props.fileName}
            </SmallText>
          </Tooltip>
        </div>
      </div>
    </Space>
  );
};

const DashboardHeader: React.FC<IProps> = (props) => {
  const { systemInfo, recordingParameters, serialNumber, fileName, filePath } =
    useSelector(selectDashboardHeader(props.fileId))!;

  return (
    <Row align="middle" justify="space-between" style={{ height: "100%" }}>
      {/* left content */}
      <Col>
        <GeneralInfo
          serialNumber={serialNumber}
          fileName={fileName}
          filePath={filePath}
        />
      </Col>

      {/* right content */}
      <Col>
        <Space direction="horizontal">
          {LicenseAccess("ADN", <UploadDatxButton fileId={props.fileId} />)}
          {LicenseAccess("ACJ", <ExportReportButton fileId={props.fileId} />)}
          <DashboardHeaderExtraInfoMenu
            recParams={recordingParameters}
            systemInfo={systemInfo}
            fileName={fileName}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default DashboardHeader;
