import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  VictoryAxis,
  VictoryBar,
  VictoryBrushContainer,
  VictoryChart,
  VictoryClipContainer,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictorySelectionContainer,
  VictoryTheme
} from "victory";
import { CallbackArgs, VictoryTickStyleObject } from "victory-core";
import { iconColors } from "../../constants/colors";
import { Nullable } from "../../helpers/datasetHelper";
import {
  PrimaryGraphZoomDomain,
  ZoomDimension,
  createPerformantPrimaryGraphData,
  dateAxisFormater,
  formatYData,
  generateTickValues,
  getNormalizerFunctions,
  getOverviewGraphData,
  graphAxisScaleSelector,
  toLowHighOrder,
  yAxisDefaultNormalizedValues,
  yAxisFormater
} from "../../helpers/graphHelper";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import {
  ChannelTypes,
  selectCompareGraphDomains
} from "../../state/compareGraphsSlice";
import {
  DataFilterStates,
  YAxisDomain,
  YAxisLowHigh,
  YAxisTickCount
} from "../../state/openDatxSlice";
import {
  selectGlobalGraphScale,
  selectGraphAxisTickCountTime
} from "../../state/persistantStateSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle,
  selectTemperatureScale
} from "../../state/sessionSlice";
import commonColors from "../../styles/commonColors";
import { commonAxisStyle } from "../../styles/graphStylesCommon";
import {
  TempScaleSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";

export interface PrimaryGraphData {
  timezone: string;
  dataFilters: DataFilterStates;
  yAxisDomain: YAxisDomain;
  yAxisTickCount: YAxisTickCount;
  recParams?: VMRecordingParameters;
  accData?: {
    xAcc?: GraphDataPoint[];
    yAcc?: GraphDataPoint[];
    zAcc?: GraphDataPoint[];
  };
  tempData?: NullableGraphDataPoint[];
  rhData?: NullableGraphDataPoint[];
}

/** A data point in primary graph. The 0th element in y will always hold the
 * measured value while the rest is for potential extra data */
export interface GraphDataPoint {
  x: Date;
  y: number[];
}

/** Used for line data where part of the dataset can be hidden by setting y to
 * null. This will cause a "break" in the data */
export interface NullableGraphDataPoint {
  x: Date;
  y: number[] | null;
}

/** Mini interface that can be combined with Victory component props to get
 * correct datum type */
interface TypedDatumProps {
  datum?: GraphDataPoint;
}

interface IProps {
  fileId: string;
  data: PrimaryGraphData;
  width: number;
  height: number;
  /** Disables interactivity with the component */
  previewMode?: boolean;
  zoomDomain: { x: [number, number]; y: [number, number] };
  activeDomain: [number, number];
  zoomDimension?: ZoomDimension;
  handleZoom: (domain: PrimaryGraphZoomDomain) => void;
  maxAccDataPoints?: number;

  channelTypes: ChannelTypes[];
}
const ComparePrimaryGraph: React.FC<IProps> = (props) => {
  /** Data to be shown in graph. Filtered for performance reasons */
  const graphData = useMemo(
    () =>
      createPerformantPrimaryGraphData(
        props.data,
        [
          dayjs.unix(props.zoomDomain.x[0]).toDate(),
          dayjs.unix(props.zoomDomain.x[1]).toDate()
        ],
        props.maxAccDataPoints ?? 600,
        props.width
      ),
    [props.data, props.zoomDomain.x, props.maxAccDataPoints, props.width]
  );

  /** Data to be shown in graph. Filtered for performance reasons */
  const overviewData = useMemo(
    () => getOverviewGraphData(props.data),
    [props.data]
  );

  const tempScale = useSelector(selectTemperatureScale);
  const globalScale = useSelector(selectGlobalGraphScale);

  const timeScaleSteps = useSelector(selectGraphAxisTickCountTime);

  const domains = useSelector(selectCompareGraphDomains(props.fileId));

  let xOffsetYaxis = [40, 85, 130];
  const getYAxisOffset = () => xOffsetYaxis.pop();

  //Variables determining which data the graph should render

  const channelType = props.channelTypes;

  const shouldRenderTempDataMultiValue =
    graphData.dataFilters.temp.dataToggle.isActive &&
    channelType.includes("temp") &&
    !isEmpty(graphData?.tempData);

  const shouldRenderTempDataSingleValue =
    graphData.dataFilters.temp.dataToggle.isActive &&
    channelType.includes("temp") &&
    graphData?.tempData?.length === 1;

  const shouldRenderRhDataMultiValue =
    graphData.dataFilters.rh.dataToggle.isActive &&
    channelType.includes("rh") &&
    !isEmpty(graphData?.rhData);

  const shouldRenderRhDataSingleValue =
    graphData.dataFilters.rh.dataToggle.isActive &&
    channelType.includes("rh") &&
    graphData?.rhData?.length === 1;

  /** logical values in graph. Ranges from -1 to 1 */
  const scale = {
    low: yAxisDefaultNormalizedValues[0],
    high: yAxisDefaultNormalizedValues[1]
  };

  const accTickCount = graphAxisScaleSelector(
    graphData.yAxisTickCount.accTick,
    globalScale.acc.count,
    globalScale.acc.toggle,
    globalScale.toggle
  );
  const tempTickCount = graphAxisScaleSelector(
    graphData.yAxisTickCount.tempTick,
    globalScale.temp.count,
    globalScale.temp.toggle,
    globalScale.toggle
  );
  const rhTickCount = graphAxisScaleSelector(
    graphData.yAxisTickCount.rhTick,
    globalScale.rh.count,
    globalScale.rh.toggle,
    globalScale.toggle
  );

  /** Callback used to style y-axis grid */
  const gridStyleCallback = (tick: number) =>
    tick === 0 || tick === scale.low ? "black" : "#ECEFF1";

  const accDomainUnit = () => {
    const domain = graphData.yAxisDomain.acc ?? yAxisDefaultNormalizedValues;
    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        domain[0],
        globalScale.acc.min,
        globalScale.acc.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        domain[1],
        globalScale.acc.max,
        globalScale.acc.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  const tempDomainUnit = () => {
    const domain = graphData.yAxisDomain.temp ?? yAxisDefaultNormalizedValues;
    const newDomain: YAxisLowHigh = [
      TemperatureToScale(
        graphAxisScaleSelector(
          domain[0],
          globalScale.temp.min,
          globalScale.temp.toggle,
          globalScale.toggle
        ),
        tempScale
      ),
      TemperatureToScale(
        graphAxisScaleSelector(
          domain[1],
          globalScale.temp.max,
          globalScale.temp.toggle,
          globalScale.toggle
        ),
        tempScale
      )
    ];
    return newDomain;
  };

  const rhDomainUnit = () => {
    const domain = graphData.yAxisDomain.rh ?? yAxisDefaultNormalizedValues;
    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        domain[0],
        globalScale.rh.min,
        globalScale.rh.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        domain[1],
        globalScale.rh.max,
        globalScale.rh.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  // Normalizer funtions is needed since this chart may contain data with
  // different scaling. Normalizing is needed to enable e.g. rh% (0 - 100) and acc
  // (-16 - 16) to be in the same chart
  const {
    normalizeValue: normalizeAccValue,
    denormalizeValue: denormalizeAccValue
  } = getNormalizerFunctions(accDomainUnit());

  const {
    normalizeValue: normalizeTempValue,
    denormalizeValue: denormalizeTempValue
  } = getNormalizerFunctions(tempDomainUnit(), scale);

  const {
    normalizeValue: normalizeRhValue,
    denormalizeValue: denormalizeRhValue
  } = getNormalizerFunctions(rhDomainUnit(), scale);

  const chartPaddingLeft = 130;
  const chartPaddingRight = 24;

  /** Describes how much a pixel is worth in ms */
  const pixelWorthMs =
    (props.width - chartPaddingLeft - chartPaddingRight) /
    (props.zoomDomain.x[1] - props.zoomDomain.x[0]) /
    1000;

  /** Utility function used for barwidth. Returns a width in pixels based on
   * acceleration duration */
  const getAccBarWidth = (v: Omit<CallbackArgs, "datum"> & TypedDatumProps) => {
    const duration = v?.datum?.y?.[1];

    if (!duration) return 0;

    const widthInPixels = duration * pixelWorthMs;

    // If the width is below 1px, it will be barely visible
    if (widthInPixels < 1.5) return 1.5;

    return widthInPixels;
  };

  interface IBounds {
    x: [Date, Date];
    y: [number, number];
  }
  const handleZoomSelection = (bounds: IBounds) => {
    // If the user select the area in the y-axis, we will get a x0 value outside
    // the zoom-domain. The lines below fixes that
    const lowerDomain = props.zoomDomain.x[0];
    const fixedBoundsX: [Date, Date] =
      bounds.x[0].getTime() < lowerDomain
        ? [dayjs.unix(lowerDomain).toDate(), bounds.x[1]]
        : bounds.x;

    //Note: If user selects from right to left, the first value will be larger
    //than the seconds value, causing a bugged selection. {toLowHighOrder} solves that
    const newXDomain: [Date, Date] = toLowHighOrder(fixedBoundsX);
    const newYDomain: [number, number] = [-1, 1];

    props.handleZoom?.({ x: newXDomain, y: newYDomain });
  };

  const formatYValueAcc = (y: number) =>
    formatYData(y, accDomainUnit(), normalizeAccValue);

  const formatYValueTemp = (y: Nullable<number>) =>
    y === null
      ? 0
      : formatYData(
          TemperatureToScale(y, tempScale),
          tempDomainUnit(),
          normalizeTempValue
        );

  const formatYValueRh = (y: Nullable<number>) =>
    y === null ? 0 : formatYData(y, rhDomainUnit(), normalizeRhValue);

  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  const graphContent = (overview?: boolean) => {
    const d = overview ? overviewData : graphData;
    const xAccData = d.accData?.xAcc ?? [];
    const yAccData = d.accData?.yAcc ?? [];
    const zAccData = d.accData?.zAcc ?? [];
    const tempData = d.tempData ?? [];
    const rhData = d.rhData ?? [];
    return (
      <VictoryGroup>
        {/* data from x-acc */}
        {channelType.includes("x") && xAccData.length > 0 && (
          <VictoryBar
            data={xAccData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.xAccA } }}
            alignment="start"
            barWidth={overview ? 2 : getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* data from y-acc */}
        {channelType.includes("y") && yAccData.length > 0 && (
          <VictoryBar
            data={yAccData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.yAccA } }}
            alignment="start"
            barWidth={overview ? 2 : getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* data from z-acc */}
        {channelType.includes("z") && zAccData.length > 0 && (
          <VictoryBar
            data={zAccData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.zAccA } }}
            alignment="start"
            barWidth={overview ? 2 : getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* data from temperature */}
        {shouldRenderTempDataMultiValue && (
          <VictoryLine
            name="temp"
            data={tempData}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueTemp(datum.y?.[0] ?? null)
            }
            style={{ data: { stroke: iconColors.temp } }}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
        {shouldRenderTempDataSingleValue && (
          <VictoryScatter
            name="temp1Data"
            data={tempData}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueTemp(datum.y?.[0] ?? null)
            }
            size={6}
            style={{ data: { fill: iconColors.temp } }}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* data from rh */}
        {shouldRenderRhDataMultiValue && (
          <VictoryLine
            name="rh"
            data={rhData}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueRh(datum.y?.[0] ?? null)
            }
            style={{ data: { stroke: iconColors.rh } }}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
        {shouldRenderRhDataSingleValue && (
          <VictoryScatter
            name="rh1Data"
            data={rhData}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueRh(datum.y?.[0] ?? null)
            }
            size={5}
            style={{ data: { fill: iconColors.rh } }}
            groupComponent={<VictoryClipContainer />}
          />
        )}
      </VictoryGroup>
    );
  };

  const xZoomDomain: [Date, Date] = [
    dayjs.unix(domains.zoomDomain.x[0]).toDate(),
    dayjs.unix(domains.zoomDomain.x[1]).toDate()
  ];

  const xActiveDomain: [Date, Date] = [
    dayjs.unix(domains.activeDomain[0]).toDate(),
    dayjs.unix(domains.activeDomain[1]).toDate()
  ];

  const strokeSize = 4;
  const { axisGray } = commonColors;

  const customTickStyle: VictoryTickStyleObject = {
    size: strokeSize,
    stroke: axisGray
  };

  const accActive =
    channelType.includes("x") ||
    channelType.includes("y") ||
    channelType.includes("z");

  return (
    <>
      <VictoryChart
        name="primary"
        width={props.width}
        height={props.height}
        scale={{ x: "time" }}
        padding={{
          top: 18,
          left: chartPaddingLeft,
          right: chartPaddingRight,
          bottom: 26
        }}
        domain={{
          x: xZoomDomain,
          y: props.zoomDomain.y
        }}
        theme={VictoryTheme.material}
        containerComponent={
          <VictorySelectionContainer
            selectionDimension="x"
            allowSelection
            onSelection={(points, bounds: any, props) => {
              handleZoomSelection(bounds as any);
            }}
          />
        }
      >
        {/* x-axis(based on time, show no values, based on origo) */}
        <VictoryAxis
          tickFormat={[]}
          tickCount={timeScaleSteps}
          style={{
            axis: {
              stroke: axisGray,
              strokeWidth: accActive ? 1.5 : 0
            },
            ticks: {
              stroke: axisGray,
              strokeWidth: accActive ? 1 : 0
            }
          }}
          axisValue={0}
        />

        {/* x-axis(based on time, show values) */}
        <VictoryAxis
          tickFormat={(t, _i, arr) =>
            dateAxisFormater(
              t,
              arr,
              timezoneSelector(
                graphData.timezone,
                timezoneState,
                timezoneToggle
              )
            )
          }
          fixLabelOverlap
          tickCount={timeScaleSteps === 0 ? undefined : timeScaleSteps}
          axisValue={props.zoomDomain.y[0]}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: "black",
              strokeWidth: 1.5
            },
            grid: {
              stroke: axisGray,
              strokeWidth: 0
            }
          }}
        />

        {accActive ? (
          <VictoryAxis
            dependentAxis
            offsetX={getYAxisOffset()}
            label={"g"}
            fixLabelOverlap
            style={{
              axis: { stroke: axisGray, strokeWidth: 2 },
              ...commonAxisStyle,
              grid: {
                stroke: (tick: any) => gridStyleCallback(tick)
              },
              ticks: customTickStyle
            }}
            axisLabelComponent={
              <VictoryLabel y={14} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(accTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizeAccValue(t), accDomainUnit())
            }
          />
        ) : undefined}

        {/* y-axis(temperature) */}
        {graphData.dataFilters.temp.dataToggle.isActive &&
          channelType.includes("temp") && (
            <VictoryAxis
              name="tempAxis"
              dependentAxis
              offsetX={getYAxisOffset()}
              label={TempScaleSymbol(tempScale)}
              fixLabelOverlap
              style={{
                ...commonAxisStyle,
                axis: { stroke: iconColors.temp, strokeWidth: 2 },
                grid: {
                  stroke: (tick: any) => gridStyleCallback(tick)
                },
                ticks: customTickStyle
              }}
              axisLabelComponent={
                <VictoryLabel y={14} dx={39} textAnchor="middle" />
              }
              tickValues={generateTickValues(tempTickCount)}
              tickFormat={(t) =>
                yAxisFormater(denormalizeTempValue(t), tempDomainUnit())
              }
            />
          )}

        {/* y-axis(rh) */}
        {graphData.dataFilters.rh.dataToggle.isActive &&
          channelType.includes("rh") && (
            <VictoryAxis
              name="rhAxis"
              dependentAxis
              offsetX={getYAxisOffset()}
              label="Rh(%)"
              fixLabelOverlap
              style={{
                ...commonAxisStyle,
                axis: { stroke: iconColors.rh, strokeWidth: 2 },
                grid: {
                  stroke: (tick: any) => gridStyleCallback(tick)
                },
                ticks: customTickStyle
              }}
              axisLabelComponent={
                <VictoryLabel y={14} dx={39} textAnchor="middle" />
              }
              tickValues={generateTickValues(rhTickCount)}
              tickFormat={(t) =>
                yAxisFormater(denormalizeRhValue(t), rhDomainUnit())
              }
            />
          )}

        {graphContent()}
      </VictoryChart>

      <VictoryChart
        name="overview"
        width={props.width}
        height={30}
        scale={{ x: "time" }}
        padding={{
          top: 0,
          left: chartPaddingLeft,
          right: chartPaddingRight,
          bottom: 0
        }}
        theme={VictoryTheme.material}
        domain={{
          x: xActiveDomain,
          y: props.zoomDomain.y
        }}
        containerComponent={
          <VictoryBrushContainer
            brushDimension="x"
            brushDomain={{
              x: xZoomDomain,
              y: [-1, 1]
            }}
            onBrushDomainChangeEnd={(bounds: any) =>
              handleZoomSelection(bounds as any)
            }
          />
        }
      >
        <VictoryAxis
          tickCount={timeScaleSteps}
          tickFormat={[]}
          axisValue={0}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: axisGray,
              strokeWidth: accActive ? 1.5 : 0
            },
            ticks: {
              stroke: axisGray,
              strokeWidth: accActive ? 1 : 0
            }
          }}
        />
        {graphContent(true)}
      </VictoryChart>
    </>
  );
};

export default React.memo(ComparePrimaryGraph);
