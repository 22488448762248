import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import { Row, Space } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { iconColors } from "../../constants/colors";
import { PrimaryGraphZoomDomain } from "../../helpers/graphHelper";
import { channelName } from "../../pages/ComparePage";
import {
  ChannelTypes,
  GraphTypes,
  selectCompareGraphDomains,
  selectDataForCompareGraph,
  selectDvaDataForCompareGraphs,
  setDvaZoomDomain,
  CompareStateZoomDomain,
  resetDvaZoomDomain,
  resetZoomDomain,
  setZoomDomain,
  closeFile
} from "../../state/compareGraphsSlice";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { LiftedCard } from "../Common/CommonCards";
import { SmallText } from "../Common/CommonFonts";
import { GraphEmpty } from "../MicroComponents/GraphEmpty";
import CompareDvaGraph from "./CompareDvaGraph";
import ComparePrimaryGraph from "./ComparePrimaryGraph";
import { size } from "../../helpers/pageHelper";

interface IProps {
  fileId: string;
  filePath: string;
  graphTypes?: GraphTypes;
  channelTypes: ChannelTypes[];
  width: number;
  height: number;
}

const CompareGraphsInline: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { fileId, filePath, graphTypes, channelTypes } = props;

  const primaryGraphData = useSelector(selectDataForCompareGraph(fileId));
  const domains = useSelector(selectCompareGraphDomains(fileId));

  const { dvaData, activeKey, zoomDomain, dvaZoomDimension, timezone } =
    useSelector(selectDvaDataForCompareGraphs(props.fileId));

  const handleZoomDomain = useCallback(
    (newDomain: PrimaryGraphZoomDomain) => {
      const domain: CompareStateZoomDomain = {
        x: [newDomain.x[0].getTime() / 1000, newDomain.x[1].getTime() / 1000],
        y: newDomain.y
      };
      dispatch(setZoomDomain({ fileId: props.fileId, newDomain: domain }));
    },
    [dispatch, props.fileId]
  );

  const handleDvaZoomDomain = useCallback(
    (newDomain: CompareStateZoomDomain) => {
      dispatch(setDvaZoomDomain({ fileId: props.fileId, newDomain }));
    },
    [dispatch, props.fileId]
  );

  const selectedDvaData = dvaData.find((e) => {
    if (activeKey) {
      return e.start.toString() + e.end.toString() === activeKey.toString();
    } else {
      return undefined;
    }
  });

  const colors = {
    x: iconColors.xAcc,
    y: iconColors.yAcc,
    z: iconColors.zAcc,
    temp: iconColors.temp,
    rh: iconColors.rh
  };

  return (
    <LiftedCard
      title={
        <Row align="middle" justify="space-between">
          <div>
            <SmallText strong style={{ fontSize: 16 }}>
              {filePath}
            </SmallText>
            <SmallText> {t("Channels")}: </SmallText>
            {channelTypes.map((type, index) => {
              return (
                <>
                  <SmallText style={{ color: colors[type] }}>
                    {channelName[type]}
                  </SmallText>
                  {index + 1 < channelTypes.length ? ", " : ""}
                </>
              );
            })}
          </div>
          <div>
            <Space>
              <NormalButtonSmall
                icon={<UndoOutlined />}
                onClick={() =>
                  graphTypes === "mainGraph"
                    ? dispatch(resetZoomDomain({ fileId: props.fileId }))
                    : dispatch(resetDvaZoomDomain({ fileId: props.fileId }))
                }
                title={t("ResetZoomLevel")}
              />
              <NormalButtonSmall
                icon={<CloseOutlined />}
                onClick={() => dispatch(closeFile(props.fileId))}
                title={t("RemoveFromComparison")}
              />
            </Space>
          </div>
        </Row>
      }
      styles={{
        body: {
          padding: size.s1
        }
      }}
      size="small"
    >
      {graphTypes === "mainGraph" && (
        <ComparePrimaryGraph
          fileId={fileId}
          data={primaryGraphData}
          width={props.width}
          height={props.height}
          zoomDomain={domains.zoomDomain}
          activeDomain={domains.activeDomain}
          channelTypes={channelTypes}
          handleZoom={handleZoomDomain}
        />
      )}
      {graphTypes === "dvaGraph" && selectedDvaData && (
        <CompareDvaGraph
          fileId={fileId}
          width={props.width}
          height={props.height}
          dvaDataBlock={selectedDvaData}
          timezone={timezone}
          zoomDomain={zoomDomain}
          handleZoom={handleDvaZoomDomain}
          resetZoom={() => dispatch(resetDvaZoomDomain({ fileId }))}
          zoomDimension={dvaZoomDimension}
          channelTypes={channelTypes}
        />
      )}
      {graphTypes === "dvaGraph" && !selectedDvaData && <GraphEmpty />}
    </LiftedCard>
  );
};

export default CompareGraphsInline;
