import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectDataForExternalIOGraph,
  selectProjectInfo,
  selectTimezoneById,
  selectDashboardHeader,
  selectGraphDomains,
  selectForDashboardRangePicker
} from "../../state/openDatxSlice";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import ExternalIOTable from "./ExternalIOTable";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import {
  PdfExportComponent,
  HeaderData,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import dayjs from "dayjs";
import { getUser } from "../../state/sessionSlice";

interface IProps {
  fileId: string;
  dragHandle: string;
  width: number;
  height: number;
  closeCard: ExtraButtonItem;
}

const ExternalIOReportsCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const externalIOGraphData = useSelector(
    selectDataForExternalIOGraph(props.fileId)
  );
  const timezone = useSelector(selectTimezoneById(props.fileId));

  // For export/print header
  const projectInfo = useSelector(selectProjectInfo(props.fileId));
  const domains = useSelector(selectGraphDomains(props.fileId));
  const { dataDomain } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;
  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);
  const utcOffsetStr = createUtcOffsetStr(timezone);

  const { isPrinting, startPrint, finishPrint } = usePrintHook();
  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      printAction: startPrint
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const headerData: HeaderData = {
    reportType: "extIO",
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <MoveableCard
      title={t("extIO")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={props.fileId}
    >
      {isPrinting && (
        <PrintableComponent
          WrappedComponent={ExternalIOTable}
          props={{ data: externalIOGraphData, timezone }}
          reportPrintDone={finishPrint}
          header={headerData}
          orientation="portrait"
        />
      )}
      {isExporting && (
        <PdfExportComponent
          ComponentBody={ExternalIOTable}
          props={{ data: externalIOGraphData, timezone }}
          type="html"
          name="external_io_data.pdf"
          header={headerData}
          reportExportDone={finishExport}
        />
      )}
      <ExternalIOTable data={externalIOGraphData} timezone={timezone} />
    </MoveableCard>
  );
};
export default ExternalIOReportsCard;
