import { notification, Typography } from "antd";
import { isUndefined } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllLicensePricesQuery,
  useMobitronGetCompaniesQuery,
  useRemoveCompanyDiscountMutation
} from "../../state/cargologRestApi";
import {
  closeRemoveLicenseDiscountModal,
  modalsState
} from "../../state/modalsSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import StandardModal from "../Common/StandardModal";

const { Text } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

type RemoveCompanyDiscountType = { companyLicensePriceDiscountId: number };

const RemoveLicenseDiscountModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedLicenseDiscount = modals.licenseDiscountObject;
  const { data: allCompanies } = useMobitronGetCompaniesQuery();
  const { data: allLicensePrices } = useGetAllLicensePricesQuery();

  let companyName = allCompanies
    ?.filter((company) => company.id === selectedLicenseDiscount?.companyId)
    .map((companyName) => companyName.name);
  let licenseName = allLicensePrices
    ?.filter(
      (license) => license.id === selectedLicenseDiscount?.licensePriceId
    )
    .map((licenseName) => licenseName.name);

  const [removeCompanyDiscount, requestStatus] =
    useRemoveCompanyDiscountMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleRemoveCompanyDiscount = async (
    request: RemoveCompanyDiscountType
  ) => {
    const result: any = await removeCompanyDiscount(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToRemoveDiscount"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("SuccessfullyRemovedDiscount") });
      dispatch(closeRemoveLicenseDiscountModal());
    }
  };

  const onFinish = () => {
    if (!isUndefined(selectedLicenseDiscount)) {
      const request = {
        companyLicensePriceDiscountId: selectedLicenseDiscount?.id
      };
      handleRemoveCompanyDiscount(request);
    }
  };

  return (
    <>
      <StandardModal
        title={t("DeleteDiscount")}
        open={modals.isRemoveLicenseDiscountModalOpen}
        onCancel={() => dispatch(closeRemoveLicenseDiscountModal())}
        closable={true}
        zIndex={1045}
        destroyOnClose={true}
        footer={[
          <NormalButton
            htmlType="submit"
            onClick={() => dispatch(closeRemoveLicenseDiscountModal())}
          >
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton
            danger
            htmlType="submit"
            onClick={onFinish}
            loading={requestIsLoading}
          >
            {t("DeleteDiscount")}
          </PrimaryButton>
        ]}
      >
        {`${t("AreYouSureYouWantToDelete")} `}
        <Text strong>{selectedLicenseDiscount?.discountPercent + "% "}</Text>
        {`${t("discountFor")} ${companyName}  ${t("on")} `}
        <Text strong>{licenseName}</Text>
        {` ${t("License").toLowerCase()}?`}
      </StandardModal>
    </>
  );
};

export default RemoveLicenseDiscountModal;
