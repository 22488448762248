import React, { useState } from "react";
import { notification, Form, Input } from "antd";
import { modalsState, closeUserRequestModal } from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Common/CommonButtons";
import { useCreateUserTransferRequestMutation } from "../../state/cargologRestApi";
import { size } from "../../helpers/pageHelper";
import { InfoAlert } from "../Common/CommonAlerts";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

type TransferRequest = { companyId: string };

// Main component
const UserRequestModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [companyId, setCompanyId] = useState<string>();

  /* Create new user transfer request*/
  const [createTransferRequest, requestStatus] =
    useCreateUserTransferRequestMutation();
  const { isLoading } = requestStatus;

  const handleTransferRequest = async (request: TransferRequest) => {
    const result: any = await createTransferRequest(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToCreateTransfer"),
        description: errorDescription
      });
    } else {
      notification.success({
        message: t("SuccessfullyCreatedTransferRequest")
      });
    }
  };

  const onFinish = () => {
    if (companyId) {
      const request: TransferRequest = { companyId: companyId };
      handleTransferRequest(request);
    }
  };
  // When user tries to finish form, but it is incomplete
  const onFinishFailed = () => {};

  return (
    <>
      <StandardModal
        title={t("RequestTransfer")}
        zIndex={1045}
        open={modals.isUserRequestModalOpen}
        onCancel={() => dispatch(closeUserRequestModal())}
        closable={true}
        footer={null}
        destroyOnClose={true}
      >
        <InfoAlert
          message={t("EnterTheTargetCompanyId")}
          style={{
            marginBottom: size.l2
          }}
        />
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Form.Item
            label={t("CompanyId")}
            name="companyid"
            rules={[{ required: true, message: t("CompanyIdIsRequired") }]}
          >
            <Input
              placeholder=""
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={isLoading}
            >
              {t("SendTransferRequest")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default UserRequestModal;
