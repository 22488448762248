import { Row } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { CardRealPapperLook } from "../Common/CommonCards";
import { HeaderData } from "./pdfExport";
import ReportHeader from "./ReportHeader";
import { getPrintValues } from "../../helpers/exportHelper";

// Types

/** Prepfeared props for components that should be printed */
interface PrintableProps {
  width: number;
  height: number;
}

interface SingleHocProps<T> {
  WrappedComponent: React.FC<T & PrintableProps>;
  props?: T;
  reportPrintDone: () => void;
  orientation: Orientation;
  header?: HeaderData;
}

interface MultiHocProps<T> {
  printableItems: PrintableItem<T>[];
  orientation: Orientation;
  itemsPerPage: number;
  reportPrintDone: () => void;
  headers: HeaderData[];
}

interface PrintMultipleProps<T> {
  WrappedComponent: React.FC<T & PrintableProps>;
  props?: T;
  reportPrintDone: () => void;
  orientation: Orientation;
}

interface WrappedPrintableComponentProps<T>
  extends Omit<MultiHocProps<T>, "reportPrintDone"> {
  previewMode?: boolean;
  headers: HeaderData[];
}

export interface PrintableItem<T> {
  Component: React.FC<T & PrintableProps>;
  props?: T;
  heightDenominator?: number;
}

/** Printed page orientation */
export type Orientation = "landscape" | "portrait";

/** Utility type: Create props without width/height. Usefull for partially
 * applying props that will be used for a printable component */
export type PartiallyAppliedPropType<T extends PrintableProps> = Omit<
  T,
  "width" | "height"
>;

// Hooks

/**
 * A Simple hooks that works great combined with "PrintableComponent"
 * returned values + use cases:
 *
 * isPrinting -> Recomended to use before PrintableComponent,
 *
 * startPrint -> call this to start printing,
 *
 * finishPrint -> use this as argument "reportPrintDone" in PrintableComponent
 */
export const usePrintHook = () => {
  const [isPrinting, setIsPrinting] = useState(false);

  const startPrint = () => {
    setIsPrinting(true);
  };
  const finishPrint = () => {
    setIsPrinting(false);
  };

  return { isPrinting, startPrint, finishPrint };
};

// Constants

/** The longer side on an A4 in pixels */
const longSide = 1485;
/** The shorter side on an A4 in pixels */
const shortSide = 1050;

/** Margin for printed pages. 7mm should look good for most printers */
const printMargin = "7mm";

/** Total margin per axis in pixels rounded up. (7mm * 2 * ~3.8) */
const marginPerAxisInPx = 53;

/** A4 size minus side margins*/
const a4Size = {
  landscape: {
    w: longSide - marginPerAxisInPx,
    h: shortSide - marginPerAxisInPx
  },
  portrait: {
    w: shortSide - marginPerAxisInPx,
    h: longSide - marginPerAxisInPx
  }
};

/** Header padding depending on orientation */
const headerPadding = {
  landscape: { top: 25, left: 50 },
  portrait: { top: 30, left: 15 }
};

// Higher order components

/**
 * Higher order component that can be used to create a component in a4 size and
 * then print it. The printing will start directly when this component is
 * finished rendering. It is therefor recomended to use the printHook (also in
 * this module) to only render it when the user want to start printing
 * @param hocProps
 */
export const PrintableComponent = <T,>(hocProps: SingleHocProps<T>) => {
  const { WrappedComponent, props, reportPrintDone, header, orientation } =
    hocProps;
  const isHorizontal = orientation === "landscape";
  const contentSize = {
    w: a4Size[orientation].w,
    h: a4Size[orientation].h - 190
  };

  const dontShow: React.CSSProperties = { display: "none" };

  const printRef = useRef();

  const tryPrintDva = useReactToPrint({
    content: () => printRef.current!,
    pageStyle: `@page { size: auto;  margin: ${printMargin}; }
        @media print { body { -webkit-print-color-adjust: exact; }
        @page {size: ${orientation};} }
        font-family: Arial;`
  });

  useEffect(() => {
    tryPrintDva?.();
    reportPrintDone();
  }, [tryPrintDva, reportPrintDone]);

  return (
    <div style={dontShow}>
      <div ref={printRef as any}>
        {header && isHorizontal && (
          <div
            style={{
              height: "190px",
              paddingTop: "15px",
              paddingLeft: "40px",
              fontSize: 12
            }}
          >
            <ReportHeader header={header} isHorizontal={true} />
          </div>
        )}

        {header && !isHorizontal && (
          <div
            style={{
              height: getPrintValues(header.reportType).header,
              paddingTop: "20px",
              paddingLeft: getPrintValues(header.reportType).paddingLeft,
              fontSize: 11
            }}
          >
            <ReportHeader header={header} isHorizontal={false} />
          </div>
        )}

        <div
          style={{ height: getPrintValues(header?.reportType ?? "").content }}
        >
          <WrappedComponent
            width={contentSize.w}
            height={contentSize.h}
            {...props!}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Higher order component that can be used to print mutliple components in a4 size.
 * The printing will start directly when this component is
 * finished rendering. It is therefore recomended to use the printHook (also in
 * this module) to only render it when the user want to start printing
 * @param hocProps
 */
export const PrintMultiple = <T,>(hocProps: PrintMultipleProps<T>) => {
  const { WrappedComponent, props, reportPrintDone, orientation } = hocProps;

  const dontShow: React.CSSProperties = { display: "none" };
  const printRef = useRef();

  const tryPrintDva = useReactToPrint({
    content: () => printRef.current!,
    pageStyle: `@page { size: auto;  margin: ${printMargin}; }
        @media print { body { -webkit-print-color-adjust: exact; }
        @page {size: ${orientation};} }
        font-family: Arial;`
  });

  useEffect(() => {
    tryPrintDva?.();
    reportPrintDone();
  }, [tryPrintDva, reportPrintDone]);

  return (
    <div style={dontShow}>
      <div ref={printRef as any}>
        <WrappedComponent
          width={a4Size[orientation].w}
          height={a4Size[orientation].h}
          {...props!}
        />
      </div>
    </div>
  );
};

/**
 * Higher order component that can be used to print mutiple components in a4
 * size. If all the components can't fit in 1 page, the final print will be more
 * than 1 pages long. The printing will start directly when this component is
 * finished rendering. It is therefor recomended to use the printHook (also in
 * this module) to only render it when the user want to start printing
 * @param hocProps
 */
export const PrintMultipleComponents = <T,>(hocProps: MultiHocProps<T>) => {
  const {
    printableItems,
    orientation,
    reportPrintDone,
    headers,
    itemsPerPage
  } = hocProps;

  return (
    <PrintMultiple
      WrappedComponent={() => (
        <WrapMultipleComponents
          printableItems={printableItems}
          orientation={orientation}
          headers={headers}
          itemsPerPage={itemsPerPage}
        />
      )}
      reportPrintDone={reportPrintDone}
      orientation={orientation}
    />
  );
};

/**
 * Wrap multible printable items to components
 * @param hocProps
 */
export const WrapMultipleComponents = <T,>(
  hocProps: WrappedPrintableComponentProps<T>
) => {
  const {
    printableItems,
    orientation: pageOrientation,
    previewMode,
    headers,
    itemsPerPage
  } = hocProps;

  const headerHeight = 190;

  // Change orientation of content if more than one item per page
  const itemIsHorizontal =
    itemsPerPage === 1
      ? pageOrientation === "landscape"
      : pageOrientation === "portrait";
  const itemOrientation = itemIsHorizontal ? "landscape" : "portrait";

  // How much we need to scale the content to fit in the page
  const itemScale = "scale(" + 1 / itemsPerPage + ")";

  // The size of the actual content (minus header and margin)
  const contentSize = {
    w: a4Size[itemOrientation].w,
    h: a4Size[itemOrientation].h - headerHeight
  };

  // Width of the li element that contains the content
  // horizontal parent, horizontal child:  w = parent w
  // vertical parent, horizontal children: w = parent w
  // vertical parent, vertical child:      w = parent w / 1
  // horizontal parent, vertical children: w = parent w / nr of children
  const itemWidth = itemIsHorizontal
    ? a4Size[pageOrientation].w
    : a4Size[pageOrientation].w / itemsPerPage;

  // Height of the li element that contains the content
  // vertical parent, vertical child:      h = parent h
  // horizontal parent, vertical children: h = parent h
  // horizontal parent, horizontal child:  h = parent h / 1
  // vertical parent, horizontal children: h = parent h / nr of children
  const itemHeight = itemIsHorizontal
    ? a4Size[pageOrientation].h / itemsPerPage
    : a4Size[pageOrientation].h;

  // The width of the li container in percent
  const itemWidthPercent = itemIsHorizontal ? "100%" : 100 / itemsPerPage + "%";

  // The height of the li container in percent
  const itemHeightPercent = itemIsHorizontal
    ? 100 / itemsPerPage + "%"
    : "100%";

  // Contains all items to be printed
  const printUlStyle: React.CSSProperties = {
    width: a4Size[pageOrientation].w,
    listStyle: "none",
    margin: 0,
    padding: 0
  };

  // Contains one item to be printed
  const printLiStyle: React.CSSProperties = {
    width: itemWidthPercent,
    height: itemHeightPercent, // needs to be percent in order to break page
    maxHeight: itemHeight,
    maxWidth: itemWidth,
    margin: 0,
    padding: 0,
    float: "left" // puts the next item on the same page
  };

  // Div width and height before scaling
  // horizontal parent, horizontal child:  w = parent w,     h = parent h * 1
  // horizontal parent, vertical children: w = parent w,     h = parent h * n
  // vertical parent, vertical child:      w = parent w * 1, h = parent h
  // vertical parent, horizontal children: w = parent w * n, h = parent h

  const unscaledDivWidth =
    pageOrientation === "landscape"
      ? a4Size[pageOrientation].w - 10
      : (a4Size[pageOrientation].w - 10) * itemsPerPage;

  const unscaledDivHeight =
    pageOrientation === "landscape"
      ? (a4Size[pageOrientation].h - 10) * itemsPerPage
      : a4Size[pageOrientation].h - 10;

  // Scales down an item to fit in the li container
  const printDivScaleStyle: React.CSSProperties = {
    width: unscaledDivWidth,
    height: unscaledDivHeight,
    margin: 0,
    padding: 0,
    transformOrigin: "top left",
    transform: itemScale,
    maxWidth: a4Size[pageOrientation].w * itemsPerPage,
    maxHeight: a4Size[pageOrientation].h * itemsPerPage
  };

  const renderContent = () => {
    return (
      <ul style={printUlStyle}>
        {printableItems.map((item, index) => {
          const { Component, props } = item;

          return (
            <li key={index} style={printLiStyle}>
              <div style={printDivScaleStyle}>
                <div
                  style={{
                    width: "100%",
                    height: headerHeight,
                    margin: 0,
                    padding: 0
                  }}
                >
                  <ReportHeader
                    header={headers[index]}
                    isHorizontal={itemIsHorizontal}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: contentSize.h - 10,
                    padding: "0 25px",
                    margin: 0
                  }}
                >
                  <Component
                    style={{
                      margin: 0,
                      padding: 0
                    }}
                    width={contentSize.w - 50}
                    height={contentSize.h - 10}
                    {...props!}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return previewMode ? (
    <ReportInPreviewMode
      printableItems={printableItems}
      a4Size={a4Size[pageOrientation]}
      orientation={pageOrientation}
      headers={headers}
    />
  ) : (
    renderContent()
  );
};

//todo: make cleaner
const ReportInPreviewMode = (props: {
  printableItems: PrintableItem<any>[];
  a4Size: { w: number; h: number };
  headers: HeaderData[];
  orientation: Orientation;
}) => {
  const { printableItems, a4Size, headers, orientation } = props;
  const previewContainerRef = createRef<HTMLUListElement>();

  const isHorizontal = orientation === "landscape";
  const headerHeight = 190;
  const componentHeight = a4Size.h - marginPerAxisInPx * 2;
  /** With of preview modal = window - margins - scrollbar */
  const modalWidth = window.innerWidth - 48 - 25;

  return (
    <Row justify="center" style={{ width: "100%" }}>
      <ul
        ref={previewContainerRef}
        style={{
          width: "100%",
          maxWidth: "100%",
          listStyle: "none",
          margin: 0,
          padding: 0
        }}
      >
        {printableItems.map((item, iterator) => {
          const { Component, props } = item;
          const margin = {
            x: modalWidth / 2 - a4Size.w / 4,
            y: -(a4Size.h / 2) + 10
          };

          return (
            <li
              key={iterator}
              style={{
                width: a4Size.w,
                height: a4Size.h,
                padding: 0,
                margin: "10px",
                marginBottom: margin.y,
                marginLeft: margin.x,
                transformOrigin: "top left",
                transform: "scale(0.5)"
              }}
            >
              <CardRealPapperLook
                style={{
                  height: "100%",
                  overflow: "hidden"
                }}
              >
                <div
                  style={{
                    height: headerHeight,
                    paddingLeft: headerPadding[orientation].left,
                    paddingTop: headerPadding[orientation].top,
                    margin: 0
                  }}
                >
                  <ReportHeader
                    header={headers[iterator]}
                    isHorizontal={isHorizontal}
                  />
                </div>
                <div
                  style={{
                    height: componentHeight - 5 - headerHeight,
                    paddingLeft: 25,
                    paddingRight: 25,
                    marginTop: 35
                  }}
                >
                  <Component
                    width={a4Size.w - 63}
                    height={componentHeight - 5 - headerHeight}
                    {...props!}
                  />
                </div>
              </CardRealPapperLook>
            </li>
          );
        })}
      </ul>
    </Row>
  );
};
