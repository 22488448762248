import { notification } from "antd";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

/** Informs the user that RecParams is invalid */
export const invalidRecordingParametersErrorMessage = () => {
  notification.error({ message: "Invalid RecParams. Check error log." });
};
