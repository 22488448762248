import React, { useState } from "react";
import { Form, Row, Col, Input, notification } from "antd";
import {
  modalsState,
  closeEditUserPhoneModal,
  setUser
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  UserForUpdate,
  User,
  useUpdateUserPropertiesMutation
} from "../../state/cargologRestApi";
import { emptyUser } from "../../helpers/sessionHelper";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

// Main component
const EditUserPhoneModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedUser = modals.userObject;
  let user = selectedUser ?? emptyUser;

  /* Request for update User Properties */
  const [updateUserProperties, requestStatus] =
    useUpdateUserPropertiesMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const [phoneNumber, setPhoneNumber] = useState<string>();

  const handleUpdateUserProperties = async (request: UserForUpdate) => {
    const result: any = await updateUserProperties(request);
    if (result.data) {
      notification.success({ message: t("SuccessfullyUpdatedUserPhone") });
      setPhoneNumber(undefined);
      dispatch(closeEditUserPhoneModal());
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("FailedtoUpdateUserPhone"),
        description: errorDescription
      });
    }
  };

  // When user finishes form sucessfully
  const changeFinished = () => {
    if (!isUndefined(selectedUser)) {
      user = selectedUser;
    }

    const newUser: User = {
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: !isUndefined(phoneNumber) ? phoneNumber : user.phoneNumber,
      companyId: user.companyId,
      temperatureScale: user.temperatureScale,
      timeZone: user.timeZone
    };

    if (
      !isUndefined(newUser.firstName) &&
      !isUndefined(newUser.lastName) &&
      !isUndefined(newUser.email) &&
      !isUndefined(selectedUser)
    ) {
      const request: UserForUpdate = {
        userId: newUser.userId,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        phoneNumber: newUser.phoneNumber
      };
      handleUpdateUserProperties(request);
      dispatch(setUser(newUser));
    }
  };
  // When user tries to finish form, but it is incomplete
  const changeFailed = () => {};

  return (
    <>
      {/* Update user phone modal */}
      <StandardModal
        title={t("UpdatePhoneNumber")}
        zIndex={1048}
        open={modals.isEditUserPhoneModalOpen}
        onCancel={() => dispatch(closeEditUserPhoneModal())}
        closable={true}
        footer={null}
        width={300}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={changeFinished}
          onFinishFailed={changeFailed}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Row gutter={size.m1}>
            <Col>
              <Form.Item
                label={t("PhoneNumber")}
                name="phoneNumber"
                style={{ width: "100%" }}
                initialValue={
                  !isUndefined(user.phoneNumber) ? user.phoneNumber : ""
                }
              >
                <Input
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={requestIsLoading}
            >
              {t("genUpdate")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default EditUserPhoneModal;
