import React from "react";
import { Row, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { projectsState } from "../../state/projectSlice";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { closePositionsModal, modalsState } from "../../state/modalsSlice";
import { SpeedConverter } from "../MicroComponents/SpeedConverter";
import { selectMySpeed } from "../../state/sessionSlice";
import { MapProviderLink } from "../MicroComponents/MapProviderLink";
import StandardModal from "../Common/StandardModal";
import { renderPositionStatus } from "../../helpers/dataModelHelper";
const { Text } = Typography;

interface tableData {
  timestamp: string;
  coordinates: [number, number];
  status: string;
  velocity: number;
}
const PositionsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { alarmsData } = useSelector(projectsState);
  const { isPositionsModalOpen } = useSelector(modalsState);
  const speedUnit = useSelector(selectMySpeed);

  const alarmColumns: ColumnsType<tableData> = [
    {
      title: t("Timestamp"),
      dataIndex: "timestamp",
      key: "timestamp",
      // width: "",
      render: (time: string) => {
        const timezoneOffset = new Date().getTimezoneOffset();
        const timestamp = dayjs
          .utc(time)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        return <Text>{timestamp}</Text>;
      },
      sorter: (a, b) => {
        if (a.timestamp < b.timestamp) {
          return -1;
        }
        if (a.timestamp > b.timestamp) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: t("Coordinates"),
      dataIndex: "coordinates",
      key: "coordinates",
      // width: "",
      render: (path: [number, number]) => (
        <>
          <Text style={{ paddingRight: size.m1 }}>
            {path[0] + ", " + path[1]}
          </Text>
        </>
      )
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      // width: "",
      render: (status: string) => <Text>{status}</Text>,
      sorter: (a, b) =>
        a.status.toLowerCase().localeCompare(b.status.toLowerCase(), "sv")
    },
    {
      title: t("Velocity"),
      dataIndex: "velocity",
      key: "velocity",
      // width: "",
      render: (velocity: number) => (
        <Text>{SpeedConverter(velocity, speedUnit) + " " + t(speedUnit)}</Text>
      ),
      sorter: (a, b) => a.velocity - b.velocity
    },
    {
      title: "Actions",
      dataIndex: "coordinates",
      key: "actions",
      render: (path: [number, number]) => (
        <Row>
          <MapProviderLink
            lat={path[0]}
            lon={path[1]}
            text={true}
            formFactor="button"
          />
        </Row>
      )
    }
  ];

  const tableData: tableData[] = alarmsData
    ? alarmsData.gpsPosData
        .map((gps: any) => {
          return {
            timestamp: gps.dateTime,
            coordinates: gps.path,
            status: renderPositionStatus(t, gps.status) ?? "",
            velocity: gps.velocity
          };
        })
        .sort((a: any, b: any) => {
          if (a.timestamp < b.timestamp) {
            return -1;
          }
          if (a.timestamp > b.timestamp) {
            return 1;
          }
          return 0;
        })
    : [];

  return (
    <>
      <StandardModal
        title={t("PositionsForDevice") + " " + alarmsData?.serialNumber}
        open={isPositionsModalOpen}
        zIndex={1045}
        onCancel={() => dispatch(closePositionsModal())}
        closable={true}
        footer={null}
        width={900}
        styles={{ body: { maxHeight: "65vh", overflow: "auto" } }}
        destroyOnClose={true}
      >
        <Table
          columns={alarmColumns}
          dataSource={tableData}
          pagination={{ hideOnSinglePage: true }}
        />
      </StandardModal>
    </>
  );
};

export default PositionsModal;
