import React, { useState } from "react";
import {
  Layout,
  Menu,
  Row,
  Divider,
  Col,
  Typography,
  notification,
  Form,
  Input,
  Space,
  Checkbox,
  Card
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../Common/CommonButtons";
import {
  closeHelpModal,
  startHelpMode,
  selectHelp,
  setCurrentHelpPage,
  setQuickTourModalOpen
} from "../../state/helpSlice";
import {
  MacCommandOutlined,
  PhoneOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { SmallText } from "../Common/CommonFonts";
import { size } from "../../helpers/pageHelper";
import { useTranslation } from "react-i18next";
import { getUser } from "../../state/sessionSlice";
import {
  SupportTicket,
  useCreateSupportTicketMutation,
  useSendEmailToMobitronMutation
} from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
import { getAppVersion, getOsVersion } from "../../helpers/versionHelper";
import Online from "../MicroComponents/Online";
import {
  selectShowQuickTourAtStartup,
  toggleShowQuickTourAtStartup
} from "../../state/sessionSlice";
import { useNavigate } from "react-router";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { QuickTour } from "../../graphics";
import { MobitronHugeLogo } from "../../logos";
import StandardModal from "../Common/StandardModal";
const { Text } = Typography;

export type HelpModalPage =
  | "quickTour"
  | "contact"
  | "technicalSupport"
  | "shortcuts";

type HelpContent = {
  [key in HelpModalPage]: {
    title: string;
    content: JSX.Element;
  };
};

const ShortcutsContent: React.FC = () => {
  const { t } = useTranslation();
  const colSpan = 12;

  return (
    <>
      <Row justify="start" style={{ paddingLeft: size.m1 }}>
        <Space direction="horizontal" align="start">
          <MacCommandOutlined />
          <Col>
            <Space direction="vertical" size={0}>
              <SmallText strong>{"Keyboard Shortcuts"}</SmallText>
            </Space>
          </Col>
        </Space>
      </Row>
      <Divider />

      <div style={{ paddingLeft: size.m1 }}>
        <Row>
          <Col span={colSpan}>{t("Openfile")}</Col>
          <Col>
            <SmallText strong>ctrl + O</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("EditPARX")}</Col>
          <Col>
            <SmallText strong>ctrl + E</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("CloseWindow")}</Col>
          <Col>
            <SmallText strong>alt + F4, ctrl + w</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("ViewStatus")}</Col>
          <Col>
            <SmallText strong>ctrl + alt + S</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("ViewParameters")}</Col>
          <Col>
            <SmallText strong>ctrl + I</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("ReadDataFromCargologDevice")}</Col>
          <Col>
            <SmallText strong>ctrl + G</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("LoadParameterIntoCargologDevice")}</Col>
          <Col>
            <SmallText strong>ctrl + L</SmallText>
          </Col>
        </Row>
        {/* <Row>
          <Col span={colSpan}>Format memory</Col>
          <Col><SmallText strong>ctrl + M</SmallText></Col>
        </Row> */}
        <Row>
          <Col span={colSpan}>{t("PrintExport")}</Col>
          <Col>
            <SmallText strong>ctrl + P</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("Dashboard")}</Col>
          <Col>
            <SmallText strong>ctrl + 1</SmallText>
          </Col>
        </Row>
        {LicenseAccess(
          "ACB",
          <Row>
            <Col span={colSpan}>{t("Reports")}</Col>
            <Col>
              <SmallText strong>ctrl + 2</SmallText>
            </Col>
          </Row>
        )}
        {LicenseAccess(
          "ACC",
          <Row>
            <Col span={colSpan}>{t("Compare")}</Col>
            <Col>
              <SmallText strong>ctrl + 3</SmallText>
            </Col>
          </Row>
        )}
        {LicenseAccess(
          "ADE",
          <Row>
            <Col span={colSpan}>{t("SetupDevice")}</Col>
            <Col>
              <SmallText strong>ctrl + 4</SmallText>
            </Col>
          </Row>
        )}
        {LicenseAccess(
          "ABA",
          <Row>
            <Col span={colSpan}>{t("MyDevices")}</Col>
            <Col>
              <SmallText strong>ctrl + 5</SmallText>
            </Col>
          </Row>
        )}
        {LicenseAccess(
          "AFA",
          <Row>
            <Col span={colSpan}>{t("Admin")}</Col>
            <Col>
              <SmallText strong>ctrl + 6</SmallText>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={colSpan}>{t("ConnectionCenter")}</Col>
          <Col>
            <SmallText strong>ctrl + 9</SmallText>
          </Col>
        </Row>
        <Row>
          <Col span={colSpan}>{t("Settings")}</Col>
          <Col>
            <SmallText strong>ctrl + 0</SmallText>
          </Col>
        </Row>
      </div>
    </>
  );
};

const HelpModal = () => {
  const dispatch = useDispatch();
  const { isHelpModalOpen: isTutorialModalOpen, currentPage } =
    useSelector(selectHelp);
  const showTutorialAtStartup = useSelector(selectShowQuickTourAtStartup);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [messageSentVisible, setMessageSentVisible] = useState(false);
  const showMessageSent = () => {
    setMessageSentVisible(true);
  };
  const closeMessageSent = () => {
    setMessageSentVisible(false);
  };

  const MessageSentModal = () => {
    return (
      <StandardModal
        title={t("YourMessageHasBeenSent")}
        open={messageSentVisible}
        onCancel={() => {
          closeMessageSent();
          setUserMessage(undefined);
        }}
        closable={false}
        footer={[
          <PrimaryButton
            onClick={() => {
              closeMessageSent();
              setUserMessage(undefined);
            }}
          >
            {t("Close")}
          </PrimaryButton>
        ]}
        width={600}
      >
        <Row
          style={{
            padding: size.m1,
            paddingTop: 0,
            maxHeight: 200,
            overflow: "auto"
          }}
        >
          <Text style={{ fontStyle: "italic", whiteSpace: "pre-line" }}>
            "{userMessage}"
          </Text>
        </Row>
      </StandardModal>
    );
  };

  // --- Contact TAB ---

  const userInfo = useSelector(getUser);

  const [sendEmail, requestStatus] = useSendEmailToMobitronMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  type Email = { message: string };
  const [userMessage, setUserMessage] = useState<string>();

  const handleSendEmail = async (request: Email) => {
    const result: any = await sendEmail(request);
    if (result.data) {
      showMessageSent();
      dispatch(closeHelpModal());
    } else if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToSendContactForm"),
        description: errorDescription
      });
    }
  };

  const onFinishContact = () => {
    if (!isUndefined(userMessage)) {
      const request: Email = { message: userMessage };
      handleSendEmail(request);
    }
  };

  const onFinishContactFailed = () => {};

  // --- Technical Support TAB ---

  const appVersion = getAppVersion();
  const osVersion = getOsVersion();
  const appVersionString =
    appVersion.appVersionMajor +
    "." +
    appVersion.appVersionMinor +
    "." +
    appVersion.appBuildMain;

  const [createSupportTicket, supportRequestStatus] =
    useCreateSupportTicketMutation();
  const { isLoading: supportRequestIsLoading } = supportRequestStatus;

  const [supportMessage, setMessage] = useState<string>();

  const handleCreateSupportTicket = async (request: SupportTicket) => {
    const result = await createSupportTicket(request);
    if ("data" in result) {
      notification.success({
        message: t("SuccessfullySentSupportTicket")
      });
    } else if ("error" in result) {
      notification.error({
        message: t("FailedToSendSupportTicket")
      });
    }
  };

  //Is run when form is successfuly finished
  const onFinish = () => {
    if (!isUndefined(supportMessage)) {
      const request: SupportTicket = {
        message: supportMessage,
        versionInfo: appVersionString,
        operatingSystem: osVersion
      };
      handleCreateSupportTicket(request);
    }
  };

  const onFinishFailed = () => {};

  const pages: HelpContent = {
    quickTour: {
      title: t("QuickTour"),
      content: (
        <>
          <Row justify="start" style={{ paddingLeft: size.m1 }}>
            <Space direction="horizontal" align="start">
              <PlayCircleOutlined />
              <Col>
                <Space direction="vertical" size={0}>
                  <SmallText strong>{t("QuickTour")}</SmallText>
                </Space>
              </Col>
            </Space>
          </Row>
          <Divider />

          <div style={{ padding: size.m1 }}>
            <Row style={{ textAlign: "center", alignItems: "center" }}>
              <Col span={12}>
                <Space direction="vertical" size="large">
                  <SmallText>{t("QuickTourIsAnInteractiveGuide")}</SmallText>
                  <PrimaryButton
                    onClick={() => {
                      dispatch(setQuickTourModalOpen(true));
                      dispatch(startHelpMode());
                      dispatch(closeHelpModal());
                      navigate("/landing");
                    }}
                  >
                    {t("StartQuickTour")} <PlayCircleOutlined />
                  </PrimaryButton>
                  <Checkbox
                    checked={showTutorialAtStartup}
                    onChange={() => dispatch(toggleShowQuickTourAtStartup())}
                  >
                    {t("ShowQuickTourAtStartup")}
                  </Checkbox>
                </Space>
              </Col>
              <Col span={12}>
                <QuickTour />
              </Col>
            </Row>
          </div>
        </>
      )
    },
    contact: {
      title: t("ContactUs"),
      content: (
        <>
          <Row justify="start" style={{ paddingLeft: size.m1 }}>
            <Space direction="horizontal" align="start">
              <PhoneOutlined />
              <Col>
                <Space direction="vertical" size={0}>
                  <SmallText strong>{t("ContactUs")}</SmallText>
                </Space>
              </Col>
            </Space>
          </Row>
          <Divider />

          <div style={{ paddingTop: size.m1, paddingInline: size.m1 }}>
            <Row>
              <Col span={12}>
                <MobitronHugeLogo />
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <Space direction="vertical" style={{ paddingTop: size.m1 }}>
                  <SmallText type="secondary">{t("Address")}</SmallText>
                  <SmallText>Hunneryds Gård 1</SmallText>
                  <SmallText>561 46 Huskvarna</SmallText>
                  <SmallText>{t("Sweden")}</SmallText>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical" style={{ paddingTop: size.m1 }}>
                  <SmallText type="secondary">{t("PhoneNumber")}</SmallText>
                  <SmallText>+46(0)36 512 25</SmallText>
                </Space>
              </Col>
            </Row>

            <Space
              direction="vertical"
              size={15}
              style={{ paddingTop: size.l2 }}
            >
              <Row>
                <Col>
                  <SmallText strong>{t("ContactUs")}</SmallText>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <SmallText>{t("contactFormInfo")}</SmallText>
                </Col>
              </Row>
            </Space>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinishContact}
              onFinishFailed={onFinishContactFailed}
              layout="vertical"
              style={{ width: "100%", paddingTop: size.m1 }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t("Message")}
                    name="messaage"
                    rules={[
                      { required: true, message: t("aMessageIsRequired") }
                    ]}
                  >
                    <Input.TextArea
                      rows={5}
                      showCount
                      maxLength={1000}
                      value={userMessage}
                      onChange={(e) => setUserMessage(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ marginBottom: 0 }}>
                <PrimaryButton
                  style={{}}
                  htmlType="submit"
                  loading={requestIsLoading}
                >
                  {t("Send")}
                </PrimaryButton>
              </Form.Item>
            </Form>
          </div>
        </>
      )
    },
    technicalSupport: {
      title: t("TechnicalSupport"),
      content: (
        <>
          <Online>
            <Row justify="start" style={{ paddingLeft: size.m1 }}>
              <Space direction="horizontal" align="start">
                <QuestionCircleOutlined />
                <Col>
                  <Space direction="vertical" size={0}>
                    <SmallText strong>{t("TechnicalSupport")}</SmallText>
                  </Space>
                </Col>
              </Space>
            </Row>
            <Divider />

            <div style={{ padding: size.m1 }}>
              <Row>
                <Col span={24} style={{ marginBottom: size.m1 }}>
                  <SmallText strong>{t("ReportAProblem")}</SmallText>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <SmallText>{t("helpPageInfo")}</SmallText>
                </Col>
              </Row>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                style={{ width: "100%" }}
              >
                <Layout
                  style={{
                    background: "#fff",
                    paddingTop: size.l2
                  }}
                >
                  <Layout.Content style={{ marginBottom: size.m1 }}>
                    <Row>
                      <SmallText type="secondary">
                        {t("DataForTroubleShooting")}:
                      </SmallText>
                    </Row>
                    <Row>
                      <Col span={10}>{t("AppVersion")}:</Col>
                      <Col>
                        <SmallText>{appVersionString}</SmallText>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>{t("OSVersion")}:</Col>
                      <Col>
                        <SmallText>{osVersion}</SmallText>
                      </Col>
                    </Row>
                    {/* <Row>
                        <Col span={10}>{t("ScreenResolution")}:</Col>
                        <Col>
                        <SmallText>{window.screen.width}x{window.screen.height}px</SmallText>
                        </Col>
                      </Row> */}

                    <Row>
                      <Col span={10}>{t("genFullName")}:</Col>
                      <Col>
                        <SmallText>
                          {!isUndefined(userInfo.firstName) &&
                          !isUndefined(userInfo.lastName)
                            ? userInfo.firstName + " " + userInfo.lastName
                            : ""}
                        </SmallText>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>{t("Email")}:</Col>
                      <Col>
                        <SmallText>
                          {!isUndefined(userInfo.email) ? userInfo.email : ""}
                        </SmallText>
                      </Col>
                    </Row>
                  </Layout.Content>
                </Layout>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={t("DescribeYourProblem")}
                      name="message"
                      rules={[
                        { required: true, message: t("aMessageIsRequired") }
                      ]}
                    >
                      <Input.TextArea
                        rows={5}
                        showCount
                        maxLength={500}
                        value={supportMessage}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item style={{ marginBottom: 0 }}>
                  <PrimaryButton
                    style={{}}
                    htmlType="submit"
                    loading={supportRequestIsLoading}
                  >
                    {t("Submit")}
                  </PrimaryButton>
                </Form.Item>
              </Form>
            </div>
          </Online>
        </>
      )
    },
    shortcuts: {
      title: t("KeyboardShortcuts"),
      content: <ShortcutsContent />
    }
  };

  const siderMenu = (
    <Layout.Sider theme="light">
      <Menu theme="light" mode="inline" defaultSelectedKeys={[currentPage]}>
        <Menu.Item
          key="quickTour"
          onClick={() => dispatch(setCurrentHelpPage("quickTour"))}
          icon={<PlayCircleOutlined />}
        >
          {pages["quickTour"].title}
        </Menu.Item>
        <Menu.Item
          key="shortcuts"
          onClick={() => dispatch(setCurrentHelpPage("shortcuts"))}
          icon={<MacCommandOutlined />}
        >
          {pages["shortcuts"].title}
        </Menu.Item>
        <Menu.Item
          key="technicalSupport"
          onClick={() => dispatch(setCurrentHelpPage("technicalSupport"))}
          icon={<QuestionCircleOutlined />}
        >
          {pages["technicalSupport"].title}
        </Menu.Item>
        <Menu.Item
          key="contact"
          onClick={() => dispatch(setCurrentHelpPage("contact"))}
          icon={<PhoneOutlined />}
        >
          {pages["contact"].title}
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );

  return (
    <>
      <StandardModal
        title={t("HelpCenter")}
        open={isTutorialModalOpen}
        onCancel={() => dispatch(closeHelpModal())}
        closable={true}
        width={900}
        footer={null}
        zIndex={1040}
        style={{
          top: "72px",
          paddingBottom: 0
        }}
        styles={{
          content: {
            padding: 0
          },
          body: {
            padding: 0
          }
        }}
      >
        <Layout>
          {siderMenu}
          <Layout.Content>
            <div
              style={{
                width: "100%",
                height: "950px",
                maxWidth: "95vh",
                maxHeight: "60vh",
                overflow: "auto"
              }}
            >
              <Card style={{ minHeight: "100%" }}>
                {pages[currentPage].content}
              </Card>
            </div>
          </Layout.Content>
        </Layout>
      </StandardModal>
      <MessageSentModal />
    </>
  );
};

export default HelpModal;
