import React from "react";
import {
  VictoryChart,
  VictoryTheme,
  VictorySelectionContainer,
  VictoryBrushContainer,
  VictoryAxis,
  VictoryLabel
} from "victory";
import { unix2Date, VMDvaData } from "../../helpers/dataModelHelper";
import {
  dateAxisFormater,
  formatYData,
  generateTickValues,
  getNormalizerFunctions,
  graphAxisScaleSelector,
  toLowHighOrder,
  yAxisDefaultNormalizedValues,
  yAxisFormater
} from "../../helpers/graphHelper";
import {
  CompareStateZoomDomain,
  ICompareGraph,
  selectGlobalSelectedDomain,
  YAxisLowHigh
} from "../../state/compareGraphsSlice";
import { useSelector } from "react-redux";
import { commonAxisStyle } from "../../styles/graphStylesCommon";
import {
  selectGlobalGraphScale,
  selectGraphAxisTickCountTime
} from "../../state/persistantStateSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import { getDvaContent } from "./DvaGraphContent";
import { guessLocalTz } from "../../helpers/dateHelper";
import { size } from "../../helpers/pageHelper";

interface IProps {
  data: ICompareGraph[];
  width: number;
  height: number;
  handleSelectedDomain: (domain: CompareStateZoomDomain | undefined) => void;
}

const OnTopGraph: React.FC<IProps> = (props) => {
  const { data } = props;

  const selectedDomain = useSelector(selectGlobalSelectedDomain());
  const timeScaleSteps = useSelector(selectGraphAxisTickCountTime);
  const globalScale = useSelector(selectGlobalGraphScale);

  const localTimezone = useSelector(guessLocalTz);
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  // Calculate the new data points for the graphs
  const quickGraphData = React.useMemo(
    () =>
      data.map((graph) => {
        const channels = graph.activeChannels;
        const offsetSeconds = graph.offsetMs ? graph.offsetMs / 1000 : 0;
        const reducedDvaGraphs = graph.compareGraphContent.dvaData.map(
          (dvaGraph) => ({
            start: graph.startTime ?? dvaGraph.start,
            end: graph.startTime
              ? graph.startTime + dvaGraph.end - dvaGraph.start
              : dvaGraph.end,
            data: dvaGraph.data
              .filter(
                (dataPoint) =>
                  (channels.includes("x") && dataPoint.xAlarm) ||
                  (channels.includes("y") && dataPoint.yAlarm) ||
                  (channels.includes("z") && dataPoint.zAlarm)
              )
              .map((dataPoint, index) => {
                const dataStart = graph.startTime ?? dvaGraph.start;
                const dvaFrequency = graph.dvaFrequency ?? 1000;
                return {
                  xAlarm: dataPoint.xAlarm,
                  yAlarm: dataPoint.yAlarm,
                  zAlarm: dataPoint.zAlarm,
                  time: dataStart + offsetSeconds + index / dvaFrequency
                };
              })
          })
        );
        const newGraph = {
          id: graph.id,
          dvaData: reducedDvaGraphs,
          metaData: {
            activeGraphType: graph.activeGraphType,
            activeChannels: graph.activeChannels,
            activeDvaGraph: graph.activeDvaGraph,
            dvaFrequency: graph.dvaFrequency,
            startTime: graph.startTime,
            offsetMs: graph.offsetMs,
            dataDomain: graph.dataDomain
          }
        };
        return newGraph;
      }),
    [data]
  );

  // Find largest domain for all graphs and active channels
  let fullDomain: YAxisLowHigh = [Number.MAX_SAFE_INTEGER, 0];
  const setMinMax = (timestamp: number) => {
    fullDomain[0] = Math.min(fullDomain[0], timestamp);
    fullDomain[1] = Math.max(fullDomain[1], timestamp);
  };

  let accDomain: YAxisLowHigh = [0, 0];
  const setAccMinMax = (value: number) => {
    accDomain[0] = Math.min(accDomain[0], value);
    accDomain[1] = Math.max(accDomain[1], value);
  };

  quickGraphData.forEach((file) => {
    if (file.metaData.activeDvaGraph) {
      const activeDvaGraph = file.dvaData.find(
        (e) =>
          e.start.toString() + e.end.toString() ===
          file.metaData.activeDvaGraph!.toString()
      );
      if (activeDvaGraph) {
        // X-values
        setMinMax(activeDvaGraph.start);
        // Y-values
        activeDvaGraph.data.forEach((a) => setAccMinMax(a.xAlarm));
        activeDvaGraph.data.forEach((a) => setAccMinMax(a.yAlarm));
        activeDvaGraph.data.forEach((a) => setAccMinMax(a.zAlarm));
      }
    }
  });

  /** logical values in graph. Ranges from -1 to 1 */
  const scale = {
    low: yAxisDefaultNormalizedValues[0],
    high: yAxisDefaultNormalizedValues[1]
  };

  // fileValue will be different in different files so it is statically set to 21
  const accTickCount = graphAxisScaleSelector(
    21,
    globalScale.acc.count,
    globalScale.acc.toggle,
    globalScale.toggle
  );

  /** Callback used to style y-axis grid */
  const gridStyleCallback = (tick: number) =>
    tick === 0 || tick === scale.low ? "black" : "#ECEFF1";

  const handleZoomSelection = (bounds: CompareStateZoomDomain) => {
    const fixedBoundsX: [number, number] =
      bounds.x[0] < 0 ? [0, bounds.x[1]] : bounds.x;

    // If user selects from right to left, the first value will be larger than the seconds value, causing a bugged selection. {toLowHighOrder} solves that
    const newXDomain: CompareStateZoomDomain["x"] =
      toLowHighOrder(fixedBoundsX);
    const newYDomain: CompareStateZoomDomain["y"] = [-1, 1];
    props.handleSelectedDomain({ x: newXDomain, y: newYDomain });
  };

  // Find the largest absolute x, y or z acceleration value in quickGraphData.dvaData and return the value as YAxisLowHigh
  const accDomainUnit = (): YAxisLowHigh => {
    let maxAcc = 1;
    quickGraphData.forEach((file) => {
      if (file.metaData.activeDvaGraph) {
        const activeDvaGraph = file.dvaData.find(
          (e) =>
            e.start.toString() + e.end.toString() ===
            file.metaData.activeDvaGraph!.toString()
        );
        if (activeDvaGraph) {
          activeDvaGraph.data.forEach(
            (a) => (maxAcc = Math.max(maxAcc, Math.abs(a.xAlarm)))
          );
          activeDvaGraph.data.forEach(
            (a) => (maxAcc = Math.max(maxAcc, Math.abs(a.yAlarm)))
          );
          activeDvaGraph.data.forEach(
            (a) => (maxAcc = Math.max(maxAcc, Math.abs(a.zAlarm)))
          );
        }
      }
    });
    return [maxAcc * -1, maxAcc * 1];
  };

  // Normalizer funtions is needed since this chart may contain data with
  // different scaling. Normalizing is needed to enable e.g. rh% (0 - 100) and acc
  // (-16 - 16) to be in the same chart
  const {
    normalizeValue: normalizeAccValue,
    denormalizeValue: denormalizeAccValue
  } = getNormalizerFunctions(accDomainUnit());

  const formatYValueAcc = (y: number) =>
    formatYData(y, accDomainUnit(), normalizeAccValue);

  // Draws the graph
  const graphContent = () =>
    quickGraphData.map((file, index) => {
      /**  The current graph is a dvaGraph */
      if (file.metaData.activeDvaGraph) {
        const dvaDataBlock: VMDvaData | undefined = file.dvaData.find((e) => {
          return (
            e.start.toString() + e.end.toString() ===
            file.metaData.activeDvaGraph!.toString()
          );
        });
        const offsetSeconds = file.metaData.offsetMs
          ? file.metaData.offsetMs / 1000
          : 0;
        const dvaFrequency = file.metaData.dvaFrequency ?? 700;
        const dvaData = dvaDataBlock
          ? dvaDataBlock.data.map((e, index) => {
              const dataStart = file.metaData.startTime ?? dvaDataBlock!.start;
              return {
                xAlarm: formatYValueAcc(e.xAlarm),
                yAlarm: formatYValueAcc(e.yAlarm),
                zAlarm: formatYValueAcc(e.zAlarm),
                time: unix2Date(
                  dataStart + offsetSeconds + index / dvaFrequency
                )
              };
            })
          : undefined;

        return dvaData ? getDvaContent(dvaData, file.metaData, index) : null;
      }
      return null;
    });

  const colorNumber = 150;
  const strokeColor = `rgb(${colorNumber}, ${colorNumber}, ${colorNumber})`;

  return (
    <>
      <VictoryChart
        name="dvaGraph"
        width={props.width}
        height={props.height}
        scale={{ x: "time" }}
        padding={{ top: size.l1, left: 50, right: size.l1, bottom: 40 }}
        domain={
          selectedDomain
            ? {
                x: selectedDomain!.x,
                y: [-1, 1]
              }
            : undefined
        }
        theme={VictoryTheme.material}
        domainPadding={{ x: [0, 5], y: [0, 0] }}
        containerComponent={
          <VictorySelectionContainer
            selectionDimension="x"
            allowSelection
            onSelection={(points, bounds: any, props) => {
              handleZoomSelection(bounds as any);
            }}
          />
        }
      >
        {/* x-axis(based on time, show no values, based on origo) */}
        <VictoryAxis
          axisValue={0}
          tickFormat={[]}
          tickCount={timeScaleSteps}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: strokeColor,
              strokeWidth: 1.5
            }
          }}
        />

        {/* x-axis(based on time, show values) */}
        <VictoryAxis
          tickFormat={(t, _i, arr) =>
            dateAxisFormater(
              t,
              arr,
              timezoneSelector(localTimezone, timezoneState, timezoneToggle)
            )
          }
          fixLabelOverlap
          tickCount={timeScaleSteps === 0 ? undefined : timeScaleSteps}
          axisValue={yAxisDefaultNormalizedValues[0]}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: "black",
              strokeWidth: 1.5
            },
            grid: {
              stroke: strokeColor,
              strokeWidth: 0
            }
          }}
        />

        <VictoryAxis
          dependentAxis
          offsetX={50}
          label={"g"}
          fixLabelOverlap
          style={{
            ...commonAxisStyle,
            axis: { stroke: strokeColor, strokeWidth: 2 },
            grid: {
              stroke: (tick: any) => gridStyleCallback(tick)
            }
          }}
          axisLabelComponent={
            <VictoryLabel y={15} dx={39} textAnchor="middle" />
          }
          tickValues={generateTickValues(accTickCount)}
          tickFormat={(t) =>
            yAxisFormater(denormalizeAccValue(t), accDomainUnit())
          }
        />
        {graphContent()}
      </VictoryChart>

      <VictoryChart
        width={props.width}
        height={90}
        scale={{ x: "time" }}
        padding={{ top: 0, left: 50, right: size.l1, bottom: size.l3 }}
        theme={VictoryTheme.material}
        domainPadding={{ x: [5, -10], y: 5 }}
        containerComponent={
          <VictoryBrushContainer
            brushDimension="x"
            brushDomain={
              selectedDomain
                ? {
                    x: selectedDomain!.x,
                    y: [-1, 1]
                  }
                : undefined
            }
            onBrushDomainChangeEnd={(bounds: any) =>
              handleZoomSelection(bounds as any)
            }
          />
        }
      >
        <VictoryAxis
          tickCount={timeScaleSteps}
          tickFormat={[]}
          axisValue={0}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: strokeColor,
              strokeWidth: 1.5
            }
          }}
        />
        {graphContent()}
      </VictoryChart>
    </>
  );
};

export default React.memo(OnTopGraph);
