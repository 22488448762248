import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { isEmpty, isNil, last, memoize } from "lodash-es";
import { v4 as uuid } from "uuid";
import { DvaCardViewModel } from "../components/GraphPage/DvaDashboardCard";
import { encryptionKey } from "../constants/FAT100-prot-constants";
import {
  dtDva2ViewModel,
  num2DateTuple,
  VMDvaData
} from "../helpers/dataModelHelper";
import {
  createDatasetWithUniqueTimestamps,
  createFilteredDataset,
  RecordingDataBlockFiltered,
  RecordingDataBlockUnique
} from "../helpers/datasetHelper";
import { guessLocalTz } from "../helpers/dateHelper";
import {
  createDatxFiltersBasedOnData,
  createDefaultYAxisDomain,
  createPrimaryGraphData,
  getDefaultYAxisTickCount,
  getDomainWithData,
  yAxisDefaultNormalizedValues
} from "../helpers/graphHelper";
import { vmRecordingParametersFromPartial } from "../helpers/paramsHelper";
import { DatxHeader } from "../helpers/parsers/parseDatxHeaderHelper";
import {
  parseDatx,
  ParsedDatxWithHeader
} from "../helpers/parsers/parseDatxHelper";
import { timezoneSelector } from "../helpers/timezoneSelector";
import { GeneralSystemInfo } from "../models/ISystemInfo";
import { VMRecordingParameters } from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { DataFilterStates } from "./openDatxSlice";
import { AppThunk, StoreApi } from "./store";

export interface ICompareGraphFiles {
  activeFileId?: string;
  openFiles: CompareGraphFiles;
  openNewFileStatus: OpenNewFileStatus;
  globalZoomDomain?: CompareStateZoomDomain;
  comparisonType: Comparison;
}

type OpenNewFileStatus = "inactive" | "parsing" | "rendering";
type CompareGraphFiles = Record<string, ICompareGraph>;
export type GraphTypes = "dvaGraph" | "mainGraph";
export type ChannelTypes = "x" | "y" | "z" | "temp" | "rh";
export type Comparison = "inline" | "ontop" | "combined";

/** Interface describing a compare graph-file */
export interface ICompareGraph {
  id: string;
  filePath: string;
  timezone: string;
  filters: DataFilterStates;
  compareGraphContent: ICompareGraphContent;
  dataDomain?: CompareStateZoomDomain["x"];
  activeDomain: CompareStateZoomDomain["x"];

  /** Contains an array that should act like stack to keep prior zoom levels
   * when zooming in */
  zoomDomainStack: CompareStateZoomDomain[];
  yAxisDomain: YAxisDomain;
  yAxisTickCount: YAxisTickCount;
  //todo: remove this and save a local copy somewhere where i can easily be removed
  rawData: number[];

  // Detailed Vibration Analysis (DVA)
  /** array index of the currently active dva data block */
  markedDvaBlockKeys: number[];
  /** Holds zoom domain for a particular dva-block */
  dvaZoomDomain?: DvaZoomDomain;

  /** Encrypted password and if file has been unlocked */
  password: string;
  isUnlocked: boolean;

  activeGraphType: GraphTypes;
  activeDvaGraph?: number;
  activeChannels: ChannelTypes[];
  startTime?: number;
  offsetMs?: number;
  dvaFrequency?: number;
}

/** Interface describing datx-content that comes from FAT100 */
export interface ICompareGraphContent {
  header: DatxHeader;
  systemInfo?: GeneralSystemInfo;
  recordingParameters: VMRecordingParameters;
  data: RecordingDataBlockUnique[];
  dvaData: VMDvaData[];
}

/** Interface describing datx-content that comes from FAT100 but with filtered
 * data. Usefull when creating graphs */
interface FilteredCompareGraphContent
  extends Omit<ICompareGraphContent, "data"> {
  data: RecordingDataBlockFiltered[];
}

export interface CommonDataFilter {
  /** Hide values that is not above/below the alarm limit */
  hideDataWithinAlarmLevel: boolean;
  dataToggle: DataToggleState;
}

export interface AccDataFilter {
  /** Hide values that is not above/below the alarm limit */
  hideDataWithinAlarmLevel: boolean;
  dataToggle: DataToggleState;
  /** Hide value that are not inside dynamic values */
  showDynamicFilter?: boolean;
  accMin?: number;
  accMax?: number;
  accDuration?: number;
}

export interface AccDataToggleState {
  /** wether the channel is visible right now */
  isActive: boolean;
  /** wether the channel has been used at all during the recording period */
  isUsed: boolean;
}

export interface DataToggleState {
  /** wether the channel is visible right now */
  isActive: boolean;
  /** wether the channel has been used at all during the recording period */
  isUsed: boolean;
}

/** Zoom representation in the store */
export type CompareStateZoomDomain = {
  x: [number, number];
  y: [number, number];
};

export interface YAxisDomain {
  acc?: YAxisLowHigh;
  temp?: YAxisLowHigh;
  rh?: YAxisLowHigh;
}

export type YAxisLowHigh = [number, number];

export interface YAxisTickCount {
  accTick: number;
  tempTick: number;
  rhTick: number;
  pressureTick: number;
}

/** Contains zoomDomain stack (x y) and the dva-block-index the zoom belongs to */
export interface DvaZoomDomain {
  blockKey: number;
  zoomDomainStack: CompareStateZoomDomain[];
}

const initialState: ICompareGraphFiles = {
  openNewFileStatus: "inactive",
  openFiles: {},
  globalZoomDomain: undefined,
  comparisonType: "inline"
};

export const slice = createSlice({
  name: "compareGraphs",
  initialState,
  reducers: {
    setComparisonType: (
      state,
      action: PayloadAction<{
        comparison: Comparison;
      }>
    ) => {
      const { comparison } = action.payload;
      state.comparisonType = comparison;
    },
    setGraphType: (
      state,
      action: PayloadAction<{
        id: string;
        graphType: GraphTypes;
      }>
    ) => {
      const { id, graphType } = action.payload;
      state.openFiles[id].activeGraphType = graphType;
    },
    setChannelTypes: (
      state,
      action: PayloadAction<{
        id: string;
        channelType: ChannelTypes[];
      }>
    ) => {
      const { id, channelType } = action.payload;

      state.openFiles[id].activeChannels = channelType;
    },
    setOffset: (
      state,
      action: PayloadAction<{
        id: string;
        offset: number | undefined;
      }>
    ) => {
      const { id, offset } = action.payload;

      state.openFiles[id].offsetMs = offset;
    },
    setStart: (
      state,
      action: PayloadAction<{
        id: string;
        time: number;
      }>
    ) => {
      const { id, time } = action.payload;

      state.openFiles[id].startTime = time;
    },
    resetStart: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      const { id } = action.payload;
      state.openFiles[id].startTime = undefined;
    },

    setIsOpeningFile: (state) => {
      state.openNewFileStatus = "parsing";
    },
    setFailedOpeningFile: (state) => {
      state.openNewFileStatus = "inactive";
    },

    setGlobalZoomDomain: (
      state,
      action: PayloadAction<{
        selectedDomain: CompareStateZoomDomain | undefined;
      }>
    ) => {
      const { selectedDomain } = action.payload;
      state.globalZoomDomain = selectedDomain;
    },

    setNewOpenFile: (
      state,
      action: PayloadAction<{
        filePath: string;
        unpackedData: ParsedDatxWithHeader;
        rawData: number[];
      }>
    ) => {
      const { unpackedData, filePath, rawData } = action.payload;

      let password: string = "";
      if (unpackedData.header && "password" in unpackedData.header) {
        password = unpackedData.header.password;
      }
      // Check for default or unset password
      const isUnlocked = password === "Àx:Ï" || password === "";

      const id = uuid();

      const compareGraphContent =
        unpackedDataToCompareGraphContent(unpackedData);

      //todo: change default domain
      const dataDomain: CompareStateZoomDomain["x"] = isEmpty(
        compareGraphContent.data
      )
        ? [0, 6]
        : getDomainWithData(compareGraphContent.data);

      const filters = createDatxFiltersBasedOnData(compareGraphContent.data);

      const activeDomain = dataDomain;
      const zoomDomainStack: CompareStateZoomDomain[] = [
        { x: activeDomain, y: yAxisDefaultNormalizedValues }
      ];

      const yAxisDomain = createDefaultYAxisDomain(compareGraphContent);
      const yAxisTickCount = getDefaultYAxisTickCount();

      const markedDvaBlockKeys: number[] = [];

      // Default is local timezone
      const timezone = guessLocalTz();

      const activeGraphType = "mainGraph";

      const useAcc = compareGraphContent.recordingParameters.AccParams.useAcc;
      const useTemp =
        compareGraphContent.recordingParameters.TempParams.useTemp;
      const useRh = compareGraphContent.recordingParameters.RhParams.useRh;

      const activeChannels: ChannelTypes[] = [];

      if (useAcc) {
        activeChannels.push("x", "y", "z");
      }
      if (useTemp) {
        activeChannels.push("temp");
      }
      if (useRh) {
        activeChannels.push("rh");
      }

      const startTime = undefined;
      const offsetMs = undefined;

      const newFile: ICompareGraph = {
        id,
        filePath,
        timezone,
        rawData,
        compareGraphContent,
        dataDomain,
        activeDomain,
        zoomDomainStack,
        yAxisDomain,
        yAxisTickCount,
        markedDvaBlockKeys,
        filters,
        password,
        isUnlocked,
        activeChannels,
        activeGraphType,
        startTime,
        offsetMs
      };

      // state.openFiles.push(newFile);
      state.openFiles[newFile.id] = newFile;

      //setting this file to active
      state.activeFileId = newFile.id;

      state.openNewFileStatus = "inactive";
    },
    /** Unlocks a file with fileId if it equals the set password */
    unlockCompareGraphFile: (
      state,
      action: PayloadAction<{
        fileId: string;
        password: string;
        isImpersonating?: boolean;
      }>
    ) => {
      const { fileId, password, isImpersonating } = action.payload;
      const targetFile = state.openFiles[fileId];
      const filePassword: string = targetFile.password;
      const sudo = isImpersonating === true && password === "SUDO";

      let correctPassword = true;
      for (let i = 0; i < password.length; i++) {
        correctPassword =
          correctPassword &&
          password.charCodeAt(i) ===
            (filePassword.charCodeAt(i) ^ encryptionKey[i]);
      }
      targetFile.isUnlocked = correctPassword || sudo;
    },
    closeAllOpenCompareFiles: (state) => {
      state.openFiles = {};
    },
    closeFile: (state, action: { type: string; payload: string }) => {
      delete state.openFiles[action.payload];
    },
    duplicateFile: (state, action: { type: string; payload: string }) => {
      const id = uuid();
      const newFile = { ...state.openFiles[action.payload], id };
      state.openFiles[id] = newFile;
    },

    setZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
        newDomain: CompareStateZoomDomain;
      }>
    ) => {
      const { fileId, newDomain } = action.payload;

      const activeFile = state.openFiles[fileId];

      activeFile.zoomDomainStack.push(newDomain);
    },
    resetZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
      }>
    ) => {
      const { fileId } = action.payload;
      const activeFile = state.openFiles[fileId];

      if (activeFile.activeDomain) {
        activeFile.zoomDomainStack = [
          {
            x: activeFile.activeDomain,
            y: yAxisDefaultNormalizedValues
          }
        ];
      } else {
        activeFile.zoomDomainStack = [];
      }
    },

    setActiveDvaGraph: (
      state,
      action: PayloadAction<{ fileId: string; blockKey: number }>
    ) => {
      const { fileId, blockKey } = action.payload;
      const activeFile = state.openFiles[fileId];
      activeFile.activeDvaGraph = blockKey;
    },
    setDvaFrequency: (
      state,
      action: PayloadAction<{ fileId: string; frequency: number | undefined }>
    ) => {
      const { fileId, frequency } = action.payload;
      const activeFile = state.openFiles[fileId];
      activeFile.dvaFrequency = frequency;
    },

    setDvaZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
        newDomain: CompareStateZoomDomain;
      }>
    ) => {
      const { fileId, newDomain } = action.payload;

      const activeFile = state.openFiles[fileId];

      if (
        isNil(activeFile.dvaZoomDomain) ||
        activeFile.dvaZoomDomain.blockKey !== activeFile.activeDvaGraph
      ) {
        activeFile.dvaZoomDomain = {
          blockKey: activeFile.activeDvaGraph!,
          zoomDomainStack: []
        };
      }

      activeFile.dvaZoomDomain.zoomDomainStack.push(newDomain);
    },
    resetDvaZoomDomain: (state, action: PayloadAction<{ fileId: string }>) => {
      const activeFile = state.openFiles[action.payload.fileId];

      activeFile.dvaZoomDomain = undefined;
    },

    setDataTimezone: (
      state,
      action: PayloadAction<{ fileId: string; zone: string }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      activeFile.timezone = action.payload.zone;
    }
  }
});

// =========== Actions with side effects (thunks)===========

/**
 * Unpacks a datx in a background thread and dispatch to state when ready
 * @param filePath
 * @param rawData
 */
export const unpackFile =
  (arg: { filePath: string; rawData: number[] }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsOpeningFile());
    const { filePath, rawData } = arg;

    try {
      const unpackedData = parseDatx(rawData);

      dispatch(
        setNewOpenFile({
          filePath,
          unpackedData,
          rawData
        })
      );
    } catch (e) {
      notification.error({ message: `Invalid datx-file: ${e}` });
      dispatch(setFailedOpeningFile());
    }
  };

export const {
  setComparisonType,
  setGraphType,
  setChannelTypes,
  setOffset,
  setStart,
  resetStart,
  setIsOpeningFile,
  setFailedOpeningFile,
  setGlobalZoomDomain,
  setNewOpenFile,
  unlockCompareGraphFile,
  closeAllOpenCompareFiles,
  setZoomDomain,
  resetZoomDomain,
  closeFile,
  duplicateFile,
  setActiveDvaGraph,
  setDvaFrequency,
  setDvaZoomDomain,
  resetDvaZoomDomain
} = slice.actions;

export default slice.reducer;

export const selectComparisonType = () => (state: StoreApi) =>
  state.compareGraphFiles.comparisonType;

export const selectGlobalSelectedDomain = () => (state: StoreApi) =>
  state.compareGraphFiles.globalZoomDomain;

/**
 * Select compare graph content with the data already filtered according to the current data filters
 * @param id
 */
const selectCompareGraphFilteredContentById = (id: string) => {
  return createSelector(
    [selectCompareGraphContentById(id), selectFilteredDataById(id)],
    (compareGraphContent, filteredData): FilteredCompareGraphContent => {
      return { ...compareGraphContent, data: filteredData };
    }
  );
};

const selectDvaDataInActiveDomain = memoize((id: string) => {
  return createSelector(
    [selectDvaData(id), selectActiveDomainById(id)],
    (dvaData, activeDomain) => {
      const [start, end] = activeDomain;

      return dvaData.filter((item) => {
        //Note: item start since that is the best indicator for when the event took place. Start and end ussualy has a diff of ~1sec
        return start <= item.start && item.start <= end;
      });
    }
  );
});

//todo move
const selectDvaData = (id: string) => (state: StoreApi) => {
  return state.compareGraphFiles.openFiles?.[id]?.compareGraphContent.dvaData;
};

/**
 * Select all the items that is relevant to display in a graph
 * @param id
 */
const selectCompareItemsForGraph = (id: string) => {
  return createSelector(
    [
      selectCompareGraphFilteredContentById(id),
      selectCompareGraphOptionalFiltersById(id),
      selectYAxisDomainById(id),
      selectYAxisTickCountById(id),
      selectTimezoneById(id)
    ],
    (
      datxContent,
      filters,
      yAxisDomain,
      yAxisTickCount,
      timezone
    ): ItemsForCompareGraph => {
      return {
        datxContent,
        filters,
        yAxisDomain,
        yAxisTickCount,
        timezone
      };
    }
  );
};

const selectContentAndFilters = (id: string) => {
  return createSelector(
    [
      selectCompareGraphContentById(id),
      selectCompareGraphOptionalFiltersById(id)
    ],
    (compareGraphContent, filters) => {
      return { compareGraphContent, filters };
    }
  );
};

/** Select open new file status */
export const selectOpenFileStatus = (state: StoreApi) =>
  state.compareGraphFiles.openNewFileStatus;

/**
 * Select domains. Intended to be used as an input selector.
 * @param id
 */
const selectActiveAndZoomDomain = (id: string) => {
  return createSelector(
    [selectActiveDomainById(id), selectZoomDomainById(id)],
    (activeDomain, zoomDomain) => {
      // Note: zoom domain should under normal circumstances never be nil, but
      // since it is based on the last element of the zoom stack (which is an
      // array) there is technically a possibility for that array to be empty (by
      // mistake) if that were to happen, we catch that "error" here with a backup
      // value
      if (isNil(zoomDomain)) {
        const backupZoomDomain = {
          x: activeDomain,
          y: yAxisDefaultNormalizedValues
        };

        return { activeDomain, zoomDomain: backupZoomDomain };
      }

      return { activeDomain, zoomDomain };
    }
  );
};

export const selectCompareGraphByFileById =
  (id: string) => (state: StoreApi) => {
    return state.compareGraphFiles.openFiles?.[id];
  };

const selectCompareGraphContentById = (id: string) => (state: StoreApi) =>
  selectCompareGraphByFileById(id)(state)?.compareGraphContent;

export const selectCompareGraphOptionalFiltersById =
  (id: string) => (state: StoreApi) =>
    selectCompareGraphByFileById(id)(state).filters;

const selectYAxisDomainById = (id: string) => (state: StoreApi) =>
  selectCompareGraphByFileById(id)(state).yAxisDomain;

const selectYAxisTickCountById = (id: string) => (state: StoreApi) =>
  selectCompareGraphByFileById(id)(state).yAxisTickCount;

export const selectTimezoneById = memoize((id: string) => (state: StoreApi) => {
  const timezoneState = state.persistantState.globalTimezoneState;
  const timezoneToggle = state.persistantState.globalTimezoneToggle;
  return timezoneSelector(
    selectCompareGraphByFileById(id)(state).timezone,
    timezoneState,
    timezoneToggle
  );
});

const selectActiveDomainById = (id: string) => (state: StoreApi) =>
  selectCompareGraphByFileById(id)(state)?.activeDomain;

const selectZoomDomainById = (id: string) => (state: StoreApi) => {
  const { zoomDomainStack } = selectCompareGraphByFileById(id)(state);
  return last(zoomDomainStack);
};

/**
 * Select data that is toggled on in the filters. This function is memoized for
 * performance reasons. If id is undefined, undefiend will be returned
 */
const selectFilteredDataById = memoize((id: string) => {
  return createSelector(
    selectContentAndFilters(id),
    selectFilteredCompareGraphContentAndFilters
  );
});

// View model selectors, consists of selectors that does something with the
// state-date unlike input selectors that just picks it as is

/**
 * Selects info about open files as well as the currently active file
 * @param state
 */
export const selectAllOpenCompareGraphFiles = (state: StoreApi) => {
  const { openFiles } = state.compareGraphFiles;
  const openFilesArr = openFiles ? Object.values(openFiles) : [];
  return openFilesArr;
};

export type ItemsForCompareGraph = {
  datxContent: FilteredCompareGraphContent;
  filters: DataFilterStates;
  yAxisDomain: YAxisDomain;
  yAxisTickCount: YAxisTickCount;
  timezone: string;
};

/**
 * Select relevant datx-content + extras from state and create a view-model that
 * works for graphs. This selector is memoized for performance reasons
 */
export const selectDataForCompareGraph = memoize((id: string) => {
  return createSelector(selectCompareItemsForGraph(id), (itemsForGraph) => {
    return createPrimaryGraphData(itemsForGraph);
  });
});

export const selectCompareGraphDomains = memoize((id: string) =>
  createSelector(selectActiveAndZoomDomain(id), (domains) => {
    const activeDomain = domains.activeDomain;

    const zoomDomain = {
      x: domains.zoomDomain.x,
      y: domains.zoomDomain.y
    };
    return { activeDomain, zoomDomain };
  })
);

export const selectCompareDvaGraphDomains = memoize((id: string) =>
  createSelector(selectActiveAndZoomDomain(id), (domains) => {
    const activeDomain = num2DateTuple(domains.activeDomain);

    const zoomDomain = {
      x: num2DateTuple(domains.zoomDomain.x),
      y: domains.zoomDomain.y
    };

    return { activeDomain, zoomDomain };
  })
);

/**
 * Selects all data that is active in the filters. */
const selectFilteredCompareGraphContentAndFilters = ({
  compareGraphContent,
  filters
}: Pick<ICompareGraph, "compareGraphContent" | "filters">) => {
  const { data, recordingParameters } = compareGraphContent;

  return createFilteredDataset(filters, recordingParameters, data);
};

export const selectDvaDataForCompareGraphs =
  (id: string) =>
  (state: StoreApi): DvaCardViewModel => {
    const activeFile = selectCompareGraphByFileById(id)(state);

    const dvaData = selectDvaDataInActiveDomain(id)(state);
    const activeKey = activeFile?.activeDvaGraph;
    const markedKeys = activeFile?.markedDvaBlockKeys;
    const timezone = selectTimezoneById(id)(state);
    const zoomDomain =
      activeFile.dvaZoomDomain?.blockKey === activeKey
        ? last(activeFile.dvaZoomDomain?.zoomDomainStack)
        : undefined;
    const hasZoomed = zoomDomain !== undefined;
    const dvaFrequency = activeFile.dvaFrequency ?? 1000;

    return {
      dvaData,
      activeKey,
      markedKeys,
      timezone,
      zoomDomain,
      dvaZoomDimension: "x",
      hasZoomed,
      dvaFrequency
    };
  };

export interface AccData {
  xAcc: [number, number];
  yAcc: [number, number];
  zAcc: [number, number];
  timestamp: number;
  timezone: string;
}

//utility functions todo: maybe move this section to some other file

/**
 * Creates a Datx-Content object
 * @param unpackedData
 */
export const unpackedDataToCompareGraphContent = (
  unpackedData: ParsedDatxWithHeader
): ICompareGraphContent => {
  const { systemInfo } = unpackedData;

  const recordingParameters = vmRecordingParametersFromPartial(
    unpackedData.recordingParameters
  );

  const data = createDatasetWithUniqueTimestamps(
    unpackedData.recordingData.values()
  );

  // FAT100 does not guarantee sorted data, therfor we need to sort it ourselves.
  data.sort((a, b) => a.timestamp - b.timestamp);

  const dvaData = dtDva2ViewModel(unpackedData.dvaData as any);

  return {
    header: unpackedData.header,
    recordingParameters,
    systemInfo,
    data,
    dvaData
  };
};
