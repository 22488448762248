import React from "react";
import { Space, Table } from "antd";
import { useSelector } from "react-redux";
import IAccParams from "../../models/RecordingParameters/IAccParams";
import IAngleParams from "../../models/RecordingParameters/IAngleParams";
import IRhParams from "../../models/RecordingParameters/IRhParams";
import ITempParams from "../../models/RecordingParameters/ITempParams";
import { selectTemperatureScale } from "../../state/sessionSlice";
import { TemperatureToScale } from "./TemperatureConverter";
import { useTranslation } from "react-i18next";
import { ScheduleBlock } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { TFunction } from "i18next";
import { insertIf } from "../../utils/generalUtils";
import { Atable, Atbody, Atd, Ath, Athead } from "../Common/CommonTables";

export interface VMGeneralRecordingInformationCard {
  userInfo?: string;
  projectName?: string;
  acc?: IAccParams;
  temp?: ITempParams;
  rh?: IRhParams;
  angle?: IAngleParams;
  scheduleBlocks?: ScheduleBlock[];
}

interface ITableData {
  key: number;
  leftTitle: string;
  accX: string;
  accY: string;
  accZ: string;
  temp: string;
  rh: string;
  angleX: string;
  angleY: string;
  angleZ: string;
}

interface IProps {
  data: VMGeneralRecordingInformationCard;
}

const tempAndUnit = (temp: number, unit: string): string => {
  if (unit === "F") {
    return TemperatureToScale(temp, unit) + "°F";
  } else {
    return temp + "°C";
  }
};

interface ScheduleTableData {
  name: string;
  config: string;
  istitle?: boolean;
}

const getSchedulerRows = (
  t: TFunction,
  scheduleBlocks?: ScheduleBlock[]
): ScheduleTableData[] => {
  if (!scheduleBlocks || scheduleBlocks.length === 0) {
    return [];
  }
  return scheduleBlocks.flatMap((block, index) => {
    const { blockDuration, flightMode } = block;
    const blockNumber = index + 1;
    const {
      useLteInterval,
      intervalDays: lteD,
      intervalHours: lteH,
      intervalMinutes: lteM,
      flightModeLte
    } = block.LteParams;
    const {
      useGpsInterval,
      intervalDays: gpsD,
      intervalHours: gpsH,
      intervalMinutes: gpsM,
      flightModeGps
    } = block.GpsParams;
    const durationDays = Math.floor(blockDuration / 24);
    const durationHours = Math.round((blockDuration % 24) * 100) / 100;
    return [
      {
        name: t("RecordingBlock") + " " + blockNumber,
        config: `${durationDays}d, ${durationHours}h`,
        istitle: true
      },
      ...insertIf(flightMode, {
        name: t("flightMode"),
        config: t("RadioDevicesDisabled")
      }),
      ...insertIf(flightModeLte && !flightMode, {
        name: t("SelectiveFlightmode"),
        config: t("LTEdisabled")
      }),
      ...insertIf(flightModeGps && !flightMode, {
        name: t("SelectiveFlightmode"),
        config: t("GPSdisabled")
      }),
      ...insertIf(useLteInterval && !flightModeLte && !flightMode, {
        name: t("LTEinterval"),
        config: `${lteD}d, ${lteH}h, ${lteM}min`
      }),
      ...insertIf(useGpsInterval && !flightModeGps && !flightMode, {
        name: t("GPSinterval"),
        config: `${gpsD}d, ${gpsH}h, ${gpsM}min`
      })
    ];
  });
};

const GeneralRecordingInformation = (props: IProps) => {
  const unit = useSelector(selectTemperatureScale);
  const { t } = useTranslation();

  const { acc, temp, rh, angle, scheduleBlocks } = props.data;

  const xAccReg = acc?.Xreg ? `${acc.Xreg}g` : `-`;
  const xAlarmLvl = acc?.Xreg ? `${acc.Xalarm}g ${acc.Xms}ms` : `-`;

  const yAccReg = acc?.Yreg ? `${acc.Yreg}g` : `-`;
  const yAlarmLvl = acc?.Yreg ? `${acc.Yalarm}g ${acc.Yms}ms` : `-`;

  const zAccReg = acc?.Zreg ? `${acc.Zreg}g` : `-`;
  const zAlarmLvl = acc?.Zreg ? `${acc.Zalarm}g ${acc.Zms}ms` : `-`;

  const tempReg = "";
  const tempAlarmLvl = temp
    ? `
    Min: ${tempAndUnit(temp.lowAlarm, unit)}
    Max: ${tempAndUnit(temp.highAlarm, unit)}
    `
    : `-`;

  const rhReg = "";
  const rhAlarmLvl = rh ? `Min: ${rh.lowAlarm}% Max: ${rh.highAlarm}%` : "-";

  const xAngleReg = "";
  const xAngleAlarm = angle ? `${angle.xAlarmLevel}°` : "-";

  const yAngleReg = "";
  const yAngleAlarm = angle ? `${angle.yAlarmLevel}°` : "-";

  const zAngleReg = "";
  const zAngleAlarm = angle ? `${angle.zAlarmLevel}°` : "-";

  const tableData: ITableData[] = [
    {
      key: 0,
      leftTitle: t("RegistrationLimit"),
      accX: xAccReg,
      accY: yAccReg,
      accZ: zAccReg,
      temp: tempReg,
      rh: rhReg,
      angleX: xAngleReg,
      angleY: yAngleReg,
      angleZ: zAngleReg
    },
    {
      key: 1,
      leftTitle: t("AlarmLevel"),
      accX: xAlarmLvl,
      accY: yAlarmLvl,
      accZ: zAlarmLvl,
      temp: tempAlarmLvl,
      rh: rhAlarmLvl,
      angleX: xAngleAlarm,
      angleY: yAngleAlarm,
      angleZ: zAngleAlarm
    }
  ];

  const schedulerRows = getSchedulerRows(t, scheduleBlocks);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Table<ITableData> dataSource={tableData} pagination={false}>
        <Table.Column title="" dataIndex="leftTitle" key="leftTitle" />

        <Table.ColumnGroup title={t("ACCELERATION")}>
          <Table.Column title="X-axis" dataIndex="accX" key="accX" />
          <Table.Column title="Y-axis" dataIndex="accY" key="accY" />
          <Table.Column title="Z-axis" dataIndex="accZ" key="accZ" />
        </Table.ColumnGroup>

        <Table.ColumnGroup title={t("TEMPERATURE")}>
          <Table.Column title="" dataIndex="temp" key="temp" />
        </Table.ColumnGroup>

        <Table.ColumnGroup title={t("HUMIDITY")}>
          <Table.Column title="" dataIndex="rh" key="rh" />
        </Table.ColumnGroup>

        <Table.ColumnGroup title={t("ANGLE")}>
          <Table.Column title={t("XAxis")} dataIndex="angleX" key="angleX" />
          <Table.Column title={t("YAxis")} dataIndex="angleY" key="angleY" />
          <Table.Column title={t("ZAxis")} dataIndex="angleZ" key="angleZ" />
        </Table.ColumnGroup>
      </Table>
      {schedulerRows.length > 0 ? (
        <Atable>
          {schedulerRows.map((row, index) => {
            if (row.istitle) {
              return (
                <Athead key={index}>
                  <tr>
                    <Ath>{row.name}</Ath>
                    <Ath>{row.config}</Ath>
                  </tr>
                </Athead>
              );
            } else {
              return (
                <Atbody key={index}>
                  <tr>
                    <Atd>{row.name}</Atd>
                    <Atd>{row.config}</Atd>
                  </tr>
                </Atbody>
              );
            }
          })}
        </Atable>
      ) : null}
    </Space>
  );
};

export default GeneralRecordingInformation;
