import React from "react";
import { Col, Row, Typography } from "antd";
import dayjs from "dayjs";
import { HeaderData } from "./pdfExport";
import { MobitronLogoBackground } from "../../logos";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

const { Text } = Typography;

export interface Header {
  header: HeaderData;
  isHorizontal: boolean;
}

const ReportHeader = (props: Header): React.JSX.Element => {
  const { header, isHorizontal } = props;
  const {
    reportType,
    projectName,
    hasFilters,
    recStart,
    recEnd,
    reportStart,
    reportEnd,
    exportedBy,
    deviceId
  } = header;

  const { t } = useTranslation();

  const localUtcOffset = dayjs().utcOffset() / 60;
  const localUtcOffsetStr =
    localUtcOffset >= 0 ? `(UTC+${localUtcOffset})` : `(UTC${localUtcOffset})`;

  /** Space between rows */
  const rowPadding = 2;
  const recordAndReport = (): React.JSX.Element => {
    return (
      <>
        <Col span={isHorizontal ? 9 : 8}>
          <Row>
            <Col span={7}>
              <Text strong>{t("RecordStart")}: </Text>
            </Col>
            <Col>
              <Text>{`${recStart}`}</Text>
            </Col>
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            <Col span={7}>
              <Text strong>{t("RecordEnd")}: </Text>
            </Col>
            <Col>
              <Text>{`${recEnd}`}</Text>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {reportStart && (
              <>
                <Col span={7}>
                  <Text strong>{t("ReportStart")}: </Text>
                </Col>
                <Col>
                  <Text>{`${reportStart}`}</Text>
                </Col>
              </>
            )}
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            {reportEnd && (
              <>
                <Col span={7}>
                  <Text strong>{t("ReportEnd")}: </Text>
                </Col>
                <Col>
                  <Text>{`${reportEnd}`}</Text>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </>
    );
  };

  const recordAndReportGhostElement = (): React.JSX.Element => {
    return (
      <>
        <Col span={isHorizontal ? 9 : 8}>
          <Row>
            <Col span={7}></Col>
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            <Col span={7}></Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <Col span={7}></Col>
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            <Col span={7}></Col>
          </Row>
        </Col>
      </>
    );
  };

  const hasFiltersText = (): string => {
    if (!isUndefined(hasFilters)) {
      if (hasFilters) {
        return `(${t("ReportDataIsFiltered")})`;
      } else {
        return `(${t("ReportDataIsNotFiltered")})`;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div style={{ textAlign: "start" }}>
        <Row align="top">
          <Col span={isHorizontal ? 9 : 8}>
            <MobitronLogoBackground width={300} height={100} />
          </Col>

          {header.reportType !== "ProjectReport" && (
            <>
              {/* Ghost element */}
              <Col span={8}></Col>

              <Col span={isHorizontal ? 7 : 8} style={{ paddingTop: 7 }}>
                <Row>
                  <Col>{hasFiltersText()}</Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  <Col span={7}>
                    <Text strong>{t("Report")}: </Text>
                  </Col>
                  <Col>
                    <Text>{t(reportType)}</Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  <Col span={7}>
                    <Text strong>{t("Exported")}: </Text>
                  </Col>
                  <Col>
                    <Text>
                      {dayjs().format("YYYY-MM-DD, HH:mm:ss ") +
                        localUtcOffsetStr}
                    </Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  {exportedBy && (
                    <>
                      <Col span={7}>
                        <Text strong>{t("ExportedBy")}: </Text>
                      </Col>
                      <Col>
                        <Text>{`${exportedBy.firstName} ${exportedBy.lastName}`}</Text>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>

        {header.reportType !== "ProjectReport" && (
          <>
            <Row style={{ paddingTop: size.m2, paddingLeft: 3 }}>
              {recStart && recEnd
                ? recordAndReport()
                : // If recStart or recEnd is undefined it creates ghost element to keep Project and Device id columns in place
                  recordAndReportGhostElement()}

              <Col span={isHorizontal ? 7 : 8}>
                <Row>
                  <Col span={7}>
                    <Text strong>{t("Project")}: </Text>
                  </Col>
                  <Col>
                    <Text type={projectName ? undefined : "danger"}>
                      {projectName ?? t("NoProject")}
                    </Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  <Col span={7}>
                    <Text strong>{t("SerialNumber")}: </Text>
                  </Col>
                  <Col>
                    <Text>{deviceId}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ReportHeader;
