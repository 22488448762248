import * as React from "react";

function SvgQuickTour(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="300"
      height="300"
      version="1.1"
      viewBox="76.3 40.6 299.5 228.7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="76.3"
        y="5.177"
        width="299.5"
        height="299.5"
        ry=".4574"
        fill="#fff"
        stroke-width="4.913"
      />
      <path
        d="m130.5 265.1 26.63 8.083 29.95-5.23 60.86 0.4754 30.43 5.705 42.31-9.509-47.07-2.853-25.67 2.853-66.56-0.9509-34.23-0.9509z"
        fill="#f8f8f8"
        stroke-width="4.754"
      />
      <path
        d="m267.9 77.77-5.23 28.53 35.66 2.853-4.754-16.64z"
        fill="#6f6d69"
        stroke-width="4.754"
      />
      <g transform="matrix(4.754 0 0 4.754 -286.5 -178.5)" fill="#fbb297">
        <path d="m118.2 59 0.4-1-2-1v-3.1l4-2.7 2.4 5.2-2.6 1.6 0.5 1-2.8 2.1z" />
        <path d="m118 79.4-0.3 6.5 1.1 6.6-1.7 0.1-0.9-7-0.1-6.3z" />
        <path d="m118.3 80 0.9 6.9 1.2 5.7h1.3l-1-5.4-0.4-7.2z" />
      </g>
      <g transform="matrix(4.754 0 0 4.754 -286.5 -178.5)">
        <path
          d="m116.6 53.9 2.4-0.4 0.9-1.2 0.3 2.4 2.8 6.9v-5.3l-0.3-3.8-2.2-1.4-3 0.2z"
          fill="#7e7e7e"
        />
        <path
          d="m118.2 59-6.7 3.5-1.9-2.4-1.2 1.2 2.8 3.2 4.8-2.5-0.4 0.9 0.7 3.6-0.8 3.4-0.6 12.4 1 5.2 5.6 0.1-0.4-8.2 1.3-9.9-0.9-3.8 0.5-3.6 1.9 4.8 4 0.8-0.1-1.8-2.9-0.7-2.5-6-1.7-0.3-2.4 1.3z"
          fill="#2f582c"
        />
        <path
          d="m127.7 66.4 1.6 0.3 2-1.1-0.8 1 1.8 0.3 0.4 0.8-1.4 0.8-2.7-0.8-1-0.2z"
          fill="#fbb297"
        />
        <path d="m104.2 45.5 3.7 1.3 4 0.1-3.8 2-2.9 0.8z" fill="#ee2424" />
      </g>
      <path
        d="m208.5 35.93 12.36 52.3"
        fill="none"
        stroke="#dedede"
        stroke-linecap="round"
        stroke-width="1.236px"
      />
      <path
        d="m219.4 93.46 1.426 7.607 3.328-0.4754-1.902-7.132z"
        fill="#fbb297"
        stroke-width="4.754"
      />
      <path
        d="m224.2 104.4-3.804-17.12"
        fill="none"
        stroke="#4d8f51"
        stroke-linecap="round"
        stroke-width="3.328"
      />
      <g transform="matrix(4.754 0 0 4.754 -286.5 -178.5)">
        <path
          d="m109.6 60.8-1.3-1.9-0.7-2.1-1.3 0.4 1.2 0.9 0.1 1.5 1.4 1.8z"
          fill="#fbb297"
        />
        <path
          d="m118.7 92-0.8 0.5-0.9-0.7-2.2 1.5 0.5 0.8 3.3-0.4 0.3-0.7z"
          fill="#dbdde0"
        />
        <path
          d="m121.5 91.9-0.2 0.5-1-0.4-0.7 1.5v0.5l1.8 0.1 0.6-1.1z"
          fill="#dbdde0"
        />
        <path
          d="m98 56-0.1 2.4-0.4 1.5-3-1.6-2.8 0.2 0.2-5 5.7 0.8z"
          fill="#6f6d69"
        />
        <path
          d="m97.9 55-0.2 2.7-1.9 1.7-1.3-0.6 0.7 2.4h-2.6l0.6-3.1-1.5-1.5 1.5-4 1.5-0.7z"
          fill="#fbb297"
        />
        <path
          d="m94.5 52.8 1.8 1.9 2.1 1.4-0.4-3.3-3.5-1.5-3.9 2-0.2 4.1 1.5 1.6 0.5-3.4 1.9-1.7z"
          fill="#7e7e7e"
        />
      </g>
      <path
        d="m176.6 108.2 15.21 16.64"
        fill="none"
        stroke="#ee2424"
        stroke-linecap="round"
        stroke-width="1.26"
      />
      <g transform="matrix(4.754 0 0 4.754 -286.5 -178.5)">
        <path d="m101.5 65.1-1.3-0.2 2.1-4.4 2.8 0.1-1.5 4.7z" fill="#dbdde0" />
        <path
          d="m94.7 59.7-0.2 1.4-1.6-1.3-2.3 0.8v1.3l1 4.9-0.7 3.9 0.1 3.6 0.9 3.2 0.5 8.6-1.2 7.3 1.7 0.1 1.2-7 0.9-9.6 1.3 9.1-1.2 7.4 1.7-0.3 1.3-8.3v-8.8l-0.3-3.7-0.9-6.3 1.2 2.2 5.5-2.7-0.8-1.7-4.1 2.6-1.9-6.6z"
          fill="#2f582c"
        />
        <path
          d="m92.1 60.6 3.2 4.3 2.8-1.5 0.8 1.3-3.7 2.5-4.3-5-0.1-1.4z"
          fill="#4d8f51"
        />
        <path
          d="m98.1 63.6 0.3-0.3 0.2-1.3h1.5l-0.1 1.5-0.7 0.4-0.8 0.7z"
          fill="#fbb297"
        />
        <path
          d="m102.9 64.3 0.6-0.8-0.7-0.4 0.1-1.2 2.2-0.1 0.3 1.8-2.1 1.5z"
          fill="#fbb297"
        />
        <path d="m102.4 60.7 1.5 1.3 1 2h2.8l-1.6-2.1-1-1.3z" fill="#eee" />
        <path
          d="m96.9 92.8 1.7 0.6-0.2 0.6-3.1-0.1v-0.5l0.9-0.3z"
          fill="#dbdde0"
        />
        <path
          d="m92.9 92.7 1.8 0.7-0.2 0.6-3.4-0.1v-0.7l1-0.3z"
          fill="#dbdde0"
        />
      </g>
    </svg>
  );
}

export default SvgQuickTour;
