import React from "react";

interface ChildProp {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  key?: string | number;
}

export const Atable = (props: ChildProp): React.JSX.Element => (
  <div
    className="ant-table-wrapper mobitron-antd-table"
    style={{ width: "100%" }}
  >
    <div className="ant-table ant-table-bordered">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table style={{ textAlign: "start", width: "100%", ...props.style }}>
            {props.children}
          </table>
        </div>
      </div>
    </div>
  </div>
);

export const Athead = (props: ChildProp): React.JSX.Element => (
  <thead className="ant-table-thead" style={props.style} key={props.key}>
    {props.children}
  </thead>
);

export const Atbody = (props: ChildProp): React.JSX.Element => (
  <tbody className="ant-table-tbody" style={props.style} key={props.key}>
    {props.children}
  </tbody>
);

export const Ath = (props: ChildProp): React.JSX.Element => (
  <th className="ant-table-cell" style={props.style} key={props.key}>
    {props.children}
  </th>
);

export const Atd = (props: ChildProp): React.JSX.Element => (
  <td className="ant-table-row" style={props.style}>
    {props.children}
  </td>
);
