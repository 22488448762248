/*
 form: Type of battery slot
 name: Brand and model of bettery
 type: Battery chemistry
 volt: Volt per batteri
 mah: mAh per battery
 */
export const batteryConfigurations = [
  {
    form: "2xC",
    name: "Tadiran SL-2770",
    type: "Litium",
    volt: 3.6,
    mah: 8500
  },
  {
    form: "2xC",
    name: "Saft LS26500",
    type: "Litium",
    volt: 3.6,
    mah: 7700
  },
  {
    form: "2xC",
    name: "Duracell MN1400",
    type: "Alkaline",
    volt: 1.5,
    mah: 7800
  },
  {
    form: "4xAA",
    name: "Saft LS14500",
    type: "Litium",
    volt: 3.6,
    mah: 2600
  },
  {
    form: "4xAA",
    name: "Duracell MN1500",
    type: "Alkaline",
    volt: 1.5,
    mah: 2850
  },
  {
    form: "2xC",
    name: "SAFT LSH 14",
    type: "Litium",
    volt: 3.6,
    mah: 5800
  }
];

// Constants for energy consumption of different firmware versions in mA
export const firmwareEnergyConsumptionConstants = [
  {
    fwver: "1.0.0",
    base: 0.298,
    accReg: 8.16,
    accRegDuration: 4 / 3600, // 4 seconds in hours
    tempReg: 8.16,
    tempRegDuration: 4 / 3600, // 4 seconds in hours
    rhReg: 8.16,
    rhRegDuration: 4 / 3600, // 4 seconds in hours
    angleReg: 8.16,
    angleRegDuration: 4 / 3600, // 4 seconds in hours
    healthReg: 8.16,
    healthRegDuration: 4 / 3600, // 4 seconds in hours
    gpsReg: 122,
    gpsRegDuration: 30 / 3600, // 30 seconds in hours
    lte2GReg: 1088,
    lte3GReg: 500,
    lte4GReg: 250,
    lte5GReg: 250,
    lte6GReg: 250,
    lteRegDuration: 60 / 3600 // 60 seconds in hours
  }
];
