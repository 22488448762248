import React from "react";
import { Card, Form, Input, notification } from "antd";
import { modalsState, closeChangePasswordModal } from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  useChangePasswordMutation,
  useInitiatePasswordChangeMutation,
  InitPasswordChangeType,
  ChangePasswordType
} from "../../state/cargologRestApi";
import { useState } from "react";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const ChangePasswordModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // If password is changed from user settings there is a user object
  const { isChangePasswordModalOpen, userObject: user } =
    useSelector(modalsState);

  // Form state
  const [email, setEmail] = useState<string>();
  const [resetCode, setResetCode] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [formStep, setFormStep] = useState<string>("email");

  // Get API for Init Password Change
  const [initiatePasswordChange, initiatePasswordChangeStatus] =
    useInitiatePasswordChangeMutation();
  const { isLoading: initPasswordChangeIsLoading } =
    initiatePasswordChangeStatus;

  const handleInitiatePasswordChange = async (
    request: InitPasswordChangeType
  ) => {
    const result: any = await initiatePasswordChange(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (typeof e === "string") {
        errorDescription = e;
      }
      notification.error({
        message: t("passwordModalResetCodeErrorMessage"),
        description: errorDescription
      });
    } else {
      setFormStep("password");
      notification.success({
        message: t("PasswordModalResetCodeSuccess") + (email ?? user?.email)
      });
    }
  };

  /*  Get API for Perform Password Change */
  const [changePassword, changePasswordStatus] = useChangePasswordMutation();
  const { isLoading: changePasswordIsLoading } = changePasswordStatus;

  const handleChangePassword = async (request: ChangePasswordType) => {
    const result: any = await changePassword(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (typeof e === "string") {
        errorDescription = e;
      } else if (Array.isArray(e)) {
        errorDescription = e[0].description;
      }
      notification.error({
        message: t("ChangePasswordErrorMessage"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("ChangePasswordSuccessMessage") });
      setEmail(undefined);
      setResetCode(undefined);
      setPassword(undefined);
      setFormStep("email");
      dispatch(closeChangePasswordModal());
    }
  };

  // When user klicks Send Reset Code
  const initFinished = () => {
    const userEmail = !isUndefined(email) ? email : user?.email;
    if (!isUndefined(userEmail)) {
      const request = {
        email: userEmail
      };
      handleInitiatePasswordChange(request);
    }
  };

  // When user klicks Send Reset Code, but form is incomplete
  const initFailed = () => {};

  // When user klicks Set New Password
  const changeFinished = () => {
    const userEmail = email ?? user?.email;
    if (
      !isUndefined(userEmail) &&
      !isUndefined(resetCode) &&
      !isUndefined(password)
    ) {
      const request = {
        email: userEmail,
        token: resetCode,
        password: password
      };
      handleChangePassword(request);
    }
  };

  // When user klicks Set New Password, but it is incomplete
  const changeFailed = () => {};

  return (
    <>
      <StandardModal
        title={t("ResetPassword")}
        zIndex={1045}
        open={isChangePasswordModalOpen}
        onCancel={() => {
          setFormStep("email");
          dispatch(closeChangePasswordModal());
        }}
        closable={true}
        footer={null}
        destroyOnClose={true}
      >
        <Card type="inner" style={{ marginBottom: size.m1 }}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={initFinished}
            onFinishFailed={initFailed}
            layout="vertical"
            style={{ width: "100%" }}
            disabled={formStep !== "email"}
          >
            <Form.Item
              label={t("Email")}
              name="email"
              rules={[{ required: true, message: t("EmailIsRequired") }]}
              initialValue={!isUndefined(user?.email) ? user?.email : ""}
            >
              <Input
                placeholder="user@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <PrimaryButton
                style={{ width: "100%", height: "40px" }}
                htmlType="submit"
                loading={initPasswordChangeIsLoading}
              >
                {t("SendResetCode")}
              </PrimaryButton>
            </Form.Item>
          </Form>
        </Card>

        <Card type="inner">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={changeFinished}
            onFinishFailed={changeFailed}
            layout="vertical"
            style={{ width: "100%" }}
            disabled={formStep !== "password"}
          >
            <Form.Item
              label={t("ResetCode")}
              name="resetCode"
              rules={[{ required: true, message: t("genResetCodeRequired") }]}
            >
              <Input
                placeholder=""
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={t("NewPassword")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("MustHaveAtLeast6Characters"),
                  min: 6
                }
              ]}
              required
              tooltip={
                <>
                  {t("MustBeAtLeast6CharactersLong")}
                  <br />
                  {t("MustContainAtLeastOneNumber")}
                </>
              }
            >
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <PrimaryButton
                style={{ width: "100%", height: "40px" }}
                htmlType="submit"
                loading={changePasswordIsLoading}
              >
                {t("btnSetNewPassword")}
              </PrimaryButton>
            </Form.Item>
          </Form>
        </Card>
      </StandardModal>
    </>
  );
};

export default ChangePasswordModal;
