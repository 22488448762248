import React from "react";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";
import { QuickReportData } from "../GraphPage/QuickReportDashboardCard";
import QuickReportButton from "./QuickReportButton";
import { useSelector } from "react-redux";
import { selectActiveDatxFile } from "../../state/openDatxSlice";
import { RecordingDataBlockUnique } from "../../helpers/datasetHelper";

interface IProps {
  data: QuickReportData;
}

const QuickReport = (props: IProps) => {
  const { t } = useTranslation();
  const data = props.data;
  const datXData = useSelector(selectActiveDatxFile);

  const angleParams = data.recParams?.AngleParams;
  const accParams = data.recParams?.AccParams;
  const tempParams = data.recParams?.TempParams;
  const rhParams = data.recParams?.RhParams;
  const pressureParams = data.recParams?.PressureParams;

  type dataBlock = RecordingDataBlockUnique[] | undefined;
  const calculateNumOfAcc = () => {
    const acc: dataBlock[] = [];
    acc.push(
      datXData?.datxContent.data.filter(
        (t) =>
          !isUndefined(t.xAcc) && !isUndefined(t.yAcc) && !isUndefined(t.zAcc)
      )
    );
    return acc.map((e) => e?.length);
  };

  const calculateNumOfTemp = () => {
    const temp: dataBlock[] = [];
    temp.push(datXData?.datxContent.data.filter((t) => !isUndefined(t.temp)));
    return temp.map((e) => e?.length);
  };

  const calculateNumOfRh = () => {
    const rh: dataBlock[] = [];
    rh.push(datXData?.datxContent.data.filter((t) => !isUndefined(t.rh)));
    return rh.map((e) => e?.length);
  };

  const calculateNumOfAngle = () => {
    const angle: dataBlock[] = [];
    angle.push(datXData?.datxContent.data.filter((t) => !isUndefined(t.angle)));
    return angle.map((e) => e?.length);
  };

  const calculateNumOfPressure = () => {
    const pressure: dataBlock[] = [];
    pressure.push(
      datXData?.datxContent.data.filter((t) => !isUndefined(t.pressureRaw))
    );
    return pressure.map((e) => e?.length);
  };

  const green = "#8bca83";
  const gray = "#d9d9d9";
  const red = "#ee2624";

  const accColor = (): string => {
    if (isUndefined(data.accWarning)) {
      return gray;
    } else if (data.accWarning === false) {
      return green;
    } else if (data.accWarning === true) {
      return red;
    } else return gray;
  };
  const tempColor = (): string => {
    if (isUndefined(data.tempWarning)) {
      return gray;
    } else if (data.tempWarning === false) {
      return green;
    } else if (data.tempWarning === true) {
      return red;
    } else return gray;
  };
  const rhColor = (): string => {
    if (isUndefined(data.rhWarning)) {
      return gray;
    } else if (data.rhWarning === false) {
      return green;
    } else if (data.rhWarning === true) {
      return red;
    } else return gray;
  };
  const angleColor = (): string => {
    if (isUndefined(data.angleWarning)) {
      return gray;
    } else if (data.angleWarning === false) {
      return green;
    } else if (data.angleWarning === true) {
      return red;
    } else return gray;
  };
  const pressureColor = (): string => {
    if (isUndefined(data.pressureWarning)) {
      return gray;
    } else if (data.pressureWarning === false) {
      return green;
    } else if (data.pressureWarning === true) {
      return red;
    } else return gray;
  };

  /* 
    Sometimes useAcc, useTemp etc. returns true when it doesn't contain any data.
    Checking if warning is undefined as well solves that issue.
  */
  const accCheck = accParams?.useAcc && !isUndefined(data.accWarning);
  const tempCheck = tempParams?.useTemp && !isUndefined(data.tempWarning);
  const rhCheck = rhParams?.useRh && !isUndefined(data.rhWarning);
  const angleCheck = angleParams?.useAngle && !isUndefined(data.angleWarning);
  const pressureCheck =
    pressureParams?.usePressure && !isUndefined(data.pressureWarning);

  const quantity =
    Number(accCheck) +
    Number(tempCheck) +
    Number(rhCheck) +
    Number(angleCheck) +
    Number(pressureCheck);

  /** Calulating width of table based on number of columns present in table */
  const calcTableWidth = () => {
    switch (quantity) {
      case 2:
        return "40%";
      case 3:
        return "60%";
      case 4:
        return "80%";
      case 5:
        return "100%";
      default:
        return "20%";
    }
  };

  /**
   * Setting a fixed width on each cell, otherwise the cells have different widths
   * depending on the length of its text
   */
  const calcCellWidth = () => {
    switch (quantity) {
      case 2:
        return "50%";
      case 3:
        return "33%";
      case 4:
        return "25%";
      case 5:
        return "20%";
      default:
        return "100%";
    }
  };

  const standardStyle: React.CSSProperties = {
    fontSize: "small",
    color: "gray",
    paddingInline: size.s1,
    width: calcCellWidth()
  };

  const headingStyle: React.CSSProperties = {
    padding: size.s1
  };

  const trStyle: React.CSSProperties = {
    lineHeight: "normal"
  };

  return (
    <>
      <table
        style={{
          width: calcTableWidth(),
          marginTop: size.xl1,
          textAlign: "center"
        }}
      >
        <tr>
          {accCheck ? (
            <td>
              <QuickReportButton color={accColor()} warning={data.accWarning} />
            </td>
          ) : undefined}
          {tempCheck ? (
            <td>
              <QuickReportButton
                color={tempColor()}
                warning={data.tempWarning}
              />
            </td>
          ) : undefined}
          {rhCheck ? (
            <td>
              <QuickReportButton color={rhColor()} warning={data.rhWarning} />
            </td>
          ) : undefined}
          {angleCheck ? (
            <td>
              <QuickReportButton
                color={angleColor()}
                warning={data.angleWarning}
              />
            </td>
          ) : undefined}
          {pressureCheck ? (
            <td>
              <QuickReportButton
                color={pressureColor()}
                warning={data.pressureWarning}
              />
            </td>
          ) : undefined}
        </tr>

        {/* Table header */}
        <tr>
          {accCheck ? (
            <th style={headingStyle}>{t("Acceleration")}</th>
          ) : undefined}
          {tempCheck ? (
            <th style={headingStyle}>{t("Temperature")}</th>
          ) : undefined}
          {rhCheck ? <th style={headingStyle}>{t("Humidity")}</th> : undefined}
          {angleCheck ? <th style={headingStyle}>{t("Angle")}</th> : undefined}
          {pressureCheck ? (
            <th style={headingStyle}>{t("Pressure")}</th>
          ) : undefined}
        </tr>

        {/* Table row 1 */}
        <tr style={trStyle}>
          {accCheck ? (
            <td style={standardStyle}>
              {t("XAlarm")}: {accParams?.params.Xalarm}g {accParams?.params.Xms}
              ms
            </td>
          ) : undefined}
          {tempCheck ? (
            <td style={standardStyle}>
              {t("HighAlarm")}: {tempParams?.params.highAlarm}°C
            </td>
          ) : undefined}
          {rhCheck ? (
            <td style={standardStyle}>
              {t("HighAlarm")}: {rhParams?.params.highAlarm}%
            </td>
          ) : undefined}
          {angleCheck ? (
            <td style={standardStyle}>
              {t("XAlarm")}: {angleParams?.params.xAlarmLevel}°
            </td>
          ) : undefined}
          {pressureCheck ? (
            <td style={standardStyle}>
              {t("HighAlarm")}: {pressureParams?.params.highAlarm}mBar
            </td>
          ) : undefined}
        </tr>

        {/* Table row 2 */}
        <tr style={trStyle}>
          {accCheck ? (
            <td style={standardStyle}>
              {t("YAlarm")}: {accParams?.params.Yalarm}g {accParams?.params.Yms}
              ms
            </td>
          ) : undefined}
          {tempCheck ? (
            <td style={standardStyle}>
              {t("LowAlarm")}: {tempParams?.params.lowAlarm}°C
            </td>
          ) : undefined}
          {rhCheck ? (
            <td style={standardStyle}>
              {t("LowAlarm")}: {rhParams?.params.lowAlarm}%
            </td>
          ) : undefined}
          {angleCheck ? (
            <td style={standardStyle}>
              {t("YAlarm")}: {angleParams?.params.yAlarmLevel}°
            </td>
          ) : undefined}
          {pressureCheck ? (
            <td style={standardStyle}>
              {t("LowAlarm")}: {pressureParams?.params.lowAlarm}mBar
            </td>
          ) : undefined}
        </tr>

        {/* Table row 3 */}
        <tr style={trStyle}>
          {accCheck ? (
            <td style={standardStyle}>
              {t("ZAlarm")}: {accParams?.params.Zalarm}g {accParams?.params.Zms}
              ms
            </td>
          ) : undefined}
          {tempCheck ? <td style={standardStyle}></td> : undefined}
          {rhCheck ? <td style={standardStyle}></td> : undefined}
          {angleCheck ? (
            <td style={standardStyle}>
              {t("ZAlarm")}: {angleParams?.params.zAlarmLevel}°
            </td>
          ) : undefined}
          {pressureCheck ? <td style={standardStyle}></td> : undefined}
        </tr>

        {/* Table row 4 */}
        <tr style={trStyle}>
          {accCheck ? (
            <td style={standardStyle}>
              {t("Measurements")}: {calculateNumOfAcc()}
            </td>
          ) : undefined}
          {tempCheck ? (
            <td style={standardStyle}>
              {t("Measurements")}: {calculateNumOfTemp()}
            </td>
          ) : undefined}
          {rhCheck ? (
            <td style={standardStyle}>
              {t("Measurements")}: {calculateNumOfRh()}
            </td>
          ) : undefined}
          {angleCheck ? (
            <td style={standardStyle}>
              {t("Measurements")}: {calculateNumOfAngle()}
            </td>
          ) : undefined}
          {pressureCheck ? (
            <td style={standardStyle}>
              {t("Measurements")}: {calculateNumOfPressure()}
            </td>
          ) : undefined}
        </tr>
      </table>
    </>
  );
};

export default QuickReport;
