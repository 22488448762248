import React from "react";
import { Card, CardProps, Row } from "antd";
import colors from "../../styles/commonColors";
import { LargeHeaderTitle } from "./CommonFonts";
import { impersonateBannerHeight, size } from "../../helpers/pageHelper";

const { subMenuColor } = colors;

const boxShadowStyle: React.CSSProperties = {
  boxShadow: "0 2px 12px -2px rgb(0 0 0 / 25%)"
};

const subMenuStyle: React.CSSProperties = {
  backgroundColor: subMenuColor,
  ...boxShadowStyle
};

const headerCardHeight = "65px";

export const NormalCard: React.FC<CardProps> = (props) => {
  return (
    <Card
      size="small"
      {...props}
      styles={{
        body: { padding: 0 },
        header: { height: headerCardHeight },
        ...props.styles
      }}
      style={{
        height: "100%",
        ...boxShadowStyle,
        ...props.style
      }}
    >
      {props.children}
    </Card>
  );
};

/* Standard Antd card plus box shadow */
export const LiftedCard: React.FC<CardProps> = (props) => {
  return (
    <Card
      {...props}
      style={{
        ...boxShadowStyle,
        ...props.style
      }}
    >
      {props.children}
    </Card>
  );
};

export const NormalHeaderCard: React.FC<CardProps> = (props) => {
  return (
    <NormalCard
      {...props}
      styles={{
        body: {
          height: headerCardHeight,
          paddingInline: size.m1,
          paddingBlock: size.s2
        },
        ...props.styles
      }}
      style={{
        ...subMenuStyle,
        width: "100%",
        height: undefined,
        ...props.style
      }}
    >
      {props.children}
    </NormalCard>
  );
};

export interface NormalSideMenuCardProps extends CardProps {
  isExpanded?: boolean;
  isFloating?: boolean;
  isImpersonating: boolean;
}

export const NormalSideMenuCard: React.FC<NormalSideMenuCardProps> = (
  props
) => {
  // Separate isExpanded, isFloating and isImpersonating from normal CardProps
  const { isExpanded, isFloating, isImpersonating, ...cardprops } = props;
  const width = isExpanded ? props.style?.width ?? "280px" : 0;

  const renderTitle = () => (
    <Row style={{ height: "100%" }} align="middle">
      <LargeHeaderTitle>{props.title}</LargeHeaderTitle>
    </Row>
  );

  return (
    <>
      {/* Note: This is a trick to avoid content underneeth the menu (if the prop "isFloating" is false) */}
      <div style={{ paddingRight: isFloating ? 0 : width }} />
      <NormalCard
        size="default"
        {...cardprops}
        title={renderTitle()}
        style={{
          ...subMenuStyle,
          top: isImpersonating
            ? `calc(48px + ${impersonateBannerHeight}px)`
            : "48px",
          bottom: 0,
          textAlign: "left",
          minHeight: `calc(100vh ${isImpersonating && "- 48px"})`,
          maxHeight: `calc(100vh ${isImpersonating && "- 48px"})`,
          position: "fixed",
          width: width,
          zIndex: 2,
          transitionProperty: "width",
          transitionDuration: "500ms",
          transitionTimingFunction: "ease",
          overflow: "hidden",
          ...props.style
        }}
        styles={{
          body: {
            height: "100%",
            padding: "0"
          },
          header: {
            paddingLeft: size.m2,
            paddingRight: size.m2
          },
          ...props.styles
        }}
      >
        {props.children}
      </NormalCard>
    </>
  );
};

/** Card that aims to look like a real life paper. Usefull for previewing things
 *that can be printer to paper */
export const CardRealPapperLook = (props: CardProps) => {
  return (
    <Card
      {...props}
      style={{
        boxShadow: "0 2px 14px rgba(0,0,0,.4)",
        ...props.style
      }}
    >
      {props.children}
    </Card>
  );
};
