import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ILteStatus, selectLteStatus } from "../../state/openDatxSlice";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import LteTable, { LteRowType, LteTableData } from "./LteTable";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import { connectionColors } from "../../constants/colors";
import { lteStatusBitMasks } from "../../constants/FAT100-prot-constants";
import { TFunction } from "i18next";

interface IProps {
  fileId: string;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}

interface RowPart {
  rowType: LteRowType;
  message: string;
  color: string;
}
const getError = (status: number, t: TFunction): RowPart | undefined => {
  const {
    lteFailedToInitLTEModule,
    lteFailedToConnectToNetwork,
    lteFailedToReachDataserver,
    lteTransmissionNotCompleted,
    lteNoSuitablePowerAvailable,
    lteNoSIMFound,
    lteAntennaError
  } = lteStatusBitMasks;

  const errorCode = status & lteStatusBitMasks.errorCodeMask;
  switch (errorCode) {
    case lteFailedToInitLTEModule:
      return {
        rowType: "error",
        message: t("FailedToInitLTEModule"),
        color: connectionColors.error
      };
    case lteFailedToConnectToNetwork:
      return {
        rowType: "error",
        message: t("FailedToConnectToNetwork"),
        color: connectionColors.error
      };
    case lteFailedToReachDataserver:
      return {
        rowType: "error",
        message: t("FailedToReachDataserver"),
        color: connectionColors.error
      };
    case lteTransmissionNotCompleted:
      return {
        rowType: "error",
        message: t("TransmissionNotCompleted"),
        color: connectionColors.error
      };
    case lteNoSuitablePowerAvailable:
      return {
        rowType: "error",
        message: t("NoSuitablePowerAvailable"),
        color: connectionColors.error
      };
    case lteNoSIMFound:
      return {
        rowType: "error",
        message: t("NoSIMFound"),
        color: connectionColors.error
      };
    case lteAntennaError:
      return {
        rowType: "error",
        message: t("LTEAntennaError"),
        color: connectionColors.error
      };
  }
  return undefined;
};

const getStatus = (status: number, t: TFunction): RowPart | undefined => {
  const {
    lteBlockedByGPS,
    lteFailedToInitCoMCU,
    lteCommunicationProblemsCoMCU,
    ltePowerOn,
    ltePowerOff,
    lteSessionEnded
  } = lteStatusBitMasks;

  if (status & lteBlockedByGPS) {
    return {
      rowType: "blocked",
      message: t("BlockedByGPS"),
      color: connectionColors.blocked
    };
  }
  if (status & lteFailedToInitCoMCU) {
    return {
      rowType: "error",
      message: t("FailedToInitCoMCU"),
      color: connectionColors.error
    };
  }
  if (status & lteCommunicationProblemsCoMCU) {
    return {
      rowType: "error",
      message: t("CommunicationProblemsCoMCU"),
      color: connectionColors.error
    };
  }
  if (status & ltePowerOn) {
    return {
      rowType: "power",
      message: t("LTEPowerOn"),
      color: connectionColors.powerOn
    };
  }
  if (status & ltePowerOff) {
    return {
      rowType: "power",
      message: t("LTEPowerOff"),
      color: connectionColors.powerOff
    };
  }
  if (status & lteSessionEnded) {
    return {
      rowType: "sessionEnded",
      message: t("LTESessionEnded"),
      color: connectionColors.sessionEnded
    };
  }
  return undefined;
};

const getTrigger = (status: number, t: TFunction): RowPart | undefined => {
  const { lteTriggeredBySensor, lteTriggeredBySchedule } = lteStatusBitMasks;

  if (status & lteTriggeredBySensor) {
    return {
      rowType: "sensor",
      message: t("LTETriggeredBySensor"),
      color: connectionColors.sensor
    };
  }
  if (status & lteTriggeredBySchedule) {
    return {
      rowType: "schedule",
      message: t("LTETriggeredBySchedule"),
      color: connectionColors.schedule
    };
  }

  return undefined;
};

const createLteTableData = (
  data: ILteStatus[],
  t: TFunction
): LteTableData[] => {
  let statusRows: LteTableData[] = [];
  data.forEach((status, i) => {
    // Only add status rows with a status
    if (status.status.length > 0) {
      // A status can include multiple status codes on the same timestamp
      status.status.forEach((s) => {
        const errorCode = getError(s, t);
        const statusCode = getStatus(s, t);
        const trigger = getTrigger(s, t);
        if (errorCode && trigger) {
          statusRows.push({
            key: statusRows.length,
            rowType: [errorCode.rowType, trigger.rowType],
            message: [errorCode.message, trigger.message],
            color: [errorCode.color, trigger.color],
            timestamp: status.timestamp
          });
        }
        if (statusCode && trigger) {
          statusRows.push({
            key: statusRows.length,
            rowType: [statusCode.rowType, trigger.rowType],
            message: [statusCode.message, trigger.message],
            color: [statusCode.color, trigger.color],
            timestamp: status.timestamp
          });
        }
      });
    }
  });

  return statusRows;
};

const LteDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { lteStatus, timezone } = useSelector(selectLteStatus(props.fileId));

  const tableData = createLteTableData(lteStatus, t);

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  return (
    <MoveableCard
      title={t("lteLog")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={props.fileId}
    >
      <LteTable dataObject={tableData} timezone={timezone} />
    </MoveableCard>
  );
};
export default LteDashboardCard;
