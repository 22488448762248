import React from "react";
import GeneralRecordingInformation from "../MicroComponents/GeneralRecordingInformation";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import { useSelector } from "react-redux";
import {
  selectDashboardHeader,
  selectDataForGeneralRecordingInfo,
  selectForDashboardRangePicker,
  selectGraphDomains,
  selectProjectInfo
} from "../../state/openDatxSlice";
import {
  PdfExportComponent,
  HeaderData,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { useTranslation } from "react-i18next";
import { getUser } from "../../state/sessionSlice";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import dayjs from "dayjs";

interface IProps {
  fileId: string;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}
const GeneralRecordingInformationDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const data = useSelector(selectDataForGeneralRecordingInfo(props.fileId))!;
  const projectInfo = useSelector(selectProjectInfo(props.fileId));
  const domains = useSelector(selectGraphDomains(props.fileId));
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const { dataDomain, timezone } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);

  const utcOffsetStr = createUtcOffsetStr(timezone);

  const { isPrinting, startPrint, finishPrint } = usePrintHook();
  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      printAction: startPrint
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const headerData: HeaderData = {
    reportType: "RecordingInformation",
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <MoveableCard
      title={t("RecordingInformation")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={props.fileId}
    >
      {isPrinting && (
        <PrintableComponent
          WrappedComponent={GeneralRecordingInformation}
          props={{ data }}
          reportPrintDone={finishPrint}
          header={headerData}
          orientation="portrait"
        />
      )}
      {isExporting && (
        <PdfExportComponent
          ComponentBody={GeneralRecordingInformation}
          props={{ data }}
          type="html"
          name="recording_information.pdf"
          header={headerData}
          reportExportDone={finishExport}
        />
      )}
      <GeneralRecordingInformation data={data} />
    </MoveableCard>
  );
};

export default GeneralRecordingInformationDashboardCard;
