import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreApi } from "./store";

export type CreateUserProgress = 0 | 1;

export type CreateUserProgressStepStatus =
  | "waiting"
  | "inProgress"
  | "validated";

export type CurrentAdminTab = "users" | "licenses" | "invoices" | "store";

export type CurrentMobitronAdminTab =
  | "users"
  | "licenses"
  | "invoices"
  | "store"
  | "discounts"
  | "companies"
  | "help"
  | "dev";

/** Main interface for this state-slice */
export interface NavigationState {
  currentMobitronAdminTab: CurrentMobitronAdminTab;
  currentAdminTab: CurrentAdminTab;
  createUserProgress: CreateUserProgress;
  isCreateUserSuccess: boolean;
  isCreateCompanySuccess: boolean;
  isParamsSideMenuOpen: boolean;
  isGraphSideMenuOpen: boolean;
  isCompareGraphSideMenuOpen: boolean;
}

/** Initial state for this slice */
const initialState: NavigationState = {
  currentMobitronAdminTab: "users",
  currentAdminTab: "users",
  createUserProgress: 0,
  isCreateUserSuccess: false,
  isCreateCompanySuccess: false,
  isParamsSideMenuOpen: false,
  isGraphSideMenuOpen: false,
  isCompareGraphSideMenuOpen: false
};

export const slice = createSlice({
  name: "Navigation",
  initialState,
  reducers: {
    setCurrentAdminTab: (state, action: PayloadAction<CurrentAdminTab>) => {
      state.currentAdminTab = action.payload;
    },
    setCurrentMobitronAdminTab: (
      state,
      action: PayloadAction<CurrentMobitronAdminTab>
    ) => {
      state.currentMobitronAdminTab = action.payload;
    },
    resetNavigationState: (state) => {
      state.currentMobitronAdminTab = "users";
      state.currentAdminTab = "users";
    },

    setCreateUserAccountProgress: (state, action) => {
      state.createUserProgress = action.payload;
    },
    nextCreateUserPage: (state) => {
      state.createUserProgress += 1;
    },
    prevCreateUserPage: (state) => {
      state.createUserProgress -= 1;
    },
    setCreateUserSuccess: (state, action: PayloadAction<boolean>) => {
      state.isCreateUserSuccess = action.payload;
    },
    setCreateCompanySuccess: (state, action: PayloadAction<boolean>) => {
      state.isCreateCompanySuccess = action.payload;
    },
    setHoverParamsSideMenu: (state, action: PayloadAction<boolean>) => {
      state.isParamsSideMenuOpen = action.payload;
    },
    setHoverGraphSideMenu: (state, action: PayloadAction<boolean>) => {
      state.isGraphSideMenuOpen = action.payload;
    },
    setHoverCompareGraphSideMenu: (state, action: PayloadAction<boolean>) => {
      state.isCompareGraphSideMenuOpen = action.payload;
    }
  }
});

export const {
  setCurrentAdminTab,
  setCurrentMobitronAdminTab,
  resetNavigationState,
  setCreateUserAccountProgress,
  nextCreateUserPage,
  prevCreateUserPage,
  setCreateUserSuccess,
  setCreateCompanySuccess,
  setHoverParamsSideMenu,
  setHoverGraphSideMenu,
  setHoverCompareGraphSideMenu
} = slice.actions;

export const selectNavigation = (state: StoreApi) => state.navigation;

export const selectCreateUserPageProgress = (state: StoreApi) => {
  const { createUserProgress } = state.navigation;

  const getStatus = (
    key: CreateUserProgress,
    isValid: boolean
  ): CreateUserProgressStepStatus =>
    createUserProgress === key
      ? "inProgress"
      : isValid
        ? "validated"
        : "waiting";

  const userValid = state.navigation.isCreateUserSuccess; // Check if user account is created successfully
  const companyValid = state.navigation.isCreateCompanySuccess; // Check if company is created successfully

  const progressStatus: Record<
    CreateUserProgress,
    CreateUserProgressStepStatus
  > = {
    0: getStatus(0, userValid),
    1: getStatus(1, companyValid)
  };

  return { createUserProgress, progressStatus };
};

export const selectParamsSideMenuOpen = (state: StoreApi) =>
  state.navigation.isParamsSideMenuOpen;

export const selectGraphSideMenuOpen = (state: StoreApi) =>
  state.navigation.isGraphSideMenuOpen;

export const selectCompareGraphSideMenuOpen = (state: StoreApi) =>
  state.navigation.isCompareGraphSideMenuOpen;

export default slice.reducer;
