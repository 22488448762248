import { FilePdfOutlined } from "@ant-design/icons";
import { Col, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createUtcOffsetStr, guessLocalTz } from "../../helpers/dateHelper";
import { getEndDate, getStartDate } from "../../helpers/exportHelper";
import useKeyPress from "../../helpers/useKeyPress";
import { selectRecordingParametersItemsForPrint } from "../../state/openParxSlice";
import {
  nextParamsPage,
  prevParamsPage,
  selectParamsPageState
} from "../../state/paramsPageSlice";
import { NormalButton, PrintButton } from "../Common/CommonButtons";
import { NormalCard } from "../Common/CommonCards";
import { Help } from "../MicroComponents/Help";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import {
  HeaderData,
  PdfExportComponent,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import ParamsPreview from "./ParamsPreview";
import { size } from "../../helpers/pageHelper";

interface headerProps {
  header: HeaderData;
}

/** Local component: Print current recording parameters */
const PrintRecordingParametersButton = (headerProps: headerProps) => {
  const { t } = useTranslation();

  const { recordingParameters, targetDevice: systemInfo } = useSelector(
    selectRecordingParametersItemsForPrint
  );

  const printHelper = usePrintHook();

  const onKeyPressPrint = () => {
    printHelper.startPrint();
  };

  useKeyPress(["p"], onKeyPressPrint, "Control");

  return (
    <>
      {printHelper.isPrinting && (
        <PrintableComponent
          WrappedComponent={ParamsPreview}
          props={{
            systemInfo,
            recordingParameters
          }}
          header={headerProps.header}
          reportPrintDone={printHelper.finishPrint}
          orientation="portrait"
        />
      )}
      <PrintButton onClick={printHelper.startPrint}>{t`Print`}</PrintButton>
    </>
  );
};

/** Local component: Export current recording parameters as PDF*/
const ExportRecordingParametersAsPdfButton = (headerProps: headerProps) => {
  const { recordingParameters, targetDevice: systemInfo } = useSelector(
    selectRecordingParametersItemsForPrint
  );

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  return (
    <>
      {isExporting && (
        <PdfExportComponent
          ComponentBody={ParamsPreview}
          props={{
            systemInfo,
            recordingParameters
          }}
          header={headerProps.header}
          type="html"
          name="recording_parameters.pdf"
          reportExportDone={finishExport}
        />
      )}
      <NormalButton icon={<FilePdfOutlined />} onClick={startExport}>
        PDF
      </NormalButton>
    </>
  );
};

interface IProps {
  contentWidth: number;
}
const ParamsBuilderFooter: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { userProgress } = useSelector(selectParamsPageState);

  const dispatch = useDispatch();

  const shouldPrevBtnRender = userProgress !== 0;
  const shouldLoadBtnRender = userProgress === 3;

  const goPrev = () => {
    dispatch(prevParamsPage());
  };

  const goNext = () => {
    dispatch(nextParamsPage());
  };

  const { recordingParameters, targetDevice } = useSelector(
    selectRecordingParametersItemsForPrint
  );

  const systemInfo = targetDevice;

  const timezoneStr = useSelector(guessLocalTz);
  const utcOffsetStr = createUtcOffsetStr(timezoneStr);
  const timezone = {
    timezoneStr,
    utcOffsetStr
  };

  const header: HeaderData = {
    reportType: "ParametersPreview",
    projectName: recordingParameters.ProjectName,
    recStart: getStartDate(recordingParameters, timezone, t),
    recEnd: getEndDate(recordingParameters, timezone, t),
    deviceId: systemInfo.serial.toString()
  };

  return (
    <NormalCard size="default" style={{ width: "100%", padding: size.l2 }}>
      <Row justify="center">
        <Row
          justify="space-between"
          style={{ maxWidth: props.contentWidth, width: "100%" }}
        >
          <Col>
            {shouldPrevBtnRender && (
              <NormalButton onClick={goPrev}>{t("Previous")}</NormalButton>
            )}
          </Col>
          <Col>
            {shouldLoadBtnRender ? (
              <Space>
                <ExportRecordingParametersAsPdfButton header={header} />
                <PrintRecordingParametersButton header={header} />
              </Space>
            ) : (
              Help(
                <NormalButton onClick={goNext}>{t("Next")}</NormalButton>,
                t("ClickHereToGoToTheNextStep"),
                "left"
              )
            )}
          </Col>
        </Row>
      </Row>
    </NormalCard>
  );
};

export default ParamsBuilderFooter;
