import React, { useState } from "react";
import { Col, Form, Input, notification, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCreateProjectModal,
  projectsState
} from "../../state/projectSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import { size } from "../../helpers/pageHelper";
import {
  ProjectForCreation,
  useCreateProjectMutation
} from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
import StandardModal from "../Common/StandardModal";

const { TextArea } = Input;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const CreateProjectModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isCreateProjectModalOpen } = useSelector(projectsState);

  const [createProject, requestStatus] = useCreateProjectMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();

  const handleCreateProject = async (request: ProjectForCreation) => {
    const result: any = await createProject(request);
    if (result.data) {
      notification.success({ message: t("SuccessfullyCreatedProject") });
      dispatch(closeCreateProjectModal());
    } else {
      notification.error({ message: t("UnableToCreateProject") });
    }
  };

  const onFinish = () => {
    if (!isUndefined(title)) {
      const request: ProjectForCreation = {
        title,
        description
      };
      handleCreateProject(request);
    }
  };

  return (
    <StandardModal
      title={t("CreateProject")}
      open={isCreateProjectModalOpen}
      onCancel={() => dispatch(closeCreateProjectModal())}
      closable={true}
      destroyOnClose={true}
      zIndex={1045}
      footer={null}
    >
      <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish}>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t("ProjectTitle")}
              name="projectTitle"
              rules={[{ required: true, message: t("ProjectTitleIsRequired") }]}
            >
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength={50}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingBottom: size.l2 }}>
          <Col span={24}>
            <Form.Item
              label={t("ProjectDescription")}
              name="projectDescription"
            >
              <TextArea
                rows={3}
                maxLength={512}
                showCount
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginBottom: 0 }}>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={requestIsLoading}
          >
            {t("CreateProject")}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </StandardModal>
  );
};

export default CreateProjectModal;
