import { Col, Row, Space } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { iconColors } from "../../constants/colors";

import { useTranslation } from "react-i18next";
import { SmallText } from "../Common/CommonFonts";
import { isNil } from "lodash-es";
import { VMDvaData } from "../../helpers/dataModelHelper";
import { AccGeneral } from "../../icons";
import { selectDvaGraphTools } from "../../state/openDatxSlice";
import { size } from "../../helpers/pageHelper";

interface IProps {
  dvaDataBlock: VMDvaData;
}
const DvaScoreValues: React.FC<IProps> = (props) => {
  const { dvaDataBlock } = props;
  const { t } = useTranslation();

  const graphTools = useSelector(selectDvaGraphTools);

  const scoreValues =
    !isNil(graphTools?.dvaScoreValuesCursorPosIndex) &&
    dvaDataBlock.data[graphTools!.dvaScoreValuesCursorPosIndex];

  if (scoreValues) {
    return (
      <>
        <div>
          <Row
            justify="space-between"
            align="middle"
            style={{ width: "100%", paddingBottom: size.s2 }}
          >
            <Col>
              <Space>
                <AccGeneral width="25px" height="25px" />
                <SmallText strong>{t("generalAccTitle")}</SmallText>
              </Space>
            </Col>
          </Row>

          <Row justify="space-between" style={{ width: "100%" }}>
            <Col style={{ minWidth: "45px" }}>
              <Row justify="start">
                <SmallText style={{ color: iconColors.xAcc }}>X</SmallText>
              </Row>
              <Row justify="start">
                <SmallText>{scoreValues.xAlarm}g</SmallText>
              </Row>
            </Col>
            <Col style={{ minWidth: "45px" }}>
              <Row justify="start">
                <SmallText style={{ color: iconColors.yAcc }}>Y</SmallText>
              </Row>
              <Row justify="start">
                <SmallText>{scoreValues.yAlarm}g</SmallText>
              </Row>
            </Col>
            <Col style={{ minWidth: "45px" }}>
              <Row justify="start">
                <SmallText style={{ color: iconColors.zAcc }}>Z</SmallText>
              </Row>
              <Row justify="start">
                <SmallText>{scoreValues.zAlarm}g</SmallText>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default React.memo(DvaScoreValues);
