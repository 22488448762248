import { createApi } from "@reduxjs/toolkit/query/react";
import { AvailableDashboardKey, UserDashboard } from "../helpers/layoutHelper";
import { ISystemInfoV2 } from "../models/ISystemInfo";
import baseQueryWithReauth from "../utils/baseQuery";
import { Buffer } from "buffer";
type AppType =
  | "app"
  | "app-dev"
  | "web"
  | "web-dist"
  | "win"
  | "win-dev"
  | "win-dist";

/** App type variables:

 * Mobile app
 ** "app"
 ** "app-dev"
 
 * Web app:
 ** "web"
 ** "web-dist"

* Windows app:
 ** "win"
 ** "win-dev"
 ** "win-dist"
 */
export const appType: AppType = "web";

/** Used for
 ** requestAuth Request */
export interface LoginDetails {
  email: string;
  password: string;
  appType?: string;
}

/** Used for
 ** requestAuth Response */
export interface Tokens {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpiry: string;
}

/** Used for
 ** initiatePasswordChange Request
 */
export interface InitPasswordChangeType {
  email: string;
  uri?: string;
}
/** Used for
 ** changePassword Request
 */
export interface ChangePasswordType {
  email: string;
  token: string;
  password: string;
}

/** Used for:
 ** getCompanyDetails Response
 ** updateCompanyDetails Request and Response
 ** createANewCompany Response
 ** mobitronGetCompanies Response
 */
export interface Company {
  id?: string;
  name: string;
  address: string;
  address2?: string;
  postalCode: string;
  city: string;
  countryCode?: string;
  currency?: string;
  orgNumber?: string;
  vatNumber?: string;
  invoiceEmail?: string;
  invoiceAddress?: string;
  invoicePostalCode?: string;
  invoiceCity?: string;
  adminEmail?: string;
  parentCompanyId?: string;
}

/** Used for:
 ** getCompanyDiscounts Response
 ** addCompanyDiscount Response
 ** removeCompanyDiscount Response
 */
export interface CompanyDiscount {
  id: number;
  created: string;
  companyId: string;
  company: Company;
  licensePriceId: string;
  licensePrice: LicensePrice;
  discountPercent: number;
}

export interface DatxListItem {
  id: string;
  created: string;
  companyId: string;
  fileName: string;
  userId: string;
  length: number;
  projectId?: string;
}

export interface OnlineFilesListItem {
  id: string;
  fileName: string;
  projectId: string;
  projectName: string;
  hasData: boolean;
  isActive: boolean;
  firstStart: string;
  userRights: string[];
}

/** Used for:
 ** getRecentDevice Response
 */
export interface RecentOnlineDevice {
  configVersion: number;
  systemInfo: ISystemInfoV2;
  lastInteraction: number;
  lastUser: User;
}

/** Used for:
 ** getCompanyTree Response
 */
export interface ChildCompany {
  id: string;
  name?: string;
  countryCode?: string;
  userCount?: number;
  childCompanies?: ChildCompany[];
}

/** Used for:
 ** getInvoices Response
 ** setInvoiceAsPaid Response
 ** mobitronGetInvoices Response
 */
export interface Invoice {
  id: number;
  created: string;
  expires: string;
  priceExclVat: number;
  priceTotal: number;
  vatAmount: number;
  vatPercent: number;
  currency: string;
  paymentTypeId: number;
  purchaseOrderNumber: string;
  paid?: string;
  companyId: string;
  orderLines: InvoiceOrderLines[];
}

/** Used for:
 ** Invoice
 */
export interface InvoiceOrderLines {
  text?: string;
  count: number;
  price: number;
  currency?: string;
  discountPercent: number;
  months: number;
}

/** Used for
 ** getLicenses Response */
export interface LicenseWithCost {
  id: string;
  created: string;
  endDate: string;
  userId: string;
  user: User;
  renewAuto: boolean;
  isActivated: boolean;
  text: string;
  price: number;
  currency: string;
  companyId: string;
  deviceType: number;
  licensePriceId: string;
  projectId?: string;
  orderId: string;
}

/* TODO: Clean up any types */
/** Used for
 ** getLicense Response */
export interface License {
  id: string;
  created: string;
  endDate: string;
  companyId: string;
  company?: Company;
  userId: string;
  user: User;
  projectId?: string;
  project?: any;
  renewAuto: boolean;
  isActivated: boolean;
  isDeleted?: string;
  lastOrderLineId?: number;
  lastOrderLine?: any;
  orderLineLicense?: any;
  licenseUserRights?: any;
}

/** Used for:
 ** getAllLicensePrices Response
 ** createLicensePrice Response
 ** updateLicensePrice Response
 */
export interface LicensePrice {
  id: string;
  created: string;
  userId: string;
  name: string;
  description: string;
  deviceType: number;
  color: string;
  licensePriceDefs: ILicensePriceDefs[];
  userRights: string[];
  companyDiscountPercent: number;
}

/** Used for:
 ** createLicensePrice Request
 ** updateLicensePrice Request
 */
export interface NewLicensePrice {
  id?: string;
  created?: string;
  userId?: string;
  name: string;
  description: string;
  deviceType: number;
  color: string;
  licensePriceDefs?: ILicensePriceDefs[];
  userRights?: string[];
  companyDiscountPercent?: number;
}

/** Used by
 ** LicensePrice Interface */
export interface ILicensePriceDefs {
  price: number;
  currency: string;
  months: number;
}

/** Used for
 ** mobitronGetUsers Response */
export interface UserWithCompanyAndLastActivity {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyId: string;
  temperatureScale: string;
  timeZone: string;
  company: Company;
  lastActivity?: string;
}

/** Used in
 ** mobitronImpersonate */
export interface Impersonate {
  id: string;
  userId?: string;
  userFullName?: string;
  impersonatedUserId?: string;
  impersonatedFullName?: string;
  start: string;
  end: string;
  fromAppType?: string;
  isLoggedOff: boolean;
}

/** Used for:
 ** getAllOrders Response
 ** createOrder Response
 */
export interface Order {
  id: string;
  invoiceNumber: string;
  created: string;
  expires?: string;
  userId?: string;
  user: User;
  priceTotal: number;
  priceExclVat?: number;
  vatAmount?: number;
  vatPercent?: number;
  paymentTypeId: number;
  currency: string;
  purchaseOrderNumber?: string;
  paid?: string;
  companyId: string;
  orderLines?: OrderLine[];
}

/** Used in
 ** Order Interface */
export interface OrderLine {
  id: number;
  licensePriceId: string;
  orderId: string;
  text: string;
  count: number;
  price: number;
  currency: string;
  discountPercent: number;
  months: number;
}

/** Used for
 ** createOrder Request */
export interface OrderForCreation {
  paymentTypeId: number;
  currency: string;
  subscriptionId?: string;
  planId?: string;
  purchaseOrderNumber?: string;
  licensePriceIdAndCount?: ILicensePriceIdAndCount[];
}

/** Used in
 ** OrderForCreation Interface */
interface ILicensePriceIdAndCount {
  licensePriceId?: string;
  count?: number;
  months?: number;
}

export interface RenewalOrder {
  paymentTypeId: number;
  currency: string;
  subscriptionId?: string;
  planId?: string;
  months?: number;
  purchaseOrderNumber?: string;
  licenses: string[];
}

/** Used in
 ** getParameters
 ** createParameters
 */
export interface Parameter {
  id: string;
  companyId: string;
  projectId: string;
  deviceName?: string;
  deviceDescription?: string;
  fileName?: string;
  hasData?: boolean;
  isActive?: boolean;
  created?: string;
  creator?: string;
  lastData?: string;
  lastUploader?: string;
  lastUploadType?: string;
  firstStart?: string;
}

/** Used in
 ** getParameters
 */
export interface GetParamsParams {
  projectId?: string;
  hasData?: boolean;
  count?: number;
  sort?: string;
  sortDirection?: string;
}

/** Used in
 ** createParameters */
export interface ParameterForCreation {
  id: string;
  projectId: string;
  deviceName?: string;
  deviceDescription?: string;
}

/** Used in
 ** updateParameters */
export interface ParameterForAlteration {
  id?: string;
  projectId: string;
  deviceName?: string;
  deviceDescription?: string;
  isActive?: boolean;
}

/** Used in
 ** createPayPalPlan */
export interface CreatePayPalPlanBody {
  amount: number;
  currency: string;
  totalCycles: number;
}

export interface CreatePayPalPlanResponse {
  id: string;
  product_Id: string;
  name: string;
  status: string;
  description: string;
  create_Time: string;
  links: PayPalLink[];
}

export interface PayPalLink {
  href: string;
  rel: string;
  method: string;
}

/** Used in
 ** GetAllProjects
 ** createProject
 ** updateProjectTitleDescription
 ** updateProjectStatus
 */
export interface Project {
  id: string;
  created: string;
  title: string;
  description?: string;
  status: number;
  createdByUserId?: string;
  companyId: string;
  companyName?: string;
  projectUsers?: ProjectUserWithoutProject[];
  projectNotesCount: number;
  alarmCount: number;
  datXFileCount: number;
  deviceCount: number;
  start?: string;
  end?: string;
}

/** Used in
 ** projectById
 */
export interface ProjectWithUserRights {
  id: string;
  created: string;
  title: string;
  description?: string;
  status: number;
  start?: string;
  end?: string;
  createdByUserId?: string;
  companyId: string;
  companyName?: string;
  projectUsers?: ProjectUserWithoutProject[];
  projectNotesCount: number;
  datXFiles?: DatxListItem[];
  alarms?: Alarms[];
  userRights?: string[]; // User's rights in this project
  parameterIds: ParameterInfo[];
}

/** Used in
 ** ProjectWithUserRights
 */
export interface ParameterInfo {
  parameterId?: string;
  deviceName?: string;
  hasData: boolean;
  fileName?: string;
  timeStamp?: string;
}

/** Used in
 ** ProjectWithUserRights */
export interface Alarms {
  parameterId?: string;
  dateTime: string;
  alarms?: string[];
}

/** Used in
 ** getProjectGps */
export interface GpsPerParameterId {
  parameterId?: string;
  projectId: string;
  gpsData?: GpsData[];
}

/** Used in
 ** GpsPerParameterId */
export interface GpsData {
  lat: number;
  lon: number;
  velocity: number;
  dateTime: string;
  status: number;
}

/** Used in
 ** Project */
export interface ProjectUserWithoutProject {
  id: string;
  created: string;
  user: UserWithCompanyAndLicense;
}

/** Used in
 ** ProjectUserWithoutProject */
export interface UserWithCompanyAndLicense {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  companyId?: string;
  temperatureScale?: string;
  timeZone?: string;
  company: Company;
  licenseNames?: string[];
  projectNotificationType: number;
}

/** Used in
 ** getProjectOverview Response */
export interface ProjectOverview {
  title: string;
  description: string;
  start: string;
  end: string;
  parameterActiveCount: number;
  parameterInactiveCount: number;
  parameterTotalCount: number;
  noteCount: number;
  latestData: {
    parameterId: string;
    deviceName: string;
    latestData: string;
    isActive: boolean;
    errorCount: number;
  }[];
  status: number;
  alarms: ProjectAlarms[];
  noteLatest: ProjectNote;
  alarmCount: number;
}

/** Used in
 ** getProjectAlarm Response */
export interface ProjectAlarms {
  dateTime: string;
  type: number;
  data: number[];
  parameterId: string;
  serial: string;
  fileName: string;
}

/** Used in
 ** createProject Response */
export interface ProjectForCreation {
  title: string;
  description?: string;
  status?: number;
}

/** Used in
 ** updateProjectTitleDescription */
export interface ProjectForUpdate {
  projectId: string;
  title: string;
  description?: string;
}

/** Used in
 ** createProjectInvite
 ** getProjectInvites */
export interface ProjectInvite {
  id: string;
  created: string;
  answered?: string;
  createdByUserId?: string;
  createdByUser: User;
  email?: string;
  inviteAccepted?: string;
  companyId: string;
  company: Company;
  licenseId: string;
  license: License;
  projectId: string;
  project: Project;
  connectedUserId?: string;
  connectedUser: User;
  isDeleted: boolean;
  accepted?: boolean;
}

/** Used in
 ** acceptProjectInvite */
export interface AcceptProjectInvite {
  projectInviteId: string;
  accept: boolean;
}

/** Used in
 ** createProjectInvite */
export interface ProjectInviteForCreation {
  email: string;
  licenseId: string;
  projectId: string;
}

/** Used in:
 ** getProjectNotes
 ** addProjectnote
 ** updateProjectNote */
export interface ProjectNote {
  id: string;
  created: string;
  projectId: string;
  user: ProjectNoteUser;
  text: string;
  lastEdit: string;
}

interface ProjectNoteUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

/** Used in:
 ** updateProjectNote */
export interface UpdateProjectNote {
  id: string;
  projectId: string;
  text: string;
}

/** Used in:
 ** getProjectNotificationById */
export interface ProjectNotification {
  id: string;
  projectId: string;
  userId?: string;
  type: number;
}

// export type EmailType = { ProjectNotificationType: 0 | 1 | 2 | 3 };

export interface ProjectNotificationForCreation {
  projectId: string;
  userId?: string;
  type: number;
}

export interface ProjectNotificationForAlteration {
  id: string;
  type: number;
}

/** Used for
 ** getSupportTickets Response */
export interface Support {
  id: string;
  created: string;
  userId?: string;
  userDto?: User;
  lastUserActivity?: string;
  companyId?: string;
  company?: Company;
  message: string;
  versionInfo?: string;
  operatingSystem?: string;
}

/** Used for
 ** createSupportTicket Request */
export interface SupportTicket {
  message: string;
  versionInfo?: string;
  operatingSystem?: string;
}

/** Used for
 ** getUsers Response
 ** createNewUser Response
 ** getCurrentUser Response
 ** getUserDetails Response
 ** deleteUserById Response
 */
export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyId: string;
  temperatureScale: string;
  timeZone: string;
}

/** Used for
 ** updateUserProperties Request */
export interface UserForUpdate {
  userId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  temperatureScale?: string;
  timeZone?: string;
}

/** Used for
 ** createNewUser Request */
export interface UserForRegistration {
  firstName: string;
  lastName?: string;
  password: string;
  email: string;
  phoneNumber?: string;
}

/** Used for
 ** getReportSettings */
export interface Reports {
  active: AvailableDashboardKey;
  available: Record<AvailableDashboardKey, UserDashboard>;
}

export type Language = "en" | "sv";
export type Currency = "SEK" | "USD" | "EUR";
export type TemperatureScale = "C" | "F";
export type SpeedUnit = "kmph" | "mph";
export type MapProvider = "google" | "openStreetMap" | "hereWeGo";
export type CsvFormat = "swe" | "eng";

/** Used for
 ** getUserSettings */
export interface UserSettings {
  language: Language;
  currency: Currency;
  temperatureScale: TemperatureScale;
  speedUnit: SpeedUnit;
  mapProvider: MapProvider;
  csvFormat: CsvFormat;
  useGlobalTimezone: boolean;
  globalTimezone?: string;
  showNearValues: boolean;
  autoUploadDatx: boolean;
  showQuickTourAtStartup: boolean;
  defaultBattery?: string;
}

/** Used for
 ** getUserTransferRequests Request */
export interface UserTransferRequest {
  id: string;
  created: string;
  userId: string;
  firstName: string;
  lastName: string;
}

export const cargologRestApi = createApi({
  reducerPath: "cargologRestApi",
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 60 * 60, // 1 hour
  tagTypes: [
    "user",
    "company",
    "devices",
    "invoice",
    "license",
    "discount",
    "licenseprice",
    "order",
    "support",
    "usertransferrequest",
    "reports",
    "datx",
    "project",
    "projectinvite",
    "projectuser",
    "projectnote",
    "projectnotification",
    "parameter",
    "impersonate"
  ],
  endpoints: (builder) => ({
    /* AUTH */
    // Login
    requestAuth: builder.mutation<Tokens, LoginDetails>({
      query: (authBody) => ({
        url: "auth/",
        method: "POST",
        body: authBody
      })
    }),
    // Impersonate
    impersonate: builder.mutation<
      Tokens,
      {
        userId: string;
      }
    >({
      query: (authBody) => ({
        url: "auth/impersonate/",
        method: "POST",
        body: authBody
      }),
      invalidatesTags: ["impersonate"]
    }),
    // Stop impersonate
    stopImpersonate: builder.mutation<Tokens, void>({
      query: () => ({
        url: "auth/impersonate/",
        method: "DELETE"
      }),
      invalidatesTags: ["impersonate"]
    }),
    // Initiate password change
    initiatePasswordChange: builder.mutation<void, InitPasswordChangeType>({
      query: (requestBody) => ({
        url: "auth/reset-password/",
        method: "POST",
        body: requestBody
      })
    }),
    // Change password
    changePassword: builder.mutation<void, ChangePasswordType>({
      query: (requestBody) => ({
        url: "auth/reset-password/",
        method: "PUT",
        body: requestBody
      })
    }),
    // Initiate email change
    initiateEmailChange: builder.mutation<
      void,
      {
        email: string;
        uri?: string;
      }
    >({
      query: (requestBody) => ({
        url: "auth/reset-email/",
        method: "POST",
        body: requestBody
      })
    }),
    // Change email
    changeEmail: builder.mutation<
      void,
      {
        newEmail: string;
        token: string;
      }
    >({
      query: (requestBody) => ({
        url: "auth/reset-email/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["user"]
    }),
    // Confirm email address
    confirmEmailAddress: builder.mutation<
      void,
      {
        email: string;
        token: string;
      }
    >({
      query: (requestBody) => ({
        url: "auth/confirm-email/",
        method: "PUT",
        body: requestBody
      })
    }),

    /* COMPANY */
    getCompanyTree: builder.query<ChildCompany, void>({
      query: () => ({
        url: "Company/tree/",
        method: "GET"
      }),
      providesTags: ["company"]
    }),

    // Get company details
    getCompanyDetails: builder.query<Company, { companyId: string }>({
      query: (companyId) => ({
        url: "Company/" + companyId.companyId,
        method: "GET"
      }),
      providesTags: ["company"]
    }),
    // Update company details
    updateCompanyDetails: builder.mutation<Company, Company>({
      query: (requestBody) => ({
        url: "Company/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["company"]
    }),
    // Create a new company
    createANewCompany: builder.mutation<void, Company>({
      query: (requestBody) => ({
        url: "Company/",
        method: "POST",
        body: requestBody
      })
    }),

    /* COMPANY LICENSE PRICE DISCOUNT */
    // Get all company descounts
    getAllCompanyDiscounts: builder.query<CompanyDiscount[], void>({
      query: (request) => ({
        url: "CompanyLicensePriceDiscount/",
        method: "GET"
      }),
      providesTags: ["discount"]
    }),
    // Add company discount
    addCompanyDiscount: builder.mutation<
      CompanyDiscount,
      {
        id?: number;
        created?: string;
        companyId: string;
        licensePriceId: string;
        discountPercent: number;
      }
    >({
      query: (requestBody) => ({
        url: "CompanyLicensePriceDiscount/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["discount", "licenseprice"]
    }),
    // Remove company discount
    removeCompanyDiscount: builder.mutation<
      CompanyDiscount,
      { companyLicensePriceDiscountId: number }
    >({
      query: (request) => ({
        url:
          "CompanyLicensePriceDiscount/" +
          request.companyLicensePriceDiscountId,
        method: "DELETE"
      }),
      invalidatesTags: ["discount", "licenseprice"]
    }),

    /** Online files  */
    // Get list of all online files in company
    getDatxList: builder.query<DatxListItem[], void>({
      query: () => ({
        url: "DatX/",
        method: "GET"
      }),
      providesTags: ["datx"]
    }),
    // Upload a new online file
    uploadDatx: builder.mutation<DatxListItem, Buffer>({
      query: (datx: Buffer) => ({
        url: "DatX/",
        method: "POST",
        body: datx,
        headers: {
          "Content-Type": "application/octet-stream"
        }
      }),
      invalidatesTags: ["datx", "project"]
    }),

    getOnlineFilesList: builder.query<OnlineFilesListItem[], void>({
      query: () => ({
        url: "DatX/file/list/",
        method: "GET"
      }),
      providesTags: ["datx"]
    }),

    // Get file serialized from datX data
    downloadSerializedData: builder.query<Buffer, { parameterId: string }>({
      query: (request) => ({
        url: "Datx/" + request.parameterId,
        method: "GET",
        headers: {
          Accept: "application/octet-stream"
        },
        responseHandler: (response) => {
          return response.arrayBuffer().then((buffer) => Buffer.from(buffer));
        }
      }),
      providesTags: ["datx"]
    }),
    // Download an online file
    downloadDatx: builder.query<Buffer, { datxFileId: string }>({
      query: (request) => ({
        url: "DatX/file/" + request.datxFileId,
        method: "GET",
        headers: {
          Accept: "application/octet-stream"
        },
        responseHandler: (response) => {
          return response.arrayBuffer().then((buffer) => Buffer.from(buffer));
        }
      })
    }),
    // Delete an online file
    deleteDatx: builder.mutation<void, { datxFileId: string }>({
      query: (request) => ({
        url: "DatX/file/" + request.datxFileId,
        method: "DELETE"
      }),
      invalidatesTags: ["datx"]
    }),

    /** FAT */
    // Get recent devices
    getRecentDevice: builder.query<RecentOnlineDevice[], void>({
      query: () => ({
        url: "Fat/systeminfo/",
        method: "GET"
      }),
      providesTags: ["devices"]
    }),
    // Add a recent device
    setRecentDevice: builder.mutation<void, ISystemInfoV2>({
      query: (requestBody) => ({
        url: "Fat/systeminfo/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["devices"]
    }),
    // Remove a recent device
    removeRecentDevice: builder.mutation<void, { serialNum: string }>({
      query: (request) => ({
        url: "Fat/systeminfo/" + request.serialNum,
        method: "DELETE"
      }),
      invalidatesTags: ["devices"]
    }),

    /* INVOICE */

    createTest: builder.mutation<void, void>({
      query: () => ({
        url: "Invoice/",
        method: "POST"
      })
    }),

    // Get all invoices for company
    getInvoices: builder.query<Invoice[], void>({
      query: () => ({
        url: "Invoice/",
        method: "GET"
      }),
      providesTags: ["invoice"]
    }),
    //Remove Invoice
    removeInvoice: builder.mutation<void, { invoiceId: number }>({
      query: (request) => ({
        url: "Invoice/" + request.invoiceId,
        method: "DELETE"
      }),
      invalidatesTags: ["invoice"]
    }),
    //Set invoice as paid
    setInvoiceAsPaid: builder.mutation<
      Invoice,
      { invoiceId: number; paid: boolean }
    >({
      query: (request) => ({
        url: "Invoice/" + request.invoiceId + "/paid/",
        method: "PUT",
        body: { paid: request.paid }
      }),
      invalidatesTags: ["invoice"]
    }),

    /* LICENSE */
    // Get licenses in current company
    getLicenses: builder.query<LicenseWithCost[], void>({
      query: () => ({
        url: "License/",
        method: "GET"
      }),
      providesTags: ["license", "project", "projectuser", "order"]
    }),
    // Get licence
    getLicense: builder.query<License, { id: string }>({
      query: (request) => ({
        url: "License/" + request.id,
        method: "GET"
      }),
      providesTags: ["license"]
    }),
    // Set license renewal
    setLicenseRenewAuto: builder.mutation<
      void,
      {
        licenseId: string;
        renewAuto: boolean;
        cancelReason?: string;
        orderId?: string;
      }
    >({
      query: (request) => ({
        url: "License/" + request.licenseId + "/renewal/",
        method: "PUT",
        body: {
          renewAuto: request.renewAuto,
          cancelReason: request.cancelReason,
          orderId: request.orderId
        }
      }),
      invalidatesTags: ["license"]
    }),
    // Remove license
    removeLicense: builder.mutation<void, { licenseId: string }>({
      query: (request) => ({
        url: "License/" + request.licenseId,
        method: "DELETE"
      }),
      invalidatesTags: ["license"]
    }),
    // Assign licence to user
    assignLicenseToUser: builder.mutation<
      void,
      {
        userId: string;
        licenseId: string;
      }
    >({
      query: (request) => ({
        url: "License/" + request.licenseId + "/user/",
        method: "PUT",
        body: { userId: request.userId }
      }),
      invalidatesTags: ["license", "project"]
    }),

    // Remove assigned user from license
    removeLicenseFromUser: builder.mutation<void, { licenseId: string }>({
      query: (request) => ({
        url: "License/" + request.licenseId + "/user/",
        method: "DELETE"
      }),
      invalidatesTags: ["license", "projectinvite"]
    }),
    // Activate license
    activateLicense: builder.mutation<void, { licenseId: string }>({
      query: (request) => ({
        url: "License/" + request.licenseId + "/activate/",
        method: "PUT"
      }),
      invalidatesTags: ["license"]
    }),
    // Deactivate license
    deactivateLicense: builder.mutation<void, { licenseId: string }>({
      query: (request) => ({
        url: "License/" + request.licenseId + "/deactivate/",
        method: "PUT"
      }),
      invalidatesTags: ["license"]
    }),

    /* LICENSE PRICE */
    //Get LicensePrices
    getAllLicensePrices: builder.query<LicensePrice[], void>({
      query: () => ({
        url: "LicensePrice/",
        method: "GET"
      }),
      providesTags: ["licenseprice"]
    }),
    // Create LicensePrice
    createLicensePrice: builder.mutation<LicensePrice, NewLicensePrice>({
      query: (requestBody) => ({
        url: "LicensePrice/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["licenseprice"]
    }),
    // Update LicensePrice
    updateLicensePrice: builder.mutation<LicensePrice, NewLicensePrice>({
      query: (requestBody) => ({
        url: "LicensePrice/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["licenseprice"]
    }),
    //Remove LicensePrice
    removeLicensePrice: builder.mutation<void, { licensePriceId: string }>({
      query: (request) => ({
        url: "LicensePrice/" + request.licensePriceId,
        method: "DELETE"
      }),
      invalidatesTags: ["licenseprice"]
    }),

    /* MOBITRON */
    // Get all invoices for all companies
    mobitronGetInvoices: builder.query<Invoice[], void>({
      query: () => ({
        url: "Mobitron/invoice/",
        method: "GET"
      }),
      providesTags: ["invoice"]
    }),
    // Get all orders for all companies
    mobitronGetOrders: builder.query<Order[], void>({
      query: () => ({
        url: "Mobitron/order/",
        method: "GET"
      }),
      providesTags: ["order"]
    }),
    // Get users in all companies
    mobitronGetUsers: builder.query<UserWithCompanyAndLastActivity[], void>({
      query: () => ({
        url: "Mobitron/user/",
        method: "GET"
      }),
      providesTags: ["user"]
    }),
    // Get all impersonations
    mobitronImpersonations: builder.query<
      Impersonate[],
      void
      // { maxCount: number }
    >({
      query: () => ({
        url: "Mobitron/impersonate/",
        method: "GET"
      }),
      providesTags: ["impersonate"]
    }),
    // Transfer user to another company
    mobitronSetUserCompany: builder.mutation<
      void,
      {
        userId: string;
        companyId: string;
      }
    >({
      query: (requestBody) => ({
        url: "Mobitron/user/transfer/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["user"]
    }),
    // Get licenses in all companies
    mobitronGetLicenses: builder.query<LicenseWithCost[], void>({
      query: () => ({
        url: "Mobitron/license/",
        method: "GET"
      }),
      providesTags: ["license"]
    }),
    // Create a MOBITRON license
    createMobitronLicense: builder.mutation<void, void>({
      query: () => ({
        url: "Mobitron/license/superadmin/",
        method: "POST"
      }),
      invalidatesTags: ["license"]
    }),
    // Set parent company
    mobitronSetParentCompany: builder.mutation<
      Company,
      {
        companyId: string;
        parentCompanyId: string;
      }
    >({
      query: (requestBody) => ({
        url: "Mobitron/company/parent/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["license"]
    }),
    // Get all companies
    mobitronGetCompanies: builder.query<Company[], void>({
      query: () => ({
        url: "Mobitron/company/",
        method: "GET"
      }),
      providesTags: ["company"]
    }),

    // Delete empty company
    mobitronDeleteCompany: builder.mutation<void, { companyId: string }>({
      query: (request) => ({
        url: "Mobitron/company/" + request.companyId,
        method: "DELETE"
      }),
      invalidatesTags: ["company"]
    }),

    /* ORDER */
    // Get all orders
    getAllOrders: builder.query<Order[], void>({
      query: () => ({
        url: "Order/",
        method: "GET"
      }),
      providesTags: ["order"]
    }),
    // Get order details
    getOrderDetails: builder.query<Order, { invoiceNumber: number }>({
      query: (request) => ({
        url: "Order/" + request.invoiceNumber,
        method: "GET"
      }),
      providesTags: ["order"]
    }),
    // Create order
    createOrder: builder.mutation<Order, OrderForCreation>({
      query: (requestBody) => ({
        url: "Order/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["order", "license"]
    }),
    //Remove order
    removeOrder: builder.mutation<void, { orderId: string }>({
      query: (request) => ({
        url: "Order/" + request.orderId,
        method: "DELETE"
      }),
      invalidatesTags: ["order"]
    }),
    //Set order as paid
    setOrderAsPaid: builder.mutation<Order, { orderId: string; paid: boolean }>(
      {
        query: (request) => ({
          url: "Order/" + request.orderId + "/paid/",
          method: "PUT",
          body: { paid: request.paid }
        }),
        invalidatesTags: ["order", "license"]
      }
    ),

    /* RENEW LICENSE ORDER */
    // Create renew order
    createRenewOrder: builder.mutation<Order, RenewalOrder>({
      query: (requestBody) => ({
        url: "Order/renewOrder/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["order", "license"]
    }),

    /* PARAMETER */
    // Get parameters
    // Can be filtered by:
    // hasData: true/false
    // count: number
    // sort: deviceName/lastdata
    // sortDirection: asc/desc
    getParameters: builder.query<Parameter[], GetParamsParams>({
      query: (arg) => {
        const { projectId, hasData, count, sort, sortDirection } = arg;
        return {
          url: "Parameter/",
          method: "GET",
          params: { projectId, hasData, count, sort, sortDirection }
        };
      },
      providesTags: ["parameter", "project"]
    }),
    // Create parameter
    createParameter: builder.mutation<Parameter, ParameterForCreation>({
      query: (requestBody) => ({
        url: "Parameter/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["parameter", "project"]
    }),
    // Update parameter
    updateParameter: builder.mutation<Parameter, ParameterForAlteration>({
      query: (requestBody) => ({
        url: "Parameter/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["parameter", "project"]
    }),

    /* PAYPAL */
    // Create plan
    createPayPalPlan: builder.mutation<
      CreatePayPalPlanResponse,
      CreatePayPalPlanBody
    >({
      query: (requestBody) => ({
        url: "PayPal/createPlan/",
        method: "POST",
        body: requestBody
      })
    }),

    testPayPal: builder.mutation<
      void,
      {
        amount: number;
        currency: string;
        totalCycles: number;
      }
    >({
      query: (requestBody) => ({
        url: "PayPal/test/",
        method: "POST",
        body: requestBody
      })
    }),

    /* PROJECT */
    // Get all projects
    getAllProjects: builder.query<Project[], void>({
      query: () => ({
        url: "Project/",
        method: "GET"
      }),
      providesTags: ["project", "license"]
    }),
    // Create project
    createProject: builder.mutation<Project, ProjectForCreation>({
      query: (requestBody) => ({
        url: "Project",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["project"]
    }),
    // Get all GPS positions
    getAllProjectGps: builder.query<GpsPerParameterId[], void>({
      query: () => ({
        url: "Project/gps",
        method: "GET"
      }),
      providesTags: ["project", "license", "projectnotification"]
    }),
    // Project id
    projectById: builder.query<ProjectWithUserRights, { id: string }>({
      query: (requestBody) => ({
        url: "Project/" + requestBody.id,
        method: "GET"
      }),
      providesTags: ["project", "license", "projectnotification"]
    }),
    // Project overview by project id
    getProjectOverview: builder.query<ProjectOverview, { id: string }>({
      query: (requestBody) => ({
        url: "Project/" + requestBody.id + "/overview/",
        method: "GET"
      }),
      providesTags: ["project"]
    }),
    // Project GPS positions by project id
    getProjectGps: builder.query<GpsPerParameterId[], { id: string }>({
      query: (requestBody) => ({
        url: "Project/" + requestBody.id + "/gps/",
        method: "GET"
      }),
      providesTags: ["project", "projectnotification", "parameter"]
    }),
    // Project alarms by project id
    getProjectAlarm: builder.query<ProjectAlarms[], { id: string }>({
      query: (requestBody) => ({
        url: "Project/" + requestBody.id + "/alarm/",
        method: "GET"
      }),
      providesTags: ["project"]
    }),
    // Update project title and description
    updateProjectTitleDescription: builder.mutation<Project, ProjectForUpdate>({
      query: (requestBody) => ({
        url: "Project/" + requestBody.projectId,
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["project"]
    }),
    // Update project status
    updateProjectStatus: builder.mutation<
      Project,
      {
        projectId: string;
        status: number;
      }
    >({
      query: (requestBody) => ({
        url: "Project/" + requestBody.projectId + "/status",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["project"]
    }),
    // Project
    project: builder.mutation<
      void,
      {
        created?: string;
        title: string;
        message: string;
        type: number;
        status?: number;
      }
    >({
      query: (requestBody) => ({
        url: "Project/",
        method: "POST",
        body: requestBody
      })
    }),

    /* PROJECT INVITE */
    // Get project invites
    getProjectInvites: builder.query<ProjectInvite[], void>({
      query: () => ({
        url: "ProjectInvite/",
        method: "GET"
      }),
      providesTags: ["projectinvite"]
    }),
    // Create project invite
    createProjectInvite: builder.mutation<
      ProjectInvite,
      ProjectInviteForCreation
    >({
      query: (requestBody) => ({
        url: "ProjectInvite/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["projectinvite", "project"]
    }),
    // Accept invite
    acceptProjectInvite: builder.mutation<Project, AcceptProjectInvite>({
      query: (requestBody) => ({
        url: "ProjectInvite/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["projectinvite", "project"]
    }),
    // Remove active invite
    removeActiveInvite: builder.mutation<Project, { projectInviteId: string }>({
      query: (request) => ({
        url: "ProjectInvite/" + request.projectInviteId,
        method: "DELETE"
      }),
      invalidatesTags: ["projectinvite", "project"]
    }),

    /* PROJECT USER */
    // Add project user
    addProjectUser: builder.mutation<
      void,
      {
        projectId: string;
        userId: string;
      }
    >({
      query: (requestBody) => ({
        url: "ProjectUser/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["project", "projectuser"]
    }),
    // Delete project user
    deleteProjectUser: builder.mutation<void, { projectUserId: string }>({
      query: (request) => ({
        url: "ProjectUser/" + request.projectUserId,
        method: "DELETE"
      }),
      invalidatesTags: ["project", "projectuser"]
    }),

    /* PROJECT NOTES */
    // Get project notes
    getProjectNotes: builder.query<ProjectNote[], string>({
      query: (string) => ({
        url: "ProjectNote/",
        method: "GET",
        params: { projectId: string }
      }),
      providesTags: ["projectnote"]
    }),
    // Add project note
    addProjectNote: builder.mutation<
      ProjectNote[],
      {
        projectId: string;
        text: string;
      }
    >({
      query: (requestBody) => ({
        url: "ProjectNote/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["projectnote", "project"]
    }),
    // Update project note
    updateProjectNote: builder.mutation<ProjectNote[], UpdateProjectNote>({
      query: (requestBody) => ({
        url: "ProjectNote/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["projectnote"]
    }),

    /* PROJECT NOTIFICATION */
    // Get project notifications by projectId or userId
    getProjectNotificationById: builder.query<ProjectNotification[], string>({
      query: (string) => ({
        url: "ProjectNotification/",
        method: "GET",
        params: { projectId: string, userId: string }
      }),
      providesTags: ["projectnotification", "project"]
    }),
    // Create project notification
    createProjectNotification: builder.mutation<
      ProjectNotification,
      ProjectNotificationForCreation
    >({
      query: (requestBody) => ({
        url: "ProjectNotification/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["projectnotification", "project"]
    }),
    // Update project notification
    updateProjectNotification: builder.mutation<
      ProjectNotification,
      ProjectNotificationForAlteration
    >({
      query: (requestBody) => ({
        url: "ProjectNotification/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["projectnotification", "project"]
    }),
    // Delete project notification
    deleteProjectNotification: builder.mutation<void, { id: string }>({
      query: (request) => ({
        url: "ProjectNotification/" + request.id,
        method: "DELETE"
      }),
      invalidatesTags: ["projectnotification", "project"]
    }),

    /* SUPPORT */
    // Get all support tickets by projectId or userId
    getSupportTickets: builder.query<Support[], void>({
      query: (requestBody) => ({
        url: "Support/",
        method: "GET",
        body: requestBody
      }),
      providesTags: ["support"]
    }),
    // Create Support tickets
    createSupportTicket: builder.mutation<void, SupportTicket>({
      query: (requestBody) => ({
        url: "Support/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["support"]
    }),
    // Send email to Mobitron
    sendEmailToMobitron: builder.mutation<void, { message: string }>({
      query: (requestBody) => ({
        url: "Support/sendemail/",
        method: "POST",
        body: requestBody
      })
    }),

    /* TEST */
    // GET test
    getTest: builder.mutation<void, void>({
      query: () => ({
        url: "Test/",
        method: "GET"
      })
    }),
    // POST test
    postTest: builder.mutation<void, { aString?: string }>({
      query: (requestBody) => ({
        url: "Test/",
        method: "POST",
        body: requestBody
      })
    }),
    // PUT test
    putTest: builder.mutation<void, { aString?: string }>({
      query: () => ({
        url: "Test/",
        method: "PUT"
      })
    }),

    /* TOKEN */
    // Refresh access token is handled in the baseQuery

    // Revoke access token
    revokeAccessToken: builder.mutation<void, string | undefined>({
      query: (string) => ({
        url: "Token/revoke/",
        method: "POST",
        params: { appType: string }
      })
    }),

    /* USER */
    // Get users in current company
    getUsers: builder.query<User[], void>({
      query: () => ({
        url: "User/",
        method: "GET"
      }),
      providesTags: ["user"]
    }),

    // Update user properties
    updateUserProperties: builder.mutation<User, UserForUpdate>({
      query: (requestBody) => ({
        url: "User/",
        method: "PUT",
        body: requestBody
      }),
      invalidatesTags: ["user"]
    }),

    // Create new user
    createNewUser: builder.mutation<User, UserForRegistration>({
      query: (requestBody) => ({
        url: "User/",
        method: "POST",
        body: requestBody
      }),
      invalidatesTags: ["user"]
    }),
    // Get current logged in user
    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: "User/current/",
        method: "GET"
      }),
      providesTags: ["user"]
    }),
    // Get current user rights
    getUserRights: builder.query<string[], void>({
      query: () => ({
        url: "User/rights",
        method: "GET"
      }),
      providesTags: ["license"]
    }),
    // Get user details
    getUserDetails: builder.query<User, { userId: string }>({
      query: (request) => ({
        url: "User/" + request.userId,
        method: "GET"
      }),
      providesTags: ["user"]
    }),
    //Remove User with userID
    deleteUserById: builder.mutation<User, { userId: string }>({
      query: (request) => ({
        url: "User/" + request.userId,
        method: "DELETE"
      }),
      invalidatesTags: ["user"]
    }),
    // Fetch report/dashboard settings as JSON
    getReportSettings: builder.query<Reports, void>({
      query: () => ({
        url: "User/dashboard/",
        method: "GET"
      })
    }),
    // Save report/dashboard settings as JSON
    saveReportSettings: builder.mutation<User, string>({
      query: (request) => ({
        url: "User/dashboard/",
        method: "PUT",
        body: request
      })
    }),

    // Fetch user settings as JSON
    getUserSettings: builder.query<UserSettings, void>({
      query: () => ({
        url: "User/settings/",
        method: "GET"
      })
    }),
    // Save user settings as JSON
    saveUserSettings: builder.mutation<void, UserSettings>({
      query: (request) => ({
        url: "User/settings/",
        method: "PUT",
        body: request
      })
    }),

    /* USER TRANSFER */
    // Get user transfer requests
    getUserTransferRequests: builder.query<UserTransferRequest[], void>({
      query: () => ({
        url: "UserTransferRequest/",
        method: "GET"
      }),
      providesTags: ["usertransferrequest"]
    }),
    // Create user transfer request
    createUserTransferRequest: builder.mutation<void, { companyId: string }>({
      query: (request) => ({
        url: "UserTransferRequest/",
        method: "POST",
        body: request
      }),
      invalidatesTags: ["usertransferrequest"]
    }),
    // Reply to user transfer request
    replyUserTransferRequest: builder.mutation<
      void,
      {
        userTransferRequestId: string;
        approved: boolean;
      }
    >({
      query: (request) => ({
        url: "UserTransferRequest/reply/",
        method: "PUT",
        body: request
      }),
      invalidatesTags: ["usertransferrequest"]
    })
  })
});

export const {
  useRequestAuthMutation,
  useImpersonateMutation,
  useStopImpersonateMutation,
  useInitiatePasswordChangeMutation,
  useChangePasswordMutation,
  useInitiateEmailChangeMutation,
  useChangeEmailMutation,
  useConfirmEmailAddressMutation,
  useUpdateCompanyDetailsMutation,
  useCreateANewCompanyMutation,
  useGetCompanyDetailsQuery,
  useGetCompanyTreeQuery,
  useGetAllCompanyDiscountsQuery,
  useAddCompanyDiscountMutation,
  useRemoveCompanyDiscountMutation,
  useGetDatxListQuery,
  useUploadDatxMutation,
  useGetOnlineFilesListQuery,
  useDownloadSerializedDataQuery,
  useDownloadDatxQuery,
  useDeleteDatxMutation,
  useGetRecentDeviceQuery,
  useSetRecentDeviceMutation,
  useRemoveRecentDeviceMutation,
  useGetInvoicesQuery,
  useRemoveInvoiceMutation,
  useSetInvoiceAsPaidMutation,
  useGetLicensesQuery,
  useGetLicenseQuery,
  useSetLicenseRenewAutoMutation,
  useRemoveLicenseMutation,
  useAssignLicenseToUserMutation,
  useRemoveLicenseFromUserMutation,
  useActivateLicenseMutation,
  useDeactivateLicenseMutation,
  useGetAllLicensePricesQuery,
  useCreateLicensePriceMutation,
  useUpdateLicensePriceMutation,
  useRemoveLicensePriceMutation,
  useMobitronGetInvoicesQuery,
  useMobitronGetOrdersQuery,
  useMobitronGetUsersQuery,
  useMobitronImpersonationsQuery,
  useMobitronSetUserCompanyMutation,
  useMobitronGetLicensesQuery,
  useCreateMobitronLicenseMutation,
  useMobitronSetParentCompanyMutation,
  useMobitronGetCompaniesQuery,
  useMobitronDeleteCompanyMutation,
  useGetAllOrdersQuery,
  useGetOrderDetailsQuery,
  useCreateOrderMutation,
  useRemoveOrderMutation,
  useSetOrderAsPaidMutation,
  useCreateRenewOrderMutation,
  useGetParametersQuery,
  useCreateParameterMutation,
  useUpdateParameterMutation,
  useCreatePayPalPlanMutation,
  useTestPayPalMutation,
  useCreateTestMutation,
  useGetAllProjectsQuery,
  useCreateProjectMutation,
  useGetAllProjectGpsQuery,
  useProjectByIdQuery,
  useGetProjectOverviewQuery,
  useGetProjectGpsQuery,
  useGetProjectAlarmQuery,
  useUpdateProjectTitleDescriptionMutation,
  useUpdateProjectStatusMutation,
  useProjectMutation,
  useGetProjectInvitesQuery,
  useCreateProjectInviteMutation,
  useAcceptProjectInviteMutation,
  useRemoveActiveInviteMutation,
  useAddProjectUserMutation,
  useDeleteProjectUserMutation,
  useGetProjectNotesQuery,
  useAddProjectNoteMutation,
  useUpdateProjectNoteMutation,
  useGetProjectNotificationByIdQuery,
  useCreateProjectNotificationMutation,
  useUpdateProjectNotificationMutation,
  useDeleteProjectNotificationMutation,
  useGetSupportTicketsQuery,
  useCreateSupportTicketMutation,
  useSendEmailToMobitronMutation,
  useGetTestMutation,
  usePostTestMutation,
  usePutTestMutation,
  useRevokeAccessTokenMutation,
  useGetUsersQuery,
  useUpdateUserPropertiesMutation,
  useCreateNewUserMutation,
  useGetCurrentUserQuery,
  useGetUserRightsQuery,
  useGetUserDetailsQuery,
  useDeleteUserByIdMutation,
  useGetReportSettingsQuery,
  useSaveReportSettingsMutation,
  useGetUserSettingsQuery,
  useSaveUserSettingsMutation,
  useGetUserTransferRequestsQuery,
  useCreateUserTransferRequestMutation,
  useReplyUserTransferRequestMutation
} = cargologRestApi;
