import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Space, Typography, Input, Table } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import {
  useGetSupportTicketsQuery,
  useMobitronGetCompaniesQuery,
  useMobitronGetUsersQuery
} from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectImpersonate } from "../../state/persistantStateSlice";
import {
  impersonatingHeight143,
  impersonatingHeight320,
  size
} from "../../helpers/pageHelper";

const { Text, Title } = Typography;

interface ITableData {
  id: string;
  company: string;
  created: string;
  name: string;
  email: string;
  userData: IUserData;
  versionInfo?: string;
  operatingSystem?: string;
  message: string;
  lastSeen: string;
}

interface IUserData {
  name: string;
  email: string;
}

const MobitronAdminHelp = () => {
  const { t } = useTranslation();
  const { isImpersonating } = useSelector(selectImpersonate);

  const [stringValue, setStringValue] = useState("");

  let tableData: ITableData[] | undefined = undefined;

  const { data: ticketData, isLoading: ticketIsLoading } =
    useGetSupportTicketsQuery();
  const { data: allUsers, isLoading: usersIsLoading } =
    useMobitronGetUsersQuery();
  const { data: allCompanies, isLoading: companiesIsLoading } =
    useMobitronGetCompaniesQuery();
  const timezoneOffset = new Date().getTimezoneOffset();

  if (!isUndefined(ticketData)) {
    tableData = ticketData
      .filter((ticket) => {
        const user = allUsers?.filter(
          (user) => user.userId === ticket.userId
        )[0];
        const dataContainsInput =
          stringValue === "" ||
          ticket.created.toLowerCase().includes(stringValue.toLowerCase()) ||
          user?.firstName.toLowerCase().includes(stringValue.toLowerCase()) ||
          user?.lastName.toLowerCase().includes(stringValue.toLowerCase()) ||
          user?.email.toLowerCase().includes(stringValue.toLowerCase()) ||
          ticket.versionInfo
            ?.toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          ticket.operatingSystem
            ?.toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          ticket.message.toLowerCase().includes(stringValue.toLowerCase());
        return dataContainsInput;
      })
      .map((ticket) => {
        const user = allUsers?.filter(
          (user) => user.userId === ticket.userId
        )[0];
        const company = allCompanies?.filter(
          (company) => company.id === ticket.companyId
        )[0];
        const companyName = company?.name ?? "";
        const fullName =
          user?.firstName && user?.lastName
            ? user?.firstName + " " + user?.lastName
            : "";
        const created = dayjs
          .utc(ticket.created)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm");
        const lastSeen = user?.lastActivity
          ? dayjs
              .utc(user.lastActivity)
              .subtract(timezoneOffset, "minutes")
              .format("YYYY-MM-DD, HH:mm")
          : "";
        let row = {
          id: ticket.id,
          company: companyName,
          created: created,
          name: fullName,
          email: user?.email ?? "",
          userData: { name: fullName, email: user?.email ?? "" },
          versionInfo: ticket.versionInfo,
          operatingSystem: ticket.operatingSystem,
          message: ticket.message,
          lastSeen: lastSeen
        };
        return row;
      });
  }

  const columns: ColumnsType<ITableData> = [
    {
      title: t("Company"),
      dataIndex: "company",
      key: "company",
      width: 10,
      sorter: (a, b) => {
        if (a.company < b.company) {
          return -1;
        }
        if (a.company > b.company) {
          return 1;
        }
        return 0;
      },
      render: (company) => <Text strong>{company}</Text>
    },
    {
      title: t("Name"),
      dataIndex: "userData",
      key: "userData",
      width: 20,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      render: (userdata: IUserData) => (
        <>
          <Title level={5} style={{ marginBlock: 0 }}>
            {userdata?.name}
          </Title>
          <Text type="secondary">{userdata?.email}</Text>
        </>
      )
    },
    {
      title: t("tableMessage"),
      dataIndex: "message",
      key: "message",
      width: 40,
      sorter: (a, b) => {
        if (a.message < b.message) {
          return -1;
        }
        if (a.message > b.message) {
          return 1;
        }
        return 0;
      },
      render: (message) => <Text strong>{message}</Text>
    },
    {
      title: t("tableCreated"),
      dataIndex: "created",
      key: "created",
      width: 15,
      sorter: (a, b) => {
        if (a.created < b.created) {
          return -1;
        }
        if (a.created > b.created) {
          return 1;
        }
        return 0;
      },
      render: (created) => <Text strong>{created}</Text>
    },
    {
      title: t("tableVersionInfo"),
      dataIndex: "versionInfo",
      key: "versionInfo",
      width: 10,
      sorter: (a, b) => {
        a.versionInfo = a.versionInfo || "";
        b.versionInfo = b.versionInfo || "";
        return a.versionInfo.localeCompare(b.versionInfo);
      },
      render: (versionInfo) => <Text strong>{versionInfo}</Text>
    },
    {
      title: t("tableOsVersion"),
      dataIndex: "operatingSystem",
      key: "operatingSystem",
      width: 10,
      sorter: (a, b) => {
        a.operatingSystem = a.operatingSystem || "";
        b.operatingSystem = b.operatingSystem || "";
        return a.operatingSystem.localeCompare(b.operatingSystem);
      },
      render: (operatingSystem) => <Text strong>{operatingSystem}</Text>
    },
    {
      title: t("LastSeen"),
      dataIndex: "lastSeen",
      key: "lastSeen",
      width: 15,
      sorter: (a, b) => {
        if (a.lastSeen < b.lastSeen) {
          return -1;
        }
        if (a.lastSeen > b.lastSeen) {
          return 1;
        }
        return 0;
      }
    }
  ];

  return (
    <LiftedCard
      style={{
        margin: size.m1,
        height: impersonatingHeight143(isImpersonating)
      }}
    >
      <Row justify="space-between" style={{ marginBottom: size.l2 }}>
        <Col>
          <Space>
            <LargeHeaderTitle>{t("SupportTickets")}</LargeHeaderTitle>
          </Space>
        </Col>
        <Col>
          <Input
            placeholder={t("FilterTickets")}
            prefix={<SearchOutlined />}
            onChange={(e) => setStringValue(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Table
          columns={columns}
          dataSource={tableData}
          scroll={{
            x: "calc(100vw - 300px)",
            y: impersonatingHeight320(isImpersonating)
          }}
          style={{ width: "100%" }}
          size="small"
          loading={usersIsLoading || ticketIsLoading || companiesIsLoading}
          pagination={{
            defaultPageSize: 20,
            hideOnSinglePage: true,
            showSizeChanger: true
          }}
        />
      </Row>
    </LiftedCard>
  );
};

export default MobitronAdminHelp;
