import React from "react";
import { Row, Table, Typography, notification, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeProjectInvitesModal,
  projectsState
} from "../../state/projectSlice";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  AcceptProjectInvite,
  useAcceptProjectInviteMutation,
  useGetProjectInvitesQuery
} from "../../state/cargologRestApi";
import { isNil, isUndefined } from "lodash-es";
import dayjs from "dayjs";
import { getUser } from "../../state/sessionSlice";
import StandardModal from "../Common/StandardModal";

const { Text, Title } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

/** All the fields for table used in this component */
interface ITableData {
  key: string;
  userData: IUserData;
  project: IProject;
  actions?: IRequestActions;
  created: string;
}

/** user data fields in the table */
interface IUserData {
  firstName: string;
  lastName: string;
  userId: string;
}

interface IProject {
  projectName: string;
  companyName: string;
}

/** all aviable actions for a field in the table */
interface IRequestActions {
  accept: () => void;
  reject: () => void;
}

const ProjectInvitesModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isProjectInvitesModalOpen } = useSelector(projectsState);
  const user = useSelector(getUser);

  const timezoneOffset = new Date().getTimezoneOffset();
  let tableData: ITableData[] | undefined = undefined;

  const { data: inviteData } = useGetProjectInvitesQuery();

  const [acceptInvite] = useAcceptProjectInviteMutation();

  const handleAcceptInvite = async (request: AcceptProjectInvite) => {
    const result: any = await acceptInvite(request);
    if (result.data) {
      notification.success({ message: t("InviteAcceptedSuccessfully") });
    } else {
      notification.error({
        message: t("FailedToAcceptTheInvitation")
      });
    }
  };

  const handleRejectInvite = async (request: AcceptProjectInvite) => {
    const result: any = await acceptInvite(request);
    if (result.data) {
      notification.success({ message: t("InviteHasBeenRejected") });
    } else {
      notification.error({
        message: t("FailedToRejectTheInvitation"),
        description: result.error.data ?? ""
      });
    }
  };

  if (!isUndefined(inviteData)) {
    tableData = inviteData
      .filter((invite) => invite.email === user.email && isNil(invite.answered))
      .map((request) => {
        const created = dayjs
          .utc(request.created)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm");
        let row = {
          key: request.id,
          userData: {
            firstName: request.connectedUser?.firstName ?? "",
            lastName: request.connectedUser?.lastName ?? "",
            userId: request.connectedUserId ?? "",
            email: request.email
          },
          project: {
            companyName: request.company.name ?? "",
            projectName: request.project.title ?? ""
          },
          actions: {
            accept: () =>
              handleAcceptInvite({ projectInviteId: request.id, accept: true }),
            reject: () =>
              handleRejectInvite({ projectInviteId: request.id, accept: false })
          },
          created: created
        };
        return row;
      });
  }

  const columns: ColumnsType<ITableData> = [
    {
      title: t("User"),
      dataIndex: "userData",
      key: "userData",
      width: "25%",
      render: (data) => (
        <>
          <Title level={5} style={{ marginBlock: 0 }}>
            {data.firstName} {data.lastName}
          </Title>
          <Text type="secondary">{data.email}</Text>
        </>
      )
    },
    {
      title: t("Project"),
      dataIndex: "project",
      key: "project",
      width: "25%",
      render: (project: IProject) => (
        <>
          <Row>
            <Text>{project.projectName}</Text>
          </Row>
          {project.companyName && (
            <Row>
              <Text type="secondary">({project.companyName})</Text>
            </Row>
          )}
        </>
      )
    },
    {
      title: t("Invited"),
      dataIndex: "created",
      key: "created",
      width: "18%",
      render: (created) => (
        <>
          <Text>{created}</Text>
        </>
      )
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      width: "32%",
      render: (actions: IRequestActions) => (
        <Space>
          <NormalButtonSmall icon={<CheckOutlined />} onClick={actions.accept}>
            {t("AcceptInvite")}
          </NormalButtonSmall>
          <NormalButtonSmall icon={<CloseOutlined />} onClick={actions.reject}>
            {t("RejectInvite")}
          </NormalButtonSmall>
        </Space>
      )
    }
  ];

  return (
    <StandardModal
      title={t("ProjectInvites")}
      open={isProjectInvitesModalOpen}
      closable={true}
      destroyOnClose={true}
      width={950}
      zIndex={1045}
      footer={null}
      onCancel={() => dispatch(closeProjectInvitesModal())}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        style={{ width: "100%" }}
        size="small"
        pagination={{ hideOnSinglePage: true }}
      />
    </StandardModal>
  );
};

export default ProjectInvitesModal;
