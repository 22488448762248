import React from "react";
import { Card, Form, Input, notification } from "antd";
import {
  modalsState,
  closeChangeEmailModal,
  setUser
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  useChangeEmailMutation,
  useInitiateEmailChangeMutation,
  User
} from "../../state/cargologRestApi";
import { useState } from "react";
import { emptyUser } from "../../helpers/sessionHelper";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

type InitEmailChangeType = {
  email: string;
};

type ChangeEmailType = {
  newEmail: string;
  token: string;
};

const ChangeEmailModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedUser = modals.userObject;
  const user = selectedUser ?? emptyUser;

  // Form state
  const [resetCode, setResetCode] = useState<string>();
  const [email, setEmail] = useState<string>();

  // Get API for Init Password Change
  const [initiateEmailChange, initiateEmailChangeStatus] =
    useInitiateEmailChangeMutation();

  // Get loading state, return data and error
  const { isLoading: initEmailChangeIsLoading } = initiateEmailChangeStatus;

  const handleInitiateEmailChange = async (request: InitEmailChangeType) => {
    const result: any = await initiateEmailChange(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("emailModalResetCodeErrorMessage"),
        description: errorDescription
      });
    } else {
      notification.success({
        message: t("emailModalResetCodeSuccess") + user.email
      });
    }
  };

  /*  Get API for Perform Email Change */
  const [changeEmail, changeEmailStatus] = useChangeEmailMutation();
  const { isLoading: changeEmailIsLoading } = changeEmailStatus;

  const handleChangeEmail = async (request: ChangeEmailType) => {
    const result: any = await changeEmail(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (Array.isArray(e)) {
        errorDescription = e[0].description;
      }
      notification.error({
        message: t("ChangeEmailErrorMessage"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("ChangeEmailSuccessMessage") + email });
      const newUser: User = {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: !isUndefined(email) ? email : user.email,
        companyId: user.companyId,
        temperatureScale: user.temperatureScale,
        timeZone: user.timeZone
      };
      dispatch(setUser(newUser));
      dispatch(closeChangeEmailModal());
    }
  };

  // When user klicks Send Reset Code
  const initFinished = () => {
    const newEmail = !isUndefined(email) ? email : user.email;
    if (!isUndefined(newEmail)) {
      const request = {
        email: newEmail
      };
      handleInitiateEmailChange(request);
    }
  };

  // When user klicks Send Reset Code, but form is incomplete
  const initFailed = () => {};

  // When user klicks Set New Email
  const changeFinished = () => {
    const newEmail = email;
    if (!isUndefined(newEmail) && !isUndefined(resetCode)) {
      const request = {
        newEmail: newEmail,
        token: resetCode
      };
      handleChangeEmail(request);
    }
  };

  // When user klicks Set New Email, but form is incomplete
  const changeFailed = () => {};

  return (
    <>
      <StandardModal
        title={t("ResetEmail")}
        open={modals.isChangeEmailModalOpen}
        onCancel={() => dispatch(closeChangeEmailModal())}
        closable={true}
        footer={null}
        destroyOnClose={true}
        zIndex={1045}
      >
        <Card type="inner" style={{ marginBottom: size.m1 }}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={initFinished}
            onFinishFailed={initFailed}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <Form.Item
              label={t("NewEmail")}
              name="email"
              rules={[{ required: true, message: t("EmailIsRequired") }]}
            >
              <Input
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <PrimaryButton
                style={{ width: "100%", height: "40px" }}
                htmlType="submit"
                loading={initEmailChangeIsLoading}
              >
                {t("SendResetCode")}
              </PrimaryButton>
            </Form.Item>
          </Form>
        </Card>
        <Card type="inner" style={{ marginBottom: size.m1 }}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={changeFinished}
            onFinishFailed={changeFailed}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <Form.Item
              label={t("ResetCode")}
              name="resetCode"
              rules={[{ required: true, message: t("genResetCodeRequired") }]}
            >
              <Input
                placeholder=""
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <PrimaryButton
                style={{ width: "100%", height: "40px" }}
                htmlType="submit"
                loading={changeEmailIsLoading}
              >
                {t("btnSetNewEmail")}
              </PrimaryButton>
            </Form.Item>
          </Form>
        </Card>
      </StandardModal>
    </>
  );
};

export default ChangeEmailModal;
