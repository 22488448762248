import React from "react";
import {
  AimOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  EditOutlined,
  FormOutlined,
  PlaySquareOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  ProjectOverview,
  ProjectWithUserRights,
  useGetProjectOverviewQuery
} from "../../state/cargologRestApi";
import { selectTemperatureScale } from "../../state/sessionSlice";
import {
  Card,
  Col,
  Progress,
  Row,
  Spin,
  Statistic,
  Tag,
  Typography,
  notification
} from "antd";
import { isUndefined } from "lodash-es";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import wrinkleBackground from "../../assets/wrinkle-banner.jpg";
import { toMsStr } from "../../helpers/dataModelHelper";
import { size } from "../../helpers/pageHelper";
import { calcTimeDiff, renderStatus } from "../../pages/ProjectsPage";
import {
  openEditProjectModal,
  projectsState,
  setProjectModalTab
} from "../../state/projectSlice";
import mobitronColors from "../../styles/mobitronColors";
import { GhostButton } from "../Common/CommonButtons";
import { LargeTitle } from "../Common/CommonFonts";
import { CheckProjectRightsAccess } from "../MicroComponents/LicenseAccess";
import {
  TempScaleSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import {
  AlarmRow,
  accString,
  getAlarmData,
  renderAlarmSensor,
  round2Decimals
} from "../Projects/AlarmsTab";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const { Text, Paragraph } = Typography;

interface OverviewProps {
  project?: ProjectWithUserRights;
}
const OverviewTab = (props: OverviewProps): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedProjectId } = useSelector(projectsState);

  const { project } = props;

  const tempScale = useSelector(selectTemperatureScale);

  const { data: projectOverview } = useGetProjectOverviewQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  const loading =
    !projectOverview || !project || projectOverview.title !== project.title;

  const overviewCardHeight = 190;
  const warningColor = (isWarning: boolean): string => {
    return isWarning ? "#cf1322" : "#3f8600";
  };

  const timezoneOffset = new Date().getTimezoneOffset();

  const startTime = dayjs
    .utc(projectOverview?.start)
    .subtract(timezoneOffset, "minutes")
    .format("YYYY-MM-DD");
  const endTime = dayjs
    .utc(projectOverview?.end)
    .subtract(timezoneOffset, "minutes")
    .format("YYYY-MM-DD");
  const latestNote = projectOverview?.noteLatest;
  const alarmsData: AlarmRow[] = projectOverview
    ? projectOverview.alarms.map((alarm) => ({
        sensor: alarm.type,
        serial: alarm.serial,
        timestamp: alarm.dateTime,
        parameterId: alarm.parameterId,
        fileName: alarm.fileName,
        data: getAlarmData(alarm)
      }))
    : [];

  return (
    // The div swallows the negative margin of the Row, which would otherwise cause the page to scroll horizontally
    <div style={{ overflow: "hidden" }}>
      <Row gutter={[size.m1, size.m1]}>
        <Col span={12}>
          <Spin spinning={loading}>
            <Card
              style={{ height: "100%", boxShadow: "none" }}
              bordered={false}
            >
              <Row justify="space-between">
                <Col span={24}>
                  <Row justify="space-between">
                    <LargeTitle>{projectOverview?.title}</LargeTitle>
                    {project?.userRights &&
                      CheckProjectRightsAccess(project.userRights, "ADK") && (
                        <Row style={{ marginBottom: size.m1 }}>
                          <GhostButton
                            onClick={() => dispatch(openEditProjectModal())}
                          >
                            <EditOutlined />
                          </GhostButton>
                        </Row>
                      )}
                  </Row>
                  <Paragraph
                    type="secondary"
                    ellipsis={{ rows: 3, expandable: true, symbol: t("more") }}
                    style={{ width: "100%", marginBottom: size.m1 }}
                  >
                    {projectOverview?.description}
                  </Paragraph>
                  <Tag
                    color={renderStatus(t, projectOverview?.status).color}
                    style={{ cursor: "pointer" }}
                    onClick={() => dispatch(setProjectModalTab("status"))}
                  >
                    {renderStatus(t, projectOverview?.status)
                      .string?.split(" ", 1)
                      .toString()}
                  </Tag>
                </Col>
              </Row>
            </Card>
          </Spin>
        </Col>

        <Col span={12}>
          <Spin spinning={loading}>
            <Card
              style={{
                height: "100%",
                backgroundImage: `url(${wrinkleBackground})`
              }}
              bordered={false}
              styles={{ body: { height: "100%" } }}
            >
              <Row justify="center" style={{ height: "100%" }}>
                <Row style={{ marginBlock: "auto" }}>
                  {startTime !== "Invalid date" &&
                  endTime !== "Invalid date" ? (
                    <>
                      <Row justify="center" style={{ width: "100%" }}>
                        <Progress
                          percent={calcTimeDiff(startTime, endTime)}
                          type="dashboard"
                          strokeColor={{
                            "0%": mobitronColors.altDarkGreen,
                            "100%": mobitronColors.altLightGreen
                          }}
                          trailColor={"#d4edda"}
                          style={{ fontWeight: 600 }}
                        />
                      </Row>
                      <Row justify="center" style={{ width: "100%" }}>
                        <Col>
                          <Text strong>{startTime}</Text>
                        </Col>
                        <Col style={{ paddingInline: size.m1 }}>-</Col>
                        <Col>
                          <Text strong>{endTime}</Text>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Text strong type="secondary">
                      {t("NoActiveLoggers")}
                    </Text>
                  )}
                </Row>
              </Row>
            </Card>
          </Spin>
        </Col>
        <Col span={12}>
          <Spin spinning={loading}>
            <Card
              style={{
                minHeight: overviewCardHeight,
                cursor: "pointer",
                background: "#f0f2f5"
              }}
              bordered={false}
              onClick={() => dispatch(setProjectModalTab("alarms"))}
            >
              <Statistic
                style={{ textAlign: "start" }}
                title="Alarms in Project"
                value={projectOverview?.alarmCount ?? 0}
                prefix={
                  projectOverview && projectOverview.alarmCount > 0 ? (
                    <WarningOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                valueStyle={{
                  color: warningColor(
                    !isUndefined(projectOverview) &&
                      projectOverview.alarmCount > 0
                  ),
                  fontWeight: 600
                }}
              />
              <Row justify="start" style={{ paddingTop: size.m1 }}>
                <table>
                  <tbody>
                    {alarmsData.length > 0 ? (
                      alarmsData.map((row, index) => {
                        const alarmTime = dayjs
                          .utc(row.timestamp)
                          .subtract(timezoneOffset, "minutes")
                          .format("YY-MM-DD HH:mm");

                        if (!row.data) return <></>;
                        return (
                          <tr key={index}>
                            <td style={{ paddingInlineEnd: size.m1 }}>
                              <Text ellipsis>
                                {renderAlarmSensor(row.sensor, t)}
                              </Text>
                            </td>
                            <td style={{ paddingInlineEnd: size.m1 }}>
                              {row.data.sensorname === "acc" && (
                                <Text>
                                  X:{" "}
                                  <Text strong>{accString(row.data.xAcc)}</Text>{" "}
                                  Y:{" "}
                                  <Text strong>{accString(row.data.yAcc)}</Text>{" "}
                                  Z:{" "}
                                  <Text strong>{accString(row.data.zAcc)}</Text>{" "}
                                  d:{" "}
                                  <Text strong>
                                    {toMsStr(row.data.duration)}
                                  </Text>
                                </Text>
                              )}
                              {row.data.sensorname === "temp" && (
                                <Text
                                  strong
                                >{`${round2Decimals(TemperatureToScale(row.data.temp, tempScale))}${TempScaleSymbol(tempScale)}`}</Text>
                              )}
                              {row.data.sensorname === "rh" && (
                                <Text strong>{row.data.rh}%</Text>
                              )}
                              {row.data.sensorname === "angle" && (
                                <Text>
                                  X:{" "}
                                  <Text strong>
                                    {row.data.xAngle ?? "N/A"}°
                                  </Text>{" "}
                                  Y:{" "}
                                  <Text strong>
                                    {row.data.yAngle ?? "N/A"}°
                                  </Text>{" "}
                                  Z:{" "}
                                  <Text strong>
                                    {row.data.zAngle ?? "N/A"}°
                                  </Text>
                                </Text>
                              )}
                            </td>
                            <td>
                              <Text ellipsis>{alarmTime}</Text>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <Text>{t("NoAlarmsInProject")}</Text>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Row>
            </Card>
          </Spin>
        </Col>

        <Col span={6}>
          <Spin spinning={loading}>
            <Card
              style={{
                minHeight: overviewCardHeight,
                cursor: "pointer",
                background: "#f0f2f5"
              }}
              bordered={false}
              onClick={() => dispatch(setProjectModalTab("recordings"))}
            >
              <Statistic
                style={{
                  textAlign: "start",
                  paddingBottom: size.m1
                }}
                title={"Active Recordings"}
                value={projectOverview?.parameterActiveCount}
                prefix={<AimOutlined />}
                suffix={"/ " + projectOverview?.parameterTotalCount}
                valueStyle={{ fontWeight: 600 }}
              />
              {projectOverview?.latestData &&
              projectOverview?.latestData.length > 0 ? (
                projectOverview.latestData.map((file, index) => {
                  return (
                    <Row justify="start" key={index}>
                      {file.isActive && (
                        <Text
                          style={{
                            paddingRight: size.s1,
                            color: warningColor(false)
                          }}
                        >
                          <PlaySquareOutlined />
                        </Text>
                      )}
                      {!file.isActive && (
                        <Text disabled style={{ paddingRight: size.s1 }}>
                          <CheckSquareOutlined />
                        </Text>
                      )}
                      <Text style={{ paddingRight: size.m1 }}>
                        {file.deviceName}
                      </Text>
                      <Statistic
                        style={{ textAlign: "start" }}
                        value={file.errorCount}
                        prefix={
                          file.errorCount > 0 ? (
                            <WarningOutlined />
                          ) : (
                            <CheckCircleOutlined />
                          )
                        }
                        valueStyle={{
                          color: warningColor(file.errorCount > 0),
                          fontSize: 14
                        }}
                      />
                    </Row>
                  );
                })
              ) : (
                <Text>{t("NoFilesInProject")}</Text>
              )}
            </Card>
          </Spin>
        </Col>

        <Col span={6}>
          <Spin spinning={loading}>
            <Card
              style={{
                minHeight: overviewCardHeight,
                cursor: "pointer",
                background: "#f0f2f5"
              }}
              bordered={false}
              onClick={() => dispatch(setProjectModalTab("notes"))}
            >
              <Statistic
                style={{ textAlign: "start" }}
                title="Project Notes"
                value={projectOverview?.noteCount ?? 0}
                prefix={<FormOutlined />}
                valueStyle={{ fontWeight: 600 }}
              />
              <Row justify="start" style={{ paddingTop: size.m1 }}>
                {latestNote ? (
                  <>
                    <Text
                      strong
                      type="secondary"
                      style={{ width: "100%", marginBottom: 0 }}
                    >
                      {latestNote.user.firstName} {latestNote.user.lastName}
                    </Text>
                    <Paragraph
                      ellipsis={{ rows: 2 }}
                      italic
                      style={{ marginBottom: 0 }}
                    >
                      {latestNote.text}
                    </Paragraph>
                  </>
                ) : (
                  <Text>{t("NoNotesInProject")}</Text>
                )}
              </Row>
            </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default OverviewTab;
