export interface ITableData {
  key: string;
  name: string;
}

export const checkBoxData = [
  {
    key: "AAA",
    name: "Phone App (not used)"
  },
  {
    key: "AAB",
    name: "PC App"
    // Applied to device communication item in sidebar menu // Fel -> ADE
  },
  {
    key: "AAC",
    name: "Cloud (not used)"
  },
  {
    key: "AAD",
    name: "Bluetooth (not used)"
  },
  {
    key: "ABA",
    name: "Access to my devices page"
    // Applied to access to My Devices page
    // Applied to showing calibration, devices and map landing cards
    // Applied to keyboard shortcuts to My Devices page
  },
  {
    key: "ABB",
    name: "See all devices (not used)"
  },
  {
    key: "ACA",
    name: "Read data from device"
    // Applied to "read next device" button
    // Applied to reqAllData keyboard shortcut
    // Applied to "Read Out Data" item in connection center
  },
  {
    key: "ACB",
    name: "Access to reports page (only quick report)"
    // Applied to Reports item in sidebar menu
    // Applied to showing Reports page in quick tour
  },
  {
    key: "ACC",
    name: "Access to compare page"
    // Applied to Compare item in sidebar menu
    // Applied to compare button in project modal
    // Applied to keyboard shortcuts to Compare page
  },
  {
    key: "ACD",
    name: "Standard report, reports landing card and graph settings"
    // Applied to showing reports landing card
    // Applied to showing "Graph" settings page
    // Applied to showing Primary Graph in reports page
    // Applied to showing filters and reports header bar in reports page
  },
  {
    key: "ACE",
    name: "DVA data"
    // Applied to showing DVA card in reports page
    // Applied to showing DVA link in score values
  },
  {
    key: "ACF",
    name: "View parameters from report"
    // Applied to showing file parameters in Reports page
  },
  {
    key: "ACG",
    name: "Export PDF and CSV from report card"
    // Applied to any PDF and CSV exports from card in reports page
  },
  {
    key: "ACH",
    name: "Share data (not used)"
  },
  {
    key: "ACI",
    name: "Print data from report card"
    // Applied to print from card reports page
  },
  {
    key: "ACJ",
    name: "Export full reports"
    // Applied to export of entire report in reports page
    // Applied to "Open In Reports" in project modal // Fel?
  },
  {
    key: "ADA",
    name: "Create custom reports"
    // Applied to hide card buttons on Reports page
    // Applied to showing any other cards than Primary Graph, DVA graph and Quick Report
  },
  {
    key: "ADB",
    name: "Create, edit and share templates (not used)"
  },
  {
    key: "ADC",
    name: "View templates (not used)"
  },
  {
    key: "ADD",
    name: "Debug (not used)"
  },
  {
    key: "ADE",
    name: "Create, read and write params to device"
    // Applied to "Setup Device" and "Format" in connection center
    // Applied to "Edit Parameters" in Reports page
    // Applied to showing "Setup Device" in sidebar menu
    // Applied to Setup Device in Quick Tour
    // Applied to keyboard shortcuts to Setup Device
    // Applied to "Read Parameters" item in connection center
    // Applied to "Get Time" item in connection center
  },
  {
    key: "ADF",
    name: "Set device password level 3 (not used)"
  },
  {
    key: "ADG",
    name: "Set device password level 2 (not used)"
  },
  {
    key: "ADH",
    name: "Set device password level 1 (not used)"
  },
  {
    key: "ADI",
    name: "Access to projects page"
    // Applied to "Open Project" in device map
    // Applied to showing projects in sidebar menu
  },
  {
    key: "ADJ",
    name: "Open report data from project"
    // Applied to report data in project modal
  },
  {
    key: "ADK",
    name: "Edit project details, invites and notifications"
    // Applied to Change Project in My Devices page
    // Applied to Change Project in project modal
    // Applied to showing edit project details in project modal
    // Applied to administrating other people's email notifications in project modal
    // Applied to inviting internal and external users in project modal
  },
  {
    key: "ADL",
    name: "Archive and recover projects"
    // Applied to Archive Project and Recover Project buttons
  },
  {
    key: "ADM",
    name: "Mark logger as finished transport"
    // Applied to Finish Transport in My Devices page
    // Applied to Finish Transport in project modal
  },
  {
    key: "ADN",
    name: "Upload data to cloud manually"
    // Applied to showing manual DATX upload button in Reports page
  },
  {
    key: "ADO",
    name: "Auto sync of data to cloud (not used)"
  },
  {
    key: "AEA",
    name: "Set status test/repair (not used)"
  },
  {
    key: "AEB",
    name: "Update device firmware"
    // Applied to "Update Firmware" in connection center
  },
  {
    key: "AEC",
    name: "Terminal mode (not used)"
  },
  {
    key: "AED",
    name: "Debug (not used)"
  },
  {
    key: "AEE",
    name: "Send message to unit (not used)"
  },
  {
    key: "AEF",
    name: "Set new parameter online (not used)"
  },
  {
    key: "AFA",
    name: "Access to admin page and users admin tab"
    // Applied to showing Users landing card
    // Applied to showing license store in Quick Tour // fel?
    // Applied to showing "Admin" in sidebar menu
    // Applied to showing "Users" on admin page
    // Applied to keyboard shortcuts to Admin page
  },
  {
    key: "AFB",
    name: "Administrate and buy company licenses"
    // Applied to showing licenses landing card
    // Applied to showing orders on landing card
    // Applied to "Buy Project Licenses" buttons
    // Applied to currency settings in settings modal
    // Applied to licenses tab on Admin page
    // Applied to orders tab on Admin page
    // Applied to license store on Admin page
  },
  {
    key: "AFC",
    name: "Handle company details in settings"
    // Applied to showing company section in settings modal
  }
  // {
  //     key: "AGA",
  //     name: "Mobitron"
  // }
];

export const eLabelData = [
  {
    key: "BAA",
    name: "Phone app Android"
  },
  {
    key: "BAB",
    name: "Phone app IOS"
  },
  {
    key: "BAC",
    name: "NFC communication"
  },
  {
    key: "BBD",
    name: "ReadData from label"
  },
  {
    key: "BBE",
    name: "View quick data and comment"
  },
  {
    key: "BBF",
    name: "Print quick report (PDF)"
  },
  {
    key: "BBG",
    name: "View Parameters (configuration)"
  },
  {
    key: "BBH",
    name: "ShareData (send file to other user)"
  },
  {
    key: "BBI",
    name: "View all measuring data with timestamp"
  },
  {
    key: "BBJ",
    name: "Export all Data (CSV)"
  },
  {
    key: "BBK",
    name: "PrintData (all data)"
  },
  {
    key: "BBL",
    name: "Save data/file localy"
  },
  {
    key: "BBM",
    name: "Open saved data"
  },
  {
    key: "BCA",
    name: "Create/Edit/Set device parameters and comment (Load)/View Parameters and comment"
  },
  {
    key: "BCB",
    name: "Activate DVA data (Event samples)"
  },
  {
    key: "BCC",
    name: "Create/Edit/Share/View Templates"
  },
  {
    key: "BCD",
    name: "Debug"
  },
  {
    key: "BDA",
    name: "Upon activation of elabel, upload data to the backend. S/N number, who programed it, parameters, GPS position for device, date and time for device, Company ID, other information"
  },
  {
    key: "BDB",
    name: "Create Projects"
  },
  {
    key: "BDC",
    name: "Mark logger as finished transport"
  },
  {
    key: "BDD",
    name: "Upon read out of data, upload data to the backend automatically. S/N number, date and time for device at readout, GPS position, full data (measuring values, notifications, alarms, comments), filename, Company ID, other information (damaged goods, pictures)"
  },
  {
    key: "BDE",
    name: "Open saved data and upload data to the backend manually. S/N number, date and time for device at readout, GPS position, full data (measuring values, notifications, alarms, comments), filename, Company ID, other information (damaged goods, pictures)"
  },
  {
    key: "BEA",
    name: "Set Status test/repair"
  },
  {
    key: "BEB",
    name: "UpdateFirmware"
  }
];
