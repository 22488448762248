import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { minMaxDataToCsv } from "../../helpers/dataExportHelper";
import {
  DtValues,
  MinMaxRecordingDataBlocks
} from "../../helpers/datasetHelper";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr,
  createTzDate
} from "../../helpers/dateHelper";
import {
  selectDashboardHeader,
  selectForDashboardRangePicker,
  selectGraphDomains,
  selectMinMaxData,
  selectProjectInfo
} from "../../state/openDatxSlice";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import MinMaxTable from "./MinMaxTable";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import { GenericTimestampValue } from "./ScoreValues";
import {
  selectCsvFormat,
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import {
  PdfExportComponent,
  HeaderData,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import { getUser } from "../../state/sessionSlice";
import dayjs from "dayjs";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";

interface MinMaxData<T extends DtValues> {
  min: GenericTimestampValue<T>;
  max: GenericTimestampValue<T>;
}

export interface MinMaxDataExtSensor<T extends DtValues> {
  sensorId: number;
  min: GenericTimestampValue<T>;
  max: GenericTimestampValue<T>;
}

interface MaxAccData<T extends DtValues> {
  max: GenericTimestampValue<T>;
}
export interface MinMaxDataObject {
  xAcc?: MaxAccData<[number, number]>;
  yAcc?: MaxAccData<[number, number]>;
  zAcc?: MaxAccData<[number, number]>;
  temp?: MinMaxData<number>;
  rh?: MinMaxData<number>;
  pressureRaw?: MinMaxData<number>;
  pressureComp?: MinMaxData<number>;
  xAngle?: MinMaxData<number>;
  yAngle?: MinMaxData<number>;
  zAngle?: MinMaxData<number>;
  externalTemp?: MinMaxDataExtSensor<number>[];
  externalRh?: MinMaxDataExtSensor<number>[];
}

/**
 * Get table data for min/max dashboard card
 * @param data
 * @param timezone
 */
export const getMinMaxTableData = (
  data: MinMaxRecordingDataBlocks,
  timezone: string,
  timezoneState: string | undefined,
  timezoneToggle: boolean
) => {
  const response: MinMaxDataObject = {
    xAcc: undefined,
    yAcc: undefined,
    zAcc: undefined,
    temp: undefined,
    rh: undefined,
    pressureRaw: undefined,
    pressureComp: undefined,
    xAngle: undefined,
    yAngle: undefined,
    zAngle: undefined,
    externalTemp: [],
    externalRh: []
  };

  const curTimezone = timezoneSelector(timezone, timezoneState, timezoneToggle);

  if (data.xAcc) {
    const max = {
      timestamp: createTzDate(data.xAcc.max.timestamp, curTimezone),
      value: data.xAcc.max.xAcc
    };
    response.xAcc = { max };
  }

  if (data.yAcc) {
    const max = {
      timestamp: createTzDate(data.yAcc.max.timestamp, curTimezone),
      value: data.yAcc.max.yAcc
    };
    response.yAcc = { max };
  }

  if (data.zAcc) {
    const max = {
      timestamp: createTzDate(data.zAcc.max.timestamp, curTimezone),
      value: data.zAcc.max.zAcc
    };
    response.zAcc = { max };
  }

  if (data.temp) {
    const min = {
      timestamp: createTzDate(data.temp.min.timestamp, curTimezone),
      value: data.temp.min.temp
    };

    const max = {
      timestamp: createTzDate(data.temp.max.timestamp, curTimezone),
      value: data.temp.max.temp
    };

    response.temp = {
      min,
      max
    };
  }

  if (data.rh) {
    const min = {
      timestamp: createTzDate(data.rh.min.timestamp, curTimezone),
      value: data.rh.min.rh
    };

    const max = {
      timestamp: createTzDate(data.rh.max.timestamp, curTimezone),
      value: data.rh.max.rh
    };

    response.rh = {
      min,
      max
    };
  }

  if (data.pressureRaw) {
    const min = {
      timestamp: createTzDate(data.pressureRaw.min.timestamp, curTimezone),
      value: data.pressureRaw.min.pressureRaw
    };

    const max = {
      timestamp: createTzDate(data.pressureRaw.max.timestamp, curTimezone),
      value: data.pressureRaw.max.pressureRaw
    };

    response.pressureRaw = {
      min,
      max
    };
  }

  if (data.pressureComp) {
    const min = {
      timestamp: createTzDate(data.pressureComp.min.timestamp, curTimezone),
      value: data.pressureComp.min.pressureComp
    };

    const max = {
      timestamp: createTzDate(data.pressureComp.max.timestamp, curTimezone),
      value: data.pressureComp.max.pressureComp
    };

    response.pressureComp = {
      min,
      max
    };
  }

  if (data.xAngle) {
    const min = {
      timestamp: createTzDate(data.xAngle.min.timestamp, curTimezone),
      value: data.xAngle.min.angle[0]
    };

    const max = {
      timestamp: createTzDate(data.xAngle.max.timestamp, curTimezone),
      value: data.xAngle.max.angle[0]
    };

    response.xAngle = {
      min,
      max
    };
  }

  if (data.yAngle) {
    const min = {
      timestamp: createTzDate(data.yAngle.min.timestamp, curTimezone),
      value: data.yAngle.min.angle[1]
    };

    const max = {
      timestamp: createTzDate(data.yAngle.max.timestamp, curTimezone),
      value: data.yAngle.max.angle[1]
    };

    response.yAngle = {
      min,
      max
    };
  }

  if (data.zAngle) {
    const min = {
      timestamp: createTzDate(data.zAngle.min.timestamp, curTimezone),
      value: data.zAngle.min.angle[2]
    };

    const max = {
      timestamp: createTzDate(data.zAngle.max.timestamp, curTimezone),
      value: data.zAngle.max.angle[2]
    };

    response.zAngle = {
      min,
      max
    };
  }

  if (data.externalTemp) {
    data.externalTemp.forEach((externalTemp) => {
      const min = {
        timestamp: createTzDate(externalTemp.min.timestamp, curTimezone),
        value: externalTemp.min.externalTemp.temp
      };

      const max = {
        timestamp: createTzDate(externalTemp.max.timestamp, curTimezone),
        value: externalTemp.max.externalTemp.temp
      };

      const sensorId = externalTemp.min.externalTemp.sensorId;

      response.externalTemp!.push({ sensorId, min, max });
    });
  }

  if (data.externalRh) {
    data.externalRh.forEach((externalRh) => {
      const min = {
        timestamp: createTzDate(externalRh.min.timestamp, curTimezone),
        value: externalRh.min.externalRh.rh
      };

      const max = {
        timestamp: createTzDate(externalRh.max.timestamp, curTimezone),
        value: externalRh.max.externalRh.rh
      };

      const sensorId = externalRh.min.externalRh.sensorId;

      response.externalRh!.push({ sensorId, min, max });
    });
  }

  return response;
};

interface IProps {
  fileId: string;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}
const MinMaxDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { data, timezone } = useSelector(selectMinMaxData(props.fileId));
  const projectInfo = useSelector(selectProjectInfo(props.fileId));

  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  const domains = useSelector(selectGraphDomains(props.fileId));
  const { dataDomain } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);
  const utcOffsetStr = createUtcOffsetStr(timezone);

  const dataObject = useMemo(
    () => getMinMaxTableData(data, timezone, timezoneState, timezoneToggle),
    [data, timezone]
  );

  const { isPrinting, startPrint, finishPrint } = usePrintHook();
  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const csvFormat = useSelector(selectCsvFormat);

  const exportAsCsv = () => {
    const csvData = minMaxDataToCsv(
      data,
      csvFormat,
      timezone,
      timezoneState,
      timezoneToggle,
      t
    );
    saveAsCsv(csvData);
  };

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      csvAction: exportAsCsv,
      printAction: startPrint
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const headerData: HeaderData = {
    reportType: "MinMaxValues",
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <MoveableCard
      title={t("minMax")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={props.fileId}
    >
      {isPrinting && (
        <PrintableComponent
          WrappedComponent={MinMaxTable}
          props={{ dataObject }}
          reportPrintDone={finishPrint}
          header={headerData}
          orientation="portrait"
        />
      )}
      {isExporting && (
        <PdfExportComponent
          ComponentBody={MinMaxTable}
          props={{ dataObject }}
          type="html"
          name="min_max_data.pdf"
          header={headerData}
          reportExportDone={finishExport}
        />
      )}
      <MinMaxTable dataObject={dataObject} />
    </MoveableCard>
  );
};
export default MinMaxDashboardCard;
