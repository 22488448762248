import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  List,
  notification,
  Row,
  Space,
  Typography,
  Spin
} from "antd";
import { isUndefined } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyAndPrice from "../MicroComponents/CurrencyAndPrice";
import { size } from "../../helpers/pageHelper";
import {
  OrderForCreation,
  useCreateOrderMutation,
  useCreatePayPalPlanMutation,
  useGetCompanyDetailsQuery
} from "../../state/cargologRestApi";
import { selectMyCurrency } from "../../state/sessionSlice";
import {
  closeCartDrawer,
  closeCheckoutModal,
  closeOrderDeliveredModal,
  openOrderDeliveredModal,
  setCartState,
  licenseStoreState,
  setIsProjectLicense
} from "../../state/licenseStoreSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import { getUser } from "../../state/sessionSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { OnApproveData } from "@paypal/paypal-js";
import { HeaderTitle } from "../Common/CommonFonts";
import { InfoAlert, WarningAlert } from "../Common/CommonAlerts";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import { useNavigate } from "react-router";
import { Help } from "../MicroComponents/Help";
import {
  openProjectModal,
  projectsState,
  setProjectModalTab,
  setSelectedProjectId
} from "../../state/projectSlice";
import { ScheduleOutlined } from "@ant-design/icons";
import InvoiceCheckout from "./InvoiceCheckout";
import StandardModal from "../Common/StandardModal";
import { insertIf } from "../../utils/generalUtils";

const { Text } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const LicenseCheckoutModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    cartState,
    totalCartPrice,
    months,
    isOrderDeliveredModalOpen,
    isCheckoutModalOpen
  } = useSelector(licenseStoreState);
  const persistantCurrency = useSelector(selectMyCurrency);
  const { selectedProjectId } = useSelector(projectsState);

  // Load User Company from API
  const { companyId } = useSelector(getUser);
  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId: companyId } : skipToken
  );

  // Calculate variables
  const calculateVAT = totalCartPrice * 0.25;

  /** Available pages in this component */
  type PageContent = {
    [key: string]: {
      content: JSX.Element;
    };
  };

  const roundedTotalPrice = Math.round(totalCartPrice * 100) / 100;

  const [planId, setPlanId] = useState<string>();

  const [createPayPalPlan, payPalPlanStatus] = useCreatePayPalPlanMutation();
  const { isLoading: payPalPlanIsLoading } = payPalPlanStatus;

  /** Creating a PayPal product and plan on server returning a planId
   * which is used when creating PayPal button */
  const handleCreatePayPalPlan = async () => {
    const result: any = await createPayPalPlan({
      amount: roundedTotalPrice,
      currency: persistantCurrency,
      totalCycles: months
    });

    if ("data" in result) {
      setPlanId(result.data.id);
    } else {
      console.log(result.error);
      notification.error({ message: t("CouldNotCreatePayPalPlan") });
    }
  };

  // Create PayPal button when modal is opened
  useEffect(() => {
    if (
      totalCartPrice > 0 &&
      persistantCurrency &&
      months &&
      isCheckoutModalOpen
    ) {
      handleCreatePayPalPlan();
    }
  }, [isCheckoutModalOpen]);

  const [createOrderMutation, requestStatus] = useCreateOrderMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleCreateOrderMutation = async (request: OrderForCreation) => {
    const result: any = await createOrderMutation(request);
    if (result.data) {
      dispatch(openOrderDeliveredModal());
      dispatch(closeCheckoutModal());
      dispatch(closeCartDrawer());
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("UnableToSendOrder"),
        description: errorDescription
      });
    }
  };

  useEffect(() => {
    if (!isUndefined(selectedProjectId)) {
      dispatch(setIsProjectLicense(true));
    } else {
      dispatch(setIsProjectLicense(false));
    }
  }, [dispatch, selectedProjectId]);

  const OrderDeliveredModal = () => {
    const navigate = useNavigate();
    return (
      <StandardModal
        open={isOrderDeliveredModalOpen}
        onCancel={() => {
          dispatch(closeOrderDeliveredModal());
          dispatch(setCartState([]));
        }}
        closable={false}
        title={t("OrderHasBeenSent")}
        footer={[
          ...insertIf(
            selectedProjectId !== undefined,
            <PrimaryButton
              onClick={() => {
                dispatch(closeOrderDeliveredModal());
                dispatch(setCartState([]));
                dispatch(openProjectModal());
                dispatch(setProjectModalTab("people"));
                navigate("/projects");
              }}
            >
              <ScheduleOutlined /> {t("BackToLastProject")}
            </PrimaryButton>
          ),
          Help(
            <PrimaryButton
              onClick={() => {
                dispatch(closeOrderDeliveredModal());
                dispatch(setCartState([]));
                dispatch(setSelectedProjectId(undefined));
                dispatch(setCurrentAdminTab("users"));
                navigate("/admin");
              }}
            >
              {t("AssignLicenses")}
            </PrimaryButton>,
            t("AssignTheLicenseToAUser"),
            "top"
          )
        ]}
        width={450}
        zIndex={1048}
      >
        <InfoAlert
          message={t("CheckoutMessage")}
          style={{
            marginTop: size.s1,
            marginBottom: size.l2
          }}
        />
        <List
          header={
            <Row>
              <Col>
                <HeaderTitle>{t("YourOrder")}:</HeaderTitle>
              </Col>
            </Row>
          }
          bordered
          dataSource={cartState}
          renderItem={(item) => (
            <List.Item key={item.licensePriceId}>
              <List.Item.Meta
                title={<>{item.name}</>}
                description={
                  <>
                    {item.months} {t("months")}
                  </>
                }
              />
              <Space direction="vertical" style={{ textAlign: "right" }}>
                <Text strong>
                  {item.quantity} ×{" "}
                  <CurrencyAndPrice price={item.price * item.months} />
                </Text>
                {item.companyDiscountPercent > 0 ? (
                  <Text type="success">
                    -{" "}
                    <CurrencyAndPrice
                      price={
                        (item.price *
                          item.quantity *
                          item.months *
                          item.companyDiscountPercent) /
                        100
                      }
                    />
                  </Text>
                ) : (
                  <></>
                )}
              </Space>
            </List.Item>
          )}
          footer={[
            <Row justify="space-between">
              <Col>
                <Text style={{ fontSize: 18 }}>{t("cartTotalPrice")}:</Text>
              </Col>
              <Col>
                <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                  <CurrencyAndPrice price={totalCartPrice} />
                </Text>
              </Col>
            </Row>,
            ...insertIf(
              companyData !== undefined &&
                companyData.countryCode === "SE" &&
                companyData.vatNumber === "",
              <Row justify="space-between">
                <Col>
                  <Text type="secondary">{t("VAT")}:</Text>
                </Col>
                <Col>
                  <Text type="secondary">
                    +<CurrencyAndPrice price={calculateVAT} />
                  </Text>
                </Col>
              </Row>
            )
          ]}
        />
      </StandardModal>
    );
  };

  const PayPalCheckout = () => {
    const PayPalOptions = {
      // Live account PROD
      clientId:
        "AXiPIkS9yqt0ZlA9s4sCao9mt4m4MmC4HnJPKj8KMm3cTim05BJREULezGpg0w5nipl6PkC62g1aMEnx",
      currency: persistantCurrency,
      intent: "capture",
      vault: true
    };

    interface GetSubscription {
      orderID: string;
      facilitatorAccessToken: string;
      paymentSource: string;
      subscriptionID: string;
    }

    const onApprove = (data: OnApproveData, actions: any) => {
      if (data.subscriptionID && !isUndefined(planId)) {
        const orderLines = cartState.map((item) => ({
          licensePriceId: item.licensePriceId,
          count: item.quantity,
          months: item.months
        }));
        const request: OrderForCreation = {
          paymentTypeId: 2,
          currency: persistantCurrency,
          licensePriceIdAndCount: orderLines,
          subscriptionId: data.subscriptionID,
          planId: planId
        };
        handleCreateOrderMutation(request);

        return actions.subscription
          .get()
          .then((details: GetSubscription) => {});
      }
    };

    return (
      <PayPalScriptProvider options={PayPalOptions}>
        {totalCartPrice > 0 && !isUndefined(planId) && !payPalPlanIsLoading ? (
          <PayPalButtons
            style={{
              color: "white",
              layout: "horizontal",
              label: "subscribe",
              tagline: false
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id: planId
              });
            }}
            onApprove={(data, actions) => {
              return onApprove(data, actions);
            }}
            onCancel={(data: any, actions: any) => {
              notification.warning({ message: t("PayPalPaymentCancelled") });
            }}
            onError={(data) => {
              notification.error({
                message: t("SomethingWentWrongWithYourPayment")
              });
            }}
          />
        ) : payPalPlanIsLoading ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <WarningAlert
            message={t("CardPaymentIsntAvailable")}
            style={{ marginBottom: size.m1 }}
          />
        )}
      </PayPalScriptProvider>
    );
  };

  const CardContent = () => {
    return (
      <>
        {requestIsLoading ? (
          <Row justify="center">
            <Spin />
          </Row>
        ) : (
          <PayPalCheckout />
        )}
      </>
    );
  };

  const InvoiceContent = () => {
    const sendOrder = () => {
      if (cartState) {
        const orderLines = cartState.map((item) => ({
          licensePriceId: item.licensePriceId,
          count: item.quantity,
          months: item.months
        }));

        if (cartState.length > 0) {
          const request: OrderForCreation = {
            paymentTypeId: 1,
            currency: persistantCurrency,
            purchaseOrderNumber: purchaseOrderNumber,
            licensePriceIdAndCount: orderLines
          };
          handleCreateOrderMutation(request);
        }
      }
    };
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>("");

    return (
      <InvoiceCheckout
        companyData={companyData}
        sendOrder={sendOrder}
        requestIsLoading={requestIsLoading}
        purchaseOrderNumber={purchaseOrderNumber}
        setPurchaseOrderNumber={setPurchaseOrderNumber}
      />
    );
  };

  const pages: PageContent = {
    card: {
      content: <CardContent />
    },
    invoice: {
      content: <InvoiceContent />
    }
  };

  const [activeTabKey, setActiveTabKey] = useState("invoice");

  // Render functions
  const renderPaymentOptions = (active: string) => {
    return <div>{pages[active].content}</div>;
  };

  const changeTab = (key: string) => {
    setActiveTabKey(key);
  };

  const tabList = [
    {
      key: "card",
      tab: t("CreditCard")
    },
    {
      key: "invoice",
      tab: t("Invoice")
    }
  ];

  return (
    <>
      <StandardModal
        title={t("Checkout")}
        open={isCheckoutModalOpen}
        onCancel={() => dispatch(closeCheckoutModal())}
        closable={true}
        footer={null}
        width={900}
        zIndex={1045}
      >
        <Row gutter={size.m1}>
          <Col span={8}>
            <List
              header={null}
              bordered
              dataSource={cartState}
              renderItem={(item) => (
                <List.Item key={item.licensePriceId}>
                  <List.Item.Meta
                    title={<>{item.name}</>}
                    description={
                      <>
                        {item.months} {t("months")}
                      </>
                    }
                  />
                  <Space direction="vertical" style={{ textAlign: "right" }}>
                    <Text strong>
                      {item.quantity} ×{" "}
                      <CurrencyAndPrice price={item.price * item.months} />
                    </Text>
                    {item.companyDiscountPercent > 0 ? (
                      <Text type="success">
                        -{" "}
                        <CurrencyAndPrice
                          price={
                            (item.price *
                              item.quantity *
                              item.months *
                              item.companyDiscountPercent) /
                            100
                          }
                        />
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Space>
                </List.Item>
              )}
              footer={[
                <Row justify="space-between">
                  <Col>
                    <Text style={{ fontSize: 22 }}>{t("cartTotalPrice")}:</Text>
                  </Col>
                  <Col>
                    <Text style={{ fontWeight: "bold", fontSize: 22 }}>
                      <CurrencyAndPrice price={totalCartPrice} />
                    </Text>
                  </Col>
                </Row>,
                ...insertIf(
                  companyData !== undefined &&
                    companyData.countryCode === "SE" &&
                    companyData.vatNumber === "",
                  <Row justify="space-between">
                    <Col>
                      <Text type="secondary">{t("VAT")}:</Text>
                    </Col>
                    <Col>
                      <Text type="secondary">
                        +<CurrencyAndPrice price={calculateVAT} />
                      </Text>
                    </Col>
                  </Row>
                )
              ]}
            />
          </Col>
          <Col span={16}>
            <Card
              style={{ width: "100%" }}
              tabList={tabList}
              activeTabKey={activeTabKey}
              onTabChange={(key) => {
                changeTab(key);
              }}
            >
              {renderPaymentOptions(activeTabKey)}
            </Card>
          </Col>
        </Row>
      </StandardModal>
      <OrderDeliveredModal />
    </>
  );
};

export default LicenseCheckoutModal;
