import { CloseOutlined } from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Card, Col, List, notification, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import {
  useCreateRenewOrderMutation,
  useGetAllLicensePricesQuery,
  useGetCompanyDetailsQuery
} from "../../state/cargologRestApi";
import {
  closeRenewalModal,
  licenseStoreState,
  removeFromRenewalCart,
  setRenewalMonths
} from "../../state/licenseStoreSlice";
import { getUser, selectMyCurrency } from "../../state/sessionSlice";
import { NormalButtonSmall } from "../Common/CommonButtons";
import CurrencyAndPrice from "../MicroComponents/CurrencyAndPrice";
import InvoiceCheckout from "./InvoiceCheckout";
import StandardModal from "../Common/StandardModal";
import { insertIf } from "../../utils/generalUtils";

const { Text } = Typography;
const { Option } = Select;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const LicenseRenewalModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { renewalCart, renewalMonths, isRenewalModalOpen } =
    useSelector(licenseStoreState);
  const myCurrency = useSelector(selectMyCurrency);
  const { companyId } = useSelector(getUser);
  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId } : skipToken
  );
  const { data: LicensePrices } = useGetAllLicensePricesQuery();
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>("");

  const [createRenewOrder, requestStatus] = useCreateRenewOrderMutation();
  const { isLoading: requestIsLoading } = requestStatus;
  const sendOrder = () => {
    if (companyId && renewalCart.length > 0) {
      const requestCart: string[] = renewalCart.map(
        (license) => license.licenseId
      );
      const request = {
        paymentTypeId: 1,
        currency: myCurrency,
        months: renewalMonths,
        purchaseOrderNumber: purchaseOrderNumber,
        licenses: requestCart
      };
      createRenewOrder(request).then((result) => {
        if ("data" in result) {
          dispatch(closeRenewalModal());
          notification.success({
            message: t("OrderRecieved"),
            style: { zIndex: 1050 }
          });
        } else {
          notification.error({
            message: t("UnableToSendOrder"),
            style: { zIndex: 1050 }
          });
        }
      });
    }
  };

  const licenseCart = LicensePrices
    ? renewalCart.map((orderLine) => {
        const license = LicensePrices.find(
          (licensePrice) => orderLine.licensePriceId === licensePrice.id
        );
        const licensePrice = license?.licensePriceDefs.find(
          (price) =>
            price.currency === myCurrency && price.months === renewalMonths
        );

        const selectedLicense = {
          licenseId: orderLine.licenseId,
          licenseName: license?.name ?? "License Name",
          deviceType: license?.deviceType ?? "Type",
          price: licensePrice?.price ?? 0,
          discount: license?.companyDiscountPercent ?? 0,
          orderLine: orderLine
        };
        return selectedLicense;
      })
    : [];

  const totalPrice = licenseCart.reduce((total, license) => {
    const licensePrice = license.price;
    const discount = license.discount;
    return total + licensePrice * renewalMonths * (1 - discount / 100);
  }, 0);
  const vat = totalPrice * 0.25;

  return (
    <>
      <StandardModal
        title={t("RenewalCheckout")}
        open={isRenewalModalOpen}
        onCancel={() => dispatch(closeRenewalModal())}
        closable={true}
        footer={null}
        width={900}
        zIndex={1045}
      >
        <Row gutter={size.m1}>
          <Col span={8}>
            <List
              header={
                <Row>
                  <Select
                    value={renewalMonths}
                    onChange={(value) => dispatch(setRenewalMonths(value))}
                    style={{ width: "100%" }}
                    // Keeps Dropdown in place when scrolling
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                  >
                    <Option value={3}>3 {t("months")}</Option>
                    <Option value={6}>6 {t("months")}</Option>
                    <Option value={12}>12 {t("months")}</Option>
                  </Select>
                </Row>
              }
              bordered
              dataSource={licenseCart}
              renderItem={(item) => (
                <div
                  key={item.licenseId}
                  style={{
                    borderBottom: "1px solid #f0f0f0",
                    paddingBlock: size.m1,
                    paddingInline: size.l2
                  }}
                >
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{item.licenseName}</Text>
                      {/* <Text strong>{item.licenseName + " (" + item.deviceType + ")"}</Text> */}
                    </Col>
                    <Col>
                      <NormalButtonSmall
                        icon={<CloseOutlined />}
                        onClick={() =>
                          dispatch(removeFromRenewalCart(item.licenseId))
                        }
                      />
                    </Col>
                  </Row>
                  <Text ellipsis type="secondary">
                    {item.licenseId}
                  </Text>
                  <Row justify="space-between">
                    <Col>
                      <Text>
                        <CurrencyAndPrice price={item.price} /> ×{" "}
                        {renewalMonths} {t("months")}
                      </Text>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      <CurrencyAndPrice price={item.price * renewalMonths} />
                      {item.discount > 0 ? (
                        <>
                          <br />
                          <Text type="success">
                            -{" "}
                            <CurrencyAndPrice
                              price={
                                (item.price * renewalMonths * item.discount) /
                                100
                              }
                            />
                          </Text>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              footer={[
                <Row justify="space-between">
                  <Col>
                    <Text style={{ fontSize: 22 }}>{t("cartTotalPrice")}:</Text>
                  </Col>
                  <Col>
                    <Text style={{ fontWeight: "bold", fontSize: 22 }}>
                      <CurrencyAndPrice price={totalPrice} />
                    </Text>
                  </Col>
                </Row>,
                ...insertIf(
                  companyData !== undefined &&
                    companyData.countryCode === "SE" &&
                    companyData.vatNumber === "",
                  <Row justify="space-between">
                    <Col>
                      <Text type="secondary" style={{ fontSize: 16 }}>
                        VAT:
                      </Text>
                    </Col>
                    <Col>
                      <Text type="secondary" style={{ fontSize: 16 }}>
                        +<CurrencyAndPrice price={vat} />
                      </Text>
                    </Col>
                  </Row>
                )
              ]}
            />
          </Col>
          <Col span={16}>
            <Card>
              <InvoiceCheckout
                companyData={companyData}
                sendOrder={sendOrder}
                requestIsLoading={requestIsLoading}
                purchaseOrderNumber={purchaseOrderNumber}
                setPurchaseOrderNumber={setPurchaseOrderNumber}
              />
            </Card>
          </Col>
        </Row>

        {/* List of licenses to renew */}

        {/* Select number of months */}
        {/* Total price */}

        {/* Button to add more licenses to renew */}
      </StandardModal>
    </>
  );
};

export default LicenseRenewalModal;
