// import { valueType } from "antd/lib/statistic/utils";
// import { IAccFilterValues } from "../models/IAccFilterValues";
import { ISystemInfoCV, ISystemInfoV4 } from "../models/ISystemInfo";
import IAlarmOutputBits from "../models/RecordingParameters/IAlarmOutputBits";
import IChannelBits from "../models/RecordingParameters/IChannelBits";
import { VMAccParams } from "../models/ViewModelRecordingParameters/VMAccParams";
import VMAngleParams from "../models/ViewModelRecordingParameters/VMAngleParams";
import { VMExternalOutputParams } from "../models/ViewModelRecordingParameters/VMExternalOutputParams";
import { VMExternalInputParams } from "../models/ViewModelRecordingParameters/VMExtternalInputParams";
import { VMGpsParams } from "../models/ViewModelRecordingParameters/VMGpsParams";
import { VMLteParams } from "../models/ViewModelRecordingParameters/VMLteParams";
import { VMPressureParams } from "../models/ViewModelRecordingParameters/VMPressureParams";
import { VMRhParams } from "../models/ViewModelRecordingParameters/VMRhParams";
import { VMTempParams } from "../models/ViewModelRecordingParameters/VMTempParams";
import { ALL_FEATURES } from "./FAT100-prot-constants";

export const defaultStopAfterDuration = { days: 365, hours: 0 };

/** The minimum acc value (g) */
export const minAccValue = 0.1;

/** The minimum temp value (celcius) */
export const minTempValue = -40;
/** The maximum temp value (celcius) */
export const maxTempValue = 85;

/** The minimum pressure value (mbar) */
export const minPressureValue = 0;
/** The maximum pressure value (mbar) */
export const maxPressureValue = 2000;

/** The minimum rh value (0%) */
export const minRhValue = 0;
/** The maximum rh value (100%) */
export const maxRhValue = 100;

/** Min value for angle */
export const minAngleValue = 0;
/** Max value for angle */
export const maxAngleValue = 180;

/** Represents the same values that is default for fat90 */
export const defaultAccFilter = 10;

export const defaultChannelBits: IChannelBits = {
  Acceleration: false,
  Temp: false,
  Rh: false,
  Angle: false,
  Pressure: false,
  Light: false,
  GPS: false,
  LTE: false,
  Bluetooth: false,
  DeviceHealth: false
};

export const disabledAlarmInputBits = {
  input0: false,
  input1: false,
  input2: false,
  input3: false,
  input4: false,
  input5: false,
  input6: false,
  input7: false
};

const disabledOutputBit = {
  used: false,
  newStateOn: false
};

export const disabledAlarmOutputBits: IAlarmOutputBits = {
  output0: disabledOutputBit,
  output1: disabledOutputBit,
  output2: disabledOutputBit,
  output3: disabledOutputBit,
  output4: disabledOutputBit,
  output5: disabledOutputBit,
  output6: disabledOutputBit,
  output7: disabledOutputBit
};

export const defaultAccChannelBits: IChannelBits = {
  ...defaultChannelBits,
  Angle: true
};

/** The values that will be shown when acc params input is first shown */
export const defaultAccParams: VMAccParams = {
  useAcc: true,
  useX: true,
  useY: true,
  useZ: true,
  params: {
    Xreg: 0.5,
    Yreg: 0.5,
    Zreg: 0.5,
    Xalarm: 2,
    Yalarm: 2,
    Zalarm: 2,
    Xms: 25,
    Yms: 25,
    Zms: 25
  },
  accFilter: defaultAccFilter,
  useDva: false,
  dvaTriggerLevel: 2,
  IgnoreCountLTE: 0,
  IgnoreCountGPS: 0,
  channelBits: defaultAccChannelBits,
  alarmInputBits: disabledAlarmInputBits,
  alarmOutputBits: disabledAlarmOutputBits
};

/** Placeholder values for when acceleration is missing in config */
export const disabledAccParams: VMAccParams = {
  ...defaultAccParams,
  useAcc: false
};

/** The values that will be shown when temp params input is first shown */
export const defaultTempParams: VMTempParams = {
  useTemp: true,
  intervalDays: 0,
  intervalHours: 6,
  intervalMinutes: 0,
  params: {
    lowAlarm: minTempValue,
    highAlarm: maxTempValue
  },
  IgnoreCountLTE: 0,
  IgnoreCountGPS: 0,
  channelBits: defaultChannelBits,
  alarmInputBits: disabledAlarmInputBits,
  alarmOutputBits: disabledAlarmOutputBits
};

/** Placeholder values for when temperature is missing in config */
export const disabledTempParams: VMTempParams = {
  ...defaultTempParams,
  useTemp: false
};

export const defaultPressureParams: VMPressureParams = {
  usePressure: true,
  intervalDays: 0,
  intervalHours: 6,
  intervalMinutes: 0,
  params: {
    lowAlarm: 100,
    highAlarm: 150,
    slopeValue: 0,
    tempValue: 0
  },
  IgnoreCountLTE: 0,
  IgnoreCountGPS: 0,
  channelBits: defaultChannelBits,
  alarmInputBits: disabledAlarmInputBits,
  alarmOutputBits: disabledAlarmOutputBits,
  slopeCalc: {
    temp1: 10,
    temp2: 20,
    pressure1: 100,
    pressure2: 100
  }
};

/** Placeholder values for when pressure is missing in config */
export const disabledPressureParams: VMPressureParams = {
  ...defaultPressureParams,
  usePressure: false
};

/** The values that will be shown when rh params input is first shown */
export const defaultRhParams: VMRhParams = {
  useRh: false,
  intervalDays: 0,
  intervalHours: 6,
  intervalMinutes: 0,
  params: {
    lowAlarm: minRhValue,
    highAlarm: maxRhValue
  },
  IgnoreCountLTE: 0,
  IgnoreCountGPS: 0,
  channelBits: defaultChannelBits,
  alarmInputBits: disabledAlarmInputBits,
  alarmOutputBits: disabledAlarmOutputBits
};

/** Placeholder values for when rh is missing in config */
export const disabledRhParams: VMRhParams = {
  ...defaultRhParams,
  useRh: false
};

/** The values that will be shown when angle params input is first shown */
export const defaultAngleParams: VMAngleParams = {
  useAngle: false,
  useAutoHorizon: false,
  intervalDays: 0,
  intervalHours: 6,
  intervalMinutes: 0,
  params: {
    xAlarmLevel: 25,
    yAlarmLevel: 25,
    zAlarmLevel: 25
  },
  IgnoreCountLTE: 0,
  IgnoreCountGPS: 0,
  channelBits: defaultChannelBits,
  alarmInputBits: disabledAlarmInputBits,
  alarmOutputBits: disabledAlarmOutputBits
};

/** Placeholder values for when angle is missing in config */
export const disabledAngleParams: VMAngleParams = {
  ...defaultAngleParams,
  useAngle: false
};

export const defaultLteParams: VMLteParams = {
  LteBands: {
    Enable2G: false,
    Enable3G: true,
    Enable4G: true,
    Enable5G: true,
    Enable6G: false
  }
};

export const defaultGpsParams: VMGpsParams = { storeSpeed: true };

export const defaultExternalInputParams: VMExternalInputParams[] = [
  {
    inputNumber: 0,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 1",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  },
  {
    inputNumber: 1,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 2",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  },
  {
    inputNumber: 2,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 3",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  },
  {
    inputNumber: 3,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 4",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  },
  {
    inputNumber: 4,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 5",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  },
  {
    inputNumber: 5,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 6",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  },
  {
    inputNumber: 6,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 7",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  },
  {
    inputNumber: 7,
    used: false,
    triggOnImpact: true,
    triggOnReset: true,
    alarmOnTrigg: true,
    saveTrigg: true,
    description: "Input 8",
    channelBits: defaultChannelBits,
    alarmInputBits: disabledAlarmInputBits,
    alarmOutputBits: disabledAlarmOutputBits
  }
];

export const defaultExternalOutputParams: VMExternalOutputParams[] = [
  {
    outputNumber: 0,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 1"
  },
  {
    outputNumber: 1,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 2"
  },
  {
    outputNumber: 2,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 3"
  },
  {
    outputNumber: 3,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 4"
  },
  {
    outputNumber: 4,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 5"
  },
  {
    outputNumber: 5,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 6"
  },
  {
    outputNumber: 6,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 7"
  },
  {
    outputNumber: 7,
    used: false,
    saveTrigg: true,
    initialStateOn: false,
    description: "Output 8"
  }
];

export const defaultExternalRhTempConfig = {
  rhMin: minRhValue,
  rhMax: maxRhValue,
  tempMin: minTempValue,
  tempMax: maxTempValue
};

export const defaultExternalSensorParams = {
  intervalDays: 0,
  intervalHours: 6,
  intervalMinutes: 0,
  channelBits: defaultChannelBits,
  alarmInputBits: disabledAlarmInputBits,
  alarmOutputBits: disabledAlarmOutputBits
};

/** This number is used to define "automatic horizon for angle" */
export const angleAutoHorizonKey = 0x00f0;

/** Represents the same values that is default for fat90 */
/* export const defaultAccFilterValues: IAccFilterValues = {
  value1: 10,
  value2: 10,
} */

/** can be used as a backup when no device is connected */
export const defaultSystemInfo: ISystemInfoV4 = {
  configVersion: 8,
  parxVersion: 2,
  datxVersion: 7,
  serial: "default",
  hasFeatures: ALL_FEATURES,
  accStdFilter: 10,
  accExtraFilter: 10,
  accStdMaxG: 16,
  accExtraMaxG: 200,
  memoryAvailable: 131076096,
  memoryUsed: 32768,
  artNum: [
    "ü",
    "B",
    "\u0000",
    "\u0000",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5"
  ],
  powerSource: 14134,
  mainBatteryVoltage: 3.6,
  rebootTimeStamp: {
    year: 16,
    month: 0,
    day: 0,
    hour: 3,
    minute: 20,
    second: 11
  },
  mainBatteryTimeStamp: {
    year: 16,
    month: 0,
    day: 0,
    hour: 3,
    minute: 20,
    second: 11
  },
  mainBattery: 56,
  backupBattery: 3,
  runningStatus: 53,
  lastCalibration: {
    year: 16,
    month: 0,
    day: 0,
    hour: 3,
    minute: 20,
    second: 11
  },
  fwMajorVersion: 1,
  fwMinorVersion: 30,
  fwMainBuild: 0,
  fwSubBuild: 0,
  serialProtVersion: 0,
  coFWMajorVersion: 0,
  coFWMinorVersion: 0,
  coFWMainBuild: 0,
  coFWSubBuild: 0,
  ExtSensors1: 0x000f,
  ExtSensors2: 0,
  ExtSensors3: 0,
  ExtSensors4: 0
};
