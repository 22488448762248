import React, { useState } from "react";
import { Form, Row, Col, Input, notification } from "antd";
import { modalsState, closeCreateUserModal } from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  useCreateNewUserMutation,
  UserForRegistration
} from "../../state/cargologRestApi";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

// Main component
const CreateUserModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* Create new user */
  const [createNewUser, requestStatus] = useCreateNewUserMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleCreateUser = async (request: UserForRegistration) => {
    const result: any = await createNewUser(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToCreateNewUser"),
        description: errorDescription
      });
    } else {
      notification.success({
        message: t("SuccessfullyCreateNewUser")
      });
    }
  };

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setUsername] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();

  // When user finishes form sucessfully
  const onFinish = () => {
    // Add 0 to ensure password is valid (at least one number)
    const randPass = Math.random().toString(16).substr(2, 8) + "0";
    if (
      !isUndefined(firstName) &&
      !isUndefined(lastName) &&
      !isUndefined(email)
    ) {
      const request: UserForRegistration = {
        firstName,
        lastName,
        email,
        phoneNumber,
        password: randPass
      };
      handleCreateUser(request);
      setFirstName(undefined);
      setLastName(undefined);
      setUsername(undefined);
      setPhoneNumber(undefined);
      dispatch(closeCreateUserModal());
    }
  };
  // When user tries to finish form, but it is incomplete
  const onFinishFailed = () => {};

  return (
    <>
      {/* Create user modal */}
      <StandardModal
        title={t("createNewUserTitle")}
        zIndex={1045}
        open={modals.isCreateUserModalOpen}
        onCancel={() => dispatch(closeCreateUserModal())}
        closable={true}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Row gutter={size.m1}>
            <Col span={12}>
              <Form.Item
                label={t("FirstName")}
                name="firstName"
                rules={[{ required: true, message: t("FirstNameIsRequired") }]}
              >
                <Input
                  placeholder=""
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoFocus
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("LastName")}
                name="lastName"
                rules={[{ required: true, message: t("LastNameIsRequired") }]}
              >
                <Input
                  placeholder=""
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={12}>
              <Form.Item
                label={t("Email")}
                name="email"
                rules={[{ required: true, message: t("EmailIsRequired") }]}
              >
                <Input
                  placeholder="user@example.com"
                  value={email}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("PhoneNumber")} name="phoneNumber">
                <Input
                  placeholder=""
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={requestIsLoading}
            >
              {t("createUserBtn")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default CreateUserModal;
