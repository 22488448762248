// Components to
import AccHistogramGraph from "../components/GraphPage/AccHistogramGraph";
import GeneralRecordingInformation from "../components/MicroComponents/GeneralRecordingInformation";
import MinMaxTable from "../components/GraphPage/MinMaxTable";
import PrimaryGraph from "../components/GraphPage/PrimaryGraph";
import TopAccTable from "../components/GraphPage/TopAccTable";
import { VMDvaData } from "./dataModelHelper";
import DvaGraph from "../components/GraphPage/DvaGraph";
import { HeaderData } from "../components/PrintExport/pdfExport";
import dayjs from "dayjs";
import QuickReport from "../components/MicroComponents/QuickReport";
import ExternalIOTable from "../components/GraphPage/ExternalIOTable";
import ExternalTimersTable from "../components/GraphPage/ExternalTimersTable";
import AngleTable from "../components/GraphPage/AngleTable";

type exportType = "html" | "svg";

/**
 * Creates an array of exportable items containing the dva graph from every index in selectedIndexes
 * @param dvaData
 * @param selectedIndexes
 */
export const getExportableDvaGraphsFromSelected = (
  dvaData: VMDvaData[],
  selectedIndexes: number[]
): any[] => {
  if (!(selectedIndexes?.length > 0)) {
    return [];
  }

  return selectedIndexes?.map((arrayIndex) => {
    const dvaDataBlock = dvaData?.[arrayIndex];
    const ComponentBody = DvaGraph;
    const type: exportType = "svg";
    const props: any = { dvaDataBlock };
    return { ComponentBody, props, type };
  });
};

export const getExportableDvaHeadersFromSelected = (
  header: HeaderData,
  dvaData: VMDvaData[],
  selectedIndexes: number[],
  timeZone: string
): HeaderData[] => {
  if (!(selectedIndexes?.length > 0)) {
    return [header];
  }

  return selectedIndexes?.map((arrayIndex) => {
    const dvaDataBlock = dvaData?.[arrayIndex];
    const reportStart = dayjs
      .unix(dvaDataBlock.start)
      .tz(timeZone)
      .format("YYYY-MM-DD, HH:mm:ss");
    const reportEnd = dayjs
      .unix(dvaDataBlock.end)
      .tz(timeZone)
      .format("YYYY-MM-DD, HH:mm:ss");
    return {
      ...header,
      reportStart,
      reportEnd
    };
  });
};

/** Creates a exportable item for primary graph that is usefull for multipage reports */
export const getPrimaryGraphExportableItem = (props: any) => {
  const ComponentBody = PrimaryGraph;
  const type: exportType = "svg";
  return { ComponentBody, props, type };
};

/** Creates a exportable item for min/max that is usefull for multipage reports */
export const getMinMaxTableExportableItem = (props: any) => {
  const ComponentBody = MinMaxTable;
  const type: exportType = "html";
  return { ComponentBody, props, type };
};

/** Creates a exportable item for Acceleration histogram graph that is usefull for multipage reports */
export const getAccHistogramGraphExportableItem = (props: any) => {
  const ComponentBody = AccHistogramGraph;
  const type: exportType = "svg";
  return { ComponentBody, props, type };
};

/** Creates a exportable item for top accelerations table that is usefull for multipage reports */
export const getTopAccTableExportableItem = (props: any) => {
  const ComponentBody = TopAccTable;
  const type: exportType = "html";
  return { ComponentBody, props, type };
};

/** Creates a exportable item general recording information that is usefull for multipage reports */
export const getGeneralRecordingInfoExportableItem = (props: any) => {
  const ComponentBody = GeneralRecordingInformation;
  const type: exportType = "html";
  return { ComponentBody, props, type };
};

/** Creates an exportable item for Quick Report that is usefull for multipage reports */
export const getQuickReportExportableItem = (props: any) => {
  const ComponentBody = QuickReport;
  const type: exportType = "html";
  return { ComponentBody, props, type };
};

/** Creates a exportable item for external IO table */
export const getExtIOTableExportableItem = (props: any) => {
  const ComponentBody = ExternalIOTable;
  const type: exportType = "html";
  return { ComponentBody, props, type };
};

/** Creates a exportable item for external timers table */
export const getExtTimersTableExportableItem = (props: any) => {
  const ComponentBody = ExternalTimersTable;
  const type: exportType = "html";
  return { ComponentBody, props, type };
};

/** Creates a exportable item for angle table */
export const getAngleTableExportableItem = (props: any) => {
  const ComponentBody = AngleTable;
  const type: exportType = "html";
  return { ComponentBody, props, type };
};
