import { EditOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { saveAsParx } from "../../helpers/paramsHelper";
import {
  newRecordingParameters,
  selectRecordingParametersItemsForPrint
} from "../../state/openParxSlice";
import {
  setActiveParametersTab,
  setParamsBasedOn,
  setParamsUserProgress
} from "../../state/paramsPageSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import {
  HeaderData,
  PdfExportComponent,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import { createUtcOffsetStr, guessLocalTz } from "../../helpers/dateHelper";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import { useTranslation } from "react-i18next";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import useKeyPress from "../../helpers/useKeyPress";
import { modalsState } from "../../state/modalsSlice";
import { getStartDate, getEndDate } from "../../helpers/exportHelper";
import ParamsPreview from "../ParamsPage/ParamsPreview";
import { getUser } from "../../state/sessionSlice";
import { cleanParams } from "../../helpers/dataModelHelper";
import { getAppVersion } from "../../helpers/versionHelper";
import { NormalButton, PrintButton, SaveButton } from "../Common/CommonButtons";

interface IProps {
  recordingParameters: VMRecordingParameters;
  recordingParametersPreviewRef: React.MutableRefObject<undefined>;
  deviceId?: string;
  fileName?: string;
}
/** Simple component that can be used with the ParamsPreview-component to
 * provide some buttons with extra functionallity */
const ParamsPreviewExtraOptions: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isParamsPreviewModalOpen } = useSelector(modalsState);
  const { companyId } = useSelector(getUser);

  const recordingParameters = props.recordingParameters;

  // all new stuff that should be its own component later
  const handleUseParamsForNewDevice = () => {
    const neutralParams = cleanParams(
      props.recordingParameters,
      getAppVersion(),
      companyId
    );
    dispatch(newRecordingParameters(neutralParams));
    dispatch(setParamsUserProgress(1));
    dispatch(setActiveParametersTab("freeText"));
    dispatch(setParamsBasedOn(props.fileName ?? "DATX file"));
    navigate("/params");
  };

  const { targetDevice } = useSelector(selectRecordingParametersItemsForPrint);

  const systemInfo = targetDevice;

  const localTimezone = useSelector(guessLocalTz);
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);
  const timezoneStr = timezoneSelector(
    localTimezone,
    timezoneState,
    timezoneToggle
  );
  const utcOffsetStr = createUtcOffsetStr(timezoneStr);
  const timezone = { timezoneStr, utcOffsetStr };

  const header: HeaderData = {
    reportType: "RecordingParameters",
    projectName: props.recordingParameters.ProjectName,
    recStart: getStartDate(props.recordingParameters, timezone, t),
    recEnd: getEndDate(props.recordingParameters, timezone, t),
    deviceId: props.deviceId
  };

  /** Save parx as a file */
  const handleSaveParx = () => {
    saveAsParx(recordingParameters, targetDevice);
  };

  /** Local component: Print current recording parameters Preview */
  const PrintParametersPreviewButton = () => {
    const printHelper = usePrintHook();

    const onKeyPressPrint = () => {
      printHelper.startPrint();
    };

    useKeyPress(
      [isParamsPreviewModalOpen ? "p" : ""],
      onKeyPressPrint,
      "Control"
    );

    return (
      <>
        {printHelper.isPrinting && (
          <PrintableComponent
            WrappedComponent={ParamsPreview}
            props={{
              systemInfo,
              recordingParameters
            }}
            header={header}
            reportPrintDone={printHelper.finishPrint}
            orientation="portrait"
          />
        )}
        <PrintButton onClick={printHelper.startPrint}>{t("Print")}</PrintButton>
      </>
    );
  };

  /** Local component: Export current recording parameters Preview as PDF*/
  const ExportRecordingParametersPrevAsPdfButton = () => {
    const { isExporting, startExport, finishExport } = useExportPdfHook();

    return (
      <>
        {isExporting && (
          <PdfExportComponent
            ComponentBody={ParamsPreview}
            props={{
              systemInfo,
              recordingParameters
            }}
            header={header}
            type="html"
            name="recording_parameters.pdf"
            reportExportDone={finishExport}
          />
        )}
        <NormalButton icon={<FilePdfOutlined />} onClick={startExport}>
          PDF
        </NormalButton>
      </>
    );
  };

  return (
    <Space>
      <NormalButton
        icon={<EditOutlined />}
        onClick={handleUseParamsForNewDevice}
      >
        {t("genEdit")}
      </NormalButton>
      <PrintParametersPreviewButton />
      <ExportRecordingParametersPrevAsPdfButton />
      <SaveButton onClick={handleSaveParx}>PARX</SaveButton>
    </Space>
  );
};

export default ParamsPreviewExtraOptions;
