import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { isNil, isUndefined } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toGStr } from "../../helpers/dataModelHelper";
import { size } from "../../helpers/pageHelper";
import { accFilterCtValueToHzLookup } from "../../helpers/paramsHelper";
import {
  getExternalSensor,
  getNumberOfIO,
  interpretSystemInfoHasFeatures
} from "../../helpers/parsers/parseSystemInfoHelper";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import IChannelBits from "../../models/RecordingParameters/IChannelBits";
import { VMDeviceFeatures } from "../../models/VMDeviceFeatures";
import { VMAccParams } from "../../models/ViewModelRecordingParameters/VMAccParams";
import VMAngleParams from "../../models/ViewModelRecordingParameters/VMAngleParams";
import { VMExternalOutputParams } from "../../models/ViewModelRecordingParameters/VMExternalOutputParams";
import { VMExternalInputParams } from "../../models/ViewModelRecordingParameters/VMExtternalInputParams";
import { VMPressureParams } from "../../models/ViewModelRecordingParameters/VMPressureParams";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { VMRhParams } from "../../models/ViewModelRecordingParameters/VMRhParams";
import { VMTempParams } from "../../models/ViewModelRecordingParameters/VMTempParams";
import { selectAlsoRecord } from "../../state/openParxSlice";
import { selectTemperatureScale } from "../../state/sessionSlice";
import { insertIf } from "../../utils/generalUtils";
import {
  TempScaleSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import { VMExternalSensorParams } from "../../models/ViewModelRecordingParameters/VMExternalSensorParams";
import {
  RHTemp1M,
  RHTemp1S,
  RHTemp2M,
  RHTemp3M,
  RHTemp4M,
  RHTemp5M,
  RHTemp6M,
  RHTemp7M,
  RHTemp8M,
  RhTemp
} from "../../models/FAT100DataTypes";

const { Text } = Typography;

interface TableData {
  name: string;
  regLimit: string;
  alarmLimit: string;
  config: string;
}

/**
 * Get rows for acceleration parameters. If acceleration isn't used an empty
 * array will be returned
 * @param accParams
 */
const getAccParamsRows = (
  accParams: VMAccParams,
  t: TFunction
): TableData[] => {
  const shouldIncludeAcc =
    accParams.useAcc && (accParams.useX || accParams.useY || accParams.useZ);

  if (!shouldIncludeAcc) {
    return [];
  }

  return [
    {
      name: t("generalAccTitle"),
      regLimit: "",
      alarmLimit: "",
      config: ""
    },
    ...insertIf(accParams.useX, {
      name: t("genXAxis"),
      regLimit: accParams.params.Xreg + "g",
      alarmLimit: `${accParams.params.Xalarm}g, ${accParams.params.Xms}ms`,
      config: ""
    }),

    ...insertIf(accParams.useY, {
      name: t("genYAxis"),
      regLimit: accParams.params.Yreg + "g",
      alarmLimit: `${accParams.params.Yalarm}g, ${accParams.params.Yms}ms`,
      config: ""
    }),

    ...insertIf(accParams.useZ, {
      name: t("genZAxis"),
      regLimit: accParams.params.Zreg + "g",
      alarmLimit: `${accParams.params.Zalarm}g, ${accParams.params.Zms}ms`,
      config: ""
    }),

    {
      name: t("cpAccLowPassFilterTitle"),
      regLimit: "",
      alarmLimit: "",
      config: `${accFilterCtValueToHzLookup[accParams.accFilter]}Hz`
    },
    {
      name: t("cpAccDvaTitle"),
      regLimit: accParams.useDva ? toGStr(accParams.dvaTriggerLevel) : "",
      alarmLimit: "",
      config: accParams.useDva ? t("genYes") : t("genNo")
    }
  ];
};

const getSensorType = (sensorTypeId: number) => {
  switch (sensorTypeId) {
    case RHTemp1S:
      return "RH/Temp Sensor nr 1";
    case RHTemp1M:
      return "RH/Temp Sensor nr 1";
    case RHTemp2M:
      return "RH/Temp Sensor nr 2";
    case RHTemp3M:
      return "RH/Temp Sensor nr 3";
    case RHTemp4M:
      return "RH/Temp Sensor nr 4";
    case RHTemp5M:
      return "RH/Temp Sensor nr 5";
    case RHTemp6M:
      return "RH/Temp Sensor nr 6";
    case RHTemp7M:
      return "RH/Temp Sensor nr 7";
    case RHTemp8M:
      return "RH/Temp Sensor nr 8";
    default:
      return "";
  }
};

/**
 * Get rows for temperature parameters. If temperature isn't used an empty
 * array will be returned
 * @param tempParams
 */
const getTempParamsRows = (
  tempParams: VMTempParams,
  t: TFunction,
  tempScale: string
): TableData[] => {
  if (!tempParams.useTemp) {
    return [];
  }

  return [
    {
      name: t("generalTempTitle"),
      regLimit: "",
      alarmLimit: "",
      config: ""
    },
    {
      name: t("genSampleInterval"),
      regLimit: "",
      alarmLimit: "",
      config: `${tempParams.intervalDays}d, ${tempParams.intervalHours}h, ${tempParams.intervalMinutes}min`
    },
    {
      name: t("cpGenLow"),
      regLimit: "",
      alarmLimit:
        TemperatureToScale(tempParams.params.lowAlarm, tempScale).toString() +
        TempScaleSymbol(tempScale),
      config: ""
    },
    {
      name: t("cpGenHigh"),
      regLimit: "",
      alarmLimit:
        TemperatureToScale(tempParams.params.highAlarm, tempScale).toString() +
        TempScaleSymbol(tempScale),
      config: ""
    }
  ];
};

/**
 * Get rows for pressure parameters. If pressure isn't used an empty
 * array will be returned
 * @param pressureParams
 */
const getPressureParamsRows = (
  pressureParams: VMPressureParams,
  t: TFunction
): TableData[] => {
  if (!pressureParams.usePressure) {
    return [];
  }

  return [
    {
      name: t("generalPressureTitle"),
      regLimit: "",
      alarmLimit: "",
      config: ""
    },
    {
      name: t("genSampleInterval"),
      regLimit: "",
      alarmLimit: "",
      config: `${pressureParams.intervalDays}d, ${pressureParams.intervalHours}h, ${pressureParams.intervalMinutes}min`
    },
    {
      name: t("cpGenLow"),
      regLimit: "",
      alarmLimit: `${pressureParams.params.lowAlarm}mbar`,
      config: ""
    },
    {
      name: t("cpGenHigh"),
      regLimit: "",
      alarmLimit: `${pressureParams.params.highAlarm}mbar`,
      config: ""
    },
    {
      name: t("AlarmLevelOrigo"),
      regLimit: "",
      alarmLimit: "",
      config: `${pressureParams.params.tempValue.toFixed(2)}°C`
    },
    {
      name: t("CompensationSlope"),
      regLimit: "",
      alarmLimit: "",
      config: `${pressureParams.params.slopeValue.toFixed(3)}mbar/°C`
    }
  ];
};

/**
 * Get rows for humidity parameters. If humidity isn't used an empty
 * array will be returned
 * @param rhParams
 */
const getRhParamsRows = (rhParams: VMRhParams, t: TFunction): TableData[] => {
  if (!rhParams.useRh) {
    return [];
  }

  return [
    {
      name: t("generalRhTitle"),
      regLimit: "",
      alarmLimit: "",
      config: ""
    },
    {
      name: t("genSampleInterval"),
      regLimit: "",
      alarmLimit: "",
      config: `${rhParams.intervalDays}d, ${rhParams.intervalHours}h, ${rhParams.intervalMinutes}min`
    },
    {
      name: t("cpGenLow"),
      regLimit: "",
      alarmLimit: `${rhParams.params.lowAlarm}% Rh`,
      config: ""
    },
    {
      name: t("cpGenHigh"),
      regLimit: "",
      alarmLimit: `${rhParams.params.highAlarm}% Rh`,
      config: ""
    }
  ];
};

/**
 * Get rows for angle parameters. If angle isn't used an empty
 * array will be returned
 * @param angleParams
 */
const getAngleParamsRows = (
  angleParams: VMAngleParams,
  t: TFunction
): TableData[] => {
  if (!angleParams.useAngle) {
    return [];
  }

  return [
    {
      name: t("generalAngleTitle"),
      regLimit: "",
      alarmLimit: "",
      config: ""
    },
    {
      name: t("genXAxis"),
      regLimit: "",
      alarmLimit: `±${angleParams.params.xAlarmLevel}°`,
      config: ""
    },
    {
      name: t("genYAxis"),
      regLimit: "",
      alarmLimit: `±${angleParams.params.yAlarmLevel}°`,
      config: ""
    },
    {
      name: t("genZAxis"),
      regLimit: "",
      alarmLimit: `±${angleParams.params.zAlarmLevel}°`,
      config: ""
    },
    ...insertIf(
      angleParams.useAutoHorizon,
      {
        name: t("HorizonXAxis"),
        regLimit: "",
        alarmLimit: t("Auto"),
        config: ""
      },
      {
        name: t("HorizonYAxis"),
        regLimit: "",
        alarmLimit: t("Auto"),
        config: ""
      }
    )
  ];
};

/**
 * Get rows for external input parameters. If external inputs isn't used an empty
 * array will be returned
 * @param externalInputParams
 */
const getExternalInputRows = (
  externalInputParams: VMExternalInputParams[],
  t: TFunction,
  IONumber: number
): TableData[] => {
  const impactString = (isImpact: boolean, isReset: boolean) => {
    if (isImpact && isReset) return t("Impact") + ", " + t("Reset");
    if (isImpact && !isReset) return t("Impact");
    if (!isImpact && isReset) return t("Reset");
    return t("None");
  };

  return externalInputParams.flatMap((input, index) => {
    if (!input.used || IONumber < index + 1) {
      return [];
    }

    return [
      {
        name: `${input.description} (${t("ExternalInput")} ${index + 1})`,
        regLimit: "",
        alarmLimit: "",
        config: ""
      },
      {
        name: t("SaveChangesToData"),
        regLimit: "",
        alarmLimit: "",
        config: input.saveTrigg ? t("genYes") : t("genNo")
      },
      {
        name: t("TriggOn"),
        regLimit: "",
        alarmLimit: "",
        config: impactString(input.triggOnImpact, input.triggOnReset)
      }
    ];
  });
};

/**
 * Get rows for external output parameters. If external outputs isn't used an empty
 * array will be returned
 * @param externalOutputParams
 */
const getExternalOutputRows = (
  externalOutputParams: VMExternalOutputParams[],
  t: TFunction,
  IONumber: number
): TableData[] => {
  return externalOutputParams.flatMap((output, index) => {
    if (!output.used || IONumber < index + 1) {
      return [];
    }

    return [
      {
        name: `${output.description} (${t("ExternalOutput")} ${index + 1})`,
        regLimit: "",
        alarmLimit: "",
        config: ""
      },
      {
        name: t("SaveChangesToData"),
        regLimit: "",
        alarmLimit: "",
        config: output.saveTrigg ? t("genYes") : t("genNo")
      },
      {
        name: t("StartAs"),
        regLimit: "",
        alarmLimit: "",
        config: output.initialStateOn ? t("On") : t("Off")
      }
    ];
  });
};

/**
 * Get rows for external sensor parameters. If external sensors isn't used an empty
 * array will be returned
 * @param externalSensorParams
 */
const getExternalSensorRows = (
  externalSensorParams: VMExternalSensorParams[],
  t: TFunction
): TableData[] => {
  if (externalSensorParams.length === 0) {
    return [];
  }

  return externalSensorParams.flatMap((sensor) => {
    const { params } = sensor;

    if (RhTemp.includes(params.sensorTypeId)) {
      return [
        {
          name: `${getSensorType(params.sensorTypeId)} (${params.sensorName})`,
          regLimit: "",
          alarmLimit: "",
          config: ""
        },
        {
          name: t("genSampleInterval"),
          regLimit: "",
          alarmLimit: "",
          config: `${sensor.intervalDays}d, ${sensor.intervalHours}h, ${sensor.intervalMinutes}min`
        },
        {
          name: `${t("Temp Low")}`,
          regLimit: "",
          alarmLimit: `${params.sensorConfig?.tempMin}°C`,
          config: ""
        },
        {
          name: `${t("Temp High")}`,
          regLimit: "",
          alarmLimit: `${params.sensorConfig?.tempMax}°C`,
          config: ""
        },
        {
          name: `${t("RH Low")}`,
          regLimit: "",
          alarmLimit: `${params.sensorConfig?.rhMin}% Rh`,
          config: ""
        },
        {
          name: `${t("RH High")}`,
          regLimit: "",
          alarmLimit: `${params.sensorConfig?.rhMax}% Rh`,
          config: ""
        }
      ];
    } else {
      return [];
    }
  });
};

/**
 * Get rows for schedulers.
 * @param scheduleBlocks
 */
const getSchedulerRows = (
  recParams: VMRecordingParameters,
  t: TFunction,
  systemInfo: GeneralSystemInfo
): TableData[] => {
  if (recParams.scheduleBlocks.length === 0) {
    return [];
  }
  const { scheduleBlocks, RecParams } = recParams;
  const { startRecordingType, startTimestamp } = RecParams;
  const hasStartDate = startRecordingType === "date" && !isNil(startTimestamp);

  const deviceFeatures = interpretSystemInfoHasFeatures(systemInfo.hasFeatures);

  // Adds the durations from all schedule blocks until the given index
  const timeAtIndex = (index: number) => {
    let time = 0;
    for (let i = 0; i < index; i++) {
      time += scheduleBlocks[i].blockDuration;
    }
    return time;
  };

  return scheduleBlocks.flatMap((block, index) => {
    const { blockDuration, flightMode } = block;
    const blockNumber = index + 1;
    const {
      useLteInterval,
      intervalDays: lteD,
      intervalHours: lteH,
      intervalMinutes: lteM,
      GpsRequired,
      alwaysOn: lteAlwaysOn,
      flightModeLte
    } = block.LteParams;
    const {
      useGpsInterval,
      intervalDays: gpsD,
      intervalHours: gpsH,
      intervalMinutes: gpsM,
      alwaysOn: gpsAlwaysOn,
      flightModeGps
    } = block.GpsParams;
    const durationDays = Math.floor(block.blockDuration / 24);
    const durationHours = Math.round((block.blockDuration % 24) * 100) / 100;
    return [
      {
        name: t("RecordingBlock") + " " + blockNumber,
        regLimit: "",
        alarmLimit: "",
        config: ""
      },
      ...insertIf(hasStartDate, {
        name: t("RecordingPeriod"),
        regLimit: "",
        alarmLimit: "",
        config:
          dayjs
            .unix(startTimestamp!)
            .add(timeAtIndex(index), "hours")
            .format("YY-MM-DD HH:mm") +
          " - " +
          dayjs
            .unix(startTimestamp!)
            .add(timeAtIndex(index) + blockDuration, "hours")
            .format("YY-MM-DD HH:mm")
      }),
      ...insertIf(!hasStartDate, {
        name: t("RecordingPeriod"),
        regLimit: "",
        alarmLimit: "",
        config: durationDays + "d " + durationHours + "h"
      }),
      ...insertIf(
        (deviceFeatures.DF_LTE || deviceFeatures.DF_GPS) && flightMode,
        {
          name: t("flightMode"),
          regLimit: "",
          alarmLimit: "",
          config: t("RadioDevicesDisabled")
        }
      ),
      ...insertIf(deviceFeatures.DF_LTE && flightModeLte && !flightMode, {
        name: t("SelectiveFlightmode"),
        regLimit: "",
        alarmLimit: "",
        config: t("LTEdisabled")
      }),
      ...insertIf(deviceFeatures.DF_GPS && flightModeGps && !flightMode, {
        name: t("SelectiveFlightmode"),
        regLimit: "",
        alarmLimit: "",
        config: t("GPSdisabled")
      }),
      ...insertIf(
        deviceFeatures.DF_LTE &&
          useLteInterval &&
          !flightModeLte &&
          !flightMode,
        {
          name: t("LTEinterval"),
          regLimit: "",
          alarmLimit: "",
          config: `${lteD}d, ${lteH}h, ${lteM}min`
        }
      ),
      ...insertIf(
        deviceFeatures.DF_LTE &&
          deviceFeatures.DF_GPS &&
          useLteInterval &&
          GpsRequired &&
          !flightModeGps &&
          !flightModeLte &&
          !flightMode,
        {
          name: t("RequireGPS"),
          regLimit: "",
          alarmLimit: "",
          config: t("OnlySyncWithPosition")
        }
      ),
      ...insertIf(
        deviceFeatures.DF_LTE && lteAlwaysOn && !flightModeLte && !flightMode,
        {
          name: t("LTEPower"),
          regLimit: "",
          alarmLimit: "",
          config: t("LTENeverSleeps")
        }
      ),
      ...insertIf(
        deviceFeatures.DF_GPS &&
          useGpsInterval &&
          !flightModeGps &&
          !flightMode,
        {
          name: t("GPSinterval"),
          regLimit: "",
          alarmLimit: "",
          config: `${gpsD}d, ${gpsH}h, ${gpsM}min`
        }
      ),
      ...insertIf(
        deviceFeatures.DF_GPS && gpsAlwaysOn && !flightModeGps && !flightMode,
        {
          name: t("GPSPower"),
          regLimit: "",
          alarmLimit: "",
          config: t("GPSNeverSleeps")
        }
      )
    ];
  });
};

interface AlsoRecord {
  acc: IChannelBits;
  pressure: IChannelBits;
  temp: IChannelBits;
  rh: IChannelBits;
  angle: IChannelBits;
}

const getTriggerRows = (
  deviceFeatures: VMDeviceFeatures,
  alsoRecord: AlsoRecord,
  t: TFunction,
  IONumber: number,
  recParams: VMRecordingParameters,
  hasExtSensors: boolean
): TableData[] => {
  const { acc, temp, pressure, rh, angle } = alsoRecord;
  const {
    AccParams,
    TempParams,
    PressureParams,
    RhParams,
    AngleParams,
    ExternalInputParams,
    ExternalOutputParams,
    ExternalSensorParams
  } = recParams;

  const triggerStrings = (
    channel: IChannelBits,
    isUsed: boolean,
    outputs: boolean[],
    ignoreCountGPS?: number,
    ignoreCountLTE?: number
  ) => {
    if (!isUsed) return "";

    let triggersList: string[] = [];
    if (channel.Acceleration && deviceFeatures.DF_ACC) {
      triggersList.push("Acceleration");
    }
    if (channel.Temp && deviceFeatures.DF_TEMP) {
      triggersList.push("Temperature");
    }
    if (channel.Pressure && deviceFeatures.DF_PRESSURE) {
      triggersList.push("Pressure");
    }
    if (channel.Rh && deviceFeatures.DF_RH) {
      triggersList.push("Humidity");
    }
    if (channel.Angle && deviceFeatures.DF_ANGLE) {
      triggersList.push("Angle");
    }
    // GPS
    if (
      channel.GPS &&
      deviceFeatures.DF_GPS &&
      (isUndefined(ignoreCountGPS) || ignoreCountGPS === 0)
    ) {
      triggersList.push("GPS");
    }
    if (
      channel.GPS &&
      deviceFeatures.DF_GPS &&
      !isUndefined(ignoreCountGPS) &&
      ignoreCountGPS > 0
    ) {
      triggersList.push(`GPS (ignore ${ignoreCountGPS} repeated)`);
    }
    // LTE
    if (
      channel.LTE &&
      deviceFeatures.DF_LTE &&
      (isUndefined(ignoreCountLTE) || ignoreCountLTE === 0)
    ) {
      triggersList.push("LTE");
    }
    if (
      channel.LTE &&
      deviceFeatures.DF_LTE &&
      !isUndefined(ignoreCountLTE) &&
      ignoreCountLTE > 0
    ) {
      triggersList.push(`LTE (ignore ${ignoreCountLTE} repeated)`);
    }
    // Outputs
    outputs.forEach((outputUsed, index) => {
      if (outputUsed) {
        triggersList.push(ExternalOutputParams[index].description.toString());
      }
    });

    return triggersList.join(", ");
  };

  /** Which outputs are trigged, used and available */
  const accOutputs: boolean[] = Object.values(AccParams.alarmOutputBits).map(
    (output, index) =>
      output.used && ExternalOutputParams[index].used && index < IONumber
  );
  const tempOutputs: boolean[] = Object.values(TempParams.alarmOutputBits).map(
    (output, index) =>
      output.used && ExternalOutputParams[index].used && index < IONumber
  );
  const pressureOutputs: boolean[] = Object.values(
    PressureParams.alarmOutputBits
  ).map(
    (output, index) =>
      output.used && ExternalOutputParams[index].used && index < IONumber
  );
  const rhOutputs: boolean[] = Object.values(RhParams.alarmOutputBits).map(
    (output, index) =>
      output.used && ExternalOutputParams[index].used && index < IONumber
  );
  const angleOutputs: boolean[] = Object.values(
    AngleParams.alarmOutputBits
  ).map(
    (output, index) =>
      output.used && ExternalOutputParams[index].used && index < IONumber
  );

  const accTriggers = triggerStrings(
    acc,
    AccParams.useAcc,
    accOutputs,
    AccParams.IgnoreCountGPS,
    AccParams.IgnoreCountLTE
  );
  const tempTriggers = triggerStrings(
    temp,
    TempParams.useTemp,
    tempOutputs,
    TempParams.IgnoreCountGPS,
    TempParams.IgnoreCountLTE
  );
  const pressureTriggers = triggerStrings(
    pressure,
    PressureParams.usePressure,
    pressureOutputs,
    PressureParams.IgnoreCountGPS,
    PressureParams.IgnoreCountLTE
  );
  const rhTriggers = triggerStrings(
    rh,
    RhParams.useRh,
    rhOutputs,
    RhParams.IgnoreCountGPS,
    RhParams.IgnoreCountLTE
  );
  const angleTriggers = triggerStrings(
    angle,
    AngleParams.useAngle,
    angleOutputs,
    AngleParams.IgnoreCountGPS,
    AngleParams.IgnoreCountLTE
  );

  let sensorTriggers: string = "";
  let sensorColumns: TableData[] = [];
  ExternalSensorParams.forEach((sensor: VMExternalSensorParams) => {
    if (!hasExtSensors) return;

    // Which outputs are trigged, used and available
    const sensorOutputs: boolean[] = Object.values(sensor.alarmOutputBits).map(
      (output, oIndex) =>
        output.used && ExternalOutputParams[oIndex].used && oIndex < IONumber
    );

    const triggers = triggerStrings(
      sensor.channelBits,
      true, // Already checked above
      sensorOutputs,
      undefined,
      undefined
    );

    // If no triggers are set, no line is added
    if (triggers === "") return;
    sensorTriggers += triggers;

    const sensorName = sensor.params.sensorName
      ? `(${sensor.params.sensorName})`
      : "";

    sensorColumns.push({
      name: `${getSensorType(sensor.params.sensorTypeId)} ${sensorName}`,
      regLimit: "",
      alarmLimit: "",
      config: triggers
    });
  });

  let inputTriggers: string = "";
  let inputColumns: TableData[] = [];
  ExternalInputParams.forEach((param, index) => {
    // If input is not used or not available in device no line is added
    if (!param.used || index >= IONumber) return;

    // Which outputs are trigged, used and available
    const inputOutputs: boolean[] = Object.values(param.alarmOutputBits).map(
      (output, oIndex) =>
        output.used && ExternalOutputParams[oIndex].used && oIndex < IONumber
    );

    const triggers = triggerStrings(
      param.channelBits,
      true, // Already checked above
      inputOutputs,
      undefined,
      undefined
    );
    // If no triggers are set, no line is added
    if (triggers === "") return;
    inputTriggers += triggers;

    inputColumns.push({
      name: `${param.description} (${t("ExternalInput")} ${index + 1})`,
      regLimit: "",
      alarmLimit: "",
      config: triggers
    });
  });

  const allTriggers =
    accTriggers +
    tempTriggers +
    rhTriggers +
    angleTriggers +
    sensorTriggers +
    inputTriggers;

  /** List of all external input columns to be shown in table */

  return [
    ...insertIf(allTriggers !== "", {
      name: `${t("Triggers")}`,
      regLimit: "",
      alarmLimit: "",
      config: ""
    }),
    ...insertIf(accTriggers !== "", {
      name: `${t("Acceleration")}`,
      regLimit: "",
      alarmLimit: "",
      config: accTriggers
    }),
    ...insertIf(tempTriggers !== "", {
      name: `${t("Temperature")}`,
      regLimit: "",
      alarmLimit: "",
      config: tempTriggers
    }),
    ...insertIf(pressureTriggers !== "", {
      name: `${t("Pressure")}`,
      regLimit: "",
      alarmLimit: "",
      config: pressureTriggers
    }),
    ...insertIf(rhTriggers !== "", {
      name: `${t("Humidity")}`,
      regLimit: "",
      alarmLimit: "",
      config: rhTriggers
    }),
    ...insertIf(angleTriggers !== "", {
      name: `${t("Angle")}`,
      regLimit: "",
      alarmLimit: "",
      config: angleTriggers
    }),
    ...sensorColumns,
    ...inputColumns
  ];
};

interface IProps {
  recordingParameters: VMRecordingParameters;
  systemInfo: GeneralSystemInfo;
  withValidation?: boolean;
}
const ParamsPreview: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const recParams = props.recordingParameters;

  const isSubTitleRow = (row: TableData) =>
    row.name.length > 0 &&
    row.regLimit.length === 0 &&
    row.alarmLimit.length === 0 &&
    row.config.length === 0;

  /** if the row is detected as a subtitle-row, the columns are not rendered */
  const renderRow = (value: any, row: any, index: any) => {
    const obj = {
      children: value,
      props: {} as {
        colSpan?: number;
      }
    };
    if (isSubTitleRow(row)) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns: ColumnsType<Object> = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (value, row: any, index) => {
        const obj = {
          children: value,
          props: {} as {
            colSpan?: number;
            style?: React.CSSProperties;
          }
        };
        if (isSubTitleRow(row)) {
          obj.props.colSpan = 4;
          obj.props.style = { backgroundColor: "#fafafa", fontWeight: 600 };
        }

        return obj;
      }
    },
    {
      title: t("cpRegLvlTitle"),
      dataIndex: "regLimit",
      align: "right",
      key: "regLimit",
      render: renderRow
    },
    {
      title: t("cpAlarmLimit"),
      dataIndex: "alarmLimit",
      align: "right",
      key: "alarmLimit",
      render: renderRow
    },
    {
      title: t("cpConfigTitle"),
      dataIndex: "config",
      align: "right",
      key: "config",
      width: "25%",
      render: renderRow
    }
  ];

  const tempScale = useSelector(selectTemperatureScale);

  const deviceFeatures = interpretSystemInfoHasFeatures(
    props.systemInfo.hasFeatures
  );

  const alsoRecord = useSelector(selectAlsoRecord);
  const numberOfIO = getNumberOfIO(deviceFeatures.DF_EXT_IO_CFG);

  const extSensorTech = getExternalSensor(deviceFeatures.DF_EXT_IO_CFG);
  const hasExtSensors = extSensorTech === "I2C";

  const data = [
    ...insertIf(
      deviceFeatures.DF_ACC,
      ...getAccParamsRows(recParams.AccParams, t)
    ),
    ...insertIf(
      deviceFeatures.DF_TEMP,
      ...getTempParamsRows(recParams.TempParams, t, tempScale)
    ),
    ...insertIf(
      deviceFeatures.DF_PRESSURE,
      ...getPressureParamsRows(recParams.PressureParams, t)
    ),
    ...insertIf(
      deviceFeatures.DF_RH,
      ...getRhParamsRows(recParams.RhParams, t)
    ),
    ...insertIf(
      deviceFeatures.DF_ANGLE,
      ...getAngleParamsRows(recParams.AngleParams, t)
    ),
    ...insertIf(
      deviceFeatures.DF_EXT_IO,
      ...getExternalInputRows(recParams.ExternalInputParams, t, numberOfIO)
    ),
    ...insertIf(
      deviceFeatures.DF_EXT_IO,
      ...getExternalOutputRows(recParams.ExternalOutputParams, t, numberOfIO)
    ),
    ...insertIf(
      hasExtSensors,
      ...getExternalSensorRows(recParams.ExternalSensorParams, t)
    ),
    ...getTriggerRows(
      deviceFeatures,
      alsoRecord,
      t,
      numberOfIO,
      recParams,
      hasExtSensors
    ),
    ...getSchedulerRows(recParams, t, props.systemInfo)
  ];

  return (
    <>
      {recParams.UserInfo && (
        <div
          style={{ paddingTop: size.s1, paddingInline: 3, textAlign: "start" }} // size: special case?
        >
          <div>
            <Text strong>{t("Description")}:</Text>
          </div>
          <div>
            <Text>{recParams.UserInfo}</Text>
          </div>
        </div>
      )}
      <div style={{ paddingTop: size.xl1 }}>
        <Table
          //This class overrides titles color
          className="mobitron-antd-table"
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};

export default ParamsPreview;
