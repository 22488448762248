import React from "react";
import { notification, Row, Typography } from "antd";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import {
  useProjectByIdQuery,
  useUpdateProjectStatusMutation
} from "../../state/cargologRestApi";
import {
  closeRecoverProjectModal,
  projectsState
} from "../../state/projectSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { skipToken } from "@reduxjs/toolkit/query";
import StandardModal from "../Common/StandardModal";

const { Text } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

type RecoverProjectType = { projectId: string; status: number };

const RecoverProjectModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedProjectId, isRecoverProjectModalOpen } =
    useSelector(projectsState);

  const { data: project } = useProjectByIdQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  const [updateProjectStatus, requestStatus] = useUpdateProjectStatusMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleRecoverProject = async (request: RecoverProjectType) => {
    const result: any = await updateProjectStatus(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToRecoverProject"),
        description: errorDescription
      });
    } else {
      notification.success({
        message: t("SuccessfullyRecoveredProject") + project?.title
      });
      dispatch(closeRecoverProjectModal());
    }
  };

  const onFinish = () => {
    if (!isUndefined(selectedProjectId)) {
      // status = 2 represents Inactive project
      const request = { projectId: selectedProjectId, status: 2 };
      handleRecoverProject(request);
    }
  };

  return (
    <StandardModal
      title={t("RecoverProject")}
      open={isRecoverProjectModalOpen}
      zIndex={1045}
      onCancel={() => dispatch(closeRecoverProjectModal())}
      closable={true}
      footer={[
        <NormalButton
          style={{ marginRight: size.m1 }}
          htmlType="submit"
          onClick={() => dispatch(closeRecoverProjectModal())}
        >
          {t("Cancel")}
        </NormalButton>,
        <PrimaryButton
          htmlType="submit"
          onClick={onFinish}
          loading={requestIsLoading}
        >
          {t("ConfirmRecovery")}
        </PrimaryButton>
      ]}
    >
      <Row>
        <Text>
          {t("ConfirmRecoveryOfProject")} <Text strong>{project?.title}</Text>
        </Text>
      </Row>
    </StandardModal>
  );
};

export default RecoverProjectModal;
