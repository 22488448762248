import React, { useState } from "react";
import { Form, Row, Col, notification, TreeSelect } from "antd";
import {
  modalsState,
  closeEditUserCompanyModal,
  setUser
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";
import {
  ChildCompany,
  useGetCompanyTreeQuery,
  useMobitronSetUserCompanyMutation,
  User
} from "../../state/cargologRestApi";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

type UserCompanyType = {
  userId: string;
  companyId: string;
};

// Main component
const EditUserCompanyModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedUser = modals.userObject;

  interface DataNode {
    title?: string;
    value: string;
    children?: DataNode[];
    disabled: boolean;
  }

  const [newUserCompanyId, setNewUserCompanyId] = useState<string>();

  const { data: companyTreeData } = useGetCompanyTreeQuery();

  const CompanyTree: React.FC<{}> = () => {
    let walkTree = (company: ChildCompany): DataNode => {
      let treeChildren: DataNode[] = company.childCompanies
        ? company.childCompanies.map((child) => walkTree(child))
        : [];
      let tree: DataNode = {
        title: company.name ?? t("NoName"),
        value: company.id,
        children: treeChildren,
        disabled: selectedUser?.companyId === company.id
      };

      return tree;
    };
    let treeData: DataNode[] | undefined = companyTreeData
      ? [walkTree(companyTreeData)]
      : undefined;

    return (
      <TreeSelect
        placeholder={t("SelectNewCompany")}
        treeData={treeData}
        treeDefaultExpandAll
        value={newUserCompanyId}
        onChange={(value) => setNewUserCompanyId(value)}
        defaultValue={t("SelectNewCompany")}
      />
    );
  };

  /* Request for update User Company */
  const [setUserCompany, requestStatus] = useMobitronSetUserCompanyMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleSetUserCompany = async (request: UserCompanyType) => {
    const result: any = await setUserCompany(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToUpdateUserCompany"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("SuccessfullyUpdatedUserCompany") });
      if (selectedUser && newUserCompanyId) {
        // update currently selected user data
        const newUser: User = {
          userId: selectedUser.userId,
          firstName: selectedUser.firstName,
          lastName: selectedUser.lastName,
          email: selectedUser.email,
          phoneNumber: selectedUser.phoneNumber,
          companyId: newUserCompanyId,
          temperatureScale: selectedUser.temperatureScale,
          timeZone: selectedUser.timeZone
        };
        dispatch(setUser(newUser));
      }
      setNewUserCompanyId(undefined);
      dispatch(closeEditUserCompanyModal());
    }
  };

  // When user finishes form sucessfully
  const changeFinished = () => {
    if (selectedUser && newUserCompanyId) {
      handleSetUserCompany({
        userId: selectedUser.userId,
        companyId: newUserCompanyId
      });
    }
  };
  // When user tries to finish form, but it is incomplete
  const changeFailed = () => {};

  return (
    <>
      {/* Update user phone modal */}
      <StandardModal
        title={t("ChangeUserCompany")}
        zIndex={1048}
        open={modals.isEditUserCompanyModalOpen}
        onCancel={() => dispatch(closeEditUserCompanyModal())}
        closable={true}
        footer={null}
        width={250}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={changeFinished}
          onFinishFailed={changeFailed}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Row>
            <Col span={24}>
              <Form.Item label={t("UserCompany")} name="userCompany">
                <CompanyTree />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={requestIsLoading}
            >
              {t("MoveUser")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default EditUserCompanyModal;
