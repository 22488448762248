import React from "react";
import { Typography, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import {
  WarningOutlined,
  ExclamationCircleOutlined,
  CarryOutOutlined
} from "@ant-design/icons";
import { setNewCursorPosition } from "../../state/openDatxSlice";
import { useDispatch } from "react-redux";
import { timestampFormat } from "../../constants/defaultComponentsProps";
import { createTzDate } from "../../helpers/dateHelper";
const { Link } = Typography;

export type LteRowType =
  | "power"
  | "error"
  | "blocked"
  | "schedule"
  | "sensor"
  | "sessionEnded"
  | "unknown";

export interface LteTableData {
  key: number;
  rowType: LteRowType[];
  message: string[];
  color: string[];
  timestamp: number;
}

export const emptyLteStatusData: LteTableData = {
  key: 0,
  rowType: ["unknown"],
  message: [""],
  color: ["black"],
  timestamp: 0
};

interface IProps {
  dataObject: LteTableData[];
  timezone: string;
}
const LteTable = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectCursorPosition = (timestamp: number) => {
    dispatch(setNewCursorPosition(timestamp));
  };

  const columns: ColumnsType<LteTableData> = [
    {
      title: t("Timestamp"),
      key: "timestamp",
      render: (data: LteTableData) => {
        return (
          <Link onClick={() => selectCursorPosition(data.timestamp)}>
            {createTzDate(data.timestamp, props.timezone).format(
              timestampFormat
            )}
          </Link>
        );
      }
    },
    {
      title: t("Event"),
      key: "event",
      render: (data: LteTableData) => {
        let elementArr: JSX.Element[] = [<></>];

        for (let i = 0; i < data.rowType.length; i++) {
          switch (data.rowType[i]) {
            case "power":
              elementArr.push(
                <div style={{ color: data.color[i] }}>{data.message[i]}</div>
              );
              break;
            case "error":
              elementArr.push(
                <div style={{ color: data.color[i] }}>
                  <WarningOutlined /> {data.message[i]}
                </div>
              );
              break;
            case "sensor":
              elementArr.push(
                <div style={{ color: data.color[i] }}>
                  <ExclamationCircleOutlined /> {data.message[i]}
                </div>
              );
              break;
            case "schedule":
              elementArr.push(
                <div style={{ color: data.color[i] }}>
                  <CarryOutOutlined /> {data.message[i]}
                </div>
              );
              break;
            default:
              elementArr.push(<div>{data.message[i]}</div>);
          }
        }
        return elementArr;
      }
    }
  ];

  return (
    <Table
      dataSource={props.dataObject}
      columns={columns}
      size="middle"
      pagination={false}
    />
  );
};

export default LteTable;
