import React, { useRef, useState } from "react";
import { Card, Col, Drawer, Input, InputRef, Result, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAndAvailableLayouts } from "../../state/openDatxSlice";
import { LayoutId } from "../../helpers/layoutHelper";
import { NormalParagraph, SmallTitle } from "../Common/CommonFonts";
import { NormalButton } from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";
import { SmileOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";
import { addCardToDashboardLayouts } from "../../state/persistantStateSlice";
import { DictionaryTransKeys } from "../../lib/i18n";
import { size } from "../../helpers/pageHelper";
import mobitronColors from "../../styles/mobitronColors";

interface IAddableDashboardCard {
  title: string;
  description: string;
  action: () => void;
  disabled?: boolean;
}

/** A item that describes an addable dashboard card */
const AddableDashboardCard = (props: IAddableDashboardCard) => {
  const { t } = useTranslation();

  return (
    <Card style={{ width: "100%" }} styles={{ body: { padding: size.m1 } }}>
      <Row justify="start" wrap={false} align="middle">
        {/* text content */}
        <Col flex="auto" style={{ paddingRight: size.s2 }}>
          <Space
            direction="vertical"
            align="start"
            size="small"
            style={{ width: "100%" }}
          >
            <SmallTitle style={{ marginBottom: 0 }}>{props.title}</SmallTitle>
            <NormalParagraph style={{ textAlign: "start", margin: "0px" }}>
              {props.description}
            </NormalParagraph>
          </Space>
        </Col>

        {/* button content */}
        <Col flex="none">
          <Row align="middle" style={{ height: "100%" }}>
            <NormalButton onClick={props.action}>
              {t("ShowInReport")}
            </NormalButton>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

/** Content to show when list of available dashboard cards is empty */
const NoAddableDashboardCardAvailable = (props: { hasFilter: boolean }) => {
  const { t } = useTranslation();

  return (
    <Result
      icon={<SmileOutlined style={{ color: mobitronColors.altDarkGreen }} />}
      title={
        props.hasFilter
          ? t("AvailableCardsGivenSearchPlaced")
          : t("AvailableCardsAlreadyPlaced")
      }
    />
  );
};

interface IProps {
  fileId: string;
  visible: boolean;
  closeDrawer: () => void;
}
const LayoutCardsLib = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");

  /** Ref for search input field. Used to auto focus on open */
  const searchInputRef = useRef<InputRef>(null);

  const { currentDashboard, availableLayouts } = useSelector(
    selectCurrentAndAvailableLayouts(props.fileId)
  );

  /** Add card to active layout */
  const addToLayout = (toAdd: LayoutId) => {
    dispatch(
      addCardToDashboardLayouts({
        toAdd,
        dashboardId: currentDashboard.dashboardKey
      })
    );
  };

  /** Maps layout id to a showable/searchable object */
  const mapToType = (item: LayoutId): IAddableDashboardCard => ({
    title: t(item),
    // layoutId+desc gets the correct description
    description: t(`${item}Desc` as DictionaryTransKeys),
    action: () => addToLayout(item)
  });

  /** Filter an item by title/description */
  const filterBySearchInput = (item: IAddableDashboardCard) => {
    if (searchInput === "") {
      return true;
    }

    const fixedSearchInput = searchInput.toLowerCase();

    return (
      item.title.toLowerCase().includes(fixedSearchInput) ||
      item.description.toLowerCase().includes(fixedSearchInput)
    );
  };

  const addableDashboardCards = availableLayouts
    .map(mapToType)
    .filter(filterBySearchInput);

  return (
    <Drawer
      title={t("AddCards")}
      open={props.visible}
      onClose={props.closeDrawer}
      placement="right"
      getContainer={false}
      size="large"
      //height of titlebar
      rootStyle={{ marginTop: size.xl2 }}
      styles={{ body: { padding: 0 } }}
      afterVisibleChange={(isOpen) => isOpen && searchInputRef.current?.focus()}
    >
      <Card style={{ padding: 0 }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {/* search */}
          <Row>
            <Input
              placeholder={t("Search")}
              onChange={(e) => setSearchInput(e.target.value)}
              maxLength={35}
              style={{ width: "300px" }}
              ref={searchInputRef}
            />
          </Row>

          {/* cards */}
          <Space direction="vertical" style={{ width: "100%" }}>
            {isEmpty(addableDashboardCards) ? (
              <NoAddableDashboardCardAvailable hasFilter={searchInput !== ""} />
            ) : (
              addableDashboardCards.map((item) => (
                <AddableDashboardCard
                  title={item.title}
                  description={item.description}
                  action={item.action}
                />
              ))
            )}
          </Space>
        </Space>
      </Card>
    </Drawer>
  );
};

export default LayoutCardsLib;
