import React, { createRef, useState } from "react";
import {
  Typography,
  Row,
  Col,
  CardProps,
  notification,
  Input,
  Form
} from "antd";
import { useTranslation } from "react-i18next";
import { projectsState } from "../../state/projectSlice";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { size } from "../../helpers/pageHelper";
import { isUndefined } from "lodash-es";
import { NormalButtonSmall, PrimaryButton } from "../Common/CommonButtons";
import {
  UpdateProjectNote,
  useAddProjectNoteMutation,
  useGetProjectNotesQuery,
  useUpdateProjectNoteMutation
} from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { LiftedCard } from "../Common/CommonCards";
import { getUser } from "../../state/sessionSlice";
import { TextAreaRef } from "antd/lib/input/TextArea";

const { TextArea } = Input;
const { Text, Paragraph } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

interface INotesData {
  id: string;
  text: string;
  created: string;
  lastEdit: string;
  user: IUserData;
}

interface IUserData {
  id: string;
  name: string;
  email: string;
}

interface IEditing {
  isEditing: boolean;
  noteId: string | undefined;
}

const NotesTab = () => {
  const { t } = useTranslation();
  const { selectedProjectId } = useSelector(projectsState);
  const user = useSelector(getUser);

  const { data: notes } = useGetProjectNotesQuery(
    selectedProjectId ? selectedProjectId : skipToken
  );

  const [editing, setEditing] = useState<IEditing>();

  let notesData: INotesData[] | undefined = undefined;

  if (!isUndefined(notes)) {
    notesData = notes.map((note) => {
      let row: INotesData = {
        id: note.id,
        text: note.text,
        created: note.created,
        lastEdit: note.lastEdit ?? "",
        user: {
          id: note.user.userId,
          name: `${note.user.firstName} ${note.user.lastName}`,
          email: note.user.email
        }
      };
      return row;
    });
  }

  /* Aligns item to top right of parent */
  const TopLeft = (props: CardProps) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        padding: 0,
        paddingTop: size.l2,
        paddingLeft: size.l2,
        fontWeight: "normal",
        fontSize: 12
      }}
    >
      {props.children}
    </div>
  );

  /* Aligns item to top right of parent */
  const TopRight = (props: CardProps) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        padding: 0,
        paddingTop: 2 + size.m1,
        paddingRight: size.m1,
        fontWeight: "normal",
        fontSize: 12
      }}
    >
      {props.children}
    </div>
  );

  // ADD NOTE SECTION
  const [addNote, addRequestStatus] = useAddProjectNoteMutation();
  const { isLoading: addRequestIsLoading } = addRequestStatus;

  const [addNoteText, setAddNoteText] = useState<string>();

  const handleAddNote = async (request: {
    projectId: string;
    text: string;
  }) => {
    const result: any = await addNote(request);
    if (result.data) {
      setAddNoteText(undefined);
    } else {
      notification.error({ message: t("FailedToAddNote") });
    }
  };

  const onAdd = () => {
    if (!isUndefined(selectedProjectId) && !isUndefined(addNoteText)) {
      const request: { projectId: string; text: string } = {
        projectId: selectedProjectId,
        text: addNoteText
      };
      handleAddNote(request);
    }
  };

  // EDIT NOTE SECTION
  const [editNote, editRequestStatus] = useUpdateProjectNoteMutation();
  const { isLoading: editRequestIsLoading } = editRequestStatus;

  const [editNoteText, setEditNoteText] = useState<string>();
  const editRef = createRef<TextAreaRef>();

  const handleEditNote = async (request: UpdateProjectNote) => {
    const result: any = await editNote(request);
    if (result.data) {
      setEditing({ noteId: undefined, isEditing: false });
    } else {
      notification.error({ message: t("FailedToEditTheNote") });
      setEditing({ noteId: undefined, isEditing: false });
    }
  };

  const onEdit = () => {
    if (
      !isUndefined(selectedProjectId) &&
      !isUndefined(editNoteText) &&
      !isUndefined(editing) &&
      !isUndefined(editing.noteId)
    ) {
      const request: UpdateProjectNote = {
        id: editing.noteId,
        projectId: selectedProjectId,
        text: editNoteText
      };
      handleEditNote(request);
    }
  };

  return (
    <>
      <Row justify="center" style={{ paddingTop: size.m1 }}>
        <Col span={16}>
          <Form
            name="addNote"
            onFinish={onAdd}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <Input.TextArea
              rows={2}
              style={{ padding: size.m1 }}
              required
              value={addNoteText}
              onChange={(e) => setAddNoteText(e.target.value)}
            />
            <Row
              justify="end"
              style={{ paddingTop: size.s1, paddingBottom: size.l2 }}
            >
              <PrimaryButton
                size="small"
                htmlType="submit"
                loading={addRequestIsLoading}
                disabled={isUndefined(addNoteText) || addNoteText.length === 0}
              >
                {t("AddNote")}
              </PrimaryButton>
            </Row>
          </Form>

          {notesData?.reverse().map((note) => {
            const timezoneOffset = new Date().getTimezoneOffset();
            const created = dayjs
              .utc(note.created)
              .subtract(timezoneOffset, "minutes")
              .format("YYYY-MM-DD, HH:mm");
            // Subtract 5 seconds to avoid showing "in a few seconds" when the note is just edited
            const lastEdit = dayjs
              .utc(note.lastEdit)
              .subtract(5, "seconds")
              .fromNow();
            const editNote = editing?.isEditing && editing.noteId === note.id;
            const showEdit = note.user.id === user.userId;

            return (
              <>
                <LiftedCard
                  style={{ marginBottom: size.m1 }}
                  styles={{
                    body: { paddingBottom: size.l2, paddingInline: size.l2 }
                  }}
                >
                  <TopLeft>
                    <Text
                      style={{ paddingRight: size.m1 }}
                      strong
                      type="secondary"
                    >
                      {note.user?.name}
                    </Text>
                    <Text type="secondary" style={{ paddingRight: size.m1 }}>
                      {created}
                    </Text>
                    <Text type="secondary">
                      {lastEdit === "Invalid date"
                        ? ""
                        : `(${t("Edited")} ${lastEdit})`}
                    </Text>
                  </TopLeft>
                  {showEdit && (
                    <TopRight>
                      <NormalButtonSmall
                        onClick={() =>
                          setEditing({ isEditing: true, noteId: note.id })
                        }
                      >
                        <Text type="secondary" style={{ fontSize: 12 }}>
                          {t("genEdit")}
                        </Text>
                      </NormalButtonSmall>
                    </TopRight>
                  )}

                  <Row style={{ paddingTop: size.l2 }}>
                    {editNote ? (
                      <>
                        <TextArea
                          autoSize={true}
                          defaultValue={note.text}
                          onChange={(e) => setEditNoteText(e.target.value)}
                          ref={editRef}
                          autoFocus
                          onFocus={(e) => {
                            // Sets the cursor position at the end of the text
                            // Saves the value
                            const val = e.target.value;
                            // Clears TextArea
                            e.target.value = "";
                            // Adds value back to the TextArea
                            e.target.value = val;
                          }}
                        />
                        <Row
                          justify="end"
                          style={{ width: "100%", paddingTop: size.s1 }}
                        >
                          <NormalButtonSmall
                            onClick={() =>
                              setEditing({
                                noteId: undefined,
                                isEditing: false
                              })
                            }
                            style={{ marginRight: size.s1 }}
                          >
                            {t("Cancel")}
                          </NormalButtonSmall>
                          <PrimaryButton
                            size="small"
                            onClick={() => onEdit()}
                            loading={editRequestIsLoading}
                            disabled={
                              isUndefined(editNoteText) ||
                              editNoteText.length === 0
                            }
                          >
                            {t("genSave")}
                          </PrimaryButton>
                        </Row>
                      </>
                    ) : (
                      <Paragraph
                        ellipsis={{
                          rows: 3,
                          expandable: true,
                          symbol: t("more")
                        }}
                        style={{
                          width: "100%",
                          marginBottom: 0,
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        {note.text}
                      </Paragraph>
                    )}
                  </Row>
                </LiftedCard>
              </>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default NotesTab;
