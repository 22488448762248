import React from "react";

interface IProps {
  color: string | undefined;
  warning: boolean | undefined;
}

const QuickReportButton: React.FC<IProps> = ({ color, warning }) => {
  const standardStyle: React.CSSProperties = {
    height: "30px",
    width: "30px",
    borderRadius: "25px",
    backgroundColor: color,
    display: "inline-block"
  };

  const triangleStyle: React.CSSProperties = {
    height: 0,
    width: 0,
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    borderBottom: `${"30px solid" + color}`,
    display: "inline-block"
  };

  return (
    <div style={!warning ? standardStyle : triangleStyle}>
      {warning && (
        <div
          style={{
            position: "relative",
            marginTop: 3,
            width: 11,
            right: 5,
            color: "white",
            fontSize: "large",
            lineHeight: "30px"
          }}
        >
          !
        </div>
      )}

      {!warning && (
        <div
          style={{
            position: "relative",
            color: "white",
            fontSize: 12,
            lineHeight: "30px"
          }}
        >
          OK
        </div>
      )}
    </div>
  );
};

export default QuickReportButton;
