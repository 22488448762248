import React from "react";

export interface ExtraButtonItem {
  title: string;
  pdfAction?: () => void;
  csvAction?: () => void;
  printAction?: () => void;
  action: () => void;
  tooltip?: string;
  disabled?: boolean;
  button?:
    | "print"
    | "exportSinglePdf"
    | "exportSingleCsv"
    | "exportMultiple"
    | "close"
    | "custom";
  icon?: JSX.Element;
}

export interface MoveableCardProps {
  title: string;
  dragHandle: string;
  closeMe: ExtraButtonItem;
  extraButtons?: ExtraButtonItem[];
  fileId: string;
  children: React.ReactNode;
}

/** Used in close-buttons in ExtraButtonItem because it requires an action.
 * Function does nothing in itself, as intended.
 */
export const emptyFunction = () => {};
