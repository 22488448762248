import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import { MobitronLogoBackground } from "../../logos";
import { isUndefined } from "lodash-es";
import { TokenData } from "../../helpers/sessionHelper";
import dayjs from "dayjs";
import { DictionaryTransKeys } from "../../lib/i18n";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

const short = 2480; // A4 width
const long = 3508; // A4 height
const padding = 144;
const logoHeight = 300;
const logoWidth = 900;
const contentWidth = long - padding - padding;
const contentHeight = short - padding - padding - logoHeight - 250;
const MobitronLogo = ReactDOMServer.renderToStaticMarkup(
  <MobitronLogoBackground />
);

/* HELPER FUNCTIONS
Also used in pdfMultiExport */

/** Function that takes a HTMLElement, removes all SVG images and returns a copy of the HTMLELement */
export const removeSvg = (html: HTMLElement) => {
  const parsedHtml = html.cloneNode(true) as HTMLElement;
  const svgElements = parsedHtml.getElementsByTagName("svg");
  for (let i = svgElements.length - 1; i >= 0; i--) {
    svgElements[i].parentNode!.removeChild(svgElements[i]);
  }
  return parsedHtml;
};

/** Function that takes a HTMLELement and finds the first SVG image and returns it as a string */
export const getSvg = (html: HTMLElement) => {
  const parsedHtml = html.cloneNode(true) as HTMLElement;
  const svgElements = parsedHtml.getElementsByTagName("svg");
  if (svgElements.length > 0) {
    return svgElements[0].outerHTML;
  } else {
    return "";
  }
};

/** Function that adds a xml declaration to a SVG string */
export const addXmlns = (svg: string) => {
  return svg.replace("<svg ", '<svg xmlns="http://www.w3.org/2000/svg" ');
};

/** Function that takes a string and replaces degree symbol with html code as btoa can't handle unicode. */
export const replaceDegree = (svg: string) => {
  return svg.replace("°", "&#176;");
};

// Function that takes a svg string and returns a image dataurl
export const getImage = (svgString: string, width: number, height: number) => {
  let returnDataUrl: Promise<string> = new Promise((resolve, reject) => {
    // Create a new canvas
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      // Fill canvas backgound (otherwise black)
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Create a new image
      const img = new Image();
      const svgData = btoa(svgString);
      img.src = "data:image/svg+xml;base64," + svgData;

      img.onload = () => {
        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // Make the canvas into a data URL
        const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
        resolve(dataUrl);
      };
    } else {
      reject("Could not create canvas");
    }
  });
  return returnDataUrl;
};

export const addLandscapeHeader = (
  pdf: jsPDF,
  t: TFunction,
  header?: HeaderData
) => {
  pdf.setFontSize(28);

  /** 1073 = 1/3 of contentWidth */
  const contentWidthCol = 1073;

  const topRow1 = padding + 60;
  const topRow2 = topRow1 + 64;
  const topRow3 = topRow2 + 64;
  const topRow4 = topRow3 + 64;

  const bottomRow1 = padding + logoHeight + 80;
  const bottomRow2 = bottomRow1 + 64;

  const col1 = 175;
  const col2 = col1 + 280;
  const col3 = contentWidthCol + 349;
  const col4 = col3 + 280;
  const col5 = contentWidthCol * 2 + 400;
  const col6 = col5 + 280;

  pdf.setTextColor("#555555");

  if (!isUndefined(header?.hasFilters)) {
    pdf.setFont("Helvetica", "normal");
    if (header?.hasFilters) {
      pdf.text(`(${t("ReportDataIsFiltered")})`, col5, topRow1);
    } else {
      pdf.text(`(${t("ReportDataIsNotFiltered")})`, col5, topRow1);
    }
  }
  if (header?.reportType) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("Report")}:`, col5, topRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(t(header.reportType), col6, topRow2);
  }

  const localUtcOffset = dayjs().utcOffset() / 60;
  const localUtcOffsetStr =
    localUtcOffset >= 0 ? `(UTC+${localUtcOffset})` : `(UTC${localUtcOffset})`;

  const now = dayjs().format("YYYY-MM-DD, HH:mm:ss ");
  pdf.setFont("Helvetica", "bold");
  pdf.text(`${t("Exported")}:`, col5, topRow3);
  pdf.setFont("Helvetica", "normal");
  pdf.text(now + localUtcOffsetStr, col6, topRow3);

  if (header?.exportedBy?.firstName && header.exportedBy.lastName) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("ExportedBy")}:`, col5, topRow4);
    pdf.setFont("Helvetica", "normal");
    pdf.text(
      header.exportedBy.firstName + " " + header.exportedBy.lastName,
      col6,
      topRow4
    );
  }

  if (header?.recStart) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("RecordStart")}:`, col1, bottomRow1);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.recStart, col2, bottomRow1);
  }

  if (header?.recEnd) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("RecordEnd")}:`, col1, bottomRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.recEnd, col2, bottomRow2);
  }

  if (header?.reportStart) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("ReportStart")}:`, col3, bottomRow1);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.reportStart, col4, bottomRow1);
  }

  if (header?.reportEnd) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("ReportEnd")}:`, col3, bottomRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.reportEnd, col4, bottomRow2);
  }

  pdf.setFont("Helvetica", "bold");
  pdf.text(`${t("Project")}:`, col5, bottomRow1);
  pdf.setFont("Helvetica", "normal");
  if (header?.projectName) {
    pdf.text(header.projectName, col6, bottomRow1);
  } else {
    pdf.text("-", col6, bottomRow1);
  }

  if (header?.deviceId) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("DeviceId")}:`, col5, bottomRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.deviceId, col6, bottomRow2);
  }
};

export const addPortraitHeader = (
  pdf: jsPDF,
  t: TFunction,
  header?: HeaderData
) => {
  pdf.setFontSize(26);

  /** 730 = 1/3 of contentWidth */
  const contentWidthCol = 730;

  const topRow1 = padding + 65;
  const topRow2 = topRow1 + 64;
  const topRow3 = topRow2 + 64;
  const topRow4 = topRow3 + 64;

  const bottomRow1 = padding + logoHeight + 40 + 64;
  const bottomRow2 = bottomRow1 + 64;

  const col1 = 120;
  const col2 = col1 + 230;
  const col3 = contentWidthCol + 156;
  const col4 = col3 + 230;
  const col5 = contentWidthCol * 2 + 194;
  const col6 = col5 + 230;

  pdf.setTextColor("#555555");

  if (!isUndefined(header?.hasFilters)) {
    pdf.setFont("Helvetica", "normal");
    if (header?.hasFilters) {
      pdf.text(`(${t("ReportDataIsFiltered")})`, col5, topRow1);
    } else {
      pdf.text(`(${t("ReportDataIsNotFiltered")})`, col5, topRow1);
    }
  }
  if (header?.reportType) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("Report")}:`, col5, topRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(t(header.reportType), col6, topRow2);
  }

  const localUtcOffset = dayjs().utcOffset() / 60;
  const localUtcOffsetStr =
    localUtcOffset >= 0 ? `(UTC+${localUtcOffset})` : `(UTC${localUtcOffset})`;

  const now = dayjs().format("YYYY-MM-DD, HH:mm:ss ");
  pdf.setFont("Helvetica", "bold");
  pdf.text(`${t("Exported")}:`, col5, topRow3);
  pdf.setFont("Helvetica", "normal");
  pdf.text(now + localUtcOffsetStr, col6, topRow3);

  if (header?.exportedBy?.firstName && header.exportedBy.lastName) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("ExportedBy")}:`, col5, topRow4);
    pdf.setFont("Helvetica", "normal");
    pdf.text(
      header.exportedBy.firstName + " " + header.exportedBy.lastName,
      col6,
      topRow4
    );
  }

  if (header?.recStart) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("RecordStart")}:`, col1, bottomRow1);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.recStart, col2, bottomRow1);
  }

  if (header?.recEnd) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("RecordEnd")}:`, col1, bottomRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.recEnd, col2, bottomRow2);
  }

  if (header?.reportStart) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("ReportStart")}:`, col3, bottomRow1);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.reportStart, col4, bottomRow1);
  }

  if (header?.reportEnd) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("ReportEnd")}:`, col3, bottomRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.reportEnd, col4, bottomRow2);
  }

  pdf.setFont("Helvetica", "bold");
  pdf.text(`${t("Project")}:`, col5, bottomRow1);
  pdf.setFont("Helvetica", "normal");
  if (header?.projectName) {
    pdf.text(header.projectName, col6, bottomRow1);
  } else {
    pdf.text("-", col6, bottomRow1);
  }

  if (header?.deviceId) {
    pdf.setFont("Helvetica", "bold");
    pdf.text(`${t("DeviceId")}:`, col5, bottomRow2);
    pdf.setFont("Helvetica", "normal");
    pdf.text(header.deviceId, col6, bottomRow2);
  }
};

const addPortraitInvoiceHeader = (pdf: jsPDF, t: TFunction) => {
  pdf.setFontSize(72);

  const row1 = padding + 185;
  const col1 = 1615;

  pdf.setTextColor("#000000");
  pdf.setFont("Helvetica", "normal");
  pdf.text(t("Order"), col1, row1);
};

/** Function that takes HTMLElement and saves the first SVG as a PDF. */
export const exportSvgAsPdf = (
  html: HTMLElement,
  fileName: string,
  t: TFunction,
  header?: HeaderData
) => {
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "px",
    format: [short, long],
    hotfixes: ["px_scaling"]
  });

  getImage(MobitronLogo, logoWidth, logoHeight).then((dataUrl) => {
    /** Add Mobitron logo to PDF */
    pdf.addImage(dataUrl, "JPEG", 175, padding, logoWidth, logoHeight);

    /** Adds header */
    if (header) {
      addLandscapeHeader(pdf, t, header);
    }

    // Find the first SVG element and convert it to a string
    const svgContent = addXmlns(replaceDegree(getSvg(html)));

    getImage(svgContent, contentWidth, contentHeight)
      .then((dataUrl) => {
        // Add the SVG graph to the PDF
        pdf.addImage(
          dataUrl,
          "JPEG",
          padding,
          logoHeight + padding + 250,
          contentWidth,
          contentHeight
        );
      })
      .then(() => {
        pdf.save(fileName);
      });
  });
};

/** Function that takes a HTMLElement and saves it as a PDF. */
export const exportHtmlAsPdf = (
  html: HTMLElement,
  fileName: string,
  t: TFunction,
  header?: HeaderData
) => {
  const htmlContent = removeSvg(html);
  htmlContent.style.letterSpacing = "initial !important";
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "px",
    format: [short, long],
    hotfixes: ["px_scaling"]
  });

  getImage(MobitronLogo, logoWidth * 1.05, logoHeight * 1.05).then(
    (dataUrl) => {
      /** Add Mobitron logo to PDF */
      pdf.addImage(
        dataUrl,
        "JPEG",
        120,
        padding,
        logoWidth * 1.05,
        logoHeight * 1.05
      );

      let margin: any[] = [];
      let yValue: number = 0;

      /** Adds header */
      if (header) {
        if (header.reportType === "Invoice") {
          addPortraitInvoiceHeader(pdf, t);
          margin = [padding, 72, padding, 72];
          yValue = 400;
        } else if (header.reportType === "ProjectReport") {
          // Empty header for Project exports
          margin = [0, 72, padding, 72];
          yValue = 600;
        } else {
          addPortraitHeader(pdf, t, header);
          margin = [padding, 72, padding, 72];
          yValue = 600;
        }
      }

      /** Adds Html content to PDF  */
      pdf
        .html(htmlContent, {
          margin: margin,
          width: 776,
          windowWidth: 776,
          autoPaging: "text",
          y: yValue,
          html2canvas: {
            scale: 3,
            backgroundColor: "none"
          }
        })
        .then(() => {
          pdf.save(fileName);
        });
    }
  );
};

/** Function that takes a HTMLElement and saves it as a landscape PDF. */
export const exportHtmlAsLandscapePdf = (
  html: HTMLElement,
  fileName: string
) => {
  const htmlContent = removeSvg(html);
  htmlContent.style.letterSpacing = "initial !important";
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "px",
    format: [short, long],
    hotfixes: ["px_scaling"]
  });

  getImage(MobitronLogo, logoWidth, logoHeight).then((dataUrl) => {
    /** Add Mobitron logo to PDF */
    pdf.addImage(dataUrl, "JPEG", padding, padding, logoWidth, logoHeight);

    /** Adds Html content to PDF  */
    pdf
      .html(htmlContent, {
        margin: [padding, 72, padding, 72],
        width: 1120,
        windowWidth: 1120,
        autoPaging: "text",
        y: 300,
        html2canvas: {
          scale: 3,
          backgroundColor: "none"
        }
      })
      .then(() => {
        pdf.save(fileName);
      });
  });
};

/** Hook to manage the export process */
export const useExportPdfHook = () => {
  const [isExporting, setIsExporting] = useState(false);

  const startExport = () => {
    setIsExporting(true);
  };
  const finishExport = () => {
    setIsExporting(false);
  };

  return { isExporting, startExport, finishExport };
};

// Note that the types are also translation strings
export type ReportTypes =
  | "AccelerationHistogram"
  | "ActiveParameters"
  | "DetailedVibrationAnalysis"
  | "extIO"
  | "extTimer"
  | "Invoice"
  | "MinMaxValues"
  | "ParametersPreview"
  | "PrimaryGraph"
  | "ProjectReport"
  | "QuickReport"
  | "RecordingInformation"
  | "RecordingParameters"
  | "Top10Accelerations"
  | "angle";

export interface HeaderData {
  reportType: ReportTypes;
  projectName?: string;
  hasFilters?: boolean;
  recStart?: string;
  recEnd?: string;
  reportStart?: string;
  reportEnd?: string;
  exportedBy?: TokenData;
  deviceId?: string;
}

/** Props for the PdfExportComponent */
interface PdfExportProps {
  ComponentBody: React.FC<any>;
  name: string;
  reportExportDone: () => void;
  props: any;
  type?: "html" | "svg";
  header?: HeaderData;
}

/** Component wraping a JSX Component  */
export const PdfExportComponent = (PEProps: PdfExportProps) => {
  const { t } = useTranslation();
  const { ComponentBody, props, name, type, reportExportDone, header } =
    PEProps;
  const itemRef = useRef();
  useEffect(() => {
    if (itemRef.current) {
      switch (type) {
        case "html":
          exportHtmlAsPdf(itemRef.current!, name, t, header);
          break;
        case "svg":
        default:
          exportSvgAsPdf(itemRef.current!, name, t, header);
          break;
      }
      reportExportDone();
    }
  }, [header, name, reportExportDone, type]);

  return (
    <div style={{ display: "none" }}>
      <div ref={itemRef as any}>
        <ComponentBody
          {...props}
          width={contentWidth / 2}
          height={contentHeight / 2}
        />
      </div>
    </div>
  );
};
