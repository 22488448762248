import React, { useState } from "react";
import { Form, Row, Col, Input, notification, TreeSelect, Select } from "antd";
import {
  modalsState,
  setCompanyId,
  closeEditCompanyMobitronModal,
  openRemoveCompanyMobitronModal,
  setCompanyName
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { isNil, isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  ChildCompany,
  Company,
  useGetCompanyDetailsQuery,
  useGetCompanyTreeQuery,
  useMobitronSetParentCompanyMutation,
  useUpdateCompanyDetailsMutation
} from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { countries } from "../../constants/countries";
import StandardModal from "../Common/StandardModal";
import RemoveCompanyMobitronModal from "./RemoveCompanyMobitronModal";
const { Option } = Select;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

interface DataNode {
  title?: string;
  key: string;
  value: string;
  children?: DataNode[];
  disabled: boolean;
}

type SetParentCompanyType = { companyId: string; parentCompanyId: string };

// Main component
const EditCompanyMobitronModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countryOptions = countries.map((country) => {
    return (
      <Option key={country.code} value={country.code}>
        {country.name}
      </Option>
    );
  });

  // Load CompanyId from state
  const companyId: string | undefined =
    modals.editCompanyMobitronModal.companyId;

  const companyDeletable: boolean = modals.editCompanyMobitronModal.isDeletable;

  let company: Company = {
    id: "",
    name: "",
    address: "",
    address2: "",
    postalCode: "",
    city: "",
    countryCode: "",
    parentCompanyId: ""
  };

  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [orgNumber, setOrgNumber] = useState<string>();
  const [vatNumber, setVatNumber] = useState<string>();
  const [invoiceEmail, setInvoiceEmail] = useState<string>();
  const [invoiceAddress, setInvoiceAddress] = useState<string>();
  const [invoicePostalCode, setInvoicePostalCode] = useState<string>();
  const [invoiceCity, setInvoiceCity] = useState<string>();
  const [adminEmail, setAdminEmail] = useState<string>();
  const [parentCompanyId, setParentCompanyId] = useState<string>();

  const handleCountryCodeChange = (value: string) => {
    setCountryCode(value);
  };

  // Load the selected company
  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId: companyId } : skipToken
  );
  if (!isUndefined(companyData) && companyData.id === companyId) {
    company = companyData;
  }

  // Get Parent Company to display parent company name in TreeSelect
  const { data: parentCompanyData } = useGetCompanyDetailsQuery(
    companyData?.parentCompanyId
      ? { companyId: companyData.parentCompanyId }
      : skipToken
  );
  const { data: companyTreeData } = useGetCompanyTreeQuery();

  const CompanyTree: React.FC<{}> = () => {
    let walkTree = (company: ChildCompany): DataNode => {
      let treeChildren: DataNode[] = [];
      if (companyId !== company.id) {
        treeChildren = company.childCompanies
          ? company.childCompanies.map((child) => walkTree(child))
          : [];
      }
      let tree: DataNode = {
        title: company.name ?? t("NoName"),
        key: company.id,
        value: company.id,
        children: treeChildren,
        disabled: companyId === company.id
      };

      return tree;
    };
    let treeData: DataNode[] | undefined = companyTreeData
      ? [walkTree(companyTreeData)]
      : undefined;

    return (
      <TreeSelect
        placeholder={t("SelectParentCompany")}
        treeData={treeData}
        treeDefaultExpandAll
        value={parentCompanyId}
        onChange={(value) => setParentCompanyId(value)}
        defaultValue={
          companyData?.parentCompanyId && parentCompanyData?.name
            ? parentCompanyData.name
            : t("SelectParentCompany")
        }
        disabled={!companyData?.parentCompanyId}
      />
    );
  };

  /* Request for update Company Properties */
  const [updateCompanyDetails, requestStatus] =
    useUpdateCompanyDetailsMutation();
  const { isLoading: companyRequestIsLoading } = requestStatus;

  const handleUpdateCompanyDetails = async (request: Company) => {
    const result: any = await updateCompanyDetails(request);
    if (result.data) {
      notification.success({ message: t("EditCompanyMobitronSuccessMessage") });
      dispatch(closeEditCompanyMobitronModal());
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("EditCompanyMobitronErrorMessage"),
        description: errorDescription
      });
    }
  };

  const [mobitronSetParentCompany, parentRequestStatus] =
    useMobitronSetParentCompanyMutation();
  const { isLoading: parentCompanyRequestIsLoading } = parentRequestStatus;

  const handleMobitronSetParentCompany = async (
    request: SetParentCompanyType
  ) => {
    const result: any = await mobitronSetParentCompany(request);
    if (result.data) {
      notification.success({
        message: t("EditParentCompanyMobitronSuccessMsg")
      });
      dispatch(closeEditCompanyMobitronModal());
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("EditParentCompanyMobitronErrorMsg"),
        description: errorDescription
      });
    }
  };

  // When user finishes form sucessfully
  const changeFinished = () => {
    const id = company.id;
    const newName = name ?? company.name;
    const newAddress = address ?? company.address;
    const newAddress2 = address2 ?? company.address2;
    const newPostalCode = postalCode ?? company.postalCode;
    const newCity = city ?? company.city;
    const newCountryCode = countryCode ?? company.countryCode;
    const newOrgNumber = orgNumber ?? company.orgNumber;
    const newVatNumber = vatNumber ?? company.vatNumber;
    const newInvoiceEmail = invoiceEmail ?? company.invoiceEmail;
    const newInvoiceAddress = invoiceAddress ?? company.invoiceAddress;
    const newInvoicePostalCode = invoicePostalCode ?? company.invoicePostalCode;
    const newInvoiceCity = invoiceCity ?? company.invoiceCity;
    const newAdminEmail = adminEmail ?? company.adminEmail;
    const newParentCompanyId = parentCompanyId ?? company.parentCompanyId;
    if (
      !isUndefined(newName) &&
      !isUndefined(newAddress) &&
      !isUndefined(newPostalCode) &&
      !isUndefined(newCity) &&
      !isUndefined(newCountryCode)
    ) {
      const request: Company = {
        id,
        name: newName,
        address: newAddress,
        address2: newAddress2,
        postalCode: newPostalCode,
        city: newCity,
        countryCode: newCountryCode,
        orgNumber: newOrgNumber,
        vatNumber: newVatNumber,
        invoiceEmail: newInvoiceEmail,
        invoiceAddress: newInvoiceAddress,
        invoicePostalCode: newInvoicePostalCode,
        adminEmail: newAdminEmail,
        invoiceCity: newInvoiceCity
      };
      if (request !== company) {
        handleUpdateCompanyDetails(request);
      }
      if (!isNil(newParentCompanyId) && !isUndefined(id)) {
        handleMobitronSetParentCompany({
          companyId: id,
          parentCompanyId: newParentCompanyId
        });
      }
      setName(undefined);
      setAddress(undefined);
      setAddress2(undefined);
      setPostalCode(undefined);
      setCity(undefined);
      setCountryCode(undefined);
      setOrgNumber(undefined);
      setVatNumber(undefined);
      setInvoiceEmail(undefined);
      setInvoiceAddress(undefined);
      setInvoicePostalCode(undefined);
      setInvoiceCity(undefined);
      setAdminEmail(undefined);
      setParentCompanyId(undefined);
    }
  };

  return (
    <>
      {/* Update company modal */}
      <StandardModal
        title={t("mobUpdateCompany")}
        zIndex={1045}
        open={modals.editCompanyMobitronModal.isOpen}
        onCancel={() => {
          dispatch(closeEditCompanyMobitronModal());
          dispatch(setCompanyId(undefined));
          dispatch(setCompanyName(undefined));
          setParentCompanyId(undefined);
        }}
        closable={true}
        footer={null}
        width={900}
        destroyOnClose={true}
      >
        <Form name="basic" layout="vertical" style={{ width: "100%" }}>
          {company && company.name ? (
            <>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item
                    label={t("genName")}
                    name="name"
                    rules={[{ required: true, message: t("NameIsRequired") }]}
                    initialValue={
                      !isUndefined(company.name) ? company.name : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("InvoiceEmail")}
                    name="invoiceEmail"
                    initialValue={
                      !isUndefined(company.invoiceEmail)
                        ? company.invoiceEmail
                        : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={invoiceEmail}
                      onChange={(e) => setInvoiceEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item
                    label={t("Address")}
                    name="address"
                    rules={[
                      { required: true, message: t("AddressIsRequired") }
                    ]}
                    initialValue={
                      !isUndefined(company.address) ? company.address : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("InvoiceAddress")}
                    name="invoiceAddress"
                    initialValue={
                      !isUndefined(company.invoiceAddress)
                        ? company.invoiceAddress
                        : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={invoiceAddress}
                      onChange={(e) => setInvoiceAddress(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item
                    label={t("Address2")}
                    name="address2"
                    initialValue={
                      !isUndefined(company.address2) ? company.address2 : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item
                    label={t("PostalCode")}
                    name="postalCode"
                    rules={[
                      { required: true, message: t("mobPostalCodeRequired") }
                    ]}
                    initialValue={
                      !isUndefined(company.postalCode) ? company.postalCode : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("InvoicePostalCode")}
                    name="invoicePostalCode"
                    initialValue={
                      !isUndefined(company.invoicePostalCode)
                        ? company.invoicePostalCode
                        : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={invoicePostalCode}
                      onChange={(e) => setInvoicePostalCode(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item
                    label={t("City")}
                    name="city"
                    rules={[{ required: true, message: t("CityIsRequired") }]}
                    initialValue={
                      !isUndefined(company.city) ? company.city : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("InvoiceCity")}
                    name="invoiceCity"
                    initialValue={
                      !isUndefined(company.invoiceCity)
                        ? company.invoiceCity
                        : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={invoiceCity}
                      onChange={(e) => setInvoiceCity(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item
                    label={t("Country")}
                    name="countryCode"
                    initialValue={
                      !isUndefined(company.countryCode)
                        ? company.countryCode
                        : ""
                    }
                    rules={[
                      { required: true, message: t("CountryIsRequired") }
                    ]}
                    style={{ marginBottom: size.s1 }}
                  >
                    <Select
                      showSearch
                      placeholder={t("SearchToSelect")}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        if (option?.children) {
                          return (
                            option.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                      filterSort={(optionA, optionB) => {
                        if (optionA.children && optionB.children) {
                          return optionA.children
                            .toString()
                            .toLowerCase()
                            .localeCompare(
                              optionB.children.toString().toLowerCase()
                            );
                        }
                        return 0;
                      }}
                      onChange={handleCountryCodeChange}
                    >
                      {countryOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item
                    label={t("OrgNumber")}
                    name="orgNumber"
                    initialValue={
                      !isUndefined(company.orgNumber) ? company.orgNumber : ""
                    }
                    style={{ marginBottom: size.s1 }}
                    rules={[
                      {
                        required: countryCode === "SE",
                        message: t("OrgNumberRequiredInSweden")
                      }
                    ]}
                  >
                    <Input
                      placeholder=""
                      value={orgNumber}
                      onChange={(e) => setOrgNumber(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("VATNumber")}
                    name="vatNumber"
                    initialValue={
                      !isUndefined(company.vatNumber) ? company.vatNumber : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={vatNumber}
                      onChange={(e) => setVatNumber(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={size.m1}>
                <Col span={12}>
                  <Form.Item label={t("mobParentCompany")} name="parentCompany">
                    <CompanyTree />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("AdminEmail")}
                    name="adminEmail"
                    initialValue={
                      !isUndefined(company.adminEmail) ? company.adminEmail : ""
                    }
                    style={{ marginBottom: size.s1 }}
                  >
                    <Input
                      placeholder=""
                      value={adminEmail}
                      onChange={(e) => setAdminEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Row justify={"space-between"}>
            <Col>
              {companyDeletable && companyId && (
                <PrimaryButton
                  danger
                  onClick={() => dispatch(openRemoveCompanyMobitronModal())}
                >
                  {t("DeleteCompany")}
                </PrimaryButton>
              )}
            </Col>
            <Col>
              <NormalButton
                onClick={() => dispatch(closeEditCompanyMobitronModal())}
                style={{ marginRight: size.s1 }}
              >
                {t("Cancel")}
              </NormalButton>
              <PrimaryButton
                htmlType="submit"
                loading={
                  companyRequestIsLoading || parentCompanyRequestIsLoading
                }
                onClick={changeFinished}
              >
                {t("mobSaveChanges")}
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
        <RemoveCompanyMobitronModal />
      </StandardModal>
    </>
  );
};

export default EditCompanyMobitronModal;
