import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  emptyScheduleBlock,
  VMRecordingParameters
} from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { RecStartType } from "../models/ViewModelRecordingParameters/VMRecParams";
import { VMRhParams } from "../models/ViewModelRecordingParameters/VMRhParams";
import { StoreApi } from "./store";
import {
  createBaseParams,
  getDays,
  getHours,
  getScheduleDuration
} from "../helpers/paramsHelper";
import { IParxHeader } from "../models/PARX/IParxHeader";
import {
  SlopeCalc,
  VMPressureParams
} from "../models/ViewModelRecordingParameters/VMPressureParams";
import { VMExternalSensorParams } from "../models/ViewModelRecordingParameters/VMExternalSensorParams";
import {
  defaultExternalRhTempConfig,
  defaultExternalSensorParams
} from "../constants/params-defaults";
import { isIExternalRhTempParams } from "../models/RecordingParameters/IExternalRhTempParams";

export interface StateOpenParx {
  parxHeader?: IParxHeader;
  recordingParameters: VMRecordingParameters;
}

const initialState: StateOpenParx = {
  recordingParameters: createBaseParams()
};

export const slice = createSlice({
  name: "openParx",
  initialState,

  reducers: {
    newRecordingParameters: (
      state,
      action: PayloadAction<VMRecordingParameters>
    ) => {
      state.recordingParameters = action.payload;
    },

    //acc
    toggleAccMainSwitch: (state) => {
      const { AccParams } = state.recordingParameters;

      AccParams.useAcc = !AccParams.useAcc;
    },

    setAcc: (state, action) => {
      state.recordingParameters.AccParams = action.payload;
    },
    toggleAccX: (state) => {
      state.recordingParameters.AccParams.useX =
        !state.recordingParameters.AccParams.useX;
    },
    toggleAccY: (state) => {
      state.recordingParameters.AccParams.useY =
        !state.recordingParameters.AccParams.useY;
    },
    toggleAccZ: (state) => {
      state.recordingParameters.AccParams.useZ =
        !state.recordingParameters.AccParams.useZ;
    },
    setAccXReg: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Xreg = action.payload;
    },
    setAccYReg: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Yreg = action.payload;
    },
    setAccZReg: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Zreg = action.payload;
    },
    setAccXAlarm: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Xalarm = action.payload;
    },
    setAccYAlarm: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Yalarm = action.payload;
    },
    setAccZAlarm: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Zalarm = action.payload;
    },
    setAccXms: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Xms = action.payload;
    },
    setAccYms: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Yms = action.payload;
    },
    setAccZms: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.params.Zms = action.payload;
    },
    setAccFilter: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.accFilter = action.payload;
    },

    toggleAccUseDva: (state) => {
      const { useDva } = state.recordingParameters.AccParams;

      state.recordingParameters.AccParams.useDva = !useDva;
    },
    setAccDvaTriggerLevel: (state, action: PayloadAction<number>) => {
      state.recordingParameters.AccParams.dvaTriggerLevel = action.payload;
    },
    setAccIgnoreCount: (state, action) => {
      state.recordingParameters.AccParams.IgnoreCountLTE = Number(
        action.payload
      );
    },
    toggleAccStoreAngle: (state) => {
      state.recordingParameters.AccParams.channelBits.Angle =
        !state.recordingParameters.AccParams.channelBits.Angle;
    },
    toggleAccStoreGps: (state) => {
      state.recordingParameters.AccParams.channelBits.GPS =
        !state.recordingParameters.AccParams.channelBits.GPS;
    },
    toggleAccUseLte: (state) => {
      state.recordingParameters.AccParams.channelBits.LTE =
        !state.recordingParameters.AccParams.channelBits.LTE;
    },

    //temp
    setTemp: (state, aciton) => {
      state.recordingParameters.TempParams = aciton.payload;
    },
    toggleTemp: (state) => {
      state.recordingParameters.TempParams.useTemp =
        !state.recordingParameters.TempParams.useTemp;
    },
    setTempIntervalDays: (state, action) => {
      state.recordingParameters.TempParams.intervalDays = Number(
        action.payload
      );
    },
    setTempIntervalHours: (state, action) => {
      state.recordingParameters.TempParams.intervalHours = Number(
        action.payload
      );
    },
    setTempIntervalMinutes: (state, action) => {
      state.recordingParameters.TempParams.intervalMinutes = Number(
        action.payload
      );
    },
    setTempLowAlarm: (state, action) => {
      state.recordingParameters.TempParams.params.lowAlarm = Number(
        action.payload
      );
    },
    setTempHighAlarm: (state, action) => {
      state.recordingParameters.TempParams.params.highAlarm = Number(
        action.payload
      );
    },
    setTempIgnoreCount: (state, action) => {
      state.recordingParameters.TempParams.IgnoreCountLTE = Number(
        action.payload
      );
    },
    toggleTempStoreGps: (state) => {
      state.recordingParameters.TempParams.channelBits.GPS =
        !state.recordingParameters.TempParams.channelBits.GPS;
    },
    toggleTempUseLte: (state) => {
      state.recordingParameters.TempParams.channelBits.LTE =
        !state.recordingParameters.TempParams.channelBits.LTE;
    },

    //pressure
    setPressure: (state, aciton) => {
      state.recordingParameters.PressureParams = aciton.payload;
    },
    togglePressure: (state) => {
      state.recordingParameters.PressureParams.usePressure =
        !state.recordingParameters.PressureParams.usePressure;
    },
    setPressureIntervalDays: (state, action) => {
      state.recordingParameters.PressureParams.intervalDays = Number(
        action.payload
      );
    },
    setPressureIntervalHours: (state, action) => {
      state.recordingParameters.PressureParams.intervalHours = Number(
        action.payload
      );
    },
    setPressureIntervalMinutes: (state, action) => {
      state.recordingParameters.PressureParams.intervalMinutes = Number(
        action.payload
      );
    },
    setPressureLowAlarm: (state, action) => {
      state.recordingParameters.PressureParams.params.lowAlarm = Number(
        action.payload
      );
    },
    setPressureHighAlarm: (state, action) => {
      state.recordingParameters.PressureParams.params.highAlarm = Number(
        action.payload
      );
    },
    setPressureSlopeValue: (state, action) => {
      state.recordingParameters.PressureParams.params.slopeValue =
        action.payload;
    },
    setPressureTempValue: (state, action) => {
      state.recordingParameters.PressureParams.params.tempValue =
        action.payload;
    },
    setPressureIgnoreCount: (state, action) => {
      state.recordingParameters.PressureParams.IgnoreCountLTE = Number(
        action.payload
      );
    },
    togglePressureStoreGps: (state) => {
      state.recordingParameters.PressureParams.channelBits.GPS =
        !state.recordingParameters.PressureParams.channelBits.GPS;
    },
    togglePressureUseLte: (state) => {
      state.recordingParameters.PressureParams.channelBits.LTE =
        !state.recordingParameters.PressureParams.channelBits.LTE;
    },
    setSlopeCalcTemp1: (state, action: PayloadAction<number>) => {
      state.recordingParameters.PressureParams.slopeCalc.temp1 = action.payload;
    },
    setSlopeCalcTemp2: (state, action: PayloadAction<number>) => {
      state.recordingParameters.PressureParams.slopeCalc.temp2 = action.payload;
    },
    setSlopeCalcPressure1: (state, action: PayloadAction<number>) => {
      state.recordingParameters.PressureParams.slopeCalc.pressure1 =
        action.payload;
    },
    setSlopeCalcPressure2: (state, action: PayloadAction<number>) => {
      state.recordingParameters.PressureParams.slopeCalc.pressure2 =
        action.payload;
    },
    //rh
    toggleRh: (state) => {
      state.recordingParameters.RhParams.useRh =
        !state.recordingParameters.RhParams.useRh;
    },
    setRhIntervalDays: (state, action) => {
      state.recordingParameters.RhParams.intervalDays = Number(action.payload);
    },
    setRhIntervalHours: (state, action) => {
      state.recordingParameters.RhParams.intervalHours = Number(action.payload);
    },
    setRhIntervalMinutes: (state, action) => {
      state.recordingParameters.RhParams.intervalMinutes = Number(
        action.payload
      );
    },
    setRhLowAlarm: (state, action) => {
      state.recordingParameters.RhParams.params.lowAlarm = Number(
        action.payload
      );
    },
    setRhHighAlarm: (state, action) => {
      state.recordingParameters.RhParams.params.highAlarm = Number(
        action.payload
      );
    },
    setRhIgnoreCount: (state, action) => {
      state.recordingParameters.RhParams.IgnoreCountLTE = Number(
        action.payload
      );
    },
    toggleRhStoreGps: (state) => {
      state.recordingParameters.RhParams.channelBits.GPS =
        !state.recordingParameters.RhParams.channelBits.GPS;
    },
    toggleRhUseLte: (state) => {
      state.recordingParameters.RhParams.channelBits.LTE =
        !state.recordingParameters.RhParams.channelBits.LTE;
    },

    //angle
    toggleAngle: (state) => {
      state.recordingParameters.AngleParams.useAngle =
        !state.recordingParameters.AngleParams.useAngle;
    },
    toggleAutoHorizon: (state) => {
      state.recordingParameters.AngleParams.useAutoHorizon =
        !state.recordingParameters.AngleParams.useAutoHorizon;
    },
    setAngleIntervalDays: (state, action) => {
      state.recordingParameters.AngleParams.intervalDays = action.payload;
    },
    setAngleIntervalHours: (state, action) => {
      state.recordingParameters.AngleParams.intervalHours = action.payload;
    },
    setAngleIntervalMinutes: (state, action) => {
      state.recordingParameters.AngleParams.intervalMinutes = action.payload;
    },
    setAngleXAlarm: (state, action) => {
      state.recordingParameters.AngleParams.params.xAlarmLevel = action.payload;
    },
    setAngleYAlarm: (state, action) => {
      state.recordingParameters.AngleParams.params.yAlarmLevel = action.payload;
    },
    setAngleZAlarm: (state, action) => {
      state.recordingParameters.AngleParams.params.zAlarmLevel = action.payload;
    },
    setAngleIgnoreCount: (state, action) => {
      state.recordingParameters.AngleParams.IgnoreCountLTE = Number(
        action.payload
      );
    },
    toggleAngleStoreGps: (state) => {
      state.recordingParameters.AngleParams.channelBits.GPS =
        !state.recordingParameters.AngleParams.channelBits.GPS;
    },
    toggleAngleUseLte: (state) => {
      state.recordingParameters.AngleParams.channelBits.LTE =
        !state.recordingParameters.AngleParams.channelBits.LTE;
    },

    toggleAlsoRecord: (state, action) => {
      const { trigger, channel } = action.payload;
      let triggerChannel;
      switch (trigger) {
        case "Acc":
          triggerChannel = state.recordingParameters.AccParams;
          break;
        case "Temp":
          triggerChannel = state.recordingParameters.TempParams;
          break;
        case "Pressure":
          triggerChannel = state.recordingParameters.PressureParams;
          break;
        case "Rh":
          triggerChannel = state.recordingParameters.RhParams;
          break;
        case "Angle":
          triggerChannel = state.recordingParameters.AngleParams;
          break;
        default:
          break;
      }
      if (!triggerChannel) return;
      switch (channel) {
        case "Temp":
          triggerChannel.channelBits.Temp = !triggerChannel.channelBits.Temp;
          break;
        case "Pressure":
          triggerChannel.channelBits.Pressure =
            !triggerChannel.channelBits.Pressure;
          break;
        case "Rh":
          triggerChannel.channelBits.Rh = !triggerChannel.channelBits.Rh;
          break;
        case "Angle":
          triggerChannel.channelBits.Angle = !triggerChannel.channelBits.Angle;
          break;
        case "Light":
          triggerChannel.channelBits.Light = !triggerChannel.channelBits.Light;
          break;
        case "GPS":
          triggerChannel.channelBits.GPS = !triggerChannel.channelBits.GPS;
          break;
        case "LTE":
          triggerChannel.channelBits.LTE = !triggerChannel.channelBits.LTE;
          break;
        case "Bluetooth":
          triggerChannel.channelBits.Bluetooth =
            !triggerChannel.channelBits.Bluetooth;
          break;
        case "DeviceHealth":
          triggerChannel.channelBits.DeviceHealth =
            !triggerChannel.channelBits.DeviceHealth;
          break;
        default:
          break;
      }
    },

    toggleAlsoRecordOutputBit: (state, action) => {
      const { trigger, outputNumber } = action.payload;
      let triggerChannel;
      switch (trigger) {
        case "Acc":
          triggerChannel = state.recordingParameters.AccParams;
          break;
        case "Temp":
          triggerChannel = state.recordingParameters.TempParams;
          break;
        case "Pressure":
          triggerChannel = state.recordingParameters.PressureParams;
          break;
        case "Rh":
          triggerChannel = state.recordingParameters.RhParams;
          break;
        case "Angle":
          triggerChannel = state.recordingParameters.AngleParams;
          break;
        default:
          break;
      }
      if (!triggerChannel) return;
      switch (outputNumber) {
        case 0:
          triggerChannel.alarmOutputBits.output0.used =
            !triggerChannel.alarmOutputBits.output0.used;
          break;
        case 1:
          triggerChannel.alarmOutputBits.output1.used =
            !triggerChannel.alarmOutputBits.output1.used;
          break;
        case 2:
          triggerChannel.alarmOutputBits.output2.used =
            !triggerChannel.alarmOutputBits.output2.used;
          break;
        case 3:
          triggerChannel.alarmOutputBits.output3.used =
            !triggerChannel.alarmOutputBits.output3.used;
          break;
        case 4:
          triggerChannel.alarmOutputBits.output4.used =
            !triggerChannel.alarmOutputBits.output4.used;
          break;
        case 5:
          triggerChannel.alarmOutputBits.output5.used =
            !triggerChannel.alarmOutputBits.output5.used;
          break;
        case 6:
          triggerChannel.alarmOutputBits.output6.used =
            !triggerChannel.alarmOutputBits.output6.used;
          break;
        case 7:
          triggerChannel.alarmOutputBits.output7.used =
            !triggerChannel.alarmOutputBits.output7.used;
          break;
        default:
          break;
      }
    },
    toggleAlsoRecordOutputBitState: (state, action) => {
      const { trigger, outputNumber } = action.payload;
      let triggerChannel;
      switch (trigger) {
        case "Acc":
          triggerChannel = state.recordingParameters.AccParams;
          break;
        case "Temp":
          triggerChannel = state.recordingParameters.TempParams;
          break;
        case "Pressure":
          triggerChannel = state.recordingParameters.PressureParams;
          break;
        case "Rh":
          triggerChannel = state.recordingParameters.RhParams;
          break;
        case "Angle":
          triggerChannel = state.recordingParameters.AngleParams;
          break;
        default:
          break;
      }
      if (!triggerChannel) return;
      switch (outputNumber) {
        case 0:
          triggerChannel.alarmOutputBits.output0.newStateOn =
            !triggerChannel.alarmOutputBits.output0.newStateOn;
          break;
        case 1:
          triggerChannel.alarmOutputBits.output1.newStateOn =
            !triggerChannel.alarmOutputBits.output1.newStateOn;
          break;
        case 2:
          triggerChannel.alarmOutputBits.output2.newStateOn =
            !triggerChannel.alarmOutputBits.output2.newStateOn;
          break;
        case 3:
          triggerChannel.alarmOutputBits.output3.newStateOn =
            !triggerChannel.alarmOutputBits.output3.newStateOn;
          break;
        case 4:
          triggerChannel.alarmOutputBits.output4.newStateOn =
            !triggerChannel.alarmOutputBits.output4.newStateOn;
          break;
        case 5:
          triggerChannel.alarmOutputBits.output5.newStateOn =
            !triggerChannel.alarmOutputBits.output5.newStateOn;
          break;
        case 6:
          triggerChannel.alarmOutputBits.output6.newStateOn =
            !triggerChannel.alarmOutputBits.output6.newStateOn;
          break;
        case 7:
          triggerChannel.alarmOutputBits.output7.newStateOn =
            !triggerChannel.alarmOutputBits.output7.newStateOn;
          break;
        default:
          break;
      }
    },

    // External inputs

    setExtInputUsed: (state, action) => {
      const { index, used } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      inputs[index].used = used;
    },

    setExtInputSaveAlarms: (state, action) => {
      const { index, saveAlarms } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      inputs[index].saveTrigg = saveAlarms;
    },

    setExtInputDescription: (state, action) => {
      const { index, description } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      inputs[index].description = description;
    },

    toggleExtInputTriggOnImpact: (state, action) => {
      const { index } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      inputs[index].triggOnImpact = !inputs[index].triggOnImpact;
    },

    toggleExtInputTriggOnReset: (state, action) => {
      const { index } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      inputs[index].triggOnReset = !inputs[index].triggOnReset;
    },

    toggleExtInputAlsoRecord: (state, action) => {
      const { trigger, channel } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      const triggerChannel = inputs[trigger];
      switch (channel) {
        case "Temp":
          triggerChannel.channelBits.Temp = !triggerChannel.channelBits.Temp;
          break;
        case "Rh":
          triggerChannel.channelBits.Rh = !triggerChannel.channelBits.Rh;
          break;
        case "Angle":
          triggerChannel.channelBits.Angle = !triggerChannel.channelBits.Angle;
          break;
        case "Pressure":
          triggerChannel.channelBits.Pressure =
            !triggerChannel.channelBits.Pressure;
          break;
        case "Light":
          triggerChannel.channelBits.Light = !triggerChannel.channelBits.Light;
          break;
        case "GPS":
          triggerChannel.channelBits.GPS = !triggerChannel.channelBits.GPS;
          break;
        case "LTE":
          triggerChannel.channelBits.LTE = !triggerChannel.channelBits.LTE;
          break;
        case "Bluetooth":
          triggerChannel.channelBits.Bluetooth =
            !triggerChannel.channelBits.Bluetooth;
          break;
        case "DeviceHealth":
          triggerChannel.channelBits.DeviceHealth =
            !triggerChannel.channelBits.DeviceHealth;
          break;
        default:
          break;
      }
    },

    toggleExtInputAlarmOutputBit: (state, action) => {
      const { trigger, outputNumber } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      switch (outputNumber) {
        case 0:
          inputs[trigger].alarmOutputBits.output0.used =
            !inputs[trigger].alarmOutputBits.output0.used;
          break;
        case 1:
          inputs[trigger].alarmOutputBits.output1.used =
            !inputs[trigger].alarmOutputBits.output1.used;
          break;
        case 2:
          inputs[trigger].alarmOutputBits.output2.used =
            !inputs[trigger].alarmOutputBits.output2.used;
          break;
        case 3:
          inputs[trigger].alarmOutputBits.output3.used =
            !inputs[trigger].alarmOutputBits.output3.used;
          break;
        case 4:
          inputs[trigger].alarmOutputBits.output4.used =
            !inputs[trigger].alarmOutputBits.output4.used;
          break;
        case 5:
          inputs[trigger].alarmOutputBits.output5.used =
            !inputs[trigger].alarmOutputBits.output5.used;
          break;
        case 6:
          inputs[trigger].alarmOutputBits.output6.used =
            !inputs[trigger].alarmOutputBits.output6.used;
          break;
        case 7:
          inputs[trigger].alarmOutputBits.output7.used =
            !inputs[trigger].alarmOutputBits.output7.used;
          break;
        default:
          break;
      }
    },
    toggleExtInputAlarmOutputBitState: (state, action) => {
      const { trigger, outputNumber } = action.payload;
      const inputs = state.recordingParameters.ExternalInputParams;
      switch (outputNumber) {
        case 0:
          inputs[trigger].alarmOutputBits.output0.newStateOn =
            !inputs[trigger].alarmOutputBits.output0.newStateOn;
          break;
        case 1:
          inputs[trigger].alarmOutputBits.output1.newStateOn =
            !inputs[trigger].alarmOutputBits.output1.newStateOn;
          break;
        case 2:
          inputs[trigger].alarmOutputBits.output2.newStateOn =
            !inputs[trigger].alarmOutputBits.output2.newStateOn;
          break;
        case 3:
          inputs[trigger].alarmOutputBits.output3.newStateOn =
            !inputs[trigger].alarmOutputBits.output3.newStateOn;
          break;
        case 4:
          inputs[trigger].alarmOutputBits.output4.newStateOn =
            !inputs[trigger].alarmOutputBits.output4.newStateOn;
          break;
        case 5:
          inputs[trigger].alarmOutputBits.output5.newStateOn =
            !inputs[trigger].alarmOutputBits.output5.newStateOn;
          break;
        case 6:
          inputs[trigger].alarmOutputBits.output6.newStateOn =
            !inputs[trigger].alarmOutputBits.output6.newStateOn;
          break;
        case 7:
          inputs[trigger].alarmOutputBits.output7.newStateOn =
            !inputs[trigger].alarmOutputBits.output7.newStateOn;
          break;
        default:
          break;
      }
    },

    // External outputs
    setExtOutputUsed: (state, action) => {
      const { index, used } = action.payload;
      const outputs = state.recordingParameters.ExternalOutputParams;
      outputs[index].used = used;
    },

    setExtOutputSaveAlarms: (state, action) => {
      const { index, saveAlarms } = action.payload;
      const outputs = state.recordingParameters.ExternalOutputParams;
      outputs[index].saveTrigg = saveAlarms;
    },

    setExtOutputInitialState: (state, action) => {
      const { index, initialState } = action.payload;
      const outputs = state.recordingParameters.ExternalOutputParams;
      outputs[index].initialStateOn = initialState;
    },

    setExtOutputDescription: (state, action) => {
      const { index, description } = action.payload;
      const outputs = state.recordingParameters.ExternalOutputParams;
      outputs[index].description = description;
    },

    // External sensors
    toggleExtSensorAlsoRecord: (state, action) => {
      const { trigger, channel } = action.payload;
      const sensors = state.recordingParameters.ExternalSensorParams;
      const triggerChannel = sensors.find(
        (sensor) => sensor.params.sensorTypeId === trigger
      );
      if (!triggerChannel) return;
      switch (channel) {
        case "Temp":
          triggerChannel.channelBits.Temp = !triggerChannel.channelBits.Temp;
          break;
        case "Pressure":
          triggerChannel.channelBits.Pressure =
            !triggerChannel.channelBits.Pressure;
          break;
        case "Rh":
          triggerChannel.channelBits.Rh = !triggerChannel.channelBits.Rh;
          break;
        case "Angle":
          triggerChannel.channelBits.Angle = !triggerChannel.channelBits.Angle;
          break;
        case "Light":
          triggerChannel.channelBits.Light = !triggerChannel.channelBits.Light;
          break;
        case "GPS":
          triggerChannel.channelBits.GPS = !triggerChannel.channelBits.GPS;
          break;
        case "LTE":
          triggerChannel.channelBits.LTE = !triggerChannel.channelBits.LTE;
          break;
        case "Bluetooth":
          triggerChannel.channelBits.Bluetooth =
            !triggerChannel.channelBits.Bluetooth;
          break;
        case "DeviceHealth":
          triggerChannel.channelBits.DeviceHealth =
            !triggerChannel.channelBits.DeviceHealth;
          break;
        default:
          break;
      }
    },

    //rec
    setStartRecordingType: (state, action: PayloadAction<RecStartType>) => {
      state.recordingParameters.RecParams.startRecordingType = action.payload;
      state.recordingParameters.RecParams.endRecordingType =
        action.payload === "button" ? "afterDuration" : "date";
    },
    setStartDate: (state, action: PayloadAction<number>) => {
      state.recordingParameters.RecParams.startTimestamp = action.payload;

      // Update stop dates
      const scheduleDuration = getScheduleDuration(
        state.recordingParameters.scheduleBlocks
      );
      state.recordingParameters.RecParams.stopTimestamp =
        action.payload + scheduleDuration * 3600;
      state.recordingParameters.RecParams.stopAfterDuration.days =
        getDays(scheduleDuration);
      state.recordingParameters.RecParams.stopAfterDuration.hours =
        getHours(scheduleDuration);
    },

    //free text
    setUserInfo: (state, action: PayloadAction<string>) => {
      state.recordingParameters.UserInfo = action.payload;
    },
    setProjectName: (state, action: PayloadAction<string>) => {
      state.recordingParameters.ProjectName = action.payload;
    },

    //companyID
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.recordingParameters.CompanyId = action.payload;
    },
    //parameterID
    setParameterId: (state, action: PayloadAction<string>) => {
      state.recordingParameters.ParameterId = action.payload;
    },

    //LTE
    setLteEnable2G: (state, action: PayloadAction<boolean>) => {
      state.recordingParameters.LteParams.LteBands.Enable2G = action.payload;
    },
    setLteEnable3G: (state, action: PayloadAction<boolean>) => {
      state.recordingParameters.LteParams.LteBands.Enable3G = action.payload;
    },
    setLteEnable4G: (state, action: PayloadAction<boolean>) => {
      state.recordingParameters.LteParams.LteBands.Enable4G = action.payload;
    },
    setLteEnable5G: (state, action: PayloadAction<boolean>) => {
      state.recordingParameters.LteParams.LteBands.Enable5G = action.payload;
    },
    setLteEnable6G: (state, action: PayloadAction<boolean>) => {
      state.recordingParameters.LteParams.LteBands.Enable6G = action.payload;
    },
    //GPS
    setGpsStoreSpeed: (state, action: PayloadAction<boolean>) => {
      state.recordingParameters.GpsParams.storeSpeed = action.payload;
    },

    //Schedulers
    // Adds a new scheduler to the list of schedulers
    addSchedulerBlock: (state) => {
      state.recordingParameters.scheduleBlocks.push(emptyScheduleBlock);

      // Update stop dates
      const scheduleDuration = getScheduleDuration(
        state.recordingParameters.scheduleBlocks
      );
      const startTimestamp = state.recordingParameters.RecParams.startTimestamp;
      state.recordingParameters.RecParams.stopTimestamp = startTimestamp
        ? startTimestamp + scheduleDuration * 3600
        : scheduleDuration * 3600;
      state.recordingParameters.RecParams.stopAfterDuration.days =
        getDays(scheduleDuration);
      state.recordingParameters.RecParams.stopAfterDuration.hours =
        getHours(scheduleDuration);
    },
    removeSchedulerBlock: (state, action: PayloadAction<number>) => {
      state.recordingParameters.scheduleBlocks.splice(action.payload, 1);

      // Update stop dates
      const scheduleDuration = getScheduleDuration(
        state.recordingParameters.scheduleBlocks
      );
      const startTimestamp = state.recordingParameters.RecParams.startTimestamp;
      state.recordingParameters.RecParams.stopTimestamp = startTimestamp
        ? startTimestamp + scheduleDuration * 3600
        : scheduleDuration * 3600;
      state.recordingParameters.RecParams.stopAfterDuration.days =
        getDays(scheduleDuration);
      state.recordingParameters.RecParams.stopAfterDuration.hours =
        getHours(scheduleDuration);
    },
    setSchedulerBlockDuration: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].blockDuration = action.payload.value;

      // Update stop dates
      const scheduleDuration = getScheduleDuration(
        state.recordingParameters.scheduleBlocks
      );
      const startTimestamp = state.recordingParameters.RecParams.startTimestamp;
      state.recordingParameters.RecParams.stopTimestamp = startTimestamp
        ? startTimestamp + scheduleDuration * 3600
        : scheduleDuration * 3600;
      state.recordingParameters.RecParams.stopAfterDuration.days =
        getDays(scheduleDuration);
      state.recordingParameters.RecParams.stopAfterDuration.hours =
        getHours(scheduleDuration);
    },
    setSchedulerFlightMode: (state, action: PayloadAction<IndexAndBool>) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].flightMode = action.payload.value;
    },
    setSchedulerUseLteFlightMode: (
      state,
      action: PayloadAction<IndexAndBool>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].LteParams.flightModeLte = action.payload.value;
    },
    setSchedulerUseLteInterval: (
      state,
      action: PayloadAction<IndexAndBool>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].LteParams.useLteInterval = action.payload.value;
    },
    setSchedulerLteAlwaysOn: (state, action: PayloadAction<IndexAndBool>) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].LteParams.alwaysOn = action.payload.value;
    },
    setSchedulerLteRequireGps: (state, action: PayloadAction<IndexAndBool>) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].LteParams.GpsRequired = action.payload.value;
    },
    setSchedulerLteIntervalDays: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].LteParams.intervalDays = action.payload.value;
    },
    setSchedulerLteIntervalHours: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].LteParams.intervalHours = action.payload.value;
    },
    setSchedulerLteIntervalMinutes: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].LteParams.intervalMinutes = action.payload.value;
    },
    setSchedulerUseGpsFlightMode: (
      state,
      action: PayloadAction<IndexAndBool>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].GpsParams.flightModeGps = action.payload.value;
    },
    setSchedulerUseGpsInterval: (
      state,
      action: PayloadAction<IndexAndBool>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].GpsParams.useGpsInterval = action.payload.value;
    },
    setSchedulerGpsAlwaysOn: (state, action: PayloadAction<IndexAndBool>) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].GpsParams.alwaysOn = action.payload.value;
    },
    setSchedulerGpsIntervalDays: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].GpsParams.intervalDays = action.payload.value;
    },
    setSchedulerGpsIntervalHours: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].GpsParams.intervalHours = action.payload.value;
    },
    setSchedulerGpsIntervalMinutes: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      state.recordingParameters.scheduleBlocks[
        action.payload.index
      ].GpsParams.intervalMinutes = action.payload.value;
    },

    //External Sensors
    addExternalSensor: (state, action: PayloadAction<IndexAndString>) => {
      const { index, value } = action.payload;
      const sensorTypeId = parseInt(value);

      if (index !== undefined) {
        const newSensors = state.recordingParameters.ExternalSensorParams.map(
          (sensor, i) => {
            if (i === index) {
              sensor.params.sensorTypeId = sensorTypeId;
            }
            return sensor;
          }
        );
        state.recordingParameters.ExternalSensorParams = newSensors;
      } else {
        const newSensor: VMExternalSensorParams = {
          ...defaultExternalSensorParams,
          params: {
            sensorTypeId,
            sensorName: "",
            sensorConfig: defaultExternalRhTempConfig
          }
        };
        state.recordingParameters.ExternalSensorParams.push(newSensor);
      }
    },
    removeExternalSensor: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.recordingParameters.ExternalSensorParams.splice(index, 1);
    },

    setExternalSensorName: (state, action: PayloadAction<IndexAndString>) => {
      const { index, value } = action.payload;
      const newState = state.recordingParameters.ExternalSensorParams.map(
        (sensor, i) => {
          if (i === index) {
            sensor.params.sensorName = value;
          }
          return sensor;
        }
      );

      state.recordingParameters.ExternalSensorParams = newState;
    },

    setExternalSensorIntervalDays: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      const { index, value } = action.payload;
      state.recordingParameters.ExternalSensorParams[index].intervalDays =
        value;
    },

    setExternalSensorIntervalHours: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      const { index, value } = action.payload;
      state.recordingParameters.ExternalSensorParams[index].intervalHours =
        value;
    },

    setExternalSensorIntervalMinutes: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      const { index, value } = action.payload;
      state.recordingParameters.ExternalSensorParams[index].intervalMinutes =
        value;
    },

    setExternalSensorTempHighAlarm: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      const { index, value } = action.payload;
      const sensorConfig =
        state.recordingParameters.ExternalSensorParams[index].params
          .sensorConfig;

      if (isIExternalRhTempParams(sensorConfig)) {
        sensorConfig.tempMax = value;
      }
    },

    setExternalSensorTempLowAlarm: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      const { index, value } = action.payload;
      const sensorConfig =
        state.recordingParameters.ExternalSensorParams[index].params
          .sensorConfig;
      if (isIExternalRhTempParams(sensorConfig)) {
        sensorConfig.tempMin = value;
      }
    },

    setExternalSensorRhHighAlarm: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      const { index, value } = action.payload;
      const sensorConfig =
        state.recordingParameters.ExternalSensorParams[index].params
          .sensorConfig;
      if (isIExternalRhTempParams(sensorConfig)) {
        sensorConfig.rhMax = value;
      }
    },

    setExternalSensorRhLowAlarm: (
      state,
      action: PayloadAction<IndexAndNumber>
    ) => {
      const { index, value } = action.payload;
      const sensorConfig =
        state.recordingParameters.ExternalSensorParams[index].params
          .sensorConfig;
      if (isIExternalRhTempParams(sensorConfig)) {
        sensorConfig.rhMin = value;
      }
    }
  }
});

interface IndexAndNumber {
  index: number;
  value: number;
}
interface IndexAndBool {
  index: number;
  value: boolean;
}

interface IndexAndString {
  index?: number;
  value: string;
}

export const {
  newRecordingParameters,

  //acc
  toggleAccMainSwitch,
  setAcc,
  toggleAccX,
  toggleAccY,
  toggleAccZ,
  setAccXReg,
  setAccXAlarm,
  setAccXms,
  setAccYReg,
  setAccYAlarm,
  setAccYms,
  setAccZReg,
  setAccZAlarm,
  setAccZms,
  setAccFilter,
  toggleAccUseDva,
  setAccDvaTriggerLevel,
  setAccIgnoreCount,
  toggleAccStoreAngle,
  toggleAccStoreGps,
  toggleAccUseLte,
  //temp
  setTemp,
  toggleTemp,
  setTempIntervalDays,
  setTempIntervalHours,
  setTempIntervalMinutes,
  setTempLowAlarm,
  setTempHighAlarm,
  setTempIgnoreCount,
  toggleTempStoreGps,
  toggleTempUseLte,
  //pressure
  setPressure,
  togglePressure,
  setPressureIntervalDays,
  setPressureIntervalHours,
  setPressureIntervalMinutes,
  setPressureLowAlarm,
  setPressureHighAlarm,
  setPressureSlopeValue,
  setPressureTempValue,
  setPressureIgnoreCount,
  togglePressureStoreGps,
  togglePressureUseLte,
  setSlopeCalcTemp1,
  setSlopeCalcTemp2,
  setSlopeCalcPressure1,
  setSlopeCalcPressure2,
  //rh
  toggleRh,
  setRhIntervalDays,
  setRhIntervalHours,
  setRhIntervalMinutes,
  setRhLowAlarm,
  setRhHighAlarm,
  setRhIgnoreCount,
  toggleRhStoreGps,
  toggleRhUseLte,
  //angle
  toggleAngle,
  toggleAutoHorizon,
  setAngleIntervalDays,
  setAngleIntervalHours,
  setAngleIntervalMinutes,
  setAngleXAlarm,
  setAngleYAlarm,
  setAngleZAlarm,
  setAngleIgnoreCount,
  toggleAngleStoreGps,
  toggleAngleUseLte,
  // Also record
  toggleAlsoRecord,
  toggleAlsoRecordOutputBit,
  toggleAlsoRecordOutputBitState,
  //external inputs
  setExtInputUsed,
  setExtInputSaveAlarms,
  setExtInputDescription,
  toggleExtInputTriggOnImpact,
  toggleExtInputTriggOnReset,
  toggleExtInputAlsoRecord,
  toggleExtInputAlarmOutputBit,
  toggleExtInputAlarmOutputBitState,
  //external outputs
  setExtOutputUsed,
  setExtOutputSaveAlarms,
  setExtOutputInitialState,
  setExtOutputDescription,
  //external sensors
  toggleExtSensorAlsoRecord,
  //rec
  setStartRecordingType,
  setStartDate,

  //free text
  setUserInfo,
  setProjectName,

  //metadata
  setCompanyId,
  setParameterId,

  //scheduler
  addSchedulerBlock,
  removeSchedulerBlock,
  setSchedulerBlockDuration,
  setSchedulerFlightMode,
  setSchedulerUseLteFlightMode,
  setSchedulerUseLteInterval,
  setSchedulerLteAlwaysOn,
  setSchedulerLteRequireGps,
  setLteEnable2G,
  setLteEnable3G,
  setLteEnable4G,
  setLteEnable5G,
  setLteEnable6G,
  setSchedulerLteIntervalDays,
  setSchedulerLteIntervalHours,
  setSchedulerLteIntervalMinutes,
  setSchedulerUseGpsFlightMode,
  setSchedulerUseGpsInterval,
  setGpsStoreSpeed,
  setSchedulerGpsAlwaysOn,
  setSchedulerGpsIntervalDays,
  setSchedulerGpsIntervalHours,
  setSchedulerGpsIntervalMinutes,

  //external sensors
  addExternalSensor,
  removeExternalSensor,
  setExternalSensorName,
  setExternalSensorIntervalDays,
  setExternalSensorIntervalHours,
  setExternalSensorIntervalMinutes,
  setExternalSensorTempHighAlarm,
  setExternalSensorTempLowAlarm,
  setExternalSensorRhHighAlarm,
  setExternalSensorRhLowAlarm
} = slice.actions;

export const selectRecordingParameters = (
  state: StoreApi
): VMRecordingParameters => state.openParx.recordingParameters;

export const selectAccParams = (state: StoreApi) =>
  state.openParx.recordingParameters.AccParams;

export const selectTemp = (state: StoreApi) =>
  state.openParx.recordingParameters.TempParams;

export const selectRh = (state: StoreApi): VMRhParams =>
  state.openParx.recordingParameters.RhParams;

export const selectPressure = (state: StoreApi): VMPressureParams =>
  state.openParx.recordingParameters.PressureParams;

export const selectSlopeCalc = (state: StoreApi): SlopeCalc =>
  state.openParx.recordingParameters.PressureParams.slopeCalc;

export const selectAngle = (state: StoreApi) =>
  state.openParx.recordingParameters.AngleParams;

export const selectExtInputs = (state: StoreApi) =>
  state.openParx.recordingParameters.ExternalInputParams;

export const selectExtOutputs = (state: StoreApi) =>
  state.openParx.recordingParameters.ExternalOutputParams;

export const selectParamsFreeText = (state: StoreApi) => {
  const { UserInfo, ProjectName } = state.openParx.recordingParameters;

  return { UserInfo, ProjectName };
};

export const selectAlsoRecord = (state: StoreApi) => {
  return {
    angle: state.openParx.recordingParameters.AngleParams.channelBits,
    acc: state.openParx.recordingParameters.AccParams.channelBits,
    temp: state.openParx.recordingParameters.TempParams.channelBits,
    pressure: state.openParx.recordingParameters.PressureParams.channelBits,
    rh: state.openParx.recordingParameters.RhParams.channelBits
  };
};

export const selectAlarmOutputBits = (state: StoreApi) => {
  return {
    angle: state.openParx.recordingParameters.AngleParams.alarmOutputBits,
    acc: state.openParx.recordingParameters.AccParams.alarmOutputBits,
    temp: state.openParx.recordingParameters.TempParams.alarmOutputBits,
    pressure: state.openParx.recordingParameters.PressureParams.alarmOutputBits,
    rh: state.openParx.recordingParameters.RhParams.alarmOutputBits
  };
};

export const selectActiveChannels = (state: StoreApi) => {
  const { AccParams, TempParams, PressureParams, RhParams, AngleParams } =
    state.openParx.recordingParameters;
  return {
    useAcc: AccParams.useAcc,
    useTemp: TempParams.useTemp,
    usePressure: PressureParams.usePressure,
    useRh: RhParams.useRh,
    useAngle: AngleParams.useAngle
  };
};

export const selectSchedulerByIndex = (index: number) => (state: StoreApi) =>
  state.openParx.recordingParameters.scheduleBlocks[index];

export const selectRecordingParametersItemsForPrint = (state: StoreApi) => {
  const { recordingParameters } = state.openParx;
  const { targetDevice } = state.paramsPage;

  return { recordingParameters, targetDevice };
};

export const selectExternalSensors = (state: StoreApi) => {
  return state.openParx.recordingParameters.ExternalSensorParams;
};

export const selectExternalSensorByIndex =
  (index: number) => (state: StoreApi) => {
    return state.openParx.recordingParameters.ExternalSensorParams[index];
  };

export default slice.reducer;
