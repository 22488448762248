import { Breakpoint } from "antd";

/** Height of electron custom titlebar */
export const titleBarHeight = 48;

/** Common style: Use full height of the screen that is available for content */
export const contentFullHeightStyle = `calc(100vh - ${titleBarHeight}px`;

/**
 * Get side menu width based on the current screensize
 * @param screenSize
 */
export const getSideMenuWidth = (
  screenSize: Partial<Record<Breakpoint, boolean>>
) => {
  if (screenSize.xxl) return "280px";
  else if (screenSize.xl) return "260px";
  else if (screenSize.lg) return "200px";
  else if (screenSize.md) return "140px";

  return "80px";
};

/** Common sizes for padding and margins.
 ** s1 = 6
 ** s2 = 8
 ** m1 = 12
 ** m2 = 16
 ** l1 = 20
 ** l2 = 24
 ** xl1 = 36
 ** xl2 = 48
 */
export const size = {
  s1: 6,
  s2: 8,
  m1: 12,
  m2: 16,
  l1: 20,
  l2: 24,
  l3: 30,
  xl1: 36,
  xl2: 48
};

export const hideBlock: React.CSSProperties = {
  display: "none"
};

export const impersonateBannerHeight = 48;

export const impersonatingHeight143 = (isImpersonating: boolean) => {
  return `calc(100vh - (143px ${isImpersonating ? `+ ${impersonateBannerHeight}px` : ""}))`;
};

export const impersonatingHeight320 = (isImpersonating: boolean) => {
  return `calc(100vh - (320px ${isImpersonating ? `+ ${impersonateBannerHeight}px` : ""}))`;
};
