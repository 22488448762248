import React from "react";
import { Col, Row, Spin, Tree } from "antd";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ChildCompany,
  useGetCompanyTreeQuery
} from "../../state/cargologRestApi";
import {
  openEditCompanyMobitronModal,
  setCompanyDeletable,
  setCompanyId,
  setCompanyName
} from "../../state/modalsSlice";
import { LiftedCard } from "../Common/CommonCards";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import EditCompanyMobitronModal from "./EditCompanyMobitronModal";
import { selectImpersonate } from "../../state/persistantStateSlice";
import { impersonatingHeight143, size } from "../../helpers/pageHelper";

interface DataNode {
  title?: string;
  key: string;
  children?: DataNode[];
}

const CompanyTree = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isImpersonating } = useSelector(selectImpersonate);
  const { data: companyTreeData, isLoading } = useGetCompanyTreeQuery();

  const isCompanyDeletable = (company: ChildCompany) => {
    if (
      (company.childCompanies && company.childCompanies.length > 0) ||
      company.userCount !== 0
    ) {
      return false;
    }
    return true;
  };

  const findCompanyInTree = (
    company: ChildCompany,
    companyId: string
  ): ChildCompany | null => {
    if (company.id === companyId) {
      return company;
    }
    if (company.childCompanies) {
      let foundCompany: ChildCompany | null = null;
      company.childCompanies.forEach((childCompany) => {
        let foundCompanyProspect = findCompanyInTree(childCompany, companyId);
        if (foundCompanyProspect) {
          foundCompany = foundCompanyProspect;
        }
      });
      return foundCompany;
    }
    return null;
  };

  const selectCompany = (selectedKeys: React.Key[]) => {
    if (!isUndefined(selectedKeys)) {
      const selectedCompanyId = selectedKeys[0].toString();
      const selectedCompany = findCompanyInTree(
        companyTreeData!,
        selectedCompanyId
      );

      if (selectedCompany) {
        dispatch(setCompanyId(selectedCompanyId));
        dispatch(setCompanyName(selectedCompany.name));
        dispatch(setCompanyDeletable(isCompanyDeletable(selectedCompany)));
        dispatch(openEditCompanyMobitronModal());
      }
    }
  };

  const CompanyTree: React.FC<{}> = () => {
    let walkTree = (company: ChildCompany): DataNode => {
      let treeChildren = company.childCompanies
        ? company.childCompanies.map((child) => walkTree(child))
        : [];
      let tree: DataNode = {
        title: `${company.name} (${company.userCount})` ?? t("mobNoName"),
        key: company.id,
        children: treeChildren
      };
      return tree;
    };

    let treeData: DataNode[] | undefined = companyTreeData
      ? [walkTree(companyTreeData)]
      : undefined;

    return (
      <Tree
        selectedKeys={[]}
        treeData={treeData}
        onSelect={selectCompany}
        defaultExpandAll
      />
    );
  };

  return (
    <>
      <LiftedCard
        style={{
          margin: size.m1,
          height: impersonatingHeight143(isImpersonating),
          overflow: "auto"
        }}
      >
        <Row justify="space-between" style={{ marginBottom: size.l2 }}>
          <Col>
            <LargeHeaderTitle>{t("mobCompanyTree")}</LargeHeaderTitle>
          </Col>
        </Row>
        <Row>
          {isLoading ? (
            <Row
              justify="center"
              align="middle"
              style={{ height: "calc(75vh - 320px)", width: "100%" }}
            >
              <Spin />
            </Row>
          ) : (
            <CompanyTree />
          )}
        </Row>
      </LiftedCard>
      <EditCompanyMobitronModal />
    </>
  );
};

export default CompanyTree;
