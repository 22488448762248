import {
  ColumnHeightOutlined,
  ColumnWidthOutlined,
  FileOutlined,
  MenuOutlined,
  OrderedListOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Checkbox,
  Col,
  List,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Space
} from "antd";
import { isNil } from "lodash-es";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PrintableLayoutId } from "../../helpers/layoutHelper";
import useKeyPress from "../../helpers/useKeyPress";
import {
  selectAvailableAndActivePrintableDashboardCardsIds,
  selectPreviewExportableDashboardCardsLazy,
  selectPreviewPrintableDashboardCardsLazy,
  selectReportPreviewHeaders,
  updatePrintLayout
} from "../../state/openDatxSlice";
import {
  ExportPdfButton,
  NormalButton,
  PrintButton
} from "../Common/CommonButtons";
import { SmallText } from "../Common/CommonFonts";
import {
  Orientation,
  PrintMultipleComponents,
  WrapMultipleComponents,
  usePrintHook
} from "../PrintExport/hocPrintables";
import { useExportPdfHook } from "../PrintExport/pdfExport";
import {
  ExportMultipleComponent,
  PaperFormat
} from "../PrintExport/pdfMultiExport";
import { size } from "../../helpers/pageHelper";
import StandardModal from "../Common/StandardModal";

/** Local component: An item that can be sorted */
const SortableItem: React.FC<{
  name: string;
  id: string;
  listPlacement: number;
}> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.id
    });

  const dragStyle: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition ?? undefined
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ ...dragStyle, width: "100%" }}
    >
      <List.Item style={{ width: "100%" }}>
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          {/* <Divider style={{ ...dividerStyle, paddingBottom: size.s1 }} /> */}
          <SmallText>{`${props.listPlacement}. ${props.name}`}</SmallText>
          <MenuOutlined {...listeners} style={{ paddingLeft: size.s1 }} />
          {/* <Divider style={{ ...dividerStyle, paddingTop: size.m1 }} /> */}
        </Row>
      </List.Item>
    </div>
  );
};

interface IProps {
  fileId: string;
  show: boolean;
  close: () => void;
}

/** A modal that displays cards that will be included in the final report if
 * printed. All cards should be true to scale (a4). */
const PrintMultiblePreviewer: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { activeLayoutIds, availableLayoutIds } = useSelector(
    selectAvailableAndActivePrintableDashboardCardsIds(props.fileId)
  );

  const cardHeaders = useSelector(selectReportPreviewHeaders(props.fileId))();
  const cardsToPrint = useSelector(
    selectPreviewPrintableDashboardCardsLazy(props.fileId)
  )();
  const cardsToExport = useSelector(
    selectPreviewExportableDashboardCardsLazy(props.fileId)
  )();

  const dispatch = useDispatch();

  const multiGraphPrint = usePrintHook();
  const multiGraphExport = useExportPdfHook();

  const updateCardsInLayout = (newLayout: PrintableLayoutId[]) => {
    const { fileId } = props;

    dispatch(updatePrintLayout({ fileId, newLayout }));
  };

  const toggleLayoutContent = (
    <Checkbox.Group
      value={activeLayoutIds}
      onChange={(v) => updateCardsInLayout(v as PrintableLayoutId[])}
      style={{ flexDirection: "column" }}
    >
      {availableLayoutIds.map((id) => (
        <Checkbox value={id} key={id}>
          {t(id)}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );

  const [orientation, setOrientation] = useState<Orientation>("landscape");
  const [itemsPerPage, setItemsPerPage] = useState<number>(1);
  const [paperFormat, setPaperFormat] = useState<PaperFormat>("A4");

  const changeOrientation = (e: RadioChangeEvent) => {
    setOrientation(e.target.value);
  };
  const changeItemsPerPage = (e: RadioChangeEvent) => {
    setItemsPerPage(e.target.value);
  };
  const changePaperFormat = (e: RadioChangeEvent) => {
    setPaperFormat(e.target.value);
  };

  const toggleOrientationContent = (
    <Radio.Group onChange={changeOrientation} value={orientation}>
      <Space direction="vertical">
        <Radio value={"landscape"}>
          <FileOutlined rotate={-90} /> {t("Landscape")}
        </Radio>
        <Radio value={"portrait"}>
          <FileOutlined /> {t("Portrait")}
        </Radio>
      </Space>
    </Radio.Group>
  );

  const layoutOrderContent = (
    <DndContext
      onDragEnd={(ev) => {
        const { active, over } = ev;

        if (isNil(over?.id) || active.id === over?.id) {
          return;
        }

        const oldIndex = activeLayoutIds.indexOf(active.id as any);
        const newIndex = activeLayoutIds.indexOf(over!.id as any);
        const updated = arrayMove(activeLayoutIds, oldIndex, newIndex);

        dispatch(
          updatePrintLayout({ fileId: props.fileId, newLayout: updated })
        );
      }}
    >
      <SortableContext items={activeLayoutIds}>
        <List
          dataSource={activeLayoutIds}
          header={false}
          split={true}
          renderItem={(id, index) => (
            <SortableItem
              name={t(id)}
              id={id}
              key={id}
              listPlacement={index + 1}
            />
          )}
        />
      </SortableContext>
    </DndContext>
  );

  const PdfOnly = <SmallText type="secondary">({t("PDFOnly")})</SmallText>;

  const onKeyPressPrint = () => {
    multiGraphPrint.startPrint();
  };
  useKeyPress(props.show ? ["p"] : [""], onKeyPressPrint, "Control");

  const footer = (
    <Row justify="space-between">
      {/* Left */}
      <Col>
        <Space>
          <Popover zIndex={1050} content={toggleLayoutContent}>
            <NormalButton icon={<UnorderedListOutlined />}>
              {t("SelectedCards")} ({activeLayoutIds.length})
            </NormalButton>
          </Popover>
          <Popover zIndex={1050} content={layoutOrderContent}>
            <NormalButton icon={<OrderedListOutlined />}>
              {t("CardOrder")}
            </NormalButton>
          </Popover>
          <Popover zIndex={1050} content={toggleOrientationContent}>
            <NormalButton
              icon={
                orientation === "landscape" ? (
                  <FileOutlined rotate={-90} />
                ) : (
                  <FileOutlined />
                )
              }
            >
              {orientation === "landscape" ? (
                <>{t("Landscape")}</>
              ) : (
                <>{t("Portrait")}</>
              )}
            </NormalButton>
          </Popover>
          <Popover
            zIndex={1050}
            content={
              <Radio.Group onChange={changePaperFormat} value={paperFormat}>
                <Space direction="vertical">
                  <Radio value={"A4"}>A4</Radio>
                  <Radio value={"A3"}>A3 {PdfOnly}</Radio>
                  <Radio value={"Letter"}>
                    {t("Letter")} {PdfOnly}
                  </Radio>
                </Space>
              </Radio.Group>
            }
          >
            <NormalButton>
              {paperFormat} {t("format")}
            </NormalButton>
          </Popover>
          <Popover
            zIndex={1050}
            content={
              <Radio.Group onChange={changeItemsPerPage} value={itemsPerPage}>
                <Space direction="vertical">
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                </Space>
              </Radio.Group>
            }
          >
            <NormalButton
              icon={
                orientation === "landscape" ? (
                  <ColumnWidthOutlined />
                ) : (
                  <ColumnHeightOutlined />
                )
              }
            >
              {itemsPerPage} {itemsPerPage > 1 ? t("Cards") : t("Card")} /{" "}
              {t("Page")}
            </NormalButton>
          </Popover>
        </Space>
      </Col>
      {/* right */}
      <Col>
        <Space>
          <NormalButton onClick={props.close}>{t("Cancel")}</NormalButton>
          <ExportPdfButton onClick={() => multiGraphExport.startExport()}>
            {t("ExportPDF")}
          </ExportPdfButton>
          <PrintButton onClick={() => multiGraphPrint.startPrint()}>
            {t("Print")}
          </PrintButton>
        </Space>
      </Col>
    </Row>
  );

  const windowWidth = window.innerWidth;

  return (
    <>
      {/* Used for printing, will never display on screen */}
      {multiGraphPrint.isPrinting && (
        <PrintMultipleComponents
          headers={cardHeaders}
          printableItems={cardsToPrint}
          orientation={orientation}
          itemsPerPage={itemsPerPage}
          reportPrintDone={multiGraphPrint.finishPrint}
        />
      )}
      {/* Used for exporting PDF */}
      {multiGraphExport.isExporting && (
        <ExportMultipleComponent
          headers={cardHeaders}
          exportableItems={cardsToExport}
          orientation={orientation}
          itemsPerPage={itemsPerPage}
          paperFormat={paperFormat}
          reportExportDone={multiGraphExport.finishExport}
        />
      )}

      {/* Content */}
      <StandardModal
        title={t("exportReportTitle")}
        open={props.show}
        onCancel={props.close}
        width={windowWidth - 48}
        closable={true}
        footer={footer}
        style={{
          top: "72px",
          paddingBottom: 0
        }}
        styles={{
          content: { width: "100%", padding: 0 },
          footer: { padding: size.m1, paddingTop: 0 }
        }}
        zIndex={1045}
      >
        <div
          style={{
            overflowY: "scroll",
            overflowX: "auto",
            height: "calc(100vh - 204px)"
          }}
        >
          <div style={{ width: "100%" }}>
            <WrapMultipleComponents
              printableItems={cardsToPrint}
              orientation={orientation}
              previewMode={true}
              headers={cardHeaders}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </div>
      </StandardModal>
    </>
  );
};

export default PrintMultiblePreviewer;
