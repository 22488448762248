import { IOpenDatx } from "../state/openDatxSlice";
import { isEmpty, isNil } from "lodash-es";
import { insertIf } from "../utils/generalUtils";

/** full width is represeented by 24 col:s */
export const fullWidth = 24;

/** Default row height is set to 65px */
export const rowHeight = 65;

export interface UserDashboard {
  name: string;
  isReadOnly: boolean;
  layouts: CustomGridLayouts;
  originalLayouts: CustomGridLayouts;
}

export type AvailableDashboardKey = "Standard" | string;

export type LayoutId =
  | "quickReport"
  | "mainGraph"
  | "dvaGraph"
  | "minMax"
  | "topAccTable"
  | "deviceInfo"
  | "accHistogram"
  | "map"
  | "lte"
  | "extTimer"
  | "extIO"
  | "angle";

export type PrintableLayoutId = Exclude<LayoutId, "scoreValues">;

export interface CustomGridLayout extends Omit<ReactGridLayout.Layout, "i"> {
  i: LayoutId;
}

export type SupportedBreakPoints = "xl" | "lg" | "md";
export type CustomGridLayouts = Record<
  SupportedBreakPoints,
  CustomGridLayout[]
>;

type WH = { w: number; h: number; x?: number };

/**
 * Constructor function for User Dashboard
 * @param name
 * @param layouts
 * @param isReadOnly
 */
export const createUserDashboard = (
  name: AvailableDashboardKey,
  layouts: CustomGridLayouts,
  isReadOnly = false
): UserDashboard => ({
  name,
  isReadOnly,
  layouts,
  originalLayouts: layouts
});

//todo: not used yet
export const pickDashboardOrDefault = (
  toPick: AvailableDashboardKey,
  available: Record<AvailableDashboardKey, UserDashboard>
): UserDashboard => {
  return available[toPick] ?? available["Standard"];
};

//todo: Maybe remove in its current form. I need a general way of handeling
//"card in dashboard is not usefull for datx"
export const createStandardDashboard = (hasDva: boolean): UserDashboard => {
  const name = "Standard";

  const layouts = hasDva
    ? addDvaToLayout(defaultStartLayout)
    : defaultStartLayout;

  return createUserDashboard(name, layouts, true);
};

const mainGraphLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "mainGraph",
  x: 0,
  y: 5,
  w,
  h,
  isResizable: true
});

const minMaxLayoutFactory = ({ w, h, x }: WH): CustomGridLayout => ({
  i: "minMax",
  x: x ?? 0,
  y: 16,
  w,
  h,
  isResizable: true
});

const dvaLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "dvaGraph",
  x: 0,
  y: 12,
  w,
  h,
  isResizable: true
});

const topAccTableLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "topAccTable",
  x: 0,
  y: 5,
  w,
  h,
  isResizable: true
});

const accHistogramLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "accHistogram",
  x: 0,
  y: 5,
  w,
  h,
  isResizable: true
});

const deviceInfoLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "deviceInfo",
  x: 0,
  y: 5,
  w,
  h,
  isResizable: true
});

const quickReportLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "quickReport",
  x: 0,
  y: 0,
  w,
  h,
  isResizable: true
});

const mapLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "map",
  x: 0,
  y: 12,
  w,
  h,
  isResizable: true
});

const lteLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "lte",
  x: 0,
  y: 16,
  w,
  h,
  isResizable: true
});

const extTimerLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "extTimer",
  x: 16,
  y: 16,
  w,
  h,
  isResizable: true
});

const extIOLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "extIO",
  x: 0,
  y: 20,
  w,
  h,
  isResizable: true
});

const angleLayoutFactory = ({ w, h }: WH): CustomGridLayout => ({
  i: "angle",
  x: 0,
  y: 12,
  w,
  h,
  isResizable: true
});

const defaultXlLayout: CustomGridLayout[] = [
  quickReportLayoutFactory({ w: fullWidth, h: 4 }),
  mainGraphLayoutFactory({ w: fullWidth, h: 12 })
];

const defaultLgLayout: CustomGridLayout[] = [
  quickReportLayoutFactory({ w: fullWidth, h: 4 }),
  mainGraphLayoutFactory({ w: fullWidth, h: 12 })
];

const defaultMdLayout: CustomGridLayout[] = [
  //todo: use obj instead
  quickReportLayoutFactory({ w: fullWidth, h: 4 }),
  mainGraphLayoutFactory({ w: fullWidth, h: 12 })
];

/** Default layout when opening a new file */
export const defaultStartLayout: CustomGridLayouts = {
  xl: defaultXlLayout,
  lg: defaultLgLayout,
  md: defaultMdLayout
};

/** Default primary graph layout */
const defaultPrimaryGraphLayout: CustomGridLayouts = {
  xl: [mainGraphLayoutFactory({ w: fullWidth, h: 12 })],
  lg: [mainGraphLayoutFactory({ w: fullWidth, h: 12 })],
  md: [mainGraphLayoutFactory({ w: fullWidth, h: 12 })]
};

/** Default min/max layout */
const defaultMinMaxLayout: CustomGridLayouts = {
  xl: [minMaxLayoutFactory({ w: fullWidth, h: 8 })],
  lg: [minMaxLayoutFactory({ w: fullWidth, h: 8 })],
  md: [minMaxLayoutFactory({ w: fullWidth, h: 8 })]
};

/** Default dva-layout */
export const defaultDvaLayout: CustomGridLayouts = {
  xl: [dvaLayoutFactory({ w: fullWidth, h: 14 })],
  lg: [dvaLayoutFactory({ w: fullWidth, h: 14 })],
  md: [dvaLayoutFactory({ w: fullWidth, h: 14 })]
};

/** Default top accelerations table */
export const defaultTopAccValuesLayout: CustomGridLayouts = {
  xl: [topAccTableLayoutFactory({ w: fullWidth, h: 12 })],
  lg: [topAccTableLayoutFactory({ w: fullWidth, h: 12 })],
  md: [topAccTableLayoutFactory({ w: fullWidth, h: 12 })]
};

/** Default acc histogram layout*/
export const defaultAccHistogramLayout: CustomGridLayouts = {
  xl: [accHistogramLayoutFactory({ w: fullWidth, h: 12 })],
  lg: [accHistogramLayoutFactory({ w: fullWidth, h: 12 })],
  md: [accHistogramLayoutFactory({ w: fullWidth, h: 12 })]
};

/** Default device info card */
export const defaultDeviceInfoLayout: CustomGridLayouts = {
  xl: [deviceInfoLayoutFactory({ w: fullWidth, h: 7 })],
  lg: [deviceInfoLayoutFactory({ w: fullWidth, h: 7 })],
  md: [deviceInfoLayoutFactory({ w: fullWidth, h: 7 })]
};

export const defaultQuickReportLayout: CustomGridLayouts = {
  xl: [quickReportLayoutFactory({ w: fullWidth, h: 4 })],
  lg: [quickReportLayoutFactory({ w: fullWidth, h: 4 })],
  md: [quickReportLayoutFactory({ w: fullWidth, h: 4 })]
};

export const defaultMapLayout: CustomGridLayouts = {
  xl: [mapLayoutFactory({ w: fullWidth, h: 10 })],
  lg: [mapLayoutFactory({ w: fullWidth, h: 10 })],
  md: [mapLayoutFactory({ w: fullWidth, h: 10 })]
};

export const defaultLteLayout: CustomGridLayouts = {
  xl: [lteLayoutFactory({ w: 16, h: 10 })],
  lg: [lteLayoutFactory({ w: 16, h: 10 })],
  md: [lteLayoutFactory({ w: 16, h: 10 })]
};

export const defaultExtTimerLayout: CustomGridLayouts = {
  xl: [extTimerLayoutFactory({ w: 8, h: 10 })],
  lg: [extTimerLayoutFactory({ w: 8, h: 10 })],
  md: [extTimerLayoutFactory({ w: 8, h: 10 })]
};

export const defaultExtIOLayout: CustomGridLayouts = {
  xl: [extIOLayoutFactory({ w: 12, h: 10 })],
  lg: [extIOLayoutFactory({ w: fullWidth, h: 10 })],
  md: [extIOLayoutFactory({ w: fullWidth, h: 10 })]
};

export const defaultAngleLayout: CustomGridLayouts = {
  xl: [angleLayoutFactory({ w: 12, h: 10 })],
  lg: [angleLayoutFactory({ w: fullWidth, h: 10 })],
  md: [angleLayoutFactory({ w: fullWidth, h: 10 })]
};

/** Add primary graph to layout */
export const addPrimaryGraphToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isPrimaryGraphLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultPrimaryGraphLayout, layouts);

/** Add score values to layout */
export const addMinMaxToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isMinMaxLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultMinMaxLayout, layouts);

/** Add dva to layout */
export const addDvaToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isDvaLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultDvaLayout, layouts);

/** Add top acc table to layout */
export const addTopAccValuesTableToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isTopAccTableLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultTopAccValuesLayout, layouts);

/** Add acc histogram to layout */
export const addAccHistogramToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isAccHistogramLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultAccHistogramLayout, layouts);

/** Add top device info to layout */
export const addDeviceInfoToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isDeviceInfoLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultDeviceInfoLayout, layouts);

export const addQuickReportToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isQuickReportLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultQuickReportLayout, layouts);

export const addMapToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isMapLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultMapLayout, layouts);

export const addLteToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isLteLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultLteLayout, layouts);

export const addExtTimerToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isExtTimerLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultExtTimerLayout, layouts);

export const addExtIOToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isExtIOLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultExtIOLayout, layouts);

export const addAngleToLayout = (
  layouts: CustomGridLayouts
): CustomGridLayouts =>
  isAngleLayoutAlreadyIncluded(layouts)
    ? layouts
    : extendLayout(defaultAngleLayout, layouts);

/**
 * General function used to extend a layout with a card
 * @param toAdd
 * @param layouts
 */
const extendLayout = (
  toAdd: Record<SupportedBreakPoints, CustomGridLayout[]>,
  layouts: CustomGridLayouts
): CustomGridLayouts => {
  const xl = layouts.xl.concat(toAdd.xl);
  const lg = layouts.lg.concat(toAdd.lg);
  const md = layouts.md.concat(toAdd.md);

  return {
    xl,
    lg,
    md
  };
};

/** Returns true if primary graph is part of layouts */
const isPrimaryGraphLayoutAlreadyIncluded = (
  layouts: CustomGridLayouts
): boolean => isLayoutAlreadyIncluded("mainGraph", layouts);

/** Returns true if min/max is part of layouts */
const isMinMaxLayoutAlreadyIncluded = (layouts: CustomGridLayouts): boolean =>
  isLayoutAlreadyIncluded("minMax", layouts);

/** Returns true if DVA is part of layouts */
const isDvaLayoutAlreadyIncluded = (layouts: CustomGridLayouts): boolean =>
  isLayoutAlreadyIncluded("dvaGraph", layouts);

/** Returns true if top acc table is part of layouts */
const isTopAccTableLayoutAlreadyIncluded = (
  layouts: CustomGridLayouts
): boolean => isLayoutAlreadyIncluded("topAccTable", layouts);

/** Returns true if device info is part of layouts */
const isDeviceInfoLayoutAlreadyIncluded = (
  layouts: CustomGridLayouts
): boolean => isLayoutAlreadyIncluded("deviceInfo", layouts);

const isQuickReportLayoutAlreadyIncluded = (
  layouts: CustomGridLayouts
): boolean => isLayoutAlreadyIncluded("quickReport", layouts);

const isAccHistogramLayoutAlreadyIncluded = (
  layouts: CustomGridLayouts
): boolean => isLayoutAlreadyIncluded("accHistogram", layouts);

const isMapLayoutAlreadyIncluded = (layouts: CustomGridLayouts): boolean =>
  isLayoutAlreadyIncluded("map", layouts);

const isLteLayoutAlreadyIncluded = (layouts: CustomGridLayouts): boolean =>
  isLayoutAlreadyIncluded("lte", layouts);

const isExtTimerLayoutAlreadyIncluded = (layouts: CustomGridLayouts): boolean =>
  isLayoutAlreadyIncluded("extTimer", layouts);

const isExtIOLayoutAlreadyIncluded = (layouts: CustomGridLayouts): boolean =>
  isLayoutAlreadyIncluded("extIO", layouts);

const isAngleLayoutAlreadyIncluded = (layouts: CustomGridLayouts): boolean =>
  isLayoutAlreadyIncluded("angle", layouts);

/**
 * Returns true if layout-id is already part of the layout
 * @param layouts
 */
const isLayoutAlreadyIncluded = (
  layoutId: LayoutId,
  layouts: CustomGridLayouts
): boolean => {
  const findLayout = (x: CustomGridLayout) => x.i === layoutId;

  const { xl, lg, md } = layouts;

  if (xl?.find(findLayout) && lg?.find(findLayout) && md?.find(findLayout)) {
    return true;
  }

  return false;
};

/**
 * Deep compares new and old layouts keys for (x, y, w and h)
 * @param newLayout
 * @param oldLayout
 */
export const isLayoutsEqual = (
  newLayout: CustomGridLayouts,
  oldLayout: CustomGridLayouts
): boolean => {
  //entries to compare
  const keysToCompare: (keyof CustomGridLayout)[] = ["x", "y", "w", "h"];

  for (const breakPointKey of Object.keys(newLayout)) {
    const newL = newLayout[breakPointKey as keyof CustomGridLayouts];
    const oldL = oldLayout[breakPointKey as keyof CustomGridLayouts];

    if (isNil(newL) || isNil(oldL)) return false;

    if (newL.length !== oldL.length) return false;

    for (let i = 0; i < newL.length; i++) {
      const newEntry = newL[i];
      const oldEntry = oldL[i];

      if (!keysToCompare.every((key) => newEntry[key] === oldEntry[key])) {
        return false;
      }
    }
  }

  return true;
};
/**
 * Get all the layout ids that is available given the datx file d
 * @param d
 */
export const getAllAvaibleLayouts = (d: IOpenDatx): LayoutId[] => {
  const { data, dvaData } = d.datxContent;

  // Some layouts will not be too usefull if the datx doesn't contain
  // acceleration data
  const hasAcc = data?.find((d) => d.xAcc || d.yAcc || d.zAcc) !== undefined;
  // const hasGps =
  //   data?.find((d) => d.gps || (d.gpsStatus && d.gpsStatus.length > 0)) !==
  //   undefined;
  // const hasLte =
  //   data?.find((d) => d.lteStatus && d.lteStatus.length > 0) !== undefined;
  const hasExtTimer = data?.find((d) => d.externalTimers) !== undefined;
  const hasExtIO =
    data?.find((d) => d.externalInputs || d.externalOutputs) !== undefined;
  const hasAngle = data?.find((d) => d.angle) !== undefined;

  return [
    "quickReport",
    "mainGraph",
    "deviceInfo",
    "minMax",
    // ...insertIf<LayoutId>(hasGps, "map"), // Not printable atm
    // ...insertIf<LayoutId>(hasLte, "lte"), // Not printable atm
    ...insertIf<LayoutId>(hasExtTimer, "extTimer"),
    ...insertIf<LayoutId>(hasExtIO, "extIO"),
    ...insertIf<LayoutId>(!isEmpty(dvaData), "dvaGraph"),
    ...insertIf<LayoutId>(hasAcc, "topAccTable"),
    ...insertIf<LayoutId>(hasAcc, "accHistogram"),
    ...insertIf<LayoutId>(hasAngle, "angle")
  ];
};

/**
 * Get all layout ids that can be printed
 * @param d
 */
export const getAllAvailablePrintableLayouts = (
  d: IOpenDatx
): PrintableLayoutId[] => {
  const printableLayouts = getAllAvaibleLayouts(d).filter((x) => x);

  return printableLayouts as PrintableLayoutId[];
};

/**
 * Get an array of content that is not already included and can be included in
 * the current layout based on data in {datxContent}
 * @param datxContent
 * @param currentLayouts
 */
export const getAddableLayouts = (
  currentLayouts: CustomGridLayouts
): LayoutId[] => {
  const includeDeviceInfo = !isLayoutAlreadyIncluded(
    "deviceInfo",
    currentLayouts
  );

  const includePrimaryGraph = !isLayoutAlreadyIncluded(
    "mainGraph",
    currentLayouts
  );

  const includeQuickReport = !isLayoutAlreadyIncluded(
    "quickReport",
    currentLayouts
  );

  const includeMinMax = !isLayoutAlreadyIncluded("minMax", currentLayouts);

  const includeDva = !isDvaLayoutAlreadyIncluded(currentLayouts);

  const includeTopAccTable =
    !isTopAccTableLayoutAlreadyIncluded(currentLayouts);

  const includeAccHistogram = !isLayoutAlreadyIncluded(
    "accHistogram",
    currentLayouts
  );

  const includeMap = !isLayoutAlreadyIncluded("map", currentLayouts);

  const includeLte = !isLayoutAlreadyIncluded("lte", currentLayouts);

  const includeExtTimer = !isLayoutAlreadyIncluded("extTimer", currentLayouts);

  const includeExtIO = !isLayoutAlreadyIncluded("extIO", currentLayouts);

  const includeAngle = !isLayoutAlreadyIncluded("angle", currentLayouts);

  let res: LayoutId[] = [];

  if (includeQuickReport) res.push("quickReport");
  if (includePrimaryGraph) res.push("mainGraph");
  if (includeDeviceInfo) res.push("deviceInfo");
  if (includeMinMax) res.push("minMax");
  if (includeDva) res.push("dvaGraph");
  if (includeTopAccTable) res.push("topAccTable");
  if (includeAccHistogram) res.push("accHistogram");
  if (includeMap) res.push("map");
  if (includeLte) res.push("lte");
  if (includeExtTimer) res.push("extTimer");
  if (includeExtIO) res.push("extIO");
  if (includeAngle) res.push("angle");

  return res;
};

export const removeFromLayout = (
  toRemove: LayoutId,
  layouts: CustomGridLayouts
): CustomGridLayouts => {
  const remover = (x: CustomGridLayout) => x.i !== toRemove;

  const xl = layouts.xl.filter(remover);
  const lg = layouts.lg.filter(remover);
  const md = layouts.md.filter(remover);

  return { xl, lg, md };
};

export const addCardToLayout = (
  toAdd: LayoutId,
  layouts: CustomGridLayouts
): CustomGridLayouts => {
  const outcomes: Record<
    LayoutId,
    (layouts: CustomGridLayouts) => CustomGridLayouts
  > = {
    quickReport: addQuickReportToLayout,
    mainGraph: addPrimaryGraphToLayout,
    minMax: addMinMaxToLayout,
    dvaGraph: addDvaToLayout,
    topAccTable: addTopAccValuesTableToLayout,
    deviceInfo: addDeviceInfoToLayout,
    accHistogram: addAccHistogramToLayout,
    map: addMapToLayout,
    lte: addLteToLayout,
    extTimer: addExtTimerToLayout,
    extIO: addExtIOToLayout,
    angle: addAngleToLayout
  };

  return outcomes?.[toAdd]?.(layouts);
};
