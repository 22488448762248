import {
  ExportOutlined,
  DeliveredProcedureOutlined,
  FileSearchOutlined
} from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { MenuButton } from "../Common/CommonButtons";
import { Help } from "../MicroComponents/Help";
import PrintMultiblePreviewer from "./PrintMultiblePreviewer";
import { Dropdown, MenuProps } from "antd";
import { selectDatxToCsvFunc } from "../../state/openDatxSlice";
import { useSelector } from "react-redux";
import { selectCsvFormat } from "../../state/sessionSlice";
import { isNil } from "lodash-es";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";

interface IProps {
  fileId: string;
  recParams?: VMRecordingParameters;
}
const ExportReportButton: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [printPreviewVisible, setPrintPreviewVisible] = useState(false);

  const windowWidth = window.innerWidth;

  const csvFormat = useSelector(selectCsvFormat);
  const getDatxAsCsv = useSelector(
    selectDatxToCsvFunc(props.fileId, csvFormat)
  );

  const exportDatxAsCsv = () => {
    const datxAsCsv = getDatxAsCsv();

    if (isNil(datxAsCsv)) {
      displayErrorMessage(t("FailedToExportCsvDataNoDataAvailable"));
      return;
    }

    saveAsCsv(datxAsCsv);
  };

  const items: MenuProps["items"] = [
    {
      label: t("PdfPrintExportPreview"),
      key: "pdfPrintExportPreview",
      icon: <FileSearchOutlined />,
      onClick: () => setPrintPreviewVisible(true)
    },
    {
      label: "CSV",
      key: "csv",
      icon: <DeliveredProcedureOutlined />,
      onClick: () => exportDatxAsCsv()
    }
  ];

  return (
    <>
      <PrintMultiblePreviewer
        fileId={props.fileId}
        show={printPreviewVisible}
        close={() => setPrintPreviewVisible(false)}
      />
      {Help(
        <Dropdown trigger={["click"]} destroyPopupOnHide menu={{ items }}>
          <MenuButton icon={<ExportOutlined />}>
            {windowWidth > 990 ? t("ExportReport") : undefined}
          </MenuButton>
        </Dropdown>,
        t("ClickHereToExportTheReport"),
        "left"
      )}
    </>
  );
};

export default ExportReportButton;
