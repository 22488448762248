import {
  DiffOutlined,
  FilterOutlined,
  LineChartOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Checkbox,
  Col,
  Input,
  Popover,
  Row,
  Space,
  Table,
  Typography
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { Buffer } from "buffer";
import { TFunction } from "i18next";
import { isUndefined } from "lodash-es";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toGStr, toMsStr } from "../../helpers/dataModelHelper";
import {
  parseDtAcc,
  parseDtAngle,
  parseDtPressure,
  parseDtRh,
  parseDtTemp
} from "../../helpers/parsers/parseDataTypesHelper";
import {
  AccGeneral,
  AngleIcon,
  PressureIcon,
  RhIcon,
  TempIcon
} from "../../icons";
import {
  DT_Acc,
  DT_Angle,
  DT_Pressure,
  DT_Rh,
  DT_Temp
} from "../../models/FAT100DataTypes";
import {
  ProjectAlarms,
  useDownloadSerializedDataQuery,
  useGetProjectAlarmQuery
} from "../../state/cargologRestApi";
import { unpackFile } from "../../state/compareGraphsSlice";
import {
  selectGraphPageVm,
  setNewCursorPosition,
  unpackDatxAsync
} from "../../state/openDatxSlice";
import { closeProjectModal, projectsState } from "../../state/projectSlice";
import { selectTemperatureScale } from "../../state/sessionSlice";
import { NormalButton, NormalButtonSmall } from "../Common/CommonButtons";
import {
  TempScaleSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import { size } from "../../helpers/pageHelper";

const { Text, Title } = Typography;

interface AlarmMetaData {
  sensor: number;
  serial?: string;
  timestamp: string;
  parameterId: string;
  fileName: string;
}

interface AccAlarmData {
  sensorname: "acc";
  xAcc: number;
  yAcc: number;
  zAcc: number;
  duration: number;
}

interface TempAlarmData {
  sensorname: "temp";
  temp: number;
}

interface RhAlarmData {
  sensorname: "rh";
  rh: number;
}

interface AngleAlarmData {
  sensorname: "angle";
  xAngle: number;
  yAngle: number;
  zAngle: number;
}

interface PressureAlarmData {
  sensorname: "pressure";
  pressureRaw: number;
  pressureComp: number;
}

type AlarmData =
  | AccAlarmData
  | TempAlarmData
  | RhAlarmData
  | AngleAlarmData
  | PressureAlarmData
  | undefined;

export interface AlarmRow extends AlarmMetaData {
  data: AlarmData;
}

interface IDatxToDownload {
  parameterId: string;
  fileName: string;
  page: "/graph" | "/compare";
  timestamp: string;
}

export const renderAlarmSensor = (alarmSensor: number, t: TFunction) => {
  switch (alarmSensor) {
    case DT_Acc:
      return (
        <Text>
          <AccGeneral /> {t("AccSensor")}
        </Text>
      );
    case DT_Temp:
      return (
        <Text>
          <TempIcon /> {t("TempSensor")}
        </Text>
      );
    case DT_Rh:
      return (
        <Text>
          <RhIcon /> {t("RhSensor")}
        </Text>
      );
    case DT_Angle:
      return (
        <Text>
          <AngleIcon /> {t("AngleSensor")}
        </Text>
      );
    case DT_Pressure:
      return (
        <Text>
          <PressureIcon />
          {t("PressureSensor")}
        </Text>
      );
  }
};

export const round2Decimals = (v: number) => Math.round(v * 100) / 100;

export const accString = (data?: number) =>
  data ? toGStr(Math.abs(data)) : "N/A";
const filterButtonColor = (length: number) =>
  length > 0 ? "#3e9736" : undefined;

export const getAlarmData = (alarm: ProjectAlarms): AlarmData => {
  switch (alarm.type) {
    case DT_Acc:
      const accData = parseDtAcc(Buffer.from(alarm.data));
      const duration = Math.max(
        accData.xAcc[1],
        accData.yAcc[1],
        accData.zAcc[1]
      );
      return {
        sensorname: "acc",
        xAcc: accData.xAcc[0],
        yAcc: accData.yAcc[0],
        zAcc: accData.zAcc[0],
        duration
      };
    case DT_Temp:
      return { sensorname: "temp", temp: parseDtTemp(Buffer.from(alarm.data)) };
    case DT_Rh:
      return { sensorname: "rh", rh: parseDtRh(Buffer.from(alarm.data)) };
    case DT_Angle:
      const angleData = parseDtAngle(Buffer.from(alarm.data));
      return {
        sensorname: "angle",
        xAngle: angleData[0],
        yAngle: angleData[1],
        zAngle: angleData[2]
      };
    case DT_Pressure:
      const pressureData = parseDtPressure(Buffer.from(alarm.data));
      return {
        sensorname: "pressure",
        pressureRaw: pressureData[0],
        pressureComp: pressureData[1]
      };
  }
};

const AlarmsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedProjectId } = useSelector(projectsState);
  const { data: projectAlarms, isLoading: loadingAlarms } =
    useGetProjectAlarmQuery(
      selectedProjectId ? { id: selectedProjectId } : skipToken
    );

  const tempScale = useSelector(selectTemperatureScale);
  const timezoneOffset = new Date().getTimezoneOffset() * 60;

  // Monitor graph page to trigger timestamp navigation once file is opened
  const { activeFileId, hasOpenFiles, openNewFileStatus } =
    useSelector(selectGraphPageVm);
  const fileIsOpen =
    activeFileId &&
    hasOpenFiles &&
    openNewFileStatus === "openFileStatusInactive";

  // FILE DOWNLOAD START

  // Current file being downloaded
  const [datxToDownload, setDatxToDownload] = useState<IDatxToDownload>();
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const openOnlineFile = (
    parameterId: string,
    fileName: string,
    page: "/graph" | "/compare",
    timestamp: string,
    index: number
  ) => {
    setLoadingIndex(index);
    setDatxToDownload({ parameterId, fileName, page, timestamp });
  };

  // Download the selected file from the server
  const { data: selectedDatxFile, isLoading: loadingDatx } =
    useDownloadSerializedDataQuery(
      datxToDownload?.parameterId
        ? { parameterId: datxToDownload.parameterId }
        : skipToken
    );

  // Unpack the downloaded file (and navigate to compare if selected)
  useEffect(() => {
    if (isUndefined(selectedDatxFile) || isUndefined(datxToDownload)) return;

    if (datxToDownload.page === "/graph") {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({
          filePath: datxToDownload.fileName ?? datxToDownload.parameterId,
          rawData
        })
      );
    }
    if (datxToDownload.page === "/compare") {
      const rawData: number[] = [...selectedDatxFile];
      setDatxToDownload(undefined); // Prevents second useEffect
      setLoadingIndex(undefined);
      dispatch<any>(
        unpackFile({
          filePath: datxToDownload.fileName ?? datxToDownload.parameterId,
          rawData
        })
      );
      dispatch(closeProjectModal());
      navigate("/compare");
    }
  }, [selectedDatxFile]);

  // Sets the cursor to the alarm time (if file is opened in graph page)
  useEffect(() => {
    if (fileIsOpen && !isUndefined(datxToDownload)) {
      const alarmTime = new Date(datxToDownload.timestamp).getTime() / 1000;
      const cursorTime = alarmTime - timezoneOffset;
      setDatxToDownload(undefined);
      setLoadingIndex(undefined);
      dispatch(setNewCursorPosition(cursorTime));
      dispatch(closeProjectModal());
      navigate("/graph");
    }
  }, [fileIsOpen]);

  // END FILE DOWNLOAD

  // Filters row
  const [searchQuery, setSearchQuery] = useState<string>("");
  const selectableDevices = projectAlarms
    ? [...new Set(projectAlarms.map((alarm) => alarm.serial))]
    : [];
  const [filterDeviceNames, setFilterDeviceNames] = useState<string[]>([]);
  const selectableSensors = projectAlarms
    ? [...new Set(projectAlarms.map((alarm) => alarm.type))]
    : [];
  const [filterSensors, setFilterSensors] = useState<number[]>([]);

  // Main data parsing and filtering
  const alarmsData: AlarmRow[] = projectAlarms
    ? projectAlarms
        .map((alarm) => ({
          sensor: alarm.type,
          serial: alarm.serial,
          timestamp: alarm.dateTime,
          parameterId: alarm.parameterId,
          fileName: alarm.fileName,
          data: getAlarmData(alarm)
        }))
        .filter(
          (alarm) =>
            (filterDeviceNames.length === 0 ||
              filterDeviceNames.find(
                (deviceName) => deviceName === alarm.serial
              )) &&
            (filterSensors.length === 0 ||
              filterSensors.find(
                (sensorValue) => sensorValue === alarm.sensor
              )) &&
            (searchQuery === "" ||
              alarm.serial.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) return 1;
          if (a.timestamp > b.timestamp) return -1;
          return 0;
        })
    : [];

  const alarmColumns: ColumnsType<AlarmRow> = [
    {
      title: t("SerialNumber"),
      dataIndex: "serial",
      key: "serial",
      render: (serial: string) => <Text>{serial}</Text>,
      sorter: (a, b) => (a.serial ?? "").localeCompare(b.serial ?? "")
    },
    {
      title: t("Sensor"),
      dataIndex: "sensor",
      key: "sensor",
      render: (sensor: number) => renderAlarmSensor(sensor, t)
    },
    {
      title: t("Data"),
      dataIndex: "data",
      key: "data",
      render: (data: AlarmData) => {
        if (!data) return <></>;
        switch (data.sensorname) {
          case "acc":
            return (
              <Text>
                X: <Text strong>{accString(data.xAcc)}</Text> Y:{" "}
                <Text strong>{accString(data.yAcc)}</Text> Z:{" "}
                <Text strong>{accString(data.zAcc)}</Text> d:{" "}
                <Text strong>{toMsStr(data.duration)}</Text>
              </Text>
            );
          case "temp":
            return (
              <Text
                strong
              >{`${round2Decimals(TemperatureToScale(data.temp, tempScale))}${TempScaleSymbol(tempScale)}`}</Text>
            );
          case "rh":
            return <Text strong>{data.rh}%</Text>;
          case "angle":
            return (
              <Text>
                X: <Text strong>{data.xAngle ?? "N/A"}°</Text> Y:{" "}
                <Text strong>{data.yAngle ?? "N/A"}°</Text> Z:{" "}
                <Text strong>{data.zAngle ?? "N/A"}°</Text>
              </Text>
            );
        }
        return <></>;
      }
    },
    {
      title: t("Timestamp"),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (date: string) => {
        const timezoneOffset = new Date().getTimezoneOffset();
        const time = dayjs
          .utc(date)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        return <Text>{time}</Text>;
      },
      sorter: (a, b) => {
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0;
      }
    },
    {
      title: t("tableActions"),
      dataIndex: "parameterId",
      key: "parameterId",
      render: (parameterId: string, data: AlarmRow, index: number) => {
        return (
          <Space>
            <NormalButtonSmall
              icon={<LineChartOutlined />}
              loading={
                loadingDatx &&
                loadingIndex === index &&
                datxToDownload?.page === "/graph"
              }
              onClick={() =>
                openOnlineFile(
                  parameterId,
                  data.fileName,
                  "/graph",
                  data.timestamp,
                  index
                )
              }
              title={t("OpenInReports")}
            />
            <NormalButtonSmall
              icon={<DiffOutlined />}
              loading={
                loadingDatx &&
                loadingIndex === index &&
                datxToDownload?.page === "/compare"
              }
              onClick={() =>
                openOnlineFile(
                  parameterId,
                  data.fileName,
                  "/compare",
                  data.timestamp,
                  index
                )
              }
              title={t("OpenInCompare")}
            />
          </Space>
        );
      }
    }
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Title style={{ marginBlock: 0, paddingLeft: size.m2 }} level={5}>
            {t("Alarms")}
          </Title>
        </Col>
        <Col>
          <Space>
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterDeviceNames(v as string[])}
                  style={{ flexDirection: "column" }}
                >
                  {selectableDevices.length > 0 &&
                    selectableDevices.map((deviceName) => (
                      <Checkbox
                        defaultChecked={true}
                        value={deviceName}
                        checked={
                          filterDeviceNames &&
                          filterDeviceNames?.includes(deviceName)
                            ? true
                            : false
                        }
                        key={deviceName}
                      >
                        {deviceName}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterDeviceNames.length > 0}
              >
                {t("SerialNumber")}
              </NormalButton>
            </Popover>
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterSensors(v as number[])}
                  style={{ flexDirection: "column" }}
                >
                  {selectableSensors.length > 0 &&
                    selectableSensors.map((sensorValue, index) => (
                      <Checkbox
                        defaultChecked={true}
                        value={sensorValue}
                        checked={
                          filterSensors && filterSensors?.includes(sensorValue)
                            ? true
                            : false
                        }
                        key={index}
                      >
                        {renderAlarmSensor(sensorValue, t)}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterSensors.length > 0}
              >
                {t("Sensor")}
              </NormalButton>
            </Popover>
            <Input
              placeholder={t("Search")}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
              style={{ marginBottom: size.m2 }}
            />
          </Space>
        </Col>
      </Row>
      <Table
        columns={alarmColumns}
        dataSource={alarmsData}
        loading={loadingAlarms}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: 8,
          showSizeChanger: true
        }}
      />
    </>
  );
};

export default AlarmsTab;
