import React from "react";
import { Form, notification, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ParameterForAlteration,
  useUpdateParameterMutation
} from "../../state/cargologRestApi";
import {
  closeFinishTransportModal,
  modalsState
} from "../../state/modalsSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import StandardModal from "../Common/StandardModal";
const { Text } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const FinishTransportModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isFinishTransportModalOpen, transportToFinish } =
    useSelector(modalsState);

  const [updateParameter, requestStatus] = useUpdateParameterMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleUpdateParameter = async (request: ParameterForAlteration) => {
    const result: any = await updateParameter(request);
    if (result.data) {
      notification.success({ message: t("FinishedTransport") });
      dispatch(closeFinishTransportModal());
    } else {
      notification.error({ message: t("FailedToFinishTransport") });
    }
  };

  const onFinish = () => {
    if (!isUndefined(transportToFinish)) {
      handleUpdateParameter(transportToFinish);
    }
  };

  return (
    <>
      <StandardModal
        title={t("FinishTransport")}
        open={isFinishTransportModalOpen}
        zIndex={1045}
        width={350}
        onCancel={() => dispatch(closeFinishTransportModal())}
        footer={null}
        destroyOnClose={true}
      >
        <Form onFinish={onFinish} layout="vertical" style={{ width: "100%" }}>
          <Form.Item>
            <Text>{t("FinishTransportWarningText")}</Text>
          </Form.Item>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={requestIsLoading}
          >
            {t("FinishTransport")}
          </PrimaryButton>
        </Form>
      </StandardModal>
    </>
  );
};

export default FinishTransportModal;
