import React from "react";
import { Dropdown, Row, Menu, Col, Tooltip, Space } from "antd";
import {
  DeliveredProcedureOutlined,
  ExportOutlined,
  EyeInvisibleOutlined,
  FilePdfOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import { NormalCard } from "./CommonCards";
import { LargeHeaderTitle } from "./CommonFonts";
import { MenuButton, NormalButton } from "./CommonButtons";
import { useSelector } from "react-redux";
import { selectActiveDashboardKeyForDatx } from "../../state/openDatxSlice";
import { useTranslation } from "react-i18next";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { MoveableCardProps } from "./MoveableCardProps";
import { size } from "../../helpers/pageHelper";

const MoveableCard: React.FC<MoveableCardProps> = (props) => {
  const cardId = props.title.split(" ").join("").toLowerCase();
  const { t } = useTranslation();

  const activeReport = useSelector(
    selectActiveDashboardKeyForDatx(props.fileId)
  );

  const renderButtons = () => (
    <Space>
      {props.extraButtons?.map((item) => (
        <div>
          {item.button === "exportMultiple" && (
            <Dropdown
              disabled={
                CheckUserRightsAccess("ACG") && CheckUserRightsAccess("ACI")
                  ? false
                  : true
              }
              // Place dropdown in card root or in parent element to make it follow along when scrolling
              getPopupContainer={(triggerNode: HTMLElement) =>
                document.getElementById(cardId) ||
                (triggerNode.parentNode as HTMLElement)
              }
              overlay={
                <Menu>
                  {item.pdfAction && (
                    <Menu.Item
                      onClick={() =>
                        item.pdfAction ? item.pdfAction() : undefined
                      }
                      disabled={CheckUserRightsAccess("ACG") ? false : true}
                    >
                      <FilePdfOutlined /> PDF
                    </Menu.Item>
                  )}
                  {item.csvAction && (
                    <Menu.Item
                      onClick={() =>
                        item.csvAction ? item.csvAction() : undefined
                      }
                      disabled={CheckUserRightsAccess("ACG") ? false : true}
                    >
                      <DeliveredProcedureOutlined /> CSV
                    </Menu.Item>
                  )}
                  {item.printAction && (
                    <Menu.Item
                      onClick={() =>
                        item.printAction ? item.printAction() : undefined
                      }
                      disabled={CheckUserRightsAccess("ACI") ? false : true}
                    >
                      <PrinterOutlined /> {t("Print")}
                    </Menu.Item>
                  )}
                </Menu>
              }
              trigger={["click"]}
              destroyPopupOnHide
            >
              <MenuButton icon={<ExportOutlined />}>
                {t("ExportCard")}
              </MenuButton>
            </Dropdown>
          )}

          {item.button === "custom" && (
            <NormalButton
              title={item.tooltip}
              onClick={() => item.action()}
              disabled={item.disabled}
            >
              {item.icon ? item.icon : undefined}
              {item.title ? item.title : undefined}
            </NormalButton>
          )}

          {item.button === "close" &&
            !item.disabled &&
            activeReport !== "Standard" && (
              <Tooltip
                placement="left"
                title={"Hide card in report"}
                // Keeps tooltip in place when scrolling
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentNode as HTMLElement
                }
              >
                <NormalButton
                  style={{
                    paddingRight: size.s2,
                    paddingLeft: size.s2
                  }}
                  onClick={() => props.closeMe.action()}
                  disabled={item.disabled}
                >
                  <EyeInvisibleOutlined style={{ fontSize: "16px" }} />
                </NormalButton>
              </Tooltip>
            )}
        </div>
      ))}
    </Space>
  );

  /* Height (49px) is card header height (65px) minus padding (2*8px) */
  const renderFullWidthTitle = () => (
    <Row justify="space-between" align="middle">
      <Col
        flex={"auto"}
        className={props.dragHandle}
        style={{ height: "64px" }}
      >
        <Row align="middle" style={{ height: "100%" }}>
          <LargeHeaderTitle>{props.title}</LargeHeaderTitle>
        </Row>
      </Col>
      <Col>
        <Row align="middle">{renderButtons()}</Row>
      </Col>
    </Row>
  );

  return (
    <NormalCard
      // Gives card a unique id based on title.
      // Used by popup menu to find the card root.
      id={cardId}
      title={renderFullWidthTitle()}
      size="small"
      style={{ padding: 0 }}
      styles={{
        header: {
          paddingInlineStart: size.l1,
          paddingInlineEnd: size.m2
        },
        body: {
          padding: 0,
          height: "calc(100% - 64px)",
          overflow: "auto"
        }
      }}
    >
      {props.children}
    </NormalCard>
  );
};

export default MoveableCard;
