import React from "react";
import { Col, Descriptions, Row, Spin, Tooltip, Alert, Typography } from "antd";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { LandingCard } from "./Landing";
import { useDispatch } from "react-redux";
import {
  useGetCompanyDetailsQuery,
  useGetCurrentUserQuery,
  useGetLicensesQuery
} from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { LargeHeaderTitle, SmallText } from "../Common/CommonFonts";
import { openSettingsModal, setCurrentPage } from "../../state/settingsSlice";
import Online from "../MicroComponents/Online";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import dayjs from "dayjs";
import { size } from "../../helpers/pageHelper";
const { Text } = Typography;

const AccountLandingCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: userData, isLoading: userLoading } = useGetCurrentUserQuery();
  // Load User Company from API
  const { data: companyData, isLoading: companyLoading } =
    useGetCompanyDetailsQuery(
      userData?.companyId ? { companyId: userData.companyId } : skipToken
    );

  const { data: licenseData, isLoading: licenseLoading } =
    useGetLicensesQuery();
  const userLicense = licenseData?.filter(
    (license) => license.userId === userData?.userId && isNil(license.projectId)
  );

  return (
    <LandingCard
      key="user"
      extra={
        <NormalButtonSmall
          icon={<SettingOutlined />}
          onClick={() => {
            dispatch(setCurrentPage("account"));
            dispatch(openSettingsModal());
          }}
        >
          {t("Settings")}
        </NormalButtonSmall>
      }
      title={
        <LargeHeaderTitle>
          <UserOutlined /> {t("Account")}
        </LargeHeaderTitle>
      }
      styles={{ body: { height: 230, overflow: "auto", paddingBottom: 0 } }}
    >
      <Online>
        {/* Print user information. Link to User settings */}
        <Spin spinning={userLoading || companyLoading || licenseLoading}>
          <Descriptions column={1} size="small">
            <Descriptions.Item
              label={t("Name")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {userData?.firstName} {userData?.lastName}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("Email")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {userData?.email}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("Phone")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {userData?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("Company")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {companyData?.name}
            </Descriptions.Item>
          </Descriptions>

          {userLicense && userLicense.length === 0 && (
            <Alert
              style={{ textAlign: "center" }}
              message={
                <>
                  <Text strong>{t("YouDontHaveLicense")}</Text>
                  <br />
                  <Text>
                    {t("ContactYourAdminAt")}: {companyData?.adminEmail}
                  </Text>
                </>
              }
              type="error"
            />
          )}

          {userLicense && userLicense.length > 0 && (
            <Row>
              <div
                className="ant-descriptions-item-label"
                style={{ fontWeight: "bold" }}
              >
                {t("ActiveLicenses")}
              </div>
            </Row>
          )}

          {userLicense
            ?.sort((a, b) => a.endDate.localeCompare(b.endDate))
            .map((license, index) => {
              const within7Days = dayjs().diff(license.endDate, "days") >= -7;

              return (
                <>
                  <Row key={index}>
                    <Col>
                      <SmallText style={{ paddingLeft: size.m1 }}>
                        <Tooltip
                          title={license.id}
                          overlayInnerStyle={{ width: 290 }}
                          placement="top"
                        >
                          <SmallText strong style={{ paddingRight: size.s1 }}>
                            {license.text}
                          </SmallText>
                        </Tooltip>

                        {license.text !== "Mobitron" ? (
                          <SmallText
                            title={t("Expires")}
                            type={within7Days ? "danger" : "secondary"}
                          >
                            ({dayjs(license.endDate).format("YYYY-MM-DD")})
                          </SmallText>
                        ) : undefined}
                      </SmallText>
                    </Col>
                  </Row>
                </>
              );
            })}
        </Spin>
      </Online>
    </LandingCard>
  );
};

export default AccountLandingCard;
