import React, { useState, useEffect } from "react";
import CustomColorButton from "./CustomColorButton";
import { Button } from "antd";
import {
  selectLicensePriceState,
  setLicensePriceColor
} from "../../state/licensePriceSlice";
import { useDispatch, useSelector } from "react-redux";

interface IProps {}

const CustomColorButtonContainer: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const licenseModals = useSelector(selectLicensePriceState);
  const selectedLicenseColor =
    licenseModals.licensePriceToEdit?.color ?? licenseModals.color;

  const buttonProps = [
    { color: "#77c77dff", borderColor: "#77c77dff", value: "green" },
    { color: "#78b6d6ff", borderColor: "#78b6d6ff", value: "blue" },
    { color: "#e5ae89ff", borderColor: "#e5ae89ff", value: "gold" },
    { color: "#adb3c3ff", borderColor: "#adb3c3ff", value: "gray" },
    { color: "#ffffff", borderColor: "#cccccc", value: "white" },
    { color: "#d67878ff", borderColor: "#d67878ff", value: "red" },
    { color: "#5f7eaaff", borderColor: "#5f7eaaff", value: "purple" },
    { color: "#78d6b6ff", borderColor: "#78d6b6ff", value: "teal" }
  ];

  const [active, setActive] = useState("");

  const setColorValue = (value: string) => {
    setActive(value);
    dispatch(setLicensePriceColor(value));
  };

  useEffect(() => {
    setActive(selectedLicenseColor);
    dispatch(setLicensePriceColor(selectedLicenseColor));
  }, [dispatch, selectedLicenseColor]);

  return (
    <>
      {buttonProps.map((button) => {
        return (
          <Button
            type={"text"}
            onClick={() => setColorValue(button.value)}
            style={{ padding: 0 }}
          >
            <CustomColorButton
              color={button.color}
              borderColor={button.borderColor}
              value={button.value}
              active={active === button.value}
            />
          </Button>
        );
      })}
    </>
  );
};

export default CustomColorButtonContainer;
