import React, { useState } from "react";
import {
  Layout,
  Dropdown,
  Menu,
  Row,
  Col,
  Space,
  Input,
  Typography,
  Checkbox,
  Popover,
  Spin,
  Divider
} from "antd";
import {
  DeliveredProcedureOutlined,
  FilterOutlined,
  ExportOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import MyDevicesList, {
  calcNextCalibration,
  fullNameString
} from "../components/DevicesPage/MyDevicesList";
import { size } from "../helpers/pageHelper";
import { MenuButton, NormalButton } from "../components/Common/CommonButtons";
import { SearchOutlined } from "@ant-design/icons";
import {
  Project,
  useGetAllProjectGpsQuery,
  useGetAllProjectsQuery,
  useGetParametersQuery,
  User,
  useGetRecentDeviceQuery
} from "../state/cargologRestApi";
import { selectCsvFormat } from "../state/sessionSlice";
import MoveDeviceModal from "../components/Projects/MoveDeviceModal";
import AlarmsModal from "../components/Modals/PositionsModal";
import { DeviceData, GpsPosData } from "../components/DevicesPage/DeviceMap";
import { LatLngExpression, LatLngTuple } from "leaflet";
import dayjs from "dayjs";
import { isUndefined, uniqBy } from "lodash-es";
import { deviceColorsArray } from "../constants/colors";
import { devicesToCsv } from "../helpers/dataExportHelper";
import { useSelector } from "react-redux";
import { LargeHeaderTitle } from "../components/Common/CommonFonts";
import { saveAsCsv } from "../helpers/fileHelperUniversal";

const { Text } = Typography;

const DevicesPage = () => {
  const { t } = useTranslation();

  const [stringValue, setStringValue] = useState("");
  const { data: myDevices, isLoading: isLoadingDevices } =
    useGetRecentDeviceQuery();
  const { data: parameters, isLoading: isLoadingParameters } =
    useGetParametersQuery({});
  const { data: projects, isLoading: isLoadingProjects } =
    useGetAllProjectsQuery();
  const { data: gpsPos, isLoading: isLoadingGps } = useGetAllProjectGpsQuery();

  const isLoading =
    isLoadingDevices &&
    isLoadingParameters &&
    isLoadingProjects &&
    isLoadingGps;

  let selectableUsers: User[] = [];
  const [filterUsers, setFilterUsers] = useState<string[]>([]);

  let selectableProjects: Project[] = [];
  const [filterProjects, setFilterProjects] = useState<string[]>([]);

  let selectableDevices: string[] = [];
  const [filterDevices, setFilterDevices] = useState<string[]>([]);
  const [isCalibrate, setIsCalibrate] = useState<boolean>(false);

  const colorsArray = [...deviceColorsArray];
  const getLineColor = () => {
    return { color: colorsArray.pop() ?? "#409c46" };
  };

  const gpsData = gpsPos
    ? gpsPos.map((gps) => {
        const dateTime = gps.gpsData?.[0].dateTime;
        const timezoneOffset = new Date().getTimezoneOffset();
        const lastDateTime = dayjs
          .utc(dateTime)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        const lastVelocity = gps.gpsData?.[0].velocity;
        const lastStatus = gps.gpsData?.[0].status ?? 100;

        const firstLat = gps.gpsData?.[gps.gpsData.length - 1].lat;
        const firstLon = gps.gpsData?.[gps.gpsData.length - 1].lon;

        const lastLat = gps.gpsData?.[0].lat ?? 57.8563;
        const lastLon = gps.gpsData?.[0].lon ?? 14.30782;

        const lastPos = {
          lastDateTime: lastDateTime,
          lastLat: lastLat,
          lastLon: lastLon,
          lastVelocity: lastVelocity ?? 0,
          lastStatus: lastStatus
        };

        /** Used for Polyline */
        const gpsPosData: GpsPosData[] = gps.gpsData
          ? gps.gpsData.map((data) => {
              return {
                path: [data.lat, data.lon],
                velocity: data.velocity,
                dateTime: data.dateTime,
                status: data.status
              };
            })
          : [];

        const sortedGpsPos = gpsPosData.sort((a, b) =>
          a.dateTime.localeCompare(b.dateTime)
        );

        return {
          parameterId: gps.parameterId ?? "",
          firstPos: {
            firstLat,
            firstLon
          },
          lastPos: lastPos,
          gpsPosData: sortedGpsPos
        };
      })
    : [];

  // sort parameters by id
  const sortedParameters = parameters
    ? [...parameters].sort((a, b) => b.id.localeCompare(a.id) ?? 0)
    : [];
  // remove duplicate parameters by deviceName
  const uniqueProjectDevices = uniqBy(sortedParameters, "deviceName");

  const recentDevices: DeviceData[] = myDevices
    ? myDevices.map((item, index) => {
        const projectDevice = uniqueProjectDevices.find(
          (device) => device.deviceName === item.systemInfo.serial
        );
        const gps = gpsData.find(
          (gps) => gps.parameterId === projectDevice?.id
        );
        const project: Project | undefined = projects?.find(
          (item) => item.id === projectDevice?.projectId
        );

        const device: DeviceData = {
          parameterId: projectDevice?.id ?? "",
          firstPos: gps?.firstPos,
          lastPos: gps?.lastPos,
          serialNumber: item.systemInfo.serial.toString(),
          deviceDescription: projectDevice?.deviceDescription ?? "",
          gpsPosData: gps?.gpsPosData ?? [],
          lineColor: !isUndefined(gps) ? getLineColor().color : "transparent",
          project: {
            id: project?.id ?? "",
            name: project?.title ?? ""
          },
          lastSeen: item.lastInteraction
            ? dayjs.unix(item.lastInteraction).format("YYYY-MM-DD, HH:mm:ss")
            : "",
          lastUser: item.lastUser,
          nextCalibration: calcNextCalibration(item.systemInfo.lastCalibration),
          recentDevice: item,
          hasData: projectDevice?.hasData ?? false,
          isActive: projectDevice?.isActive ?? false,
          key: index.toString()
        };

        // Fill devices list for dropdown filter
        const deviceIndex = selectableDevices.findIndex(
          (serialNumber) => serialNumber === device.serialNumber
        );
        if (device.serialNumber && deviceIndex <= -1) {
          selectableDevices.push(device.serialNumber);
        }
        // Fill projects list for dropdown filter
        const projectIndex = selectableProjects.findIndex(
          (p) => p.id === project?.id
        );
        if (project && projectIndex <= -1) {
          selectableProjects.push(project);
        }

        if (item.lastUser) {
          // Fill users list for dropdown filter
          const userIndex = selectableUsers.findIndex(
            (user) => user.userId === item.lastUser.userId
          );
          if (userIndex <= -1) {
            selectableUsers.push(item.lastUser);
          }
        }

        return device;
      })
    : [];

  /** Used for centering the map when there is only one device in the project */
  let center: LatLngTuple = [57.8563, 14.30782];
  /** Used for creating a map bounds that shows all the devices in the project*/
  let outerBounds: LatLngTuple[] = [];

  // Sets the map boundaries making all devices and positions visible on the map when showing the map
  recentDevices.forEach((device) => {
    if (device?.lastPos) {
      const mapOrigo: LatLngExpression = [
        device.lastPos.lastLat,
        device.lastPos.lastLon
      ];
      center = mapOrigo;
      outerBounds.push(mapOrigo);
    }

    device?.gpsPosData?.forEach((data) => {
      outerBounds.push([data.path[0], data.path[1]]);
    });
  });

  const filteredDeviceData: DeviceData[] = recentDevices
    .filter(
      (device) =>
        !isCalibrate ||
        (device.nextCalibration &&
          isCalibrate === true &&
          dayjs().diff(device.nextCalibration, "months") > -3)
    )
    .filter(
      (device) =>
        (filterProjects.length === 0 ||
          filterProjects.find((e) => e === device.project?.id)) &&
        (filterDevices.length === 0 ||
          filterDevices.find((e) => e === device.serialNumber)) &&
        (filterUsers.length === 0 ||
          filterUsers.find(
            (user) => user === device.recentDevice?.lastUser.email
          )) &&
        (stringValue === "" ||
          device.serialNumber
            ?.toString()
            .toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          device.deviceDescription
            ?.toString()
            .toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          device.project?.name
            .toString()
            .toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          (device.nextCalibration ?? "")
            .toString()
            .toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          (device.lastPos?.lastLat + ", " + device.lastPos?.lastLon)
            .toString()
            .toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          (
            (device.lastUser?.firstName ?? "") +
            " " +
            (device.lastUser?.lastName ?? "")
          )
            .toString()
            .toLowerCase()
            .includes(stringValue.toLowerCase()))
    );

  selectableUsers.sort((a, b) =>
    fullNameString(a.firstName, a.lastName)
      .toLowerCase()
      .localeCompare(
        fullNameString(b.firstName, b.lastName).toLowerCase(),
        "sv"
      )
  );
  selectableProjects.sort((a, b) =>
    a.title.toLowerCase().localeCompare(b.title.toLowerCase())
  );
  selectableDevices.sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );

  const csvFormat = useSelector(selectCsvFormat);
  const exportAsCsv = () => {
    const csvData = devicesToCsv(filteredDeviceData, csvFormat, t);
    saveAsCsv(csvData);
  };

  const [showStartPos, setShowStartPos] = useState<boolean>(true);
  const [showDevices, setShowDevices] = useState<boolean>(true);
  const [showAlarms, setShowAlarms] = useState<boolean>(true);
  const [showPolylines, setShowPolylines] = useState<boolean>(true);
  const [showMap, setShowMap] = useState<boolean>(false);

  /** Using custom component instead of Leaflets built in LayersControl in order to show green checkboxes.
   * Put it outside of the map to avoid issues when clicking the Popover.
   */
  const customLayersControl = () => (
    <Popover
      placement="bottom"
      trigger="click"
      overlayStyle={{ minWidth: 160, paddingRight: size.s1 }}
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode.parentNode as HTMLElement
      }
      content={
        <>
          <Row>
            <Checkbox
              checked={showMap}
              style={{ width: "100%" }}
              onClick={() => setShowMap(!showMap)}
            >
              {" "}
              {t("ShowMap")}
            </Checkbox>
          </Row>
          <Divider style={{ marginBlock: size.s1 }} />
          <Row>
            <Checkbox
              checked={showStartPos}
              style={{ width: "100%" }}
              onClick={() => setShowStartPos(!showStartPos)}
            >
              {t("StartPosition")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showAlarms}
              style={{ width: "100%" }}
              onClick={() => setShowAlarms(!showAlarms)}
            >
              {t("Alarms")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showDevices}
              style={{ width: "100%" }}
              onClick={() => setShowDevices(!showDevices)}
            >
              {t("Devices")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showPolylines}
              style={{ width: "100%" }}
              onClick={() => setShowPolylines(!showPolylines)}
            >
              {t("DeviceLines")}
            </Checkbox>
          </Row>
        </>
      }
    >
      <NormalButton icon={<FilterOutlined />}>{t("Map")}</NormalButton>
    </Popover>
  );

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="center" style={{ padding: size.m1 }}>
            <Layout
              style={{
                width: "100%",
                maxWidth: "100%"
              }}
            >
              <Layout.Content
                style={{
                  paddingInline: size.m1,
                  textAlign: "left"
                }}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: size.m1 }}
                >
                  <Col style={{ marginBlock: size.m1 }}>
                    <Space>
                      <LargeHeaderTitle>
                        {t("tableTitleMyDevices")}
                      </LargeHeaderTitle>
                    </Space>
                  </Col>

                  <Col style={{ marginBlock: size.m1 }}>
                    <Space>
                      <Dropdown
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => exportAsCsv()}>
                              <DeliveredProcedureOutlined /> CSV
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <MenuButton>
                          <ExportOutlined /> {t("btnExport")}
                        </MenuButton>
                      </Dropdown>
                      <Popover
                        placement="bottom"
                        trigger="click"
                        overlayInnerStyle={{ maxHeight: 300, overflow: "auto" }}
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        content={
                          <Checkbox.Group
                            onChange={(v) => setFilterUsers(v as string[])}
                            style={{ flexDirection: "column" }}
                          >
                            {selectableUsers.length > 0 ? (
                              selectableUsers.map((e, index) => (
                                <Checkbox
                                  style={{ width: "100%" }}
                                  defaultChecked={false}
                                  value={e.email}
                                  checked={
                                    filterUsers && filterUsers.includes(e.email)
                                      ? true
                                      : false
                                  }
                                  key={index}
                                >
                                  <Text>
                                    {e.firstName + " " + e.lastName}{" "}
                                    <Text
                                      style={{ fontSize: 12 }}
                                      type="secondary"
                                    >
                                      ({e.email})
                                    </Text>
                                  </Text>
                                </Checkbox>
                              ))
                            ) : (
                              <Text type="secondary">{t("NoContent")}</Text>
                            )}
                          </Checkbox.Group>
                        }
                      >
                        <NormalButton
                          icon={<FilterOutlined />}
                          active={filterUsers.length > 0}
                        >
                          {t("Users")}
                        </NormalButton>
                      </Popover>

                      <Popover
                        placement="bottom"
                        trigger="click"
                        overlayInnerStyle={{ maxHeight: 300, overflow: "auto" }}
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        content={
                          <Checkbox.Group
                            onChange={(v) => setFilterProjects(v as string[])}
                            style={{ flexDirection: "column" }}
                          >
                            {selectableProjects.length > 0 ? (
                              selectableProjects.map((e) => (
                                <Checkbox
                                  style={{ width: "100%" }}
                                  defaultChecked={false}
                                  value={e.id}
                                  checked={
                                    filterProjects &&
                                    filterProjects.includes(e.id)
                                      ? true
                                      : false
                                  }
                                  key={e.id}
                                >
                                  <Text>{e.title}</Text>
                                </Checkbox>
                              ))
                            ) : (
                              <Text type="secondary">{t("NoContent")}</Text>
                            )}
                          </Checkbox.Group>
                        }
                      >
                        <NormalButton
                          icon={<FilterOutlined />}
                          active={filterProjects.length > 0}
                        >
                          {t("Projects")}
                        </NormalButton>
                      </Popover>

                      <Popover
                        placement="bottom"
                        trigger="click"
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        overlayInnerStyle={{
                          padding: 0,
                          paddingBottom: size.m1
                        }}
                        content={
                          <>
                            <Row
                              style={{
                                maxHeight: 300,
                                overflow: "auto"
                              }}
                            >
                              <Checkbox.Group
                                onChange={(v) =>
                                  setFilterDevices(v as string[])
                                }
                                style={{
                                  paddingBlock: size.m1,
                                  width: "100%",
                                  flexDirection: "column"
                                }}
                              >
                                {selectableDevices.length > 0 ? (
                                  selectableDevices.map((e, index) => (
                                    <Checkbox
                                      style={{
                                        width: "100%",
                                        paddingLeft: size.m1
                                      }}
                                      defaultChecked={false}
                                      value={e}
                                      checked={
                                        filterDevices &&
                                        filterDevices.includes(e)
                                          ? true
                                          : false
                                      }
                                      key={index}
                                    >
                                      <Text>{e}</Text>
                                    </Checkbox>
                                  ))
                                ) : (
                                  <Text type="secondary">{t("NoContent")}</Text>
                                )}
                              </Checkbox.Group>
                            </Row>
                            <Row
                              align="middle"
                              style={{
                                paddingTop: size.m1,
                                borderTop: "1px solid rgba(0, 0, 0, 0.06)"
                              }}
                            >
                              <Checkbox
                                style={{ width: "100%", paddingLeft: size.m1 }}
                                defaultChecked={false}
                                onChange={(v) =>
                                  setIsCalibrate(v.target.checked)
                                }
                              >
                                {t("NextCalibrationWithin3Months")}
                              </Checkbox>
                            </Row>
                          </>
                        }
                      >
                        <NormalButton
                          icon={<FilterOutlined />}
                          active={filterDevices.length > 0}
                        >
                          {t("Devices")}
                        </NormalButton>
                      </Popover>

                      {customLayersControl()}

                      <Input
                        allowClear
                        placeholder={t("filterMyDevices")}
                        prefix={<SearchOutlined />}
                        onChange={(e) => setStringValue(e.target.value)}
                      />
                    </Space>
                  </Col>
                </Row>

                {isLoading && (
                  <Row
                    justify="center"
                    align="middle"
                    style={{ height: "calc(75vh - 320px)", width: "100%" }}
                  >
                    <Spin />
                  </Row>
                )}

                {!isLoading && (
                  <MyDevicesList
                    center={center}
                    outerBounds={outerBounds}
                    deviceData={filteredDeviceData}
                    showMap={showMap}
                    showStartPos={showStartPos}
                    showDevices={showDevices}
                    showAlarms={showAlarms}
                    showPolylines={showPolylines}
                  />
                )}
              </Layout.Content>
            </Layout>
          </Row>
        </Col>
      </Row>
      <MoveDeviceModal />
      <AlarmsModal />
    </>
  );
};

export default DevicesPage;
