import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Layout, Row, Switch, Tooltip, Typography } from "antd";
import { isUndefined } from "lodash-es";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Dispatch } from "redux";
import { clearSessionAndDataImp } from "./App";
import Routes from "./Routes";
import { NormalButton } from "./components/Common/CommonButtons";
import { SmallTitle } from "./components/Common/CommonFonts";
import { CheckUserRightsAccess } from "./components/MicroComponents/LicenseAccess";
import QuickTourModal from "./components/Modals/QuickTourModal";
import Sidebar from "./components/Navigation/Sidebar";
import UserAvatar from "./components/Navigation/userAvatar";
import {
  size,
  contentFullHeightStyle,
  impersonateBannerHeight
} from "./helpers/pageHelper";
import useKeyPress from "./helpers/useKeyPress";
import useSyncReportLayouts from "./hooks/useSyncReportLayouts";
import useSyncUserSettings from "./hooks/useSyncUserSettings";
import {
  Company,
  Tokens,
  User,
  useGetCompanyDetailsQuery,
  useGetUserDetailsQuery,
  useStopImpersonateMutation
} from "./state/cargologRestApi";
import {
  closeAllHelpModals,
  selectHelp,
  setQuickTourModalOpen,
  startHelpMode,
  toggleHelpMode
} from "./state/helpSlice";
import { closeAllStoreModals } from "./state/licenseStoreSlice";
import { closeAllModals } from "./state/modalsSlice";
import {
  selectImpersonate,
  setImpersonatedUserId,
  setIsImpersonating,
  setSessionTokens
} from "./state/persistantStateSlice";
import { closeAllProjectModals } from "./state/projectSlice";
import { getUser, selectShowQuickTourAtStartup } from "./state/sessionSlice";
import {
  closeSettingsModal,
  openSettingsModal,
  setCurrentPage
} from "./state/settingsSlice";
import mobitronColors from "./styles/mobitronColors";
import dayjs from "dayjs";
import timezones from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import objectSupport from "dayjs/plugin/objectSupport";

// Sets up dayjs extensions for all subcomponents
dayjs.extend(timezones);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(objectSupport);

const { Text } = Typography;

const LoggedInContainer = (): React.JSX.Element => {
  const user = useSelector(getUser);
  if (
    !isUndefined(user.firstName) &&
    !isUndefined(user.lastName) &&
    !isUndefined(user.email)
  ) {
    const initials = user.firstName[0] + user.lastName[0];
    return (
      <div className="loggedInContainer">
        <UserAvatar
          initials={initials}
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email}
        />
      </div>
    );
  } else {
    return <div className="loggedInContainer"></div>;
  }
};

interface ISwitch {
  showHelp: boolean;
}
const HelpSwitchContainer = (props: ISwitch) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className="helpSwitchContainer">
      <Switch
        checkedChildren={<QuestionCircleOutlined />}
        unCheckedChildren={<QuestionCircleOutlined />}
        checked={props.showHelp}
        title={t("ToggleHelpMode")}
        onChange={() => {
          dispatch(toggleHelpMode());
          dispatch(setQuickTourModalOpen(false));
        }}
      />
    </div>
  );
};

interface IImpersonate {
  user?: User;
  company?: Company;
  loading: boolean;
  action: () => void;
}
const ImpersonateBanner = (props: IImpersonate) => {
  const { t } = useTranslation();
  const { user, company, loading, action } = props;
  return (
    <Row
      align="middle"
      justify="space-between"
      style={{
        position: "absolute",
        top: 48,
        left: 90,
        zIndex: 1035, // todo: what value should this be?
        width: "calc(100% - 90px)",
        backgroundColor: mobitronColors.primaryRed,
        height: 48,
        paddingInline: size.l2
      }}
    >
      <Tooltip
        overlay={
          <div>
            <Row>
              <Text style={{ color: "white" }}>ID: {user?.userId ?? ""}</Text>
            </Row>
            <Row>
              <Text style={{ color: "white" }}>
                {t("Name")}:{" "}
                {`${user?.firstName ?? ""} ${user?.lastName ?? ""}` ?? ""}
              </Text>
            </Row>
            <Row>
              <Text style={{ color: "white" }}>
                {t("Email")}: {user?.email ?? ""}
              </Text>
            </Row>
            <Row>
              <Text style={{ color: "white" }}>
                {t("Company")}: {company?.name ?? ""}
              </Text>
            </Row>
          </div>
        }
        overlayStyle={{ minWidth: 300 }}
        placement={"bottomLeft"}
        arrowPointAtCenter={true}
      >
        <InfoCircleOutlined style={{ color: "white", fontSize: 18 }} />
      </Tooltip>

      <SmallTitle style={{ marginBottom: 0, color: "white" }}>
        {t("Impersonating")}{" "}
        {`${user?.firstName ?? ""} ${user?.lastName ?? ""}`}
      </SmallTitle>

      <NormalButton
        icon={<UserSwitchOutlined />}
        onClick={() => action()}
        loading={loading}
      >
        {t("Exit")}
      </NormalButton>
    </Row>
  );
};

/** Closing all modals in the entire application */
export const closeModals = (dispatch: Dispatch) => {
  dispatch(closeAllModals());
  dispatch(closeAllHelpModals());
  dispatch(closeAllProjectModals());
  dispatch(closeAllStoreModals());
  dispatch(closeSettingsModal());
};

const AppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const showQuickTour = useSelector(selectShowQuickTourAtStartup);
  const { isHelpModeActive } = useSelector(selectHelp);
  const { isImpersonating, impersonatedUserId, forcedEndSession } =
    useSelector(selectImpersonate);

  const { data: impersonateUser } = useGetUserDetailsQuery(
    impersonatedUserId ? { userId: impersonatedUserId } : skipToken
  );
  const { data: company } = useGetCompanyDetailsQuery(
    impersonateUser ? { companyId: impersonateUser.companyId } : skipToken
  );

  // Ctrl + 1
  const onKeyPressDashboard = () => {
    closeModals(dispatch);
    navigate("/landing");
  };
  // Ctrl + 2
  const onKeyPressReports = () => {
    closeModals(dispatch);
    navigate("/graph");
  };
  // Ctrl + 3
  const onKeyPressCompare = () => {
    closeModals(dispatch);
    navigate("/compare");
  };
  // Ctrl + 4
  const onKeyPressSetupDevices = () => {
    closeModals(dispatch);
    navigate("/params");
  };
  // Ctrl + 5
  const onKeyPressMyDevices = () => {
    closeModals(dispatch);
    navigate("/devices");
  };
  // Ctrl + 6
  const onKeyPressAdmin = () => {
    closeModals(dispatch);
    navigate("/admin");
  };
  // Ctrl + 0
  const onKeyPressSettings = () => {
    closeModals(dispatch);
    dispatch(setCurrentPage("general"));
    dispatch(openSettingsModal());
  };

  useKeyPress(["1"], onKeyPressDashboard, "Control");
  useKeyPress(
    [CheckUserRightsAccess("ACB") ? "2" : ""],
    onKeyPressReports,
    "Control"
  );
  useKeyPress(
    [CheckUserRightsAccess("ACC") ? "3" : ""],
    onKeyPressCompare,
    "Control"
  );
  useKeyPress(
    [CheckUserRightsAccess("ADE") ? "4" : ""],
    onKeyPressSetupDevices,
    "Control"
  );
  useKeyPress(
    [CheckUserRightsAccess("ABA") ? "5" : ""],
    onKeyPressMyDevices,
    "Control"
  );
  useKeyPress(
    [CheckUserRightsAccess("AFA") ? "6" : ""],
    onKeyPressAdmin,
    "Control"
  );
  useKeyPress(["0"], onKeyPressSettings, "Control");

  useSyncReportLayouts();
  useSyncUserSettings();

  useEffect(() => {
    if (showQuickTour) {
      dispatch(startHelpMode());
      dispatch(setQuickTourModalOpen(true));
    }
  }, [showQuickTour]);

  useEffect(() => {
    if (forcedEndSession === true && location.pathname !== "/") {
      navigate("/");
    }
  }, [forcedEndSession]);

  const [stopImpersonate, requestStatus] = useStopImpersonateMutation();
  const { isLoading: isLoadingImpersonate } = requestStatus;

  const stop = () => {
    stopImpersonate().then((result) => {
      if ("data" in result) {
        const sessionToken: Tokens = {
          accessToken: result.data.accessToken,
          refreshToken: result.data.refreshToken,
          refreshTokenExpiry: result.data.refreshTokenExpiry
        };
        clearSessionAndDataImp(dispatch);
        dispatch(setSessionTokens(sessionToken));
        dispatch(setIsImpersonating(false));
        dispatch(setImpersonatedUserId(undefined));
        navigate("/mobitronAdmin");
      }
    });
  };

  return (
    <>
      <div className="titlebar cet-windows">
        <div className="window-title">Cargolog® Connect</div>
      </div>
      <Layout
        hasSider={true}
        style={{
          height: "100%",
          maxHeight: contentFullHeightStyle
        }}
      >
        <QuickTourModal />

        {/* The avatar that is placed to the right of the minimize button */}
        <LoggedInContainer />
        <HelpSwitchContainer showHelp={isHelpModeActive} />
        <Layout.Sider width="90px">
          <Sidebar />
        </Layout.Sider>

        {isImpersonating && (
          <ImpersonateBanner
            user={impersonateUser}
            company={company}
            loading={isLoadingImpersonate}
            action={stop}
          />
        )}

        <Layout.Content
          style={{
            overflow: "auto",
            marginTop: isImpersonating ? impersonateBannerHeight : 0,
            maxHeight: isImpersonating
              ? `calc(100% - ${impersonateBannerHeight}px)`
              : undefined
          }}
        >
          <Routes />
        </Layout.Content>
      </Layout>
    </>
  );
};

export default AppLayout;
