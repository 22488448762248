import {
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Tooltip,
  Typography
} from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseOutlined,
  ExportOutlined,
  FilePdfOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import { isEmpty, isNil, isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { dvaDataToCsv } from "../../helpers/dataExportHelper";
import { VMDvaData } from "../../helpers/dataModelHelper";
import {
  createDateTupleWithOffset,
  createTzDate,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";
import {
  ZoomDimension,
  dvaSampleToMsTickFormater
} from "../../helpers/graphHelper";
import {
  StateZoomDomain,
  deactivateDvaBlock,
  redoDvaZoomDomain,
  removeDvaCursorPosition,
  resetDvaZoomDomain,
  selectActiveDatxFile,
  selectDashboardHeader,
  selectDvaDataForDashboard,
  selectDvaGraphTools,
  selectForDashboardRangePicker,
  selectProjectInfo,
  setDvaZoomDomain,
  switchDvaZoomMode
} from "../../state/openDatxSlice";
import {
  getUser,
  selectCsvFormat,
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import { Optional } from "../../utils/utilTypes";
import { MenuButton, NormalButton } from "../Common/CommonButtons";
import MoveableCard from "../Common/MoveableCard";
import { ExtraButtonItem, emptyFunction } from "../Common/MoveableCardProps";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import {
  HeaderData,
  PdfExportComponent,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import DvaGraph from "./DvaGraph";
import DvaOverviewGraph from "./DvaOverviewGraph";
import DvaScoreValues from "./DvaScoreValues";
import DvaTable from "./DvaTable";
import GraphZoomButtons from "./GraphZoomButtons";
import { size } from "../../helpers/pageHelper";
import { iconColors } from "../../constants/colors";
import { channelName } from "../../pages/ComparePage";
const { Sider } = Layout;
const { Text } = Typography;

export interface DvaCardViewModel {
  dvaData: VMDvaData[];
  activeKey?: number;
  markedKeys: number[];
  timezone: string;
  zoomDomain?: StateZoomDomain;
  dvaZoomDimension: ZoomDimension;
  hasZoomed: boolean;
  dvaFrequency?: number;
}

const DvaGraphContent = (props: {
  fileId: string;
  width: number;
  height: number;
  zoomDimension: ZoomDimension;
  hasZoomed: boolean;
  zoomDomain: Optional<StateZoomDomain>;
  dvaDataBlock: VMDvaData;
  timezone: string;
  activeKey: number;
}) => {
  const {
    fileId,
    width,
    height,
    zoomDimension,
    hasZoomed,
    zoomDomain,
    dvaDataBlock,
    timezone,
    activeKey
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeFile = useSelector(selectActiveDatxFile);
  const isCursorPosSelected = activeFile?.scoreValuesCursorPosDvaGraph ?? false;

  const graphTools = useSelector(selectDvaGraphTools);
  const scoreValues =
    !isNil(graphTools?.dvaScoreValuesCursorPosIndex) &&
    dvaDataBlock.data[graphTools!.dvaScoreValuesCursorPosIndex];

  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const { dataDomain } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);
  const utcOffsetStr = createUtcOffsetStr(timezone);

  // title: 64 + padding: 12 + border: 1
  const graphTitleHeight = 77;
  const graphContentHeight = height - graphTitleHeight;
  const overviewGraphHeight =
    graphContentHeight > 300 ? 115 : graphContentHeight * 0.3;
  const mainGraphHeight = graphContentHeight - overviewGraphHeight;
  const scoreValueWidth = 180;
  const mainGraphWidth =
    scoreValues === false ? width : width - scoreValueWidth;

  const handleZoomDomain = useCallback(
    (newDomain: StateZoomDomain) => {
      dispatch(setDvaZoomDomain({ fileId, newDomain }));
    },
    [dispatch, fileId]
  );

  /** Hook: Print the currently showing dva graph */
  const singleDvaGraphPrint = usePrintHook();

  /** Hook: Export the currently showing dva graph as PDF */
  const singleExport = useExportPdfHook();

  const recStartTitle = createTzDate(dvaDataBlock.start, timezone).format(
    "YYYY-MM-DD, HH:mm:ss"
  );
  const recEndTitle = createTzDate(dvaDataBlock.end, timezone).format(
    "YYYY-MM-DD, HH:mm:ss"
  );

  const projectInfo = useSelector(selectProjectInfo(props.fileId));

  // Get report start
  const reportStart = () => {
    if (isUndefined(zoomDomain)) {
      return (
        createTzDate(dvaDataBlock.start, timezone).format(
          "YYYY-MM-DD, HH:mm:ss "
        ) + utcOffsetStr
      );
    } else {
      // Adds zoom domain to report start
      return (
        createTzDate(
          dvaDataBlock.start +
            dvaSampleToMsTickFormater(zoomDomain.x[0] / 1000),
          timezone
        ).format("YYYY-MM-DD, HH:mm:ss.SSS ") + utcOffsetStr
      );
    }
  };
  // Get report end
  const reportEnd = () => {
    if (isUndefined(zoomDomain)) {
      return (
        createTzDate(dvaDataBlock.end, timezone).format(
          "YYYY-MM-DD, HH:mm:ss "
        ) + utcOffsetStr
      );
    } else {
      // Adds zoom domain to report end
      return (
        createTzDate(
          dvaDataBlock.end + dvaSampleToMsTickFormater(zoomDomain.x[1] / 1000),
          timezone
        ).format("YYYY-MM-DD, HH:mm:ss.SSS ") + utcOffsetStr
      );
    }
  };

  const headerData: HeaderData = {
    reportType: "DetailedVibrationAnalysis",
    projectName: projectInfo.projectName ?? "",
    hasFilters: projectInfo.isFiltered,
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart: reportStart(),
    reportEnd: reportEnd(),
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <>
      {/* print one dva graph (will not be visible in dashboard) */}
      {!isNil(activeKey) && singleDvaGraphPrint.isPrinting && (
        <PrintableComponent
          WrappedComponent={DvaGraph}
          props={{
            // This works since the key is based on the index on creation
            dvaDataBlock: dvaDataBlock
          }}
          reportPrintDone={singleDvaGraphPrint.finishPrint}
          header={headerData}
          orientation="landscape"
        />
      )}

      {!isNil(activeKey) && singleExport.isExporting && (
        <PdfExportComponent
          ComponentBody={DvaGraph}
          props={{
            dvaDataBlock: dvaDataBlock,
            zoomDomain: zoomDomain,
            zoomDimension: zoomDimension
          }}
          type="svg"
          name="dva-graph.pdf"
          reportExportDone={singleExport.finishExport}
          header={headerData}
        />
      )}

      <Card
        styles={{
          header: {
            paddingBlock: 0,
            paddingRight: size.m1,
            paddingLeft: size.l2
          },
          body: { paddingBlock: 0, paddingInline: size.m1 }
        }}
        title={
          <>
            <Row
              justify="space-between"
              align="middle"
              style={{ backgroundColor: "#fff" }}
            >
              <Space>
                <Col>
                  {recStartTitle} - {recEndTitle}
                </Col>
                <Col>
                  <Text style={{ color: iconColors.xAcc }}>
                    {channelName.x}
                  </Text>
                  {", "}
                  <Text style={{ color: iconColors.yAcc }}>
                    {channelName.y}
                  </Text>
                  {", "}
                  <Text style={{ color: iconColors.zAcc }}>
                    {channelName.z}
                  </Text>
                </Col>
              </Space>

              <Space>
                <Dropdown
                  trigger={["click"]}
                  destroyPopupOnHide
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="exportPdf"
                        onClick={() => singleExport.startExport()}
                      >
                        <FilePdfOutlined /> PDF
                      </Menu.Item>
                      <Menu.Item
                        key="print"
                        onClick={() => singleDvaGraphPrint.startPrint()}
                      >
                        <PrinterOutlined /> {t("Print")}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <MenuButton icon={<ExportOutlined />}>
                    {t("ExportGraph")}
                  </MenuButton>
                </Dropdown>

                <Tooltip
                  placement="left"
                  title={t("CloseTheViewOfTheSelectedGraph")}
                  getPopupContainer={(triggerNode: HTMLElement) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <NormalButton
                    style={{
                      paddingRight: size.s2,
                      paddingLeft: size.s2
                    }}
                    onClick={() => {
                      dispatch(removeDvaCursorPosition());
                      dispatch(deactivateDvaBlock({ fileId: fileId }));
                    }}
                  >
                    <CloseOutlined style={{ fontSize: "12px" }} />
                  </NormalButton>
                </Tooltip>
              </Space>
            </Row>
          </>
        }
      >
        <Layout>
          <Layout.Content style={{ backgroundColor: "#fff" }}>
            <GraphZoomButtons
              switchZoomMode={() =>
                dispatch(switchDvaZoomMode({ fileId: props.fileId }))
              }
              zoomOut={() =>
                dispatch(redoDvaZoomDomain({ fileId: props.fileId }))
              }
              resetZoom={() =>
                dispatch(resetDvaZoomDomain({ fileId: props.fileId }))
              }
              removeCursorPos={() => dispatch(removeDvaCursorPosition())}
              zoomDimension={zoomDimension}
              canUseZoomOut={hasZoomed}
              style={{
                position: "absolute",
                zIndex: 1000,
                right: scoreValues ? scoreValueWidth + 20 : 20,
                paddingTop: 0
              }}
              isCursorPosSelected={isCursorPosSelected}
            />

            <div style={{ height: mainGraphHeight }}>
              <DvaGraph
                width={mainGraphWidth}
                height={mainGraphHeight}
                // This works since the key is based on the index on creation
                dvaDataBlock={dvaDataBlock}
                zoomDomain={zoomDomain}
                handleZoom={handleZoomDomain}
                zoomDimension={zoomDimension}
              />
            </div>
            <div style={{ height: overviewGraphHeight }}>
              <DvaOverviewGraph
                width={mainGraphWidth}
                height={overviewGraphHeight}
                // This works since the key is based on the index on creation
                dvaDataBlock={dvaDataBlock}
                zoomDomain={zoomDomain}
                handleZoom={handleZoomDomain}
                zoomDimension={zoomDimension}
                resetZoom={() =>
                  dispatch(resetDvaZoomDomain({ fileId: props.fileId }))
                }
              />
            </div>
          </Layout.Content>
          {scoreValues && (
            <Sider theme="light" width={scoreValueWidth}>
              <div style={{ padding: size.m1 }}>
                <DvaScoreValues dvaDataBlock={dvaDataBlock} />
              </div>
            </Sider>
          )}
        </Layout>
      </Card>
    </>
  );
};

interface IProps {
  fileId: string;
  width: number;
  height: number;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}
const DvaDashboardCard: React.FC<IProps> = (props) => {
  const {
    dvaData,
    activeKey,
    timezone,
    zoomDomain,
    dvaZoomDimension,
    hasZoomed
  } = useSelector(selectDvaDataForDashboard(props.fileId));
  const { t } = useTranslation();

  const contentHeight = props.height;

  const tableHeight = contentHeight * 0.4;
  const graphContentHeight = contentHeight - tableHeight;

  const csvFormat = useSelector(selectCsvFormat);
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  const exportDvaAsCsv = () => {
    const content = dvaDataToCsv(
      dvaData,
      csvFormat,
      timezone,
      timezoneState,
      timezoneToggle,
      t
    );
    if (isEmpty(content)) {
      displayErrorMessage(t("FailedToExportDvaData"));
      return;
    }
    saveAsCsv(content);
  };

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      csvAction: exportDvaAsCsv
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  return (
    <>
      {/* Card that will be shown in the dashboard */}
      <MoveableCard
        title={t("DetailedVibrationAnalysis")}
        dragHandle={props.dragHandle}
        closeMe={props.closeCard}
        extraButtons={extraButtons}
        fileId={props.fileId}
      >
        <DvaTable
          fileId={props.fileId}
          tableHeight={!isNil(activeKey) ? tableHeight : contentHeight}
        />
        {!isNil(activeKey) ? (
          <>
            <DvaGraphContent
              fileId={props.fileId}
              dvaDataBlock={dvaData[activeKey]}
              activeKey={activeKey}
              width={props.width}
              height={graphContentHeight}
              zoomDimension={dvaZoomDimension}
              hasZoomed={hasZoomed}
              zoomDomain={zoomDomain}
              timezone={timezone}
            />
          </>
        ) : (
          <></>
        )}
      </MoveableCard>
    </>
  );
};

export default DvaDashboardCard;
