import React, { useState } from "react";
import { Drawer, Row } from "antd";
import { useSelector } from "react-redux";
import {
  selectAccHistogramData,
  selectDashboardHeader,
  selectForDashboardRangePicker,
  selectGraphDomains,
  selectProjectInfo
} from "../../state/openDatxSlice";
import AccHistogramBinSettings from "./AccHistogramBinSettings";
import AccHistogramGraph from "./AccHistogramGraph";
import { LargeTitle, SmallTitle, SmallText } from "../Common/CommonFonts";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import { accHistogramDataToCsv } from "../../helpers/dataExportHelper";
import { useTranslation } from "react-i18next";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { selectCsvFormat } from "../../state/sessionSlice";
import {
  PdfExportComponent,
  HeaderData,
  useExportPdfHook
} from "../PrintExport/pdfExport";
import { SettingOutlined } from "@ant-design/icons";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import { getUser } from "../../state/sessionSlice";
import dayjs from "dayjs";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";
import { size } from "../../helpers/pageHelper";

interface IProps {
  fileId: string;
  width: number;
  height: number;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}
const AccHistogramDashboardCard = (props: IProps): React.JSX.Element => {
  const { t } = useTranslation();

  const { fileId, width, height } = props;

  const { data, bins } = useSelector(selectAccHistogramData(fileId))!;

  const domains = useSelector(selectGraphDomains(props.fileId));
  const { dataDomain, timezone } = useSelector(
    selectForDashboardRangePicker(fileId)
  );
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);
  const utcOffsetStr = createUtcOffsetStr(timezone);

  const [showAccHistogramSettings, setShowAccHistogramSettings] =
    useState(false);

  const { isPrinting, startPrint, finishPrint } = usePrintHook();
  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const csvFormat = useSelector(selectCsvFormat);
  const projectInfo = useSelector(selectProjectInfo(props.fileId));

  const exportAsCsv = () => {
    const csvData = accHistogramDataToCsv(data, csvFormat, t);
    saveAsCsv(csvData);
  };

  const extraButtons: ExtraButtonItem[] = [
    {
      title: t("Settings"),
      icon: <SettingOutlined />,
      button: "custom",
      action: () => setShowAccHistogramSettings(true)
    },
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      csvAction: exportAsCsv,
      printAction: startPrint
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const headerData: HeaderData = {
    reportType: "AccelerationHistogram",
    projectName: projectInfo.projectName ?? "",
    hasFilters: projectInfo.isFiltered,
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <MoveableCard
      title={t("AccelerationHistogram")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={fileId}
    >
      {isPrinting && (
        <PrintableComponent
          WrappedComponent={AccHistogramGraph}
          props={{ data, bins }}
          reportPrintDone={finishPrint}
          header={headerData}
          orientation="landscape"
        />
      )}
      {isExporting && (
        <PdfExportComponent
          ComponentBody={AccHistogramGraph}
          props={{ data, bins }}
          type="svg"
          name="histogram.pdf"
          reportExportDone={finishExport}
          header={headerData}
        />
      )}
      <Drawer
        open={showAccHistogramSettings}
        onClose={() => setShowAccHistogramSettings(false)}
        placement="top"
        getContainer={false}
        style={{
          position: "absolute",
          visibility: showAccHistogramSettings ? "visible" : "hidden"
        }}
      >
        <Row justify="start">
          <LargeTitle>{t("HistogramSettings")}</LargeTitle>
        </Row>
        <Row justify="start">
          <SmallTitle>{t("DataBins")}</SmallTitle>
        </Row>
        <Row justify="start">
          <SmallText>{t("ConfigureTheSizesOfTheDataBins")}</SmallText>
        </Row>
        <div style={{ width: "100%", paddingTop: size.s2 }}>
          <AccHistogramBinSettings fileId={fileId} />
        </div>
      </Drawer>
      <AccHistogramGraph
        data={data}
        bins={bins}
        width={width}
        height={height}
      />
    </MoveableCard>
  );
};

export default AccHistogramDashboardCard;
