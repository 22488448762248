import React, { useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import { PageSizes, PDFDocument } from "pdf-lib";
import {
  addLandscapeHeader,
  addPortraitHeader,
  addXmlns,
  getImage,
  getSvg,
  HeaderData,
  removeSvg,
  replaceDegree
} from "./pdfExport";
import { MobitronLogoBackground } from "../../logos";
import { Orientation } from "./hocPrintables";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { savePdf } from "../../helpers/fileHelperUniversal";

export type PaperFormat = "A4" | "A3" | "Letter";
interface MergePagesConfig {
  pageArray: ArrayBuffer[];
  orientation: Orientation;
  itemsPerPage: number;
  paperFormat: PaperFormat;
}

/** Function that returns the right paper size for a format and orientation */
const getPageSize = (paperFormat: PaperFormat, orientation: Orientation) => {
  const landscapeA4: [number, number] = [PageSizes.A4[1], PageSizes.A4[0]];
  const landscapeA3: [number, number] = [PageSizes.A3[1], PageSizes.A3[0]];
  const landscapeLetter: [number, number] = [
    PageSizes.Letter[1],
    PageSizes.Letter[0]
  ];
  switch (paperFormat) {
    case "A4":
      return orientation === "portrait" ? PageSizes.A4 : landscapeA4;
    case "A3":
      return orientation === "portrait" ? PageSizes.A3 : landscapeA3;
    case "Letter":
      return orientation === "portrait" ? PageSizes.Letter : landscapeLetter;
    default:
      return PageSizes.A4;
  }
};

/** Function that merges individual PDF pages in a ArrayBuffer into a new PDF */
const mergePages = async (props: MergePagesConfig) => {
  const { pageArray, orientation, itemsPerPage, paperFormat } = props;
  const mergedPdf = await PDFDocument.create();
  /** Calculate number of pages in merged PDF*/
  const numberOfPages = Math.ceil(pageArray.length / itemsPerPage);

  /** Get target page size */
  const pageSize = getPageSize(paperFormat, orientation);

  /** Create blank pages in the right format */
  for (let i = 0; i < numberOfPages; i++) {
    mergedPdf.addPage(pageSize);
  }

  /** Walk through all items and add them to the merged PDF */
  const actions = pageArray.map(async (pdfBuffer, index) => {
    const [currentItem] = await mergedPdf.embedPdf(pdfBuffer);

    // Calculate how much the current item must shrink to fit the target
    const pageScale =
      orientation === "portrait"
        ? pageSize[1] / (currentItem.height * itemsPerPage)
        : pageSize[0] / (currentItem.width * itemsPerPage);

    // Calculate the new dimensions of the current item
    const currentItemDims = currentItem.scale(pageScale);

    // Calculate which target page the current item should be drawn on
    const pageIndex = Math.floor(index / itemsPerPage);
    const targetPage = mergedPdf.getPage(pageIndex);

    // Calculate current item position on the target page
    const xOffset =
      orientation === "portrait"
        ? targetPage.getWidth() / 2 - currentItemDims.width / 2
        : (index % itemsPerPage) * currentItemDims.width;
    const yOffset =
      orientation === "portrait"
        ? (itemsPerPage - 1 - (index % itemsPerPage)) * currentItemDims.height
        : targetPage.getHeight() / 2 - currentItemDims.height / 2;

    // Draw the current item onto the target page
    targetPage.drawPage(currentItem, {
      ...currentItemDims,
      x: xOffset,
      y: yOffset
    });
  });
  await Promise.all(actions);
  await mergedPdf.save().then((file) => {
    savePdf(file as Buffer);
  });
};

const short = 2480; // A4 width
const long = 3508; // A4 height
const padding = 144;
const logoHeight = 300;
const logoWidth = 900;

const htmlMaxWidth = {
  landscape: 1072,
  portrait: 730
};
const itemFormat = {
  landscape: [short, long],
  portrait: [long, short]
};
const contentSize = {
  landscape: {
    width: long - padding - padding,
    height: short - padding - padding - logoHeight - 250
  },
  portrait: {
    width: short - padding - padding,
    height: long - padding - padding - logoHeight - 250
  }
};
const MobitronLogo = ReactDOMServer.renderToStaticMarkup(
  <MobitronLogoBackground />
);

export const exportMixedAsPdf = (
  html: HTMLElement,
  orientation: Orientation,
  itemsPerPage: number,
  paperFormat: PaperFormat,
  t: TFunction,
  headers?: HeaderData[]
) => {
  let pdfPages: Promise<ArrayBuffer>[] = [];
  const liElements = html.getElementsByTagName("li");

  // Switch to the other orientation if itemsPerPage is higher than 1
  let itemOrientation = orientation;
  if (itemsPerPage > 1) {
    itemOrientation = orientation === "landscape" ? "portrait" : "landscape";
  }

  for (let i = 0; i < liElements.length; i++) {
    pdfPages[i] = new Promise((resolve, reject) => {
      // get class name
      const className = liElements[i].getAttribute("class");
      if (className === "html") {
        const doc = new jsPDF({
          orientation: itemOrientation,
          unit: "px",
          format: itemFormat[itemOrientation],
          hotfixes: ["px_scaling"]
        });

        getImage(MobitronLogo, logoWidth, logoHeight).then((dataUrl) => {
          /** Add Mobitron logo to PDF */
          doc.addImage(
            dataUrl,
            "JPEG",
            padding,
            padding,
            logoWidth,
            logoHeight
          );

          /** Add PDF header */
          if (headers && headers[i]) {
            if (itemOrientation === "landscape")
              addLandscapeHeader(doc, t, headers[i]);
            else addPortraitHeader(doc, t, headers[i]);
          } else {
            addLandscapeHeader(doc, t);
          }

          // Get html content from li element
          const htmlContent = removeSvg(
            liElements[i].firstChild as HTMLElement
          );

          doc
            .html(htmlContent, {
              margin: [logoHeight + padding + 250, padding, padding, padding],
              width: htmlMaxWidth[itemOrientation],
              windowWidth: htmlMaxWidth[itemOrientation],
              autoPaging: "text",
              html2canvas: {
                scale: 3,
                backgroundColor: "none"
              }
            })
            .then(() => {
              const buffer = doc.output("arraybuffer");
              resolve(buffer);
            });
        });
      } else if (className === "svg") {
        const doc = new jsPDF({
          orientation: itemOrientation,
          unit: "px",
          format: itemFormat[itemOrientation],
          hotfixes: ["px_scaling"]
        });

        getImage(MobitronLogo, logoWidth, logoHeight).then((dataUrl) => {
          /** Add Mobitron logo to PDF */
          doc.addImage(
            dataUrl,
            "JPEG",
            padding,
            padding,
            logoWidth,
            logoHeight
          );

          /** Add PDF header */
          if (headers && headers[i]) {
            if (itemOrientation === "landscape")
              addLandscapeHeader(doc, t, headers[i]);
            else addPortraitHeader(doc, t, headers[i]);
          } else {
            addLandscapeHeader(doc, t);
          }

          // Find the first SVG element and convert it to a string
          const svgContent = addXmlns(replaceDegree(getSvg(liElements[i])));
          getImage(
            svgContent,
            contentSize[itemOrientation].width,
            contentSize[itemOrientation].height
          ).then((dataUrl) => {
            // Add the SVG graph to the PDF
            doc.addImage(
              dataUrl,
              "JPEG",
              padding,
              logoHeight + padding + 250,
              contentSize[itemOrientation].width,
              contentSize[itemOrientation].height
            );
            const buffer = doc.output("arraybuffer");
            resolve(buffer);
          });
        });
      } else {
        reject("Unknown class name");
      }
    });
  }
  Promise.all(pdfPages).then((pages) => {
    const config: MergePagesConfig = {
      pageArray: pages,
      orientation: orientation,
      itemsPerPage: itemsPerPage,
      paperFormat: paperFormat
    };
    mergePages(config);
  });
};

/** Props for the PdfExportComponent */
interface PdfExportMultipleProps {
  ComponentBody: React.FC<any>;
  orientation: Orientation;
  itemsPerPage: number;
  paperFormat: PaperFormat;
  headers?: HeaderData[];
  reportExportDone: () => void;
}

/** Component wraping multiple JSX Components  */
export const PdfExportMultiple = (PEMProps: PdfExportMultipleProps) => {
  const { t } = useTranslation();
  const {
    ComponentBody,
    reportExportDone,
    headers,
    orientation,
    paperFormat,
    itemsPerPage
  } = PEMProps;

  const itemRef = useRef();

  useEffect(() => {
    if (itemRef.current) {
      exportMixedAsPdf(
        itemRef.current!,
        orientation,
        itemsPerPage,
        paperFormat,
        t,
        headers
      );
      reportExportDone();
    }
  }, [reportExportDone]);

  return (
    <div ref={itemRef as any}>
      <ComponentBody />
    </div>
  );
};

export interface ExportableItem {
  ComponentBody: React.FC<any>;
  props: any;
  type: "html" | "svg";
}

interface MultiPdfExportProps {
  exportableItems: ExportableItem[];
  headers?: HeaderData[];
  orientation: Orientation;
  itemsPerPage: number;
  paperFormat: PaperFormat;
  reportExportDone: () => void;
}

/**
 * Higher order component that can be used to export mutiple components in a4
 * size. If all the components can't fit in 1 page, the final PDF will be more
 * than 1 pages long. The export will start directly when this component is
 * finished rendering. It is therefore recomended to use the exportHook
 * to only render it when the user want to start exporting
 * @param props
 */
export const ExportMultipleComponent = (props: MultiPdfExportProps) => {
  const {
    exportableItems,
    headers,
    orientation,
    itemsPerPage,
    paperFormat,
    reportExportDone
  } = props;
  return (
    <PdfExportMultiple
      ComponentBody={() => (
        <WrapMultiple
          exportableItems={exportableItems}
          orientation={orientation}
        />
      )}
      headers={headers}
      orientation={orientation}
      itemsPerPage={itemsPerPage}
      paperFormat={paperFormat}
      reportExportDone={reportExportDone}
    />
  );
};

interface WrapMultipleProps {
  exportableItems: ExportableItem[];
  orientation: Orientation;
}

/**
 * Wrap multible exportable items into one component
 * @param props
 */
export const WrapMultiple = (props: WrapMultipleProps) => {
  const { exportableItems, orientation } = props;

  const ulStyle: React.CSSProperties = {
    width: contentSize[orientation].width,
    listStyle: "none",
    margin: 0,
    padding: 0
  };

  const liStyle: React.CSSProperties = {
    width: contentSize[orientation].width,
    height: contentSize[orientation].height,
    maxWidth: "100%"
  };

  return (
    <ul style={ulStyle}>
      {exportableItems.map((PEProps, index) => {
        const { ComponentBody, props, type } = PEProps;
        return (
          <li key={index} className={type} style={{ ...liStyle }}>
            <ComponentBody
              width={contentSize[orientation].width / 2}
              height={contentSize[orientation].height / 2}
              {...props!}
            />
          </li>
        );
      })}
    </ul>
  );
};
