import React, { useState } from "react";
import { Col, Form, Input, notification, Row } from "antd";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  useCreateNewUserMutation,
  useRequestAuthMutation,
  UserForRegistration,
  appType,
  LoginDetails
} from "../../state/cargologRestApi";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { size } from "../../helpers/pageHelper";
import {
  setCreateCompanySuccess,
  setCreateUserAccountProgress,
  setCreateUserSuccess
} from "../../state/navigationSlice";
import { setSessionTokens } from "../../state/persistantStateSlice";
import { LeftCircleOutlined } from "@ant-design/icons";
import { setInitalizeSwitcher } from "../../state/sessionSlice";
import Online from "../MicroComponents/Online";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const CreateUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* Create new user */
  const [createNewUser, requestStatus] = useCreateNewUserMutation();
  const { isLoading: createUserIsLoading } = requestStatus;

  /** Function that handles creation of a new user
   *  This way of building it prevents the requestAuth to fire twice.
   */
  const handleCreateUser = async (user: UserForRegistration) => {
    const result: any = await createNewUser(user);
    if (result.data) {
      notification.success({ message: t("SuccessfullyCreateNewUser") });
      if (!isUndefined(email) && !isUndefined(password)) {
        handleRequestAuth({ email, password, appType });
      }
    } else {
      notification.error({ message: t("FailedToCreateNewUser") });
    }
  };

  /* Request for access token */
  const [requestAuth, authStatus] = useRequestAuthMutation();
  const { isLoading: authIsLoading } = authStatus;

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [password, setPassword] = useState<string>();

  /** Function that handles authentication of a new user */
  const handleRequestAuth = async (request: LoginDetails) => {
    const result: any = await requestAuth(request);
    if (result.data) {
      const setTokens = async () => {
        await dispatch(setSessionTokens(result.data));
      };
      setTokens().then(() => {
        dispatch(setCreateUserSuccess(true));
        dispatch(setCreateUserAccountProgress(1));
      });
    } else {
      notification.error({ message: t("UnabletologinError") });
    }
  };

  // When user finishes form sucessfully
  const onFinish = () => {
    if (
      !isUndefined(firstName) &&
      !isUndefined(lastName) &&
      !isUndefined(email) &&
      !isUndefined(password)
    ) {
      const request: UserForRegistration = {
        firstName,
        lastName,
        email,
        phoneNumber,
        password
      };
      handleCreateUser(request);
    }
  };
  // When user tries to finish form, but it is incomplete
  const onFinishFailed = () => {};

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Row gutter={size.m1}>
          <Col span={12}>
            <Form.Item
              label={t("FirstName")}
              name="firstName"
              rules={[{ required: true, message: t("FirstNameIsRequired") }]}
            >
              <Input
                placeholder=""
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autoFocus
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("LastName")}
              name="lastName"
              rules={[{ required: true, message: t("LastNameIsRequired") }]}
            >
              <Input
                placeholder=""
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={size.m1}>
          <Col span={12}>
            <Form.Item
              label={t("Email")}
              name="email"
              rules={[{ required: true, message: t("EmailIsRequired") }]}
            >
              <Input
                placeholder="user@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("PhoneNumber")} name="phoneNumber">
              <Input
                placeholder=""
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={size.m1}>
          <Col span={12}>
            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                { required: true, message: t("PasswordMustBeAtLeast"), min: 6 }
              ]}
              required
              tooltip={
                <>
                  {t("MustBeAtLeast6CharactersLong")}
                  <br />
                  {t("MustContainAtLeastOneNumber")}
                </>
              }
            >
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <NormalButton
            icon={<LeftCircleOutlined />}
            onClick={() => {
              dispatch(setCreateUserSuccess(false));
              dispatch(setCreateCompanySuccess(false));
              dispatch(setCreateUserAccountProgress(0));
              dispatch(setInitalizeSwitcher("loginPage"));
            }}
          >
            {t("BackToLogin")}
          </NormalButton>
          <Online>
            <Form.Item style={{ marginBottom: 0 }}>
              <PrimaryButton
                style={{ width: 80, height: "35px" }}
                htmlType="submit"
                loading={createUserIsLoading || authIsLoading}
              >
                {t("Next")}
              </PrimaryButton>
            </Form.Item>
          </Online>
        </Row>
      </Form>
    </>
  );
};

export default CreateUser;
