import React, { useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  TreeSelect,
  InputNumber,
  notification,
  Typography
} from "antd";
import { modalsState, closeAddDiscountModal } from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  ChildCompany,
  useAddCompanyDiscountMutation,
  useGetAllLicensePricesQuery,
  useGetCompanyTreeQuery
} from "../../state/cargologRestApi";
import StandardModal from "../Common/StandardModal";
const { Option } = Select;
const { Text } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

interface DataNode {
  title?: string;
  key: string;
  value: string;
  children?: DataNode[];
  disabled: boolean;
}

interface RequestData {
  companyId: string;
  licensePriceId: string;
  discountPercent: number;
}

const AddDiscountModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [company, setCompany] = useState<string>();
  const [license, setLicense] = useState<string>();
  const [discount, setDiscount] = useState<number>();

  const getCompanyId: string | undefined = modals.companyIdObject;

  const [addNewDiscount, requestStatus] = useAddCompanyDiscountMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const { data: companyTreeData } = useGetCompanyTreeQuery();

  const handleAddNewDiscount = async (request: RequestData) => {
    const result: any = await addNewDiscount(request);
    if (result.data) {
      notification.success({
        message: `${t("SuccessfullyAddedA")} ${result.data.discountPercent}% ${t("discount")}`
      });
      dispatch(closeAddDiscountModal());
    } else if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (typeof e === "string") {
        errorDescription = e;
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToAddDiscount"),
        description: errorDescription
      });
    }
  };

  const CompanyTree: React.FC<{}> = () => {
    let walkTree = (company: ChildCompany): DataNode => {
      let treeChildren: DataNode[] = [];
      if (getCompanyId !== company.id) {
        treeChildren = company.childCompanies
          ? company.childCompanies.map((child) => walkTree(child))
          : [];
      }
      let tree: DataNode = {
        title: company.name ?? "",
        key: company.id,
        value: company.id,
        children: treeChildren,
        disabled: getCompanyId === company.id
      };
      return tree;
    };
    let treeData: DataNode[] | undefined = companyTreeData
      ? [walkTree(companyTreeData)]
      : undefined;
    return (
      <TreeSelect
        placeholder={t("SelectCompany")}
        treeData={treeData}
        treeDefaultExpandAll
        value={company}
        onChange={(value) => setCompany(value)}
      />
    );
  };

  const { data: licensePriceData } = useGetAllLicensePricesQuery();
  const LicenseSelect: React.FC<{}> = () => {
    const licenseSelectData = licensePriceData?.map((license) => {
      return (
        <Option key={license.id} value={license.id}>
          {license.name}
          <Text type="secondary">
            {" "}
            ({license.deviceType === 1 ? t("cargolog3rdGen") : null}
            {license.deviceType === 2 ? t("cargologEasy") : null}
            {license.deviceType === 3 ? t("vehicle") : null})
          </Text>
        </Option>
      );
    });
    return (
      <Select
        placeholder={t("SelectLicense")}
        value={license}
        onChange={(value) => setLicense(value)}
      >
        {licenseSelectData}
      </Select>
    );
  };

  const onFinish = () => {
    if (
      !isUndefined(company) &&
      !isUndefined(license) &&
      !isUndefined(discount)
    ) {
      const request: RequestData = {
        companyId: company,
        licensePriceId: license,
        discountPercent: discount
      };
      handleAddNewDiscount(request);
      setCompany(undefined);
      setLicense(undefined);
      setDiscount(undefined);
    }
  };

  const onFinishFailed = () => {};

  return (
    <>
      <StandardModal
        title={t("AddDiscountToACompany")}
        open={modals.isAddDiscountModalOpen}
        onCancel={() => {
          setCompany(undefined);
          setLicense(undefined);
          setDiscount(undefined);
          dispatch(closeAddDiscountModal());
        }}
        closable={true}
        footer={null}
        width={400}
        zIndex={1045}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          layout="vertical"
          style={{ width: "100%" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col span={24}>
              <Form.Item label={t("Company")} name={"company"} required>
                <CompanyTree />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label={t("License")} name={"license"} required>
                <LicenseSelect />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={t("DiscountPercent")}
                name={"discountPercent"}
                required
              >
                <InputNumber
                  min={1}
                  max={100}
                  onChange={(value) => setDiscount(Number(value))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={requestIsLoading}
            >
              {t("AddDiscount")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default AddDiscountModal;
