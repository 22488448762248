import { Breakpoint, Layout } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { isNil } from "lodash-es";
import React, { useCallback, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import {
  LayoutId,
  SupportedBreakPoints,
  fullWidth,
  removeFromLayout,
  rowHeight
} from "../../helpers/layoutHelper";
import { size } from "../../helpers/pageHelper";
import { selectDashboardLayoutsById } from "../../state/openDatxSlice";
import { updateDashboardLayouts } from "../../state/persistantStateSlice";
import { NormalHeaderCard } from "../Common/CommonCards";
import { ExtraButtonItem } from "../Common/MoveableCardProps";
import {
  CheckUserRightsAccess,
  LicenseAccess
} from "../MicroComponents/LicenseAccess";
import AccHistogramDashboardCard from "./AccHistogramDashboardCard";
import ClassicGraphDashboardCard from "./ClassicGraphDashboardCard";
import DashboardExtraHeader from "./DashboardExtraHeader";
import DashboardHeader from "./DashboardHeader";
import DvaDashboardCard from "./DvaDashboardCard";
import ExternalIOReportsCard from "./ExternalIOReportsCard";
import ExternalTimersReportsCard from "./ExternalTimersReportsCard";
import GeneralRecordingInformationDashboardCard from "./GeneralRecordingInformationDashboardCard";
import LayoutCardsLib from "./LayoutCardsLib";
import LteDashboardCard from "./LteDashboardCard";
import MapDashboardCard from "./MapDashboardCard";
import MinMaxDashboardCard from "./MinMaxDashboardCard";
import QuickReportDashboardCard from "./QuickReportDashboardCard";
import TopAccDashboardCard from "./TopAccDashboardCard";
import AngleReportsCard from "./AngleReportsCard";

const ResponsiveGridLayout = WidthProvider(Responsive);

const getCurrSize = (
  screenSize: Partial<Record<Breakpoint, boolean>>
): SupportedBreakPoints => {
  if (screenSize.xxl) return "xl";
  if (screenSize.xl) return "xl";
  if (screenSize.lg) return "lg";
  if (screenSize.md) return "md";

  return "lg";
};

//todo: test this
/** Calculates the dashboard breakpoint  */
const calcDashboardBreakpoint = (inp: number) =>
  inp - (window.innerWidth - inp);

interface IProps {
  fileId: string;
}
const Dashboard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;

  const { dashboardKey, layouts } = useSelector(
    selectDashboardLayoutsById(props.fileId)
  );

  const dispatch = useDispatch();

  const dvaContainerRef = useRef<HTMLDivElement>();

  const [showLayoutDrawer, setShowLayoutDrawer] = useState(false);

  /** css handle for moveable containers */
  const dragHandle = "drag-handle";

  const updateStateLayouts = (_l: any, newLayouts: ReactGridLayout.Layouts) => {
    dispatch(updateDashboardLayouts({ dashboardKey, layouts: newLayouts }));
  };

  const triggerScrollToDva = useCallback(() => {
    const ref = dvaContainerRef?.current;

    if (isNil(ref)) {
      return false;
    }

    ref.scrollIntoView({ block: "end", inline: "nearest" });
    return true;
  }, []);

  const screenSize = useBreakpoint();

  const layoutSize = getCurrSize(screenSize);

  /** Removes card from current layout */
  const createRemoveCardItem = (id: LayoutId): ExtraButtonItem => ({
    title: t("RemoveCard"),
    action: () => {
      dispatch(
        updateDashboardLayouts({
          dashboardKey,
          layouts: removeFromLayout(id, layouts)
        })
      );
    }
  });

  const dashboardItemStyle: React.CSSProperties = { height: "100%" };
  const headerBodyActiveStyle: React.CSSProperties = {
    height: "auto",
    paddingTop: size.m1,
    paddingBottom: size.m1,
    paddingLeft: size.m1,
    paddingRight: size.l2
  };
  const headerBodyDisabledStyle: React.CSSProperties = {
    height: 0,
    padding: 0
  };

  const canViewData = CheckUserRightsAccess("ACD");
  const canViewDva = CheckUserRightsAccess("ACE");
  const canEditReport = CheckUserRightsAccess("ADA");

  return (
    <Layout
      style={{
        padding: 0,
        margin: 0,
        minWidth: 500
      }}
    >
      <Layout.Header
        style={{
          position: "sticky",
          zIndex: 1,
          width: "100%",
          margin: 0,
          padding: 0,
          top: 0,
          height: "auto",
          backgroundColor: "transparent"
        }}
      >
        {/* Note: If the dashboard header and extra header is kept, it might be a good idea to make them into one */}
        <NormalHeaderCard
          title={<DashboardHeader fileId={props.fileId} />}
          styles={{
            header: { paddingLeft: size.l2, paddingRight: size.l2 },
            body: CheckUserRightsAccess("ACD")
              ? headerBodyActiveStyle
              : headerBodyDisabledStyle
          }}
        >
          {/* Extra header that is right below the "normal" header */}
          {LicenseAccess(
            "ACD",
            <DashboardExtraHeader
              fileId={props.fileId}
              showLayoutDrawer={() => setShowLayoutDrawer(true)}
            />
          )}
        </NormalHeaderCard>
      </Layout.Header>

      <Layout.Content>
        {/* Add new cards to dashboard side menu */}
        <LayoutCardsLib
          fileId={props.fileId}
          visible={showLayoutDrawer}
          closeDrawer={() => setShowLayoutDrawer(false)}
        />

        <div ref={ref}>
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            //todo: are these correct??
            breakpoints={{
              xl: calcDashboardBreakpoint(1146),
              lg: calcDashboardBreakpoint(992),
              md: calcDashboardBreakpoint(768),
              sm: calcDashboardBreakpoint(576),
              xs: calcDashboardBreakpoint(480)
            }}
            cols={{
              xl: fullWidth,
              lg: fullWidth,
              md: fullWidth,
              sm: fullWidth,
              xs: fullWidth,
              xxs: fullWidth
            }}
            rowHeight={rowHeight}
            width={componentWidth}
            containerPadding={[size.m1, size.m1]}
            draggableHandle={`.${dragHandle}`}
            onLayoutChange={updateStateLayouts}
          >
            {layouts?.[layoutSize]?.map((l) => {
              /** Describes how much '1 width' is worth in layouts */
              const widthUnit = componentWidth / fullWidth;
              switch (l.i) {
                case "mainGraph": {
                  if (!canViewData) {
                    return <></>;
                  }
                  const width = l.w * widthUnit;
                  const height = l.h * rowHeight;
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <ClassicGraphDashboardCard
                        fileId={props.fileId}
                        width={width}
                        height={height}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                        jumpToDvaCallback={triggerScrollToDva}
                      />
                    </div>
                  );
                }

                case "minMax": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <MinMaxDashboardCard
                        fileId={props.fileId}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "dvaGraph": {
                  if (!canViewDva) {
                    return <></>;
                  }
                  const width = l.w * widthUnit;
                  const height = l.h * rowHeight;

                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <div
                        ref={dvaContainerRef as any}
                        style={dashboardItemStyle}
                      >
                        <DvaDashboardCard
                          fileId={props.fileId}
                          width={width}
                          height={height}
                          dragHandle={dragHandle}
                          closeCard={createRemoveCardItem(l.i)}
                        />
                      </div>
                    </div>
                  );
                }

                case "topAccTable": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <TopAccDashboardCard
                        fileId={props.fileId}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "deviceInfo": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <GeneralRecordingInformationDashboardCard
                        fileId={props.fileId}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "accHistogram": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  const width = l.w * widthUnit;
                  const height = l.h * rowHeight;

                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <AccHistogramDashboardCard
                        fileId={props.fileId}
                        width={width}
                        height={height}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "quickReport": {
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <QuickReportDashboardCard
                        fileId={props.fileId}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "map": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <MapDashboardCard
                        fileId={props.fileId}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "lte": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <LteDashboardCard
                        fileId={props.fileId}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "extTimer": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <ExternalTimersReportsCard
                        fileId={props.fileId}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "extIO": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  const width = l.w * widthUnit;
                  const height = l.h * rowHeight;
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <ExternalIOReportsCard
                        fileId={props.fileId}
                        width={width}
                        height={height}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                case "angle": {
                  if (!canEditReport) {
                    return <></>;
                  }
                  const width = l.w * widthUnit;
                  const height = l.h * rowHeight;
                  return (
                    <div key={l.i} style={dashboardItemStyle}>
                      <AngleReportsCard
                        fileId={props.fileId}
                        width={width}
                        height={height}
                        dragHandle={dragHandle}
                        closeCard={createRemoveCardItem(l.i)}
                      />
                    </div>
                  );
                }

                default:
                  return <div />;
              }
            })}
          </ResponsiveGridLayout>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Dashboard;
