import React from "react";
import { Form, notification, Tabs } from "antd";
import { modalsState, closeCreateLicenseModal } from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CreateLicenseModalStage1 from "./CreateLicensePriceModalStage1";
import CreateLicenseModalStage2 from "./CreateLicensePriceModalStage2";
import CreateLicenseModalStage3 from "./CreateLicensePriceModalStage3";
import {
  emptyLicensePriceState,
  selectLicensePriceState
} from "../../state/licensePriceSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import {
  NewLicensePrice,
  useCreateLicensePriceMutation
} from "../../state/cargologRestApi";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

// Main component
interface IProps {}
const CreateLicensePriceModal: React.FC<IProps> = () => {
  const modals = useSelector(modalsState);
  const licenseToCreate = useSelector(selectLicensePriceState);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const [createLicensePrice, requestStatus] = useCreateLicensePriceMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const closeModal = () => {
    dispatch(emptyLicensePriceState());
    dispatch(closeCreateLicenseModal());
  };

  const handleCreateLicensePrice = async (request: NewLicensePrice) => {
    const result: any = await createLicensePrice(request);
    if (result.data) {
      notification.success({ message: t("LicenseCreatedSuccessfully") });
      closeModal();
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("FailedToCreateNewLicense"),
        description: errorDescription
      });
    }
  };

  const onFinish = () => {
    const request: NewLicensePrice = {
      name: licenseToCreate.name ?? "",
      description: licenseToCreate.description ?? "",
      deviceType: licenseToCreate.licenseType ?? 1,
      color: licenseToCreate.color,
      licensePriceDefs: [
        { price: licenseToCreate.prices.sek3 ?? 0, currency: "SEK", months: 3 },
        { price: licenseToCreate.prices.sek6 ?? 0, currency: "SEK", months: 6 },
        {
          price: licenseToCreate.prices.sek12 ?? 0,
          currency: "SEK",
          months: 12
        },
        { price: licenseToCreate.prices.eur3 ?? 0, currency: "EUR", months: 3 },
        { price: licenseToCreate.prices.eur6 ?? 0, currency: "EUR", months: 6 },
        {
          price: licenseToCreate.prices.eur12 ?? 0,
          currency: "EUR",
          months: 12
        },
        { price: licenseToCreate.prices.usd3 ?? 0, currency: "USD", months: 3 },
        { price: licenseToCreate.prices.usd6 ?? 0, currency: "USD", months: 6 },
        {
          price: licenseToCreate.prices.usd12 ?? 0,
          currency: "USD",
          months: 12
        }
      ],
      userRights: licenseToCreate.userRights
    };
    if (request?.userRights && request.userRights.length > 0) {
      handleCreateLicensePrice(request);
    } else {
      notification.error({ message: t("LicenseRequiresAtLeastOneUserRight") });
    }
  };

  return (
    <>
      <StandardModal
        title={t("tableLicensePriceCreateNewLicense")}
        open={modals.isCreateLicenseModalOpen}
        onCancel={() => closeModal()}
        closable={true}
        width={500}
        zIndex={1045}
        destroyOnClose={true}
        centered={true}
        style={{ height: "600px" }}
        footer={[
          <NormalButton onClick={() => closeModal()}>
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton onClick={onFinish} loading={requestIsLoading}>
            {t("genSave")}
          </PrimaryButton>
        ]}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Tabs type={"card"}>
            <TabPane tab={t("licensePriceNameAndDescription")} key={"0"}>
              <CreateLicenseModalStage1 />
            </TabPane>
            <TabPane tab={t("licensePricePrices")} key={"1"}>
              <CreateLicenseModalStage2 />
            </TabPane>
            <TabPane tab={t("licensePriceUserRights")} key={"2"}>
              <CreateLicenseModalStage3 />
            </TabPane>
          </Tabs>
        </Form>
      </StandardModal>
    </>
  );
};

export default CreateLicensePriceModal;
