import { Buffer } from "buffer";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { isEqual, isNil, isUndefined } from "lodash-es";
import { createTransform } from "redux-persist";
import { batteryConfigurations } from "../constants/batteryConfigurationPresets";
import { createCurrentTimeUnixTimestamp } from "../helpers/dateHelper";
import { generalSysInfoToSyncable } from "../helpers/deviceHelper";
import {
  addCardToLayout,
  AvailableDashboardKey,
  createStandardDashboard,
  createUserDashboard,
  CustomGridLayouts,
  defaultStartLayout,
  LayoutId,
  UserDashboard
} from "../helpers/layoutHelper";
import { parseAccessToken, TokenData, Tokens } from "../helpers/sessionHelper";
import BatteryType from "../models/BatteryType";
import { GeneralSystemInfo, ISystemInfoV2 } from "../models/ISystemInfo";
import {
  MapProvider,
  ParameterForCreation,
  RecentOnlineDevice,
  Reports,
  SpeedUnit
} from "./cargologRestApi";
import { StoreApi } from "./store";

/**
 * This slice includes the state that should persist between application runs
 */

interface scaleData {
  toggle: boolean;
  min: number;
  max: number;
  count: number;
}

export interface PeristantStateState {
  recentDatxFiles: RecentFile[];
  recentParxFiles: RecentFile[];
  batteries: BatteryType[];
  dashboard: Reports;
  devices: {
    recentDevices: RecentOnlineDevice[];
    mostRecentDevice?: RecentOnlineDevice;
    syncable: SyncableRecentDevice[];
  };
  sessionTokens: Tokens;
  tokenData: TokenData;
  validSetup: boolean;
  currency: string; // Moved to session slice
  speedUnit: SpeedUnit; // Moved to session slice
  mapProvider: MapProvider; // Moved to session slice
  autoUploadDatx: boolean; // Moved to session slice
  temperatureScale: string; // Moved to session slice
  csvFormat: string; // Moved to session slice
  userRights: string[];
  timeScaleSteps: number;
  showNearValues: boolean; // Moved to session slice
  globalGraphScale: {
    toggle: boolean;
    acc: scaleData;
    temp: scaleData;
    rh: scaleData;
    pressure: scaleData;
  };
  globalTimezoneState?: string; // Moved to session slice
  globalTimezoneToggle: boolean; // Moved to session slice
  lastChecked?: string;
  showQuickTourAtStartup: boolean; // Moved to session slice
  parameterIds: {
    syncable: SyncableParameterId[];
    pending?: SyncableParameterId;
  };
  syncableDatxFiles: SyncableDatxFile[];
  renameActiveDashboard: {
    isRenaming: boolean;
    renamingActive: boolean;
  };
  impersonate: {
    isImpersonating: boolean;
    impersonatedUserId?: string;
    forcedEndSession: boolean;
  };
}

/** Syncable recent device */
export interface SyncableRecentDevice {
  userId: string;
  request: ISystemInfoV2;
}

/** Syncable parameterIds */
export interface SyncableParameterId {
  userId: string;
  projectName: string;
  request: ParameterForCreation;
}

/** Syncable DATX files */
export interface SyncableDatxFile {
  userId?: string;
  fileId?: string;
  request: Buffer;
}

interface LocalFile {
  fileType: "local";
  filePath: string;
  fileName: string;
  lastInteraction: number;
  userId: string;
}

interface OnlineFile {
  fileType: "online";
  fileId: string;
  fileName: string;
  lastInteraction: number;
  userId: string;
}

export type RecentFile = LocalFile | OnlineFile;

export const initialState: PeristantStateState = {
  recentDatxFiles: [],
  recentParxFiles: [],
  devices: {
    recentDevices: [],
    mostRecentDevice: undefined,
    syncable: []
  },
  batteries: batteryConfigurations,
  //todo: figure this out
  dashboard: {
    active: "Standard",
    available: { Standard: createStandardDashboard(false) }
  },
  sessionTokens: {},
  tokenData: {},
  validSetup: false,
  currency: "SEK",
  speedUnit: "kmph",
  mapProvider: "google",
  autoUploadDatx: true,
  temperatureScale: "C",
  csvFormat: "swe",
  userRights: [],
  timeScaleSteps: 0,
  showNearValues: true,
  globalGraphScale: {
    toggle: false,
    acc: {
      toggle: false,
      min: -50,
      max: 50,
      count: 21
    },
    temp: {
      toggle: false,
      min: 0,
      max: 30,
      count: 21
    },
    rh: {
      toggle: false,
      min: 0,
      max: 100,
      count: 21
    },
    pressure: {
      toggle: false,
      min: 0,
      max: 300,
      count: 21
    }
  },
  globalTimezoneState: undefined,
  globalTimezoneToggle: false,
  lastChecked: undefined,
  showQuickTourAtStartup: true,
  parameterIds: {
    syncable: [],
    pending: undefined
  },
  syncableDatxFiles: [],
  renameActiveDashboard: {
    isRenaming: false,
    renamingActive: false
  },
  impersonate: {
    isImpersonating: false,
    impersonatedUserId: undefined,
    forcedEndSession: false
  }
};

export const slice: Draft<any> = createSlice({
  name: "persistantState",
  initialState,
  reducers: {
    setRecentDatxFiles: (state, action: PayloadAction<RecentFile[]>) => {
      state.recentDatxFiles = action.payload;
    },
    appendRecentLocalDatxFile: (
      state,
      action: PayloadAction<{ filePath: string; fileName: string }>
    ) => {
      const fileType = "local";
      const { filePath, fileName } = action.payload;
      const lastInteraction = createCurrentTimeUnixTimestamp();
      const userId = state.tokenData.userId;
      if (!userId) return;

      const entry: LocalFile = {
        fileType,
        filePath,
        fileName,
        lastInteraction,
        userId
      };

      const { recentDatxFiles } = state;
      const updated = appendRecentLocalEntry(entry, recentDatxFiles);

      state.recentDatxFiles = updated;
    },
    appendRecentOnlineDatxFile: (
      state,
      action: PayloadAction<{ fileId: string; fileName: string }>
    ) => {
      const fileType = "online";
      const { fileId, fileName } = action.payload;
      const lastInteraction = createCurrentTimeUnixTimestamp();
      const userId = state.tokenData.userId;
      if (!userId) return;

      const entry: OnlineFile = {
        fileType,
        fileId,
        fileName,
        lastInteraction,
        userId
      };

      const { recentDatxFiles } = state;
      const updated = appendRecentOnlineEntry(entry, recentDatxFiles);

      state.recentDatxFiles = updated;
    },
    removeDatxFromRecent: (
      state,
      action: PayloadAction<{ fileToRemove: string }>
    ) => {
      const userId = state.tokenData.userId;
      state.recentDatxFiles = state.recentDatxFiles.filter(
        (item) =>
          item.userId !== userId ||
          (item.fileType === "local" &&
            item.filePath !== action.payload.fileToRemove) ||
          (item.fileType === "online" &&
            item.fileId !== action.payload.fileToRemove)
      );
    },

    setRecentParxFiles: (state, action: PayloadAction<RecentFile[]>) => {
      state.recentParxFiles = action.payload;
    },
    appendRecentLocalParxFile: (
      state,
      action: PayloadAction<{ filePath: string; fileName: string }>
    ) => {
      const fileType = "local";
      const { filePath, fileName } = action.payload;
      const lastInteraction = createCurrentTimeUnixTimestamp();
      const userId = state.tokenData.userId;
      if (!userId) return;

      const entry: LocalFile = {
        fileType,
        filePath,
        fileName,
        lastInteraction,
        userId
      };

      const { recentParxFiles } = state;
      const updated = appendRecentLocalEntry(entry, recentParxFiles);

      state.recentParxFiles = updated;
    },

    removeParxFromRecent: (
      state,
      action: PayloadAction<{ fileToRemove: string }>
    ) => {
      const userId = state.tokenData.userId;
      state.recentParxFiles = state.recentParxFiles.filter(
        (item) =>
          item.userId !== userId ||
          (item.fileType === "local" &&
            item.filePath !== action.payload.fileToRemove) ||
          (item.fileType === "online" &&
            item.fileId !== action.payload.fileToRemove)
      );
    },

    resetBatteries: (state) => {
      state.batteries = batteryConfigurations;
    },
    appendBattery: (state, action: PayloadAction<BatteryType>) => {
      state.batteries = state.batteries.concat(action.payload);
    },
    removeBattery: (state, action: PayloadAction<{ battery: BatteryType }>) => {
      state.batteries = state.batteries.filter(
        (item) => !isEqual(item, action.payload.battery)
      );
    },

    updateDashboardLayouts: (
      state,
      action: PayloadAction<{
        dashboardKey: AvailableDashboardKey;
        layouts: ReactGridLayout.Layouts;
      }>
    ) => {
      const { dashboardKey, layouts } = action.payload;
      const newLayouts = layouts as CustomGridLayouts;
      const { available } = state.dashboard;

      /* If user removes a Report that is currently active in another file,
      this sets layouts to Standard, preventing a crash. */
      if (available[dashboardKey]) {
        available[dashboardKey].layouts = newLayouts;
      } else {
        available["Standard"].layouts = newLayouts;
      }
    },
    addCardToDashboardLayouts: (
      state,
      action: PayloadAction<{
        toAdd: LayoutId;
        dashboardId: AvailableDashboardKey;
      }>
    ) => {
      const { toAdd, dashboardId } = action.payload;
      const { available } = state.dashboard;

      const currentLayouts = available[dashboardId]?.layouts;

      if (isNil(currentLayouts)) {
        return;
      }

      const newLayouts = addCardToLayout(toAdd, currentLayouts);

      state.dashboard.available[dashboardId].layouts = newLayouts;
    },

    clearRecentDevices: (state) => {
      state.devices.recentDevices = [];
      state.devices.mostRecentDevice = undefined;
    },

    nameDashboard: (
      state,
      action: PayloadAction<{
        dashboardKey: AvailableDashboardKey;
        newName: string;
      }>
    ) => {
      const { dashboardKey, newName: name } = action.payload;
      const originalDashboard = state.dashboard.available[dashboardKey];

      const newDashboard = createUserDashboard(
        name,
        originalDashboard.layouts,
        false
      );

      // Reset the dashboard that we based the new dashboard on if its not
      // editable
      if (originalDashboard.isReadOnly) {
        originalDashboard.layouts = originalDashboard.originalLayouts;
      }

      // Set new dashboard layout as current layout
      state.dashboard.available[newDashboard.name] = newDashboard;
      state.dashboard.active = newDashboard.name;
    },
    changeDashboard: (
      state,
      action: PayloadAction<{
        dashboardKey: AvailableDashboardKey;
      }>
    ) => {
      const { dashboardKey } = action.payload;

      const nextDashboard = state.dashboard.available[dashboardKey];

      if (nextDashboard) {
        state.dashboard.active = dashboardKey;
      }
      state.renameActiveDashboard.isRenaming = false;
      state.renameActiveDashboard.renamingActive = false;
    },
    /** Creates a new dashboard based on the selected one and removes
     * the old dashboard. */
    renameDashboard: (
      state,
      action: PayloadAction<{
        dashboardKey: AvailableDashboardKey;
        newName: string;
      }>
    ) => {
      const { dashboardKey, newName } = action.payload;

      const active = state.dashboard.active;

      const { [dashboardKey]: dashboardToRename, ...remainingDashboards } =
        state.dashboard.available;

      const renamedDashboard = createUserDashboard(
        newName,
        dashboardToRename.layouts,
        false
      );

      if (active !== dashboardKey) {
        state.dashboard.available = remainingDashboards;
      }

      state.dashboard.available[renamedDashboard.name] = renamedDashboard;
      state.renameActiveDashboard.isRenaming = true;
    },
    setIsRenaming: (
      state,
      action: PayloadAction<{ isRenaming: boolean; renamingActive: boolean }>
    ) => {
      state.renameActiveDashboard = action.payload;
    },
    removeDashboard: (
      state,
      action: PayloadAction<{
        dashboardKey: string;
      }>
    ) => {
      const { dashboardKey } = action.payload;

      const { [dashboardKey]: objectToRemove, ...remainingObjects } =
        state.dashboard.available;

      state.dashboard.available = remainingObjects;
    },
    resetDashboardState: (state) => {
      state.dashboard = {
        active: "Standard",
        available: {
          Standard: {
            name: "Standard",
            isReadOnly: true,
            layouts: defaultStartLayout,
            originalLayouts: defaultStartLayout
          }
        }
      };
    },
    setReportsFromApi: (state, action: PayloadAction<Reports>) => {
      state.dashboard = action.payload;
    },

    setSessionTokens: (state, action: PayloadAction<Tokens>) => {
      const payload = action.payload;
      state.sessionTokens = payload;

      const accessToken = payload.accessToken;
      if (!isUndefined(accessToken)) {
        const accessTokenData = parseAccessToken(accessToken);
        state.tokenData = {
          firstName: accessTokenData.firstName,
          lastName: accessTokenData.lastName,
          email: accessTokenData.email,
          userId: accessTokenData.userId,
          companyId: accessTokenData.companyId,
          exp: accessTokenData.exp
        };
      }
    },
    clearSession: (state) => {
      state.sessionTokens = {};
      state.tokenData = {};
      state.userRights = [];
    },

    setValidSetup: (state, action: PayloadAction<boolean>) => {
      state.validSetup = action.payload;
    },

    //re hydrate
    resetStandardDashboard: (state) => {
      state.dashboard.available["Standard"].layouts =
        state.dashboard.available["Standard"].originalLayouts;
    },

    setUserRights: (state, action: PayloadAction<string[]>) => {
      state.userRights = action.payload;
    },

    setGraphAxisTickCountTime: (state, action: PayloadAction<number>) => {
      state.timeScaleSteps = action.payload;
    },

    setGlobalGraphAxisScaleAccMin: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.acc.min = action.payload;
      state.globalGraphScale.acc.max = -action.payload;
    },
    setGlobalGraphAxisScaleAccMax: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.acc.max = action.payload;
      state.globalGraphScale.acc.min = -action.payload;
    },
    setGlobalGraphAxisScaleAccCount: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.acc.count = action.payload;
    },
    setGlobalGraphAxisScaleTempMin: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.temp.min = action.payload;
    },
    setGlobalGraphAxisScaleTempMax: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.temp.max = action.payload;
    },
    setGlobalGraphAxisScaleTempCount: (
      state,
      action: PayloadAction<number>
    ) => {
      state.globalGraphScale.temp.count = action.payload;
    },
    setGlobalGraphAxisScaleRhMin: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.rh.min = action.payload;
    },
    setGlobalGraphAxisScaleRhMax: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.rh.max = action.payload;
    },
    setGlobalGraphAxisScaleRhCount: (state, action: PayloadAction<number>) => {
      state.globalGraphScale.rh.count = action.payload;
    },
    toggleGlobalGraphAxisScale: (state) => {
      state.globalGraphScale.toggle = !state.globalGraphScale.toggle;
    },
    toggleGlobalGraphScaleAcc: (state) => {
      state.globalGraphScale.acc.toggle = !state.globalGraphScale.acc.toggle;
    },
    toggleGlobalGraphScaleTemp: (state) => {
      state.globalGraphScale.temp.toggle = !state.globalGraphScale.temp.toggle;
    },
    toggleGlobalGraphScaleRh: (state) => {
      state.globalGraphScale.rh.toggle = !state.globalGraphScale.rh.toggle;
    },
    setLastChecked: (state, action: PayloadAction<string>) => {
      state.lastChecked = action.payload;
    },
    addPendingParameterId: (
      state,
      action: PayloadAction<SyncableParameterId>
    ) => {
      state.parameterIds.pending = action.payload;
    },
    approvePendingParameterId: (state) => {
      if (state.parameterIds.pending === undefined) return;
      state.parameterIds.syncable = [
        state.parameterIds.pending,
        ...state.parameterIds.syncable
      ];
      state.parameterIds.pending = undefined;
    },
    // Removes a parameterId syncable from the list based on the parameterId
    removeSyncableParameterId: (state, action: PayloadAction<string>) => {
      state.parameterIds.syncable = state.parameterIds.syncable.filter(
        (syncable) => syncable.request.id !== action.payload
      );
    },
    // Updates a parameterId syncable based on the parameterId
    updateSyncableParameterId: (
      state,
      action: PayloadAction<SyncableParameterId>
    ) => {
      state.parameterIds.syncable = state.parameterIds.syncable.map(
        (syncable) => {
          if (syncable.request.id === action.payload.request.id) {
            return action.payload;
          }
          return syncable;
        }
      );
    },
    // Adds a syncable datx file with the currently signed in user
    addSyncableDatxFile: (state, action: PayloadAction<SyncableDatxFile>) => {
      const userId = state.tokenData.userId;
      if (userId) {
        const syncable: SyncableDatxFile = {
          userId: userId,
          fileId: action.payload.fileId,
          request: action.payload.request
        };
        state.syncableDatxFiles = [syncable, ...state.syncableDatxFiles];
      }
    },
    // Removes the first syncable datx file with the provided userId
    removeSyncableDatxFile: (state, action: PayloadAction<string>) => {
      const firstItem = state.syncableDatxFiles.findIndex(
        (syncable) => syncable.userId === action.payload
      );
      if (firstItem !== -1) {
        state.syncableDatxFiles.splice(firstItem, 1);
      }
    },
    addSyncableDevice: (state, action: PayloadAction<GeneralSystemInfo>) => {
      const syncableSysInfo: ISystemInfoV2 = generalSysInfoToSyncable(
        action.payload
      );

      const userId = state.tokenData.userId;
      if (userId) {
        const syncable: SyncableRecentDevice = {
          userId: userId,
          request: syncableSysInfo
        };

        state.devices.syncable = state.devices.syncable
          ? [syncable, ...state.devices.syncable]
          : [syncable];
      }
    },
    // Removes the first syncable device with the provided userId
    removeSyncableDevice: (state, action: PayloadAction<string>) => {
      const firstItem = state.devices.syncable?.findIndex(
        (syncable) => syncable.userId === action.payload
      );
      if (firstItem !== -1) {
        state.devices.syncable.splice(firstItem, 1);
      }
    },
    setIsImpersonating: (state, action: PayloadAction<boolean>) => {
      state.impersonate.isImpersonating = action.payload;
    },
    setImpersonatedUserId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.impersonate.impersonatedUserId = action.payload;
    },
    setForcedEndSession: (state, action: PayloadAction<boolean>) => {
      state.impersonate.forcedEndSession = action.payload;
    }
  }
});

export const {
  setRecentDatxFiles,
  appendRecentLocalDatxFile,
  appendRecentOnlineDatxFile,
  removeDatxFromRecent,
  setRecentParxFiles,
  appendRecentLocalParxFile,
  removeParxFromRecent,
  clearRecentDevices,
  resetBatteries,
  appendBattery,
  removeBattery,
  updateDashboardLayouts,
  addCardToDashboardLayouts,
  nameDashboard,
  changeDashboard,
  removeDashboard,
  renameDashboard,
  setIsRenaming,
  setReportsFromApi,
  resetDashboardState,
  setSessionTokens,
  clearSession,
  setValidSetup,
  setUserRights,
  setGraphAxisTickCountTime,
  setGlobalGraphAxisScaleAccMin,
  setGlobalGraphAxisScaleAccMax,
  setGlobalGraphAxisScaleAccCount,
  setGlobalGraphAxisScaleTempMin,
  setGlobalGraphAxisScaleTempMax,
  setGlobalGraphAxisScaleTempCount,
  setGlobalGraphAxisScaleRhMin,
  setGlobalGraphAxisScaleRhMax,
  setGlobalGraphAxisScaleRhCount,
  toggleGlobalGraphAxisScale,
  toggleGlobalGraphScaleAcc,
  toggleGlobalGraphScaleTemp,
  toggleGlobalGraphScaleRh,
  setLastChecked,
  addPendingParameterId,
  approvePendingParameterId,
  removeSyncableParameterId,
  updateSyncableParameterId,
  addSyncableDatxFile,
  removeSyncableDatxFile,
  addSyncableDevice,
  removeSyncableDevice,
  setIsImpersonating,
  setImpersonatedUserId,
  setForcedEndSession
} = slice.actions;

export default slice.reducer;

export const selectDashboard = (state: StoreApi) =>
  state.persistantState.dashboard;

export const selectUserRights = (state: StoreApi) =>
  state.persistantState.userRights;

export const selectAutoUploadDatx = (state: StoreApi) =>
  state.persistantState.autoUploadDatx;

export const selectGraphAxisTickCountTime = (state: StoreApi) =>
  state.persistantState.timeScaleSteps;

export const selectGlobalGraphScale = (state: StoreApi) =>
  state.persistantState.globalGraphScale;
export const selectGlobalGraphScaleToggle = (state: StoreApi) =>
  state.persistantState.globalGraphScale.toggle;
export const selectValidSetup = (state: StoreApi) =>
  state.persistantState.validSetup;

export const selectRecentDatxFiles = (state: StoreApi) => {
  const userId = state.persistantState.tokenData.userId;
  return state.persistantState.recentDatxFiles.filter(
    (file) => file.userId === userId
  );
};

export const selectRecentParxFiles = (state: StoreApi) => {
  const userId = state.persistantState.tokenData.userId;
  return state.persistantState.recentParxFiles.filter(
    (file) => file.userId === userId
  );
};

export const selectRecentDevices = (state: StoreApi) =>
  state.persistantState.devices.recentDevices;

export const selectBatteries = (state: StoreApi) =>
  state.persistantState.batteries;

export const selectLastUpdateCheck = (state: StoreApi) =>
  state.persistantState.lastChecked;

export const selectSyncableParameterIds = (state: StoreApi) =>
  state.persistantState.parameterIds.syncable;

export const selectSyncableDatxFiles = (state: StoreApi) =>
  state.persistantState.syncableDatxFiles;

export const selectSyncableDevices = (state: StoreApi) =>
  state.persistantState.devices.syncable;

export const selectRenameActiveDashboard = (state: StoreApi) =>
  state.persistantState.renameActiveDashboard;

export const selectImpersonate = (state: StoreApi) => {
  return {
    isImpersonating: state.persistantState.impersonate.isImpersonating,
    impersonatedUserId: state.persistantState.impersonate.impersonatedUserId,
    forcedEndSession: state.persistantState.impersonate.forcedEndSession
  };
};

export const selectForcedEndSession = (state: StoreApi) =>
  state.persistantState.impersonate.forcedEndSession;

/** Rehydrates state: Resets "Standard" dashboard to its original layout  */
export const reHydrateState = createTransform<
  //from
  PeristantStateState,
  //to
  PeristantStateState
>(
  (inboundState) => inboundState,
  (outboundState) => ({
    // reset "Standard" dashboard to its original layout
    ...outboundState,
    dashboard: {
      ...outboundState.dashboard,
      available: {
        ...outboundState.dashboard.available,
        Standard: {
          ...outboundState.dashboard.available["Standard"],
          layouts: outboundState.dashboard.available["Standard"].originalLayouts
        }
      }
    }
  }),
  { whitelist: ["persistantState"] }
);

/**
 * Utility function that append a {newEntry} to {currentEntries}, sort them from
 * newest to oldest and slices the result at {recentSize}
 * @param newEntry
 * @param currentEntires
 * @param recentSize
 */
const appendRecentLocalEntry = (
  newEntry: LocalFile,
  currentEntires: RecentFile[],
  recentSize = 7
) =>
  currentEntires
    .filter((x) => x.fileType === "online" || x.filePath !== newEntry.filePath)
    .concat(newEntry)
    .sort((a, b) => b.lastInteraction - a.lastInteraction)
    .slice(0, recentSize);

const appendRecentOnlineEntry = (
  newEntry: OnlineFile,
  currentEntires: RecentFile[],
  recentSize = 7
) =>
  currentEntires
    .filter((x) => x.fileType === "local" || x.fileId !== newEntry.fileId)
    .concat(newEntry)
    .sort((a, b) => b.lastInteraction - a.lastInteraction)
    .slice(0, recentSize);

//todo: not used yet, maybe remove
const getActiveDashboard = (
  dashboard: PeristantStateState["dashboard"]
): UserDashboard => {
  const { active, available } = dashboard;

  return available[active];
};
