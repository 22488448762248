import React, { useEffect } from "react";
import { Alert, Col, Drawer, List, Row, Space, Typography } from "antd";
import { NormalButtonSmall, PrimaryButton } from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  licenseStoreState,
  openCheckoutModal,
  closeCartDrawer,
  addToCartState,
  removeFromCartState,
  calculateTotalCartPrice
} from "../../state/licenseStoreSlice";
import CurrencyAndPrice from "../MicroComponents/CurrencyAndPrice";
import { getUser } from "../../state/sessionSlice";
import { useGetCompanyDetailsQuery } from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { size } from "../../helpers/pageHelper";
import { insertIf } from "../../utils/generalUtils";

const { Text } = Typography;

const LicenseCartDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeState = useSelector(licenseStoreState);

  // Calculate variables
  const cartState = storeState.cartState;
  const totalPrice = storeState.totalCartPrice;
  const calculateVAT = storeState.totalCartPrice * 0.25;

  // Load User Company from API
  const currentUser = useSelector(getUser);
  const { data: companyData } = useGetCompanyDetailsQuery(
    currentUser.companyId ? { companyId: currentUser.companyId } : skipToken
  );

  // Putting it in a useEffect to prevent infinite loop
  // todo: need more triggers than cartState?
  useEffect(() => {
    dispatch(calculateTotalCartPrice());
  }, [cartState]);

  return (
    <Drawer
      title={t("titleLicenseCart")}
      placement="right"
      open={storeState.isCartDrawerOpen}
      onClose={() => dispatch(closeCartDrawer())}
      closable={true}
      rootStyle={{ marginTop: size.xl2 }}
      zIndex={1040}
      // width={700}
    >
      <List
        header={null}
        bordered
        dataSource={cartState}
        renderItem={(item) => (
          <>
            <List.Item key={item.licensePriceId}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row justify="space-between">
                  <Col>
                    <Space direction="vertical" style={{ gap: 0 }}>
                      <Text strong>{item.name}</Text>
                      <Text>
                        ({item.months} {t("cartMonths")})
                      </Text>
                    </Space>
                  </Col>
                  <Col>
                    <Space
                      direction="vertical"
                      style={{ textAlign: "right", width: "100%", gap: 0 }}
                    >
                      <Text strong>
                        {item.quantity} ×{" "}
                        <CurrencyAndPrice price={item.price * item.months} />
                      </Text>
                      <div>
                        <NormalButtonSmall
                          onClick={() => dispatch(removeFromCartState(item))}
                          style={{ width: 24, marginRight: size.s1 }}
                        >
                          -
                        </NormalButtonSmall>
                        <NormalButtonSmall
                          onClick={() => dispatch(addToCartState(item))}
                        >
                          +
                        </NormalButtonSmall>
                      </div>
                    </Space>
                  </Col>
                </Row>
                {item.companyDiscountPercent > 0 ? (
                  <Row style={{ paddingBottom: size.m2 }}>
                    <Alert
                      style={{ width: "100%" }}
                      message={
                        <>
                          <Row justify="space-between">
                            <Col>
                              <Text>{t("Discount")}:</Text>
                            </Col>
                            <Col>
                              <Text>
                                -{" "}
                                <CurrencyAndPrice
                                  price={
                                    (item.price *
                                      item.quantity *
                                      item.months *
                                      item.companyDiscountPercent) /
                                    100
                                  }
                                />
                              </Text>
                            </Col>
                          </Row>
                        </>
                      }
                    />
                  </Row>
                ) : (
                  <></>
                )}
              </Space>
            </List.Item>
            {/* <Divider style={{ marginTop: 10 }}></Divider> */}
          </>
        )}
        footer={[
          <Row justify="space-between">
            <Col>
              <Text style={{ fontSize: 22 }}>{t("Price")} </Text>
              <Text type="secondary" style={{ fontSize: 16 }}>
                ({t("ExclVAT")})
              </Text>
            </Col>
            <Col>
              <Text style={{ fontWeight: "bold", fontSize: 22 }}>
                <CurrencyAndPrice price={totalPrice} />
              </Text>
            </Col>
          </Row>,
          ...insertIf(
            companyData !== undefined &&
              (companyData.countryCode === "SE" ||
                companyData.vatNumber === ""),
            <Row justify="space-between">
              <Col>
                <Text type="secondary" style={{ fontSize: 16 }}>
                  {t("VAT")}:
                </Text>
              </Col>
              <Col>
                <Text type="secondary" style={{ fontSize: 16 }}>
                  +<CurrencyAndPrice price={calculateVAT} />
                </Text>
              </Col>
            </Row>
          )
        ]}
      />
      <br></br>
      {cartState.length > 0 ? (
        <PrimaryButton
          onClick={() => dispatch(openCheckoutModal())}
          style={{ textAlign: "center", marginBottom: size.xl2 }}
        >
          {t("btnProceedToCheckout")}
        </PrimaryButton>
      ) : (
        <PrimaryButton disabled>{t("btnProceedToCheckout")}</PrimaryButton>
      )}
    </Drawer>
  );
};

export default LicenseCartDrawer;
