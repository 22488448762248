import {
  AimOutlined,
  DashboardOutlined,
  FormOutlined,
  InfoCircleOutlined,
  TeamOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Tabs, notification } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { useProjectByIdQuery } from "../../state/cargologRestApi";
import { PrintButton } from "../Common/CommonButtons";
import {
  ProjectModalTabs,
  closeProjectModal,
  projectsState,
  resetProjectModalTab,
  setProjectModalTab,
  setSelectedProjectId
} from "../../state/projectSlice";
import ProjectExport from "../PrintExport/ProjectExport";
import { PrintableComponent, usePrintHook } from "../PrintExport/hocPrintables";
import NotesTab from "../Projects/NotesTab";
import AlarmsTab from "./AlarmsTab";
import OverviewTab from "./OverviewTab";
import PeopleTab from "./PeopleTab";
import RecordingsTab from "./RecordingsTab";
import StatusTab from "./StatusTab";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const { TabPane } = Tabs;

const tabBarHeight = 62;

const ProjectModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isProjectModalOpen, projectModalTab, selectedProjectId } =
    useSelector(projectsState);

  const { data: project } = useProjectByIdQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  // Detect if the selected project data has been loaded
  const loading = !selectedProjectId || selectedProjectId !== project?.id;

  const onChangeTab = (key: ProjectModalTabs) => {
    dispatch(setProjectModalTab(key));
  };

  const { isPrinting, startPrint, finishPrint } = usePrintHook();
  // const { isExporting, startExport, finishExport } = useExportPdfHook();

  const tabIconStyle: React.CSSProperties = {
    marginRight: size.s2
  };

  const closeModalFunctions = () => (
    dispatch(closeProjectModal()),
    dispatch(resetProjectModalTab()),
    dispatch(setSelectedProjectId(undefined))
  );

  return (
    <>
      {/* {isExporting && (
        <PdfExportComponent
          ComponentBody={ProjectExport}
          props={{ activePeople, project, parameters, gpsPos }}
          type="html"
          name="project_report.pdf"
          header={{
            reportType: "ProjectReport",
            projectName: project?.title ?? "",
          }}
          reportExportDone={finishExport}
        />
      )} */}
      {isPrinting && (
        <PrintableComponent
          WrappedComponent={ProjectExport}
          props={{ project }}
          header={{
            reportType: "ProjectReport",
            projectName: project?.title ?? ""
          }}
          reportPrintDone={finishPrint}
          orientation="portrait"
        />
      )}
      <StandardModal
        title={loading ? t("Loading") : project?.title}
        open={isProjectModalOpen}
        zIndex={1040}
        onCancel={() => closeModalFunctions()}
        closable={true}
        footer={null}
        width={1000}
        extraButtons={[
          <PrintButton onClick={() => startPrint()} disabled={false}>
            {t("Print")}
          </PrintButton>
        ]}
        destroyOnClose={false}
      >
        <Tabs
          defaultActiveKey="overview"
          activeKey={projectModalTab}
          onChange={(key) => onChangeTab(key as ProjectModalTabs)}
          destroyInactiveTabPane={true}
          tabBarStyle={{
            position: "absolute",
            backgroundColor: "white",
            zIndex: 1045,
            // Subtracting modalbody x-padding
            width: "calc(100% - (24px + 24px))",
            marginRight: size.l2,
            marginBottom: 0
          }}
        >
          <TabPane
            tab={
              <>
                <DashboardOutlined style={tabIconStyle} />
                {t("Overview")}
              </>
            }
            key="overview"
            style={{ paddingTop: tabBarHeight }}
          >
            <OverviewTab project={loading ? undefined : project} />
          </TabPane>

          <TabPane
            tab={
              <>
                <WarningOutlined style={tabIconStyle} />
                {t("Alarms")}
              </>
            }
            key="alarms"
            style={{ paddingTop: tabBarHeight }}
          >
            <AlarmsTab />
          </TabPane>

          <TabPane
            tab={
              <>
                <AimOutlined style={tabIconStyle} />
                {t("Recordings")}
              </>
            }
            key="recordings"
            style={{ paddingTop: tabBarHeight }}
          >
            <RecordingsTab project={loading ? undefined : project} />
          </TabPane>

          <TabPane
            tab={
              <>
                <FormOutlined style={tabIconStyle} />
                {t("Notes")}
              </>
            }
            key="notes"
            style={{ paddingTop: tabBarHeight }}
          >
            <NotesTab />
          </TabPane>

          <TabPane
            tab={
              <>
                <TeamOutlined style={tabIconStyle} />
                {t("People")}
              </>
            }
            key="people"
            style={{ paddingTop: tabBarHeight }}
          >
            <PeopleTab project={loading ? undefined : project} />
          </TabPane>

          <TabPane
            tab={
              <>
                <InfoCircleOutlined style={tabIconStyle} />
                {t("ProjectStatus")}
              </>
            }
            key="status"
            style={{ paddingTop: tabBarHeight }}
          >
            <StatusTab project={loading ? undefined : project} />
          </TabPane>
        </Tabs>
      </StandardModal>
    </>
  );
};

export default ProjectModal;
