import React from "react";
import { notification, Typography } from "antd";
import { modalsState, closeRemoveUserModal } from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { cargologRestApi } from "../../state/cargologRestApi";
import StandardModal from "../Common/StandardModal";
const { Text } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

type RemoveUserType = { userId: string };

const RemoveUserModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedUser = modals.setUserToRemove;

  const [removeUser, requestStatus] =
    cargologRestApi.endpoints.deleteUserById.useMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleRemoveUser = async (request: RemoveUserType) => {
    const result: any = await removeUser(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToRemoveUser"),
        description: errorDescription
      });
    } else {
      notification.success({
        message:
          t("SuccessfullyRemovedUser") +
          selectedUser?.firstName +
          " " +
          selectedUser?.lastName
      });
      dispatch(closeRemoveUserModal());
    }
  };

  //Method that triggers when the user is confirming deletion of an user
  const onFinish = () => {
    if (!isUndefined(selectedUser)) {
      const request = { userId: selectedUser.userId };
      handleRemoveUser(request);
    }
  };

  return (
    <>
      <StandardModal
        title={t("DeleteUser")}
        open={modals.isRemoveUserModalOpen}
        onCancel={() => dispatch(closeRemoveUserModal())}
        zIndex={1045}
        closable={true}
        destroyOnClose={true}
        footer={[
          <NormalButton
            htmlType="submit"
            onClick={() => dispatch(closeRemoveUserModal())}
          >
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton
            danger
            htmlType="submit"
            onClick={onFinish}
            loading={requestIsLoading}
          >
            {t("DeleteUser")}
          </PrimaryButton>
        ]}
      >
        {`${t("AreYouSureYouWantToDelete")} ${t("theUser")} `}
        <Text
          strong
        >{`${selectedUser?.firstName} ${selectedUser?.lastName} `}</Text>
        {`(${selectedUser?.email})?`}
      </StandardModal>
    </>
  );
};

export default RemoveUserModal;
