import React from "react";
import { Row } from "antd";
import { useResizeDetector } from "react-resize-detector";
import ParamsBuilder from "../components/ParamsPage/ParamsBuilder";
import ParamsSideMenu from "../components/ParamsPage/ParamsSideMenu";
import { useSelector } from "react-redux";
import { selectParamsSideMenuOpen } from "../state/navigationSlice";
import { isNil } from "lodash-es";

const sidebarWidth = 90;
const fileExplorerWidth = 280;

/** Local function that can be used to determine if the side menu should be
 * shown or hidden.
 * Note: The value 1365 the same used for reports and compare. Should be a constant.
 */
const isSmallScreen = (width: number | undefined) =>
  isNil(width) ? false : width > 1365 - sidebarWidth ? false : true;

const ParamsPage = () => {
  const isParamsSideMenuOpen = useSelector(selectParamsSideMenuOpen);
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth - sidebarWidth;
  const sideMenuExpanded =
    !isSmallScreen(componentWidth) || isParamsSideMenuOpen;
  const contentWidth = isSmallScreen(componentWidth)
    ? componentWidth
    : componentWidth - fileExplorerWidth;

  return (
    <div ref={ref}>
      <Row wrap={false}>
        <ParamsSideMenu
          isExpanded={sideMenuExpanded}
          isFloating={isSmallScreen(componentWidth)}
        />
        <div style={{ width: contentWidth }}>
          <ParamsBuilder contentWidth={contentWidth} />
        </div>
      </Row>
    </div>
  );
};

export default ParamsPage;
