import React, { useState } from "react";
import { Form, Row, Col, Select, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAddInternalUserModal,
  projectsState
} from "../../state/projectSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import {
  useAddProjectUserMutation,
  useGetUsersQuery,
  useProjectByIdQuery
} from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import StandardModal from "../Common/StandardModal";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

const AddPersonModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAddInternalUserModalOpen, selectedProjectId } =
    useSelector(projectsState);

  const { data: project } = useProjectByIdQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  const { data: companyUsers } = useGetUsersQuery();

  const [addProjectUser, requestStatus] = useAddProjectUserMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const [userId, setUserId] = useState<string>();

  const handleAddPerson = async (request: {
    projectId: string;
    userId: string;
  }) => {
    const result: any = await addProjectUser(request);
    if (result.data) {
      dispatch(closeAddInternalUserModal());
    } else {
      notification.error({
        message: t("FailedToAddUser"),
        style: { zIndex: 1050 }
      });
    }
  };

  const onFinish = () => {
    if (
      !isUndefined(project) &&
      !isUndefined(project?.id) &&
      !isUndefined(userId)
    ) {
      const request = {
        userId: userId,
        projectId: project.id
      };
      handleAddPerson(request);
    }
  };

  const usersOptions = companyUsers
    ?.filter(
      (companyUser) =>
        !project?.projectUsers?.find(
          (projectUser) => projectUser.user.userId === companyUser.userId
        )
    )
    .sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`,
        "sv"
      )
    )
    .map((user) => ({
      value: user.userId,
      label: `${user.firstName} ${user.lastName} (${user.email})`
    }));

  const onChange = (value: string) => {
    setUserId(value);
  };

  const onClear = () => {
    setUserId(undefined);
  };

  return (
    <StandardModal
      title={t("AddUser")}
      open={isAddInternalUserModalOpen}
      closable={true}
      destroyOnClose={true}
      zIndex={1045}
      footer={null}
      width={400}
      onCancel={() => dispatch(closeAddInternalUserModal())}
    >
      <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("User")}
              name="user"
              rules={[{ required: true, message: t("UserIsRequired") }]}
            >
              <Select
                style={{ width: "100%", alignContent: "center" }}
                placeholder="Select User"
                optionFilterProp="children"
                onChange={onChange}
                onClear={onClear}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={usersOptions}
                showSearch
                allowClear
                autoFocus
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginBottom: 0 }}>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={requestIsLoading}
          >
            {t("AddUser")}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </StandardModal>
  );
};

export default AddPersonModal;
