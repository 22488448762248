import React from "react";
import { notification, Typography } from "antd";
import {
  modalsState,
  closeRemoveLicensePriceModal
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { cargologRestApi } from "../../state/cargologRestApi";
import { selectLicensePriceState } from "../../state/licensePriceSlice";
import StandardModal from "../Common/StandardModal";
const { Text } = Typography;

notification.config({
  placement: "topRight",
  top: 60,
  duration: 5,
  maxCount: 1
});

type RemoveLicensePriceType = { licensePriceId: string };

interface IProps {}
const RemoveLicensePriceModal: React.FC<IProps> = (props) => {
  const licenseModals = useSelector(selectLicensePriceState);
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedLicensePrice = licenseModals.licensePriceToRemove;

  const [RemoveLicensePrice, requestStatus] =
    cargologRestApi.endpoints.removeLicensePrice.useMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleRemoveLicensePrice = async (request: RemoveLicensePriceType) => {
    const result: any = await RemoveLicensePrice(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToRemoveLicensePrice"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("SuccessfullyRemovedLicensePrice") });
      dispatch(closeRemoveLicensePriceModal());
    }
  };

  const onFinish = () => {
    if (!isUndefined(selectedLicensePrice)) {
      const request = { licensePriceId: selectedLicensePrice.id };
      handleRemoveLicensePrice(request);
    }
  };

  return (
    <>
      <StandardModal
        title={t("DeleteLicense")}
        open={modals.isRemoveLicensePriceModalOpen}
        onCancel={() => dispatch(closeRemoveLicensePriceModal())}
        zIndex={1045}
        closable={true}
        footer={[
          <NormalButton
            htmlType="submit"
            onClick={() => dispatch(closeRemoveLicensePriceModal())}
          >
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton
            danger
            htmlType="submit"
            onClick={onFinish}
            loading={requestIsLoading}
          >
            {t("Delete")}
          </PrimaryButton>
        ]}
      >
        {`${t("AreYouSureYouWantToRemoveThe")} ${t("License").toLowerCase()} `}
        <Text strong>{selectedLicensePrice?.name}</Text>
        {` ${t("fromTheStore")}?`}
      </StandardModal>
    </>
  );
};

export default RemoveLicensePriceModal;
