import React, { useRef } from "react";
import { Row } from "antd";
import ParamsPreviewExtraOptions from "../GraphPage/ParamsPreviewExtraOptions";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import { useDispatch, useSelector } from "react-redux";
import { closeParamsPreviewModal, modalsState } from "../../state/modalsSlice";
import { createUtcOffsetStr, guessLocalTz } from "../../helpers/dateHelper";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import ReportHeader from "../PrintExport/ReportHeader";
import { HeaderData } from "../PrintExport/pdfExport";
import { useTranslation } from "react-i18next";
import { getStartDate, getEndDate } from "../../helpers/exportHelper";
import ParamsPreview from "../ParamsPage/ParamsPreview";
import { isUndefined } from "lodash-es";
import { size } from "../../helpers/pageHelper";
import StandardModal from "../Common/StandardModal";

interface IProps {
  recParams?: VMRecordingParameters;
  systemInfo?: GeneralSystemInfo;
  fileName?: string;
}

const ParamsPreviewModal: React.FC<IProps> = (props) => {
  const recordingParametersPreviewRef = useRef();
  const modals = useSelector(modalsState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { recParams, systemInfo } = props;

  const localTimezone = useSelector(guessLocalTz);
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);
  const timezoneStr = timezoneSelector(
    localTimezone,
    timezoneState,
    timezoneToggle
  );
  const utcOffsetStr = createUtcOffsetStr(timezoneStr);
  const timezone = { timezoneStr, utcOffsetStr };

  if (isUndefined(recParams) || isUndefined(systemInfo)) {
    return <div />;
  }

  const headerData: HeaderData = {
    reportType: "ParametersPreview",
    projectName: recParams.ProjectName,
    recStart: getStartDate(recParams, timezone, t),
    recEnd: getEndDate(recParams, timezone, t),
    deviceId: systemInfo.serial.toString()
  };

  const windowWidth = window.innerWidth;

  return (
    <>
      {/* modals */}
      {/* view parameters modal */}
      <StandardModal
        title={t("Parameters")}
        open={modals.isParamsPreviewModalOpen}
        onCancel={() => dispatch(closeParamsPreviewModal())}
        onOk={() => dispatch(closeParamsPreviewModal())}
        extraButtons={[
          <ParamsPreviewExtraOptions
            recordingParameters={recParams}
            recordingParametersPreviewRef={recordingParametersPreviewRef}
            deviceId={systemInfo.serial.toString()}
            fileName={props.fileName}
          />
        ]}
        footer={null}
        style={{
          top: "72px"
        }}
        styles={{
          content: { padding: 0 },
          body: { overflow: "auto", padding: 0 }
        }}
        width={windowWidth - 48}
        zIndex={1048}
      >
        <Row
          justify="center"
          style={{
            overflowY: "scroll",
            overflowX: "auto",
            height: "calc(100vh - 156px)",
            width: "100%"
          }}
        >
          <div style={{ minWidth: "1050px", padding: size.xl1 }}>
            <ReportHeader header={headerData} isHorizontal={false} />
            <ParamsPreview
              recordingParameters={recParams}
              systemInfo={systemInfo}
            />
          </div>
        </Row>
      </StandardModal>
    </>
  );
};

export default ParamsPreviewModal;
